import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToChildPage } from '../../store/rootReducer';
import menuApi from '../api/menuApi';
import { MenuConstants } from '../definitions';
import { isSystemMenu } from '../menuUtils';
import useMigrateMenusAction from './useMigrateMenusAction';
const useMenuListActions = (showMenuEditorModal) => {
  const { defaultPageId } = MenuConstants;
  const dispatch = useDispatch();
  const {
    add,
    edit,
    remove,
    restore,
    publish,
    requestPending: itemActionRequestPending,
  } = useItemListActions(menuApi, MenuConstants);
  const { migrateMenus, requestPending: migrationRequestPending } = useMigrateMenusAction(
    defaultPageId,
    menuApi
  );
  const addOverride = useMemo(
    () => ({
      ...add,

      onClick: async ({ loaderParams }) => {
        const newMenu = await menuApi.loadRecord('new');
        const savedItem = await showMenuEditorModal(newMenu);
        if (savedItem) {
          dispatch(
            navigateToChildPage([
              MenuConstants.pluginId,
              MenuConstants.editorPageName,
              MenuConstants.defaultPageId,
              loaderParams,
              savedItem.menu_id,
            ])
          );
        }
      },
    }),
    [add, menuApi, dispatch, showMenuEditorModal]
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (context) => isSystemMenu(context),
    }),
    [remove]
  );
  const requestPending = useMemo(
    () => itemActionRequestPending || migrationRequestPending,
    [itemActionRequestPending, migrationRequestPending]
  );
  return useMemo(
    () => ({
      add: addOverride,
      edit,
      remove: removeOverride,
      restore,
      publish,
      migrateMenus,
      requestPending,
    }),
    [addOverride, edit, removeOverride, restore, publish, migrateMenus, requestPending]
  );
};

export default useMenuListActions;

import { QrScannerConstants } from '../definitions';
import { useMemo } from 'react';
import log from '../../logger';
import qrScannerApi from '../api/qrScannerApi';
import { useDispatch } from 'react-redux';
import validateEmails from '../../shared/util/validateEmails';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import { startWatchingJob } from '../../shared/jobs/store';

export default function useQrScannerExports(pageConstants) {
  const dispatch = useDispatch();

  const { defaultPageId } = pageConstants;
  const exportCsv = useMemo(
    () => ({
      label: `Export Scanner Logs`,
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([QrScannerConstants.pluginId, QrScannerConstants.exportPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [QrScannerConstants, defaultPageId, dispatch]
  );
  const createReport = useMemo(
    () => (form, exportOptions) => ({
      label: `Export Logs`,
      onClick: async () => {
        const otherEmails = form.getValues('otherEmails');
        try {
          const emailList = otherEmails
            ? otherEmails.includes(',')
              ? otherEmails.split(',').map((x) => x.trim())
              : [otherEmails.trim()]
            : [];
          if (!validateEmails(emailList)) throw new Error('Invalid email address');

          // The qrScannerApi.createReport call returns the job id
          const {
            job: { id: jobId },
          } = await qrScannerApi.exportScanLog(emailList, exportOptions);
          dispatch(startWatchingJob({ jobId, name: 'Users Report' }));
          dispatch(
            navigateToPluginPage([QrScannerConstants.pluginId, QrScannerConstants.defaultPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [QrScannerConstants, defaultPageId, dispatch]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      variant: 'default',
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([QrScannerConstants.pluginId, QrScannerConstants.defaultPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [QrScannerConstants, defaultPageId, dispatch]
  );

  return {
    exportCsv,
    createReport,
    cancel,
  };
}

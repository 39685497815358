import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/selfregistration/api';
async function purgeUserData(request) {
  try {
    const { data } = await axios.post(
      `${baseUrl}/purgeDeletedUserData`,
      { ...request },
      {
        timeout: 10_000,
      }
    );
    const { appUserId } = data;
    return appUserId;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to purge user data.');
  }
}

export const privacyApi = {
  purgeUserData,
};

import get from 'lodash/get';
import rfc from 'react-fast-compare';
import { useSelector } from 'react-redux';
const reducerName = 'snapshotChecker';

const us = (cb) => useSelector(cb, rfc);
export const selectSnapshotPollKey = () =>
  us((st) => ({
    count: get(st, `${reducerName}.checkCount`),
    key: get(st, `${reducerName}.snapshotPollKey`),
  }));
export const selectCanPublish = () => us((st) => get(st, `${reducerName}.canPublish`, false));

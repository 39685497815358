import React from 'react';
import FormFieldError from './FormFieldError';
import classNames from 'classnames';
import { validationIncludesRequired } from '../formUtils';

const ProvidedSelect = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    selectOptions,
    selectChange,
    ...selectAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const value = formContext.getValues()[fieldName];
  const isRequired = validationIncludesRequired(formFieldOptions);
  const { onChange, ...registerRest } = register(fieldName, formFieldOptions);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      <select
        className="form-control"
        {...registerRest}
        {...selectAttributes}
        defaultValue={value || ''}
        onChange={(e) => {
          onChange(e);
          const i = e.target.options.selectedIndex;
          const value = selectOptions[i - (placeholder ? 1 : 0)];
          selectChange(value);
        }}
      >
        {placeholder && (
          <option key="placeholder" value="" disabled>
            {placeholder}
          </option>
        )}
        {selectOptions &&
          selectOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
      </select>

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default ProvidedSelect;

import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';

export default function useMapsListPageActions(api, constants) {
  const dispatch = useDispatch();
  // const { remove, add, edit } = useItemListActions(api, constants);
  const { typeName, pluginId, editorPageName } = constants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: () => {
        dispatch(navigateToPluginPage([pluginId, editorPageName, 'new']));
      },
    }),
    [typeName, dispatch, pluginId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, editorPageName, context.id]));
      },
    }),
    [dispatch, pluginId, editorPageName]
  );
  const remove = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: false,
      onClick: async ({ id }, { reload }) => {
        await api.removeRecord(id);
        await reload();
      },
    }),
    [api]
  );

  const save = useCallback(
    (item) => {
      item.userGroups = item.groups;
      delete item.groups;
      return api.saveRecord(item);
    },
    [api]
  );

  return { add, edit, remove, save };
}

angular.module('user-api-service', []).service('userApiService', [
  '$q',
  '$log',
  'Upload',
  '$http',
  '$rootScope',
  'configService',
  'pluginService',
  function ($q, $log, Upload, $http, $rootScope, configService, pluginService) {
    let appUserMap = {};
    function getAllUsers(params) {
      const promise = $http.get('/user-api/getAllUsers', { params }).error(function (data) {
        $log.debug('Failed to get all appUsers. Response: ', data);
      });
      return promise;
    }
    function getUsers(start, number, params) {
      let sortPredicate;
      let sortReverse;
      let searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) searchPredicate = searchPredicate.lastName;
      }
      const httpParams = {
        params: {
          start,
          number,
          sortPredicate,
          sortReverse,
          searchPredicate,
        },
      };
      const promise = $http
        .get('/user-api/getUsers', httpParams)
        .success(function (data) {
          rebuildUserMap(data.appUsers);
        })
        .error(function (data) {
          $log.debug('Failed to get all appUsers. Response: ', data);
        });
      return promise;
    }
    function rebuildUserMap(appUsers) {
      appUserMap = {};

      appUsers.forEach((appUser) => {
        appUserMap[appUser.id] = appUser;
      });
    }
    function getUserMetadata(id) {
      return appUserMap[id];
    }
    function saveUser(user) {
      const data = {
        id: user.id,
        accessCode: user.accessCode,
        userType: user.userType,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroups: user.userGroups,
        subtenantId: user.subtenantId,
      };
      const promise = $http.post('/user-api/upsertUser', data);
      promise.then(function (result) {
        appUserMap[user.id] = result.data.appUser;
        broadcastUserUpdated(result.data.appUser);
      });
      return promise;
    }
    function sendInviteEmail(user) {
      const data = {
        id: user.id,
      };
      return $http.post('/user-api/sendInviteEmail', data);
    }
    function removeUser(appUser) {
      const data = {
        id: appUser.id,
      };
      const promise = $http.post('/user-api/deleteUser', data);
      promise.then(function () {
        delete appUserMap[appUser.id];
        broadcastUserUpdated(appUser);
      });
      return promise;
    }
    function getUserGroups() {
      return $http.get('/user-api/getAllGroups').then(function (result) {
        return result.data.groups;
      });
    }
    async function getCohorts() {
      const hasCohorts = await pluginService.pluginExists('cohorts');
      if (hasCohorts) {
        const result = await $http.get('/plugins/cohorts/api/getCohorts?limit=0');
        return result.data.cohorts;
      }
      return undefined;
    }

    function getUsersFromLogic(audienceLogic, start, number, params, limitToPushEndpoints) {
      let searchPredicate;
      if (params) {
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.lastName || searchPredicate.name;
        }
      }
      const data = {
        logic: audienceLogic,
        start,
        number,
        searchPredicate,
        withPushEndpoints: limitToPushEndpoints,
      };
      const promise = $http
        .post('/user-api/getAllUsersFromLogic', data)
        .success(function () {})
        .error(function (data) {
          $log.debug('Failed to get all appUsers from logic. Response: ', data);
        });
      return promise;
    }

    function broadcastUserUpdated(appUser) {
      $rootScope.$broadcast('userUpdated', appUser.id);
    }
    function getUserConfig() {
      return $q
        .all([configService.getConfig(), getUserGroups(), getCohorts()])

        .then(function ([config, userGroups, cohorts]) {
          config.userConfig.userGroups = userGroups;
          config.userConfig.cohorts = cohorts;
          return config.userConfig;
        });
    }
    return {
      removeUser,
      saveUser,
      getUserMetadata,
      getUsers,
      getUserConfig,
      sendInviteEmail,
      getUsersFromLogic,
      getAllUsers,
    };
  },
]);

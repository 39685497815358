import React, { useContext, useEffect, useState } from 'react';
import { getForm } from '../api/formsApi.js';
import CustomPropertyGridWrapper from './components/CustomPropertyGridWrapper';
import CustomToolboxWrapper from './components/CustomToolboxWrapper';
import blockList from '../utils/blockList';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import log from '../../logger.js';
import { FormInstanceConstants } from '../definitions';
import useFormInstanceActions from '../hooks/useFormInstanceActions';
import BackButton from '../../shared/cmsPage/components/BackButton';

import { Serializer, slk, ComponentCollection } from 'survey-core';
import * as SurveyReact from 'survey-react-ui';
import { editorLocalization } from 'survey-creator-core';
import * as SurveyCreator from 'survey-creator-react';

import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';

import './FormBuilder.css';
import { CmsContext } from '../../context/definitions.js';

const baseUrl = '/plugins/forms/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

ComponentCollection.Instance.add({
  name: 'email',
  title: 'Email',
  questionJSON: {
    type: 'text',
    inputType: 'email',
    name: 'email',
    title: 'Email',
  },
  onInit() {
    Serializer.addProperty('email', {
      category: 'general',
      name: 'sendEmail',
      displayName: 'Send Email On Completion',
      type: 'switch',
    });
    Serializer.addProperty('email', {
      category: 'general',
      name: 'subject',
      dependsOn: 'sendEmail',
      displayName: 'Email Subject',
      default: '{{title}}',
      type: 'text',
      visibleIf: function (obj) {
        return obj ? obj.sendEmail : false;
      },
    });
  },
});

const options = {
  haveCommercialLicense: true,
  // showErrorOnFailedSave: true,
  // isAutoSave: true,
  // showInvisibleElementsInTestSurveyTab: true,
  // showInvisibleElementsInPreviewTab: true,
  // showOptions: true,
  // useTabsInElementEditor: true,
  questionTypes: [
    'radiogroup',
    'rating',
    'checkbox',
    'dropdown',
    'boolean',
    'file',
    'imagepicker',
    'ranking',
    'text',
    'comment',
    'html',
    'email',
  ],
  showEmbeddedSurveyTab: false,
  showJSONEditorTab: false,
  showLogicTab: false,
  showTestSurveyTab: true,
  showTranslationTab: false,
};

Serializer.addProperty('question', { category: 'general', name: 'id:text', readOnly: true });

Serializer.addProperty('survey', {
  name: 'multipleSubmissions:switch',
  displayName: 'Allow Multiple Submissions',
  category: 'general',
});
Serializer.addProperty('survey', {
  name: 'submissionLimit:number',
  displayName: 'Submission Limit',
  maxValue: Number.MAX_SAFE_INTEGER,
  minValue: 1,
  default: 2,
  category: 'general',
  visibleIf: function (obj) {
    return obj ? obj.multipleSubmissions : false;
  },
});
Serializer.addProperty('survey', {
  name: 'submissionPrompt:text',
  displayName: 'New Submission Prompt Text',
  dependsOn: 'multipleSubmissions',
  default: 'New Attempt',
  category: 'general',
  visibleIf: function (obj) {
    return obj ? obj.multipleSubmissions : false;
  },
});
Serializer.addProperty('survey', {
  name: 'submissionPastResults',
  displayName: 'Show Past Results',
  type: 'boolean',
  default: false,
  category: 'general',
});
Serializer.addProperty('survey', {
  category: 'general',
  name: 'completionMessage',
  type: 'html',
  default: '<h3>Thank you for completing the survey!</h3>',
});

Serializer.findProperty('question', 'visible').displayName = 'Show question'; // change "is visible?" to "show question"
Serializer.findProperty('question', 'isRequired').displayName = 'Must answer'; // change "is required?" to "must answer"

const creator = new SurveyCreator.SurveyCreator(options);

// when false, removes device simulator in preview tab
creator.showSimulatorInPreviewTab = false;

function allowMarkdown(instance) {
  instance.onTextMarkdown.add(function (survey, options) {
    options.html = options.text;
  });
}

allowMarkdown(creator.survey);

creator.onShowingProperty.add(function (sender, options) {
  if (Object.keys(blockList).includes(options.obj.getType())) {
    options.canShow = !blockList[options.obj.getType()].includes(options.property.name);
  }
});
// change question title 'boolean' to 'True / False'
creator.toolbox.getItemByName('boolean').title = 'True / False';
creator.toolbox.getItemByName('boolean').tooltip = 'True / False';
// match old question type names
creator.toolbox.getItemByName('radiogroup').title = 'Radiogroup';
creator.toolbox.getItemByName('rating').title = 'Rating';
creator.toolbox.getItemByName('checkbox').title = 'Checkbox';
creator.toolbox.getItemByName('file').title = 'File';
creator.toolbox.getItemByName('text').title = 'Single Input';
creator.toolbox.getItemByName('comment').title = 'Comment';

const matrixDynamicCellTypes = Serializer.findProperty('matrixdynamic', 'cellType');
matrixDynamicCellTypes.setChoices(['dropdown', 'radiogroup', 'checkbox', 'boolean']);

// removing delete option from start page and  Disclaimer html
creator.onElementAllowOperations.add((sender, options) => {
  if (options.obj.name === 'Start_Page' || options.obj.name === 'Disclaimer') {
    options.allowDelete = false;
    options.allowEdit = false;
    options.allowChangeType = false;
    options.allowDragging = false;
    options.allowCopy = false;
  }
});

// Add text to collapse button
const collapseAction = creator.sidebar.toolbar.actions.find(
  (action) => action.id === 'svd-grid-hide'
);
collapseAction.showTitle = true;
collapseAction.title = 'Collapse';

//Store property grid survey to use it later
let propertyGridSurvey;
creator.onSurveyInstanceCreated.add((sender, options) => {
  if (options.reason !== 'property-grid') return;
  propertyGridSurvey = options.survey;

  propertyGridSurvey.onCompleting.add((sender) => {
    if (sender.data.completionMessage) sender.completedHtml = sender.data.completionMessge;
  });

  //html in description
  propertyGridSurvey.onTextMarkdown.add(function (survey, options) {
    options.html = options.text;
  });
});

//Property grid survey is setup on after new element is selected
creator.onSelectedElementChanged.add(() => {
  if (!propertyGridSurvey) return;

  // propertyGridSurvey.onUpdatePanelCssClasses.add((sender, options) => {
  //   // .control contains css for dropdown **important**
  //   options.cssClasses.control = { background: "red", fontWeight: 600 };
  // });

  propertyGridSurvey.onCompleting.add((sender) => {
    if (sender.data.completionMessage) sender.completedHtml = sender.data.completionMessge;
  });

  //html in description
  propertyGridSurvey.onTextMarkdown.add(function (survey, options) {
    options.html = options.text;
  });

  //Add new question
  const page = propertyGridSurvey.pages[0];
  const question = page.addNewQuestion('dropdown', 'category', 0);
  question.titleLocation = 'hidden';
  question.showOptionsCaption = false;
  //Set choices based on panels
  const categories = [];
  propertyGridSurvey.getAllPanels().forEach((p) => {
    if (p.elements.some((e) => e.visible))
      // only show panels that are allowed
      categories.push({ text: p.title, value: p.name });
    p.visible = false;
    p.expand();
    p.title = '';
  });
  question.choices = categories;
  question.expand();

  //Show/Hide panels
  propertyGridSurvey.onValueChanged.add((sender, options) => {
    if (options.name !== 'category') return;
    sender.getAllPanels().forEach((p) => {
      const show = question.value === p.name;
      p.visible = show;
      if (show) p.expand();
    });
    options.question.expand();
    options.question.visible = true;
  });
  question.value = categories[0].value;
  propertyGridSurvey = undefined;
});

export default function FormBuilder(params) {
  const { formId } = params;
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const { cmsConfig } = useContext(CmsContext);
  slk(cmsConfig?.surveyLicenseKey);

  const curStrings = editorLocalization.getLocale('');

  curStrings.ed.addNewQuestion = 'Add Question';
  curStrings.ed.surveyPlaceHolder =
    'The form is empty. Drag an element from the toolbox or click the button below.';
  curStrings.ed.saveSurvey = 'Save Form';
  curStrings.ed.saveSurveyTooltip = 'Save Form';
  curStrings.ed.surveyResults = 'Form Results';
  curStrings.ed.surveySettings = 'Form Settings';
  curStrings.ed.surveyTypeName = 'Form';
  curStrings.ed.testSurveyAgain = 'Preview Form Again';
  curStrings.ed.testSurveyWidth = 'Form width: ';

  curStrings.pe.maxTimeToFinish = 'Maximum time to finish';
  curStrings.pe.maxTimeToFinishPage = 'Maximum time to finish a page';
  curStrings.pe.surveyDescriptionPlaceholder = 'Enter a description';
  curStrings.pe.surveyTitlePlaceholder = 'Input title here';
  curStrings.pe.tabs.data = 'Set Correct Answer';

  curStrings.qt.boolean = 'True / False'; // change boolean question type to "True / False"

  useEffect(() => {
    function onLeave(e) {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      if (isModified) e.returnValue = 'Do you really want to leave this page?';
    }

    window.addEventListener('beforeunload', onLeave);

    return () => {
      window.removeEventListener('beforeunload', onLeave);
    };
  }, [isModified]);

  useEffect(() => {
    creator.onUploadFile.add(async (creator, options) => {
      const urls = await Promise.all(
        options.files.map(async (file) => {
          //upload the file to cms and get the S3 url
          const formData = new FormData();
          formData.append('file', file);
          const surveyPath = 'logo';
          const { data } = await axios.post(`${baseUrl}/addFormImage`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { fileName: file.name, fileType: file.type, formId, surveyPath },
            timeout: 10_000,
          });
          return data?.link;
        })
      );
      options.callback('success', urls[0]);
    });

    creator.onModified.add(() => {
      setIsModified(true);
    });

    async function setFormData() {
      try {
        setLoading(true);
        const { form: formData } = await getForm(formId);
        creator.JSON = formData.form;
        if (formData.editable === false) {
          creator.readOnly = true;
          setShowToast(true);
        } else creator.readOnly = false;
      } catch (err) {
        log.error(err);
      } finally {
        setLoading(false);
      }
    }

    setFormData();
  }, [formId]); // eslint-disable-line

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  const setModify = (val) => {
    setIsModified(val);
    return;
  };

  SurveyReact.ReactElementFactory.Instance.registerElement('svc-property-grid', (props) => {
    props.formId = formId;
    props.setModify = setModify;
    props.isModified = isModified;
    return React.createElement(CustomPropertyGridWrapper, props);
  });

  SurveyReact.ReactElementFactory.Instance.registerElement(`svc-adaptive-toolbox`, (props) => {
    return React.createElement(CustomToolboxWrapper, props);
  });

  const { backToForms } = useFormInstanceActions(formId, FormInstanceConstants);

  if (loading) return <div>...loading</div>;

  return (
    <React.Fragment>
      {backToForms && (
        <BackButton handleClick={backToForms.onClick} pageName={backToForms.pageName} />
      )}
      <React.StrictMode>
        <Snackbar open={showToast} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
            This Form may not be edited, as there is at least one completed attempt
          </Alert>
        </Snackbar>
        <SurveyCreator.SurveyCreatorComponent creator={creator} />
      </React.StrictMode>
    </React.Fragment>
  );
}

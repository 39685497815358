(function () {
  angular.module('plugin-wrapper', []).controller('pluginWrapperController', [
    '$state',
    '$transition$',
    '$location',
    '$scope',
    '$log',
    'pluginService',
    function ($state, $transition$, $location, $scope, $log, pluginService) {
      const stateParams = $transition$.params();
      const pluginName = stateParams.pluginName;
      $scope.pageName = stateParams.pageName;
      $scope.params = stateParams.params;
      $scope.splitParams = ($scope.params || '').split(',');
      pluginService
        .getPlugin(pluginName)
        .then(function (plugin) {
          $scope.plugin = plugin;
          $scope.template = plugin.templates[`${$scope.pageName}.html`];
          if (!$scope.template) {
            $log.error(`Template ${$scope.pageName} not found for plugin ${pluginName}`);
            $log.error('Plugin pages found:' + JSON.stringify(Object.keys(plugin.templates)));
            $location.path('/').replace();
          }
        })
        .catch(function (error) {
          $log.error(`Plugin ${pluginName} not found.`, error);
          $location.path('/').replace();
        });
    },
  ]);
})();

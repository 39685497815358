import React from 'react';
import CmsToolbarSection from '../../shared/cmsPage/components/CmsToolbarSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { AchievementConstants, AchievementInstanceConstants } from '../definitions';
import useAchievementActions from '../hooks/useAchievementActions';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const AchievementsListPage = () => {
  const { templateManagerPageId, typeName } = AchievementConstants;
  const { manageFields, manageCerts, add } = useAchievementActions(
    AchievementConstants,
    AchievementInstanceConstants
  );
  const toolbarActions = useArray(manageFields, manageCerts, add);
  return (
    <>
      <BreadcrumbLink
        pluginId={AchievementConstants.pluginId}
        parentPageId={AchievementConstants.defaultPageId}
        parentPageName={AchievementConstants.defaultPageName}
        parentPageTitle={'Achievements'}
      />
      <CmsPage title={'Manage Templates'}>
        <CmsToolbarSection
          pageId={templateManagerPageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
        />
      </CmsPage>
    </>
  );
};

export default AchievementsListPage;

import { Box, Typography } from '@mui/material';
import React from 'react';

const CHANGED = '#5CB85C';
const DEFAULT = '#777777';

export const LineItem = ({ text, value, changed = false, disableColors = false }) => {
  const bgcolor = changed ? CHANGED : DEFAULT;
  return (
    <Box sx={{ display: 'flex', my: '.25rem', alignItems: 'center' }}>
      <Box sx={{ mr: '.5rem', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{text}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '14px',
            ...(!disableColors && { bgcolor, color: '#FFFFFF' }),
            py: '1px',
            px: '9px',
            borderRadius: '8px',
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default function MapStats({ hasZoomChanged, hasCenterChanged, state }) {
  const { center, zoom } = state;
  const lat = center?.lat?.toFixed(6);
  const lng = center?.lng?.toFixed(6);
  return (
    <Box sx={{ my: 1, display: 'flex', flexDirection: 'column' }}>
      <LineItem
        text="Map Center Coordinates:"
        value={`${lat}, ${lng}`}
        changed={hasCenterChanged}
      />
      <LineItem text="Map Zoom (0 - 20):" value={zoom} changed={hasZoomChanged} />
    </Box>
  );
}

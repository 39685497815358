import _, { getDuplicates, uniq } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useSheetModalUpload from '../../shared/cmsPage/hooks/useSheetModalUpload';
import useWrapPageActions from '../../shared/cmsPage/hooks/useWrapPageActions';
import { navigateToChildPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import {
  downloadCurrentCsv,
  getExternalCohortMembers,
  getPotentiallyDeletedCohortGroups,
  importCohortData,
  downloadApiCsv,
} from '../api/cohortApi';
import { CohortConstants } from '../definitions';

export default function useCohortEditorActions({
  cohortId,
  item,
  loadItem,
  loaderParams,
  parentPageTitle,
  pluginId,
  reload,
  reloadPageData,
  showItemEditorModal,
}) {
  const dispatch = useDispatch();
  const editSettings = useMemo(
    () => ({
      label: 'Cohort Settings',
      onClick: async () => {
        const saved = await showItemEditorModal(item);
        if (saved) {
          dispatch(
            successGrowl({
              groupId: CohortConstants.editorPageId,
              props: 'Cohort settings saved successfully',
            })
          );
          await loadItem(cohortId);
        }
      },
      visible: true,
    }),
    [dispatch, showItemEditorModal, cohortId, item]
  );
  const back = useMemo(
    () => ({
      onClick: () => {
        dispatch(
          navigateToChildPage([
            pluginId,
            'default',
            'surveys-list',
            { limit: 25, offset: 0, search: '', sort: { order: 'ASC', sortField: 'title' } },
          ])
        );
      },
      pageName: 'Cohorts',
    }),
    [parentPageTitle, dispatch, pluginId]
  );
  const { SheetModal, open } = useSheetModalUpload({
    onClear: () => {},
    onConfirm: async ({ data }) => {
      let response = '';
      const dupes = getDuplicates(data, 'email', (str = '') => str.trim()).filter(Boolean);
      if (dupes.length > 0) {
        response += `Duplicate emails found:\n ${dupes.join(', ')} 
                    \n Please remove duplicates and try again.\n`;
      }
      const groups = uniq(data.flatMap((row) => _.map(row.groupAssignments, 'key'))).sort();
      const groupsToDelete = await getPotentiallyDeletedCohortGroups(cohortId, groups);
      if (groupsToDelete && groupsToDelete.length > 0) {
        response += `If saved, the following groups will be deleted:\n ${groupsToDelete.join(
          ', '
        )}\n`;
      }
      return response || null;
    },
    onSave: async ({ compressedData }) => {
      await importCohortData(cohortId, compressedData);
      await reload();
    },
    sheetProps: {
      allowEmpty: true,
      fieldValidators: { 'credential code': 'truthy', name: 'truthy', email: 'emailOrEmpty' },
      ignoreNoHeaderColumns: true,
      requiredHeaders: ['credential code', 'name', 'email', 'groups (comma delimited)'],
      uniqueFields: ['credential code', 'email'],
      uniqueHeaders: true,
      mapHeaders: {
        'credential code': 'credentialCode',
        name: 'name',
        email: 'email',
        'groups (comma delimited)': 'groups',
      },
      transformRow: (row) => {
        const groups = (row.groups || '')
          .split(',')
          .map((grp) => grp.trim())
          .filter(Boolean);
        const groupAssignments = groups.map((group) => ({
          group,
          key: `${cohortId}#cohort#${group
            .trim()
            .replaceAll(/[^\dA-Za-z|]/g, '_')
            .toLocaleLowerCase()}`,
        }));
        return { ...row, groups, groupAssignments };
      },
    },
    title: 'Import Members',
  });

  const downloadCohort = useMemo(
    () => ({
      link: true,
      label: 'Download CSV',
      useSpinner: true,
      onClick: async () => {
        await downloadCurrentCsv(cohortId);
      },
    }),
    [cohortId]
  );

  const ingestType = item?.ingestType || 'upload';
  const handleGetExternalMembers = useCallback(
    async ({ reload }) => {
      const { errors = [] } = await getExternalCohortMembers(cohortId);
      const count = errors?.length || 0;
      if (count) {
        dispatch(
          errorGrowl({
            groupId: CohortConstants.editorPageId,
            props: {
              message: `Found ${count} cohort error${
                count > 1 ? 's' : ''
              } - click to download report`,
              csvDownload: {
                data: JSON.stringify(errors),
                filename: 'external-cohort-errors.csv',
              },
            },
          })
        );
      } else {
        await reload();
      }
    },
    [cohortId, dispatch]
  );

  const toolbarButtons = useMemo(() => {
    const buttons = [downloadCohort, editSettings];
    if (ingestType === 'upload' || ingestType === 'both') {
      buttons.push({ label: 'Import Members', onClick: open });
    }
    if (ingestType === 'api' || ingestType === 'both') {
      buttons.splice(1, 0, {
        label: 'Download API Data',
        onClick: async () => {
          await downloadApiCsv(cohortId);
        },

        link: true,
      });
      buttons.push({
        label: 'Retrieve External Members',
        onClick: handleGetExternalMembers,
      });
    }
    return buttons;
  }, [downloadCohort, handleGetExternalMembers, editSettings, open, cohortId, ingestType]);

  const toolbarActions = useWrapPageActions(
    CohortConstants.defaultPageId,
    toolbarButtons,
    reloadPageData,
    loaderParams
  );
  const { pageTitle, onItemChange } = useEditPageTitle(cohortId, 'Cohort', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  return { SheetModal, pageTitle, back, toolbarActions };
}

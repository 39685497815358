import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useVisiblePages } from '../hooks/useVisiblePages';
const MAX_VISIBLE_PAGES = 10;

const PaginationControl = ({ pageCount, currentPage, onPageSelected }) => {
  const pages = useVisiblePages(pageCount, currentPage, MAX_VISIBLE_PAGES);
  const selectPage = useCallback(
    (page) => {
      if (page > 0 && page !== currentPage && page <= pageCount) {
        onPageSelected(page);
      }
    },
    [onPageSelected, currentPage, pageCount]
  );
  return (
    pages.length > 1 && (
      <nav>
        <ul className="pagination no-margin">
          <li>
            <a onClick={() => selectPage(1)}>«</a>
          </li>
          <li>
            <a onClick={() => selectPage(currentPage - 1)}>&#60;</a>
          </li>
          {pages.map((page, index) => (
            <li
              key={index}
              className={classNames({
                active: currentPage === page,
              })}
            >
              <a onClick={() => selectPage(page)}>{page}</a>
            </li>
          ))}
          <li>
            <a onClick={() => selectPage(currentPage + 10)}>...</a>
          </li>
          <li>
            <a onClick={() => selectPage(currentPage + 1)}>&#62;</a>
          </li>
          <li>
            <a onClick={() => selectPage(pageCount)}>»</a>
          </li>
        </ul>
      </nav>
    )
  );
};

export default PaginationControl;

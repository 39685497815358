import { useCallback, useEffect, useState } from 'react';

export default function useAdditionalSearchFieldOptions({ getOptions, options }) {
  const [searchOptions, setSearchOptions] = useState([]);
  const getSearchOptions = useCallback(async () => {
    if (getOptions) {
      const opts = await getOptions();
      setSearchOptions(opts);
    } else if (options && options.length) {
      setSearchOptions(options);
    } else {
      setSearchOptions([]);
    }
  }, [getOptions, options]);

  useEffect(() => {
    getSearchOptions();
  }, [getSearchOptions]);
  return searchOptions;
}

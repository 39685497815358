import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { TemplateItemConstants } from '../definitions';
import useTemplateItemActions from '../hooks/useTemplateItemActions';
import useTemplateItemConfigurations from '../hooks/useTemplateItemConfigurations';

const TemplateItemsListPage = () => {
  const { defaultPageId, typeName } = TemplateItemConstants;
  const { add, archive, archiveCheckbox, edit, includeArchived, refresh } =
    useTemplateItemActions(TemplateItemConstants);
  const { columns, api } = useTemplateItemConfigurations(includeArchived);
  const toolbarActions = useArray(refresh, archiveCheckbox, add);
  const itemActions = useArray(edit, archive);
  return (
    <CmsPage title={'TemplateItems'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default TemplateItemsListPage;

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl, successGrowl } from '../../../store/rootThunks';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { navigateToPluginPage } from '../../../store/rootReducer';
import useConfirmPublish from './useConfirmPublish';
const usePublishActionHandler = (
  pluginId,
  typeName,
  publishPageName,
  returnPageId,
  returnPageName,
  returnPageLabel,
  growlId,
  itemApi
) => {
  const dispatch = useDispatch();
  const confirmPublish = useConfirmPublish(typeName);
  const { publishItem } = useItemPersistence(itemApi);
  return useMemo(() => {
    let publishFn;
    if (publishItem) {
      publishFn = async (itemId, hasItemsToPublish, reload) => {
        if (hasItemsToPublish) {
          dispatch(
            navigateToPluginPage([
              pluginId,
              publishPageName,
              itemId,
              returnPageId,
              returnPageName,
              returnPageLabel,
              growlId,
            ])
          );
        } else {
          const confirmation = await confirmPublish();
          if (confirmation) {
            try {
              await publishItem(itemId);
              dispatch(
                successGrowl({
                  groupId: growlId,
                  props: `The ${typeName} has been successfully published!`,
                })
              );
              await reload();
            } catch (e) {
              dispatch(errorGrowl({ groupId: growlId, props: e.message }));
            }
          }
        }
      };
    }
    return publishFn;
  }, [
    dispatch,
    growlId,
    pluginId,
    publishItem,
    publishPageName,
    returnPageId,
    returnPageLabel,
    returnPageName,
  ]);
};

export default usePublishActionHandler;

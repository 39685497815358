import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import { WorkflowConstants } from '../definitions';
import useWorkflowActions from '../hooks/useWorkflowActions';
import useWorkflowConfigurations from '../hooks/useWorkflowConfigurations';
import useArray from '../../shared/util/hooks/useArray';

const WorkflowListPage = () => {
  const { defaultPageId, typeName } = WorkflowConstants;
  const { logs } = useWorkflowActions(WorkflowConstants);
  const { columns, api } = useWorkflowConfigurations();
  const itemActions = useArray(logs);
  const toolbarActions = useArray();

  return (
    <>
      <CmsPage title={'Create Workflow'}>
        <CmsItemListSection
          pageId={defaultPageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
        />
      </CmsPage>
    </>
  );
};

export default WorkflowListPage;

import React, { useState, useEffect, useCallback, useRef } from 'react';
// Shared Components
import AssetTable from '../components/AssetTable';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUpload, faCode } from '@fortawesome/free-solid-svg-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import UploadFileDialog from '../components/UploadFileDialog';
import AddFileDialog from '../components/AddFileDialog';
import debounce from 'lodash/debounce';
import manageAssetsApi from '../api/manageAssetsApi';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ManageAssetListPage = () => {
  useEffect(() => {
    return () => {
      debouncedSearchUpdate.cancel();
    };
  }, [debouncedSearchUpdate]);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = React.useState(false);
  const [contentShown, setContentShown] = React.useState(false);
  const [showPlugins, setShowPlugins] = React.useState(true);
  const [instance, setInstance] = React.useState('sandbox');
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [publishSuccess, setPublishSuccess] = React.useState(false);
  const [publishError, setPublishError] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [replacingAsset, setReplacingAsset] = useState(null);

  const assetTableRef = useRef();

  const handleFetchAssets = () => {
    assetTableRef.current.fetchAssets();
  };

  const handleFileUploaded = () => {
    handleFetchAssets();
    setFileUploadSuccess(true);
  };

  const handleUploadFile = () => {
    setUploadDialogOpen(true);
  };

  const handleAddFile = () => {
    setAddFileDialogOpen(true);
  };

  const handleClose = () => {
    setPublishSuccess(false);
    setPublishError(false);
    setFileUploadSuccess(false);
  };

  const handleReplaceAsset = (asset) => {
    setReplacingAsset(asset);
    setUploadDialogOpen(true); // Open the UploadFileDialog
  };

  const handleEditAsset = (asset) => {
    setReplacingAsset(asset);
    setAddFileDialogOpen(true); // Open the UploadFileDialog
  };

  const instanceChange = (event) => {
    setInstance(event.target.value);
  };

  const debouncedSearchUpdate = useCallback(
    debounce((value) => {
      setPage(0);
      setDebouncedSearch(value);
    }, 350),
    []
  );

  const searchChanged = (event) => {
    setSearch(event.target.value);
    debouncedSearchUpdate(event.target.value);
  };

  const statusChange = (event) => {
    setContentShown(event.target.value);
  };

  const publishSelected = async () => {
    try {
      await manageAssetsApi.publishContent(selectedRows);
      setPublishSuccess(true);
      setSelectedRows([]);
      handleFetchAssets();
    } catch {
      setPublishError(true);
    }
  };

  const publishAll = async () => {
    try {
      await manageAssetsApi.publishAllContent();
      setPublishSuccess(true);
      setSelectedRows([]);
      handleFetchAssets();
    } catch {
      setPublishError(true);
    }
  };

  const AddAssetMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ textWrap: 'nowrap' }}
          onClick={handleClick}
          endIcon={<FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '14px' }} />}
        >
          Add Asset
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleUploadFile}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUpload} />
            </ListItemIcon>
            <ListItemText primary="Upload File" />
          </MenuItem>
          <MenuItem onClick={handleAddFile}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faCode} />
            </ListItemIcon>
            <ListItemText primary="Add File" />
          </MenuItem>
        </Menu>
        <UploadFileDialog
          onAssetUploaded={handleFileUploaded}
          open={uploadDialogOpen}
          setOpen={setUploadDialogOpen}
          asset={replacingAsset}
        />
        <AddFileDialog
          onAssetUploaded={handleFileUploaded}
          open={addFileDialogOpen}
          setOpen={setAddFileDialogOpen}
          asset={replacingAsset}
        />
      </div>
    );
  };

  return (
    <div className="page-wrap">
      <Snackbar
        open={publishSuccess}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'publishSuccess'}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          Content Published Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={fileUploadSuccess}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'fillUploadSuccess'}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          File Uploaded Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={publishError}
        onClose={handleClose}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'publishError'}
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
          Error Publishing Content
        </Alert>
      </Snackbar>
      <h1 className="page-header">Application Assets</h1>
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            gap: '1.5rem',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <TextField
            label="Search..."
            variant="standard"
            value={search}
            onChange={searchChanged}
            style={{ flex: '10000 1 0%' }}
          />
          <FormControl variant="standard" sx={{ flex: '10000 1 0%' }}>
            <InputLabel id="demo-simple-select-standard-label">Show</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={contentShown}
              onChange={statusChange}
              label="Show"
            >
              <MenuItem value={false}>All Content</MenuItem>
              <MenuItem value={'active'}>Active Content</MenuItem>
              <MenuItem value={'changed'}>Changed Content</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ flex: '10000 1 0%' }}>
            <InputLabel id="instance-select-label">Instance</InputLabel>
            <Select
              labelId="instance-select-label"
              id="instance-select"
              value={instance}
              onChange={instanceChange}
              label="Show"
            >
              <MenuItem value={'sandbox'}>Sandbox</MenuItem>
              <MenuItem value={'live'}>Live</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                size="sm"
                checked={showPlugins}
                onChange={(event) => setShowPlugins(event.target.checked)}
              />
            }
            label="Show Plugin Assets"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '.5rem',
              flex: '10000 1 0%',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={selectedRows.length === 0}
              style={{ textWrap: 'nowrap' }}
              onClick={publishSelected}
            >
              Publish Selected
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ textWrap: 'nowrap' }}
              onClick={publishAll}
            >
              Publish All
            </Button>
            <AddAssetMenu />
          </div>
        </div>
      </div>
      <AssetTable
        ref={assetTableRef}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        search={debouncedSearch}
        instance={instance}
        page={page}
        showPlugins={showPlugins}
        status={contentShown}
        onReplace={handleReplaceAsset}
        onEdit={handleEditAsset}
      />
    </div>
  );
};

export default ManageAssetListPage;

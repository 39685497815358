import classNames from 'classnames';
import get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../../../logger';
import { errorGrowl } from '../../../../store/rootThunks';
import ConditionToText from './components/ConditionToText';
import LinkButton from './components/LinkButton';
import QueryBuilder from './query-builder';
import { cleanLogic } from './store/helpers';
import { setFieldInitState, setLocation, setOldLogic } from './store/index';
import { selectLogic } from './store/selectors';
const AudienceSelector = (props) => {
  const {
    label,
    growlId,
    formContext,
    fieldName,
    className: providedClassName,
    isRequired = false,
    selectFieldText = 'Select',
    selectTextOnNewLine = false,
    requireNew = false,
    exportMode = false,
  } = props;
  const condition = exportMode ? {} : formContext.getValues()[fieldName];
  const dispatch = useDispatch();
  const [isNew, setIsNew] = useState(true);
  useEffect(() => {
    if (fieldName && isNew) {
      dispatch(
        setFieldInitState({
          fieldName,
          logic: condition,
          requireNew,
        })
      );
      setIsNew(false);
    }
  }, [dispatch, isNew, condition, requireNew, fieldName]);
  useEffect(() => {
    if (!isNew) {
      dispatch(
        setOldLogic({
          oldLogic: condition,
          fieldName,
        })
      );
    }
  }, [condition, dispatch, fieldName, isNew]);

  const fieldError = exportMode ? false : get(formContext, `formState.errors.${fieldName}`);

  if (isRequired && !exportMode) {
    formContext.register(fieldName, {
      required: true,
    });
  }

  useEffect(
    () =>
      void dispatch(
        setLocation({
          location: 'modal.requireNew',
          value: requireNew || false,
          fieldName,
        })
      ),
    [requireNew, dispatch, fieldName]
  );
  let logic = selectLogic(fieldName);
  const onSave = useCallback(
    (audience) => {
      try {
        if (audience && !audience._reactName) logic = audience;

        if (logic && !exportMode) {
          const cleanedLogic = cleanLogic(logic);
          formContext.setValue(fieldName, cleanedLogic, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
        if (exportMode) {
          log.info('exportMode', logic);
          return logic;
          /*
          axios.get(`/plugins/reporting/api/getQueriedUsersExport`, {
            logic: JSON.stringify(logic)
          })
            .then(({reportDownloadUrl, status}) => {
              if(status !== 'success') {
                throw new Error('Error downloading report');
              }
              log.info('reportDownloadUrl', reportDownloadUrl);
              return reportDownloadUrl;

            })
            */
        }
      } catch (err) {
        log.error(err);
        dispatch(errorGrowl({ groupId: growlId, props: 'Failed to set target audience.' }));
      }
    },
    [condition, growlId, formContext, dispatch, logic, fieldName, exportMode]
  );
  const LinkBtn = useCallback(
    () => (
      <LinkButton
        selectTextOnNewLine={selectTextOnNewLine}
        isRequired={isRequired}
        selectFieldText={selectFieldText}
        fieldName={fieldName}
      />
    ),
    [selectTextOnNewLine, selectFieldText, isRequired, fieldName]
  );
  return (
    <>
      <div
        className={classNames('form-group', providedClassName, {
          'has-error': !!fieldError,
        })}
      >
        <label>
          {label}
          {!selectTextOnNewLine && <LinkBtn />}
        </label>
        <div
          style={{
            display: 'flex',
          }}
        >
          <ConditionToText condition={condition} />
          {selectTextOnNewLine && <LinkBtn />}
        </div>
      </div>
      <QueryBuilder fieldName={fieldName} oldLogic={condition} onSave={onSave} />
    </>
  );
};

export default AudienceSelector;

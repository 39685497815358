require('froala-pkgd');

require('c8s-froala-plugins/js/anchor_select');
require('c8s-froala-plugins/js/attribute');
require('c8s-froala-plugins/js/collapse');
require('c8s-froala-plugins/js/collapse_section');
require('c8s-froala-plugins/js/font_size_em');
require('c8s-froala-plugins/js/groupButton');
require('c8s-froala-plugins/js/inline_attribute');
require('c8s-froala-plugins/js/paragraph_attribute');
require('c8s-froala-plugins/js/streamingVideo');
require('c8s-froala-plugins/js/table');
require('c8s-froala-plugins/js/video');

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLocation } from '../../store';
import { selectNewAudienceName } from '../../store/selectors';

const AudienceName = ({ fieldName }) => {
  const dispatch = useDispatch();
  const newAudienceName = selectNewAudienceName(fieldName);
  const handleChange = useCallback(
    (e) => {
      dispatch(
        setLocation({
          location: 'newAudienceName',
          value: e.target.value,
          fieldName,
        })
      );
    },
    [dispatch, fieldName]
  );
  return (
    <div>
      Enter a name for the Audience. You can view or re-define this Audience by selecting Audiences
      in the left panel.
      <div>
        <label>Audience Name</label>
        <input value={newAudienceName} placeholder="New Employees" onChange={handleChange} />
      </div>
    </div>
  );
};

export default AudienceName;

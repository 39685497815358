import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'push';
export const PushNotificationConstants = createPageConstants(pluginId, 'PushNotification', {
  defaultDefault: 'PushNotificationsListPage',
  editorDefault: 'pushNotificationEditorPage',
  editor: 'pushNotificationEditorPage',
});
export const EMPTY_PUSH_NOTIFICATION = {
  label: '',
  name: '',
  isArchived: false,
  condition: undefined,
};

import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import React, { useEffect } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import FormDateOnly from '../../shared/form/components/FormDateOnly';
import FormInput from '../../shared/form/components/FormInput';
import MultiSelect from '../../shared/form/components/MultiSelect';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import attendanceApi, { getAttendanceGroups } from '../api/attendanceApi';
import { AttendanceEventConstants } from '../definitions';
export default function AttendanceEventEditorPage({ attendanceEventId = 'new' }) {
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    attendanceApi,
    attendanceEventId
  );
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, AttendanceEventConstants, attendanceEventId);
  useEffect(() => {
    if (attendanceEventId) (async () => await loadItem(attendanceEventId))();
  }, [attendanceEventId, loadItem]);

  const { pageTitle, onItemChange } = useEditPageTitle(
    attendanceEventId,
    'Attendance Event',
    'name'
  );
  useEffect(() => onItemChange(item), [item, onItemChange]);

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-editor">
        <div className="row">
          <BackButton
            pageName="Attendance Tracking Events"
            navToParent
            navParams={[
              AttendanceEventConstants.pluginId,
              'default',
              AttendanceEventConstants.pageTitle,
            ]}
          />
        </div>
        <GrowlContainer groupId={AttendanceEventConstants.editorPageId} />
        <div className="row ">
          <form role="form" className="no-left-padding">
            <FormInput
              fieldWidth="normal"
              label="Event Name"
              fieldName="eventName"
              formContext={form}
              formFieldOptions={{ required: 'This field is required.' }}
            />
            <FormDateOnly
              label={`Date of Event`}
              fieldWidth="normal"
              fieldName="date"
              formContext={form}
            />
            <MultiSelect
              fieldName="userGroups"
              fieldWidth="normal"
              formContext={form}
              formFieldOptions={{ required: 'This field is required.' }}
              label="Cohort Groups"
              labelKey="name"
              limitTags={20}
              optionsLoader={getAttendanceGroups}
              placeholder="Select Groups"
              sortTagsKey="name"
              filterFunction={(opt, selected = []) => {
                if (selected?.length === 0) return true;
                const cohortId = selected[0]?.key?.split('#')[0];
                return opt?.key?.includes(cohortId);
              }}
              isOptionEqualToValue={(opt, value) => {
                if (has(opt, 'id')) {
                  return opt.id === value.id;
                } else if (has(opt, 'key')) {
                  return opt.key === value.key;
                }
                return isEqual(opt, value);
              }}
            />
          </form>
        </div>
        <div className="col col-lg-12 col-md-12 col-sm-12">
          <div className="row module-footer">
            <CmsPageSectionFooter actions={actions} />
          </div>
        </div>
      </CmsPageSection>
    </CmsPage>
  );
}

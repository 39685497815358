import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'custom-pages';
export const CustomPageConstants = createPageConstants(
  pluginId,
  'CustomPage',
  { defaultDefault: 'customPageList', editor: 'customPageEditor' },
  'Custom Pages'
);

export const EMPTY_CUSTOM_PAGE_REVISION = { file: undefined, name: '' };

import React, { useEffect } from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { TemplateItemConstants } from '../definitions';
import TemplateItemEditor from './TemplateItemsEditor';
import generateTemplateItemsApi from '../api/templateItemsApi';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';

const TemplateItemEditorPage = (props) => {
  const { templateItemId } = props;
  const { editorPageId, typeName } = TemplateItemConstants;
  const { pageTitle } = useEditPageTitle(templateItemId, typeName, 'name');

  const api = generateTemplateItemsApi();
  const { item, loadItem, saveItem } = useItemPersistence(api);
  useEffect(() => {
    if (templateItemId) (async () => await loadItem(templateItemId))();
  }, [templateItemId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, TemplateItemConstants);

  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <TemplateItemEditor
          form={form}
          growlId={TemplateItemConstants.editorPageName}
          actions={actions}
        />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default TemplateItemEditorPage;

import React, { useState, useEffect } from 'react';
import qrScannerApi from '../api/qrScannerApi';
import { errorGrowl } from '../../store/rootThunks';
import log from '../../logger';
import { QrScannerConstants } from '../definitions';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';

export default function ScanLog() {
  const [allScanners, setAllScanners] = useState([]);
  const [exportOptions, setExportOptions] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [today] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchScanners() {
      try {
        const { records } = await qrScannerApi.getScanners(0, undefined, {
          sort: {
            predicate: 'name',
          },
          search: {},
        });
        setAllScanners(records);
        setExportOptions({ ...exportOptions, scannerId: records[0].id });
      } catch (error) {
        log.error('Failed to fetch scanners.', error);
      }
    }

    fetchScanners();
  }, []);

  async function handleExportScans(event) {
    event.preventDefault();

    if (!exportOptions.scannerId) {
      errorGrowl({ message: 'No scanner specified', type: 'error' });
      return;
    }
    if (!exportOptions.startDate) {
      errorGrowl({ message: 'No Start Date Specified', type: 'error' });
      return;
    }
    if (!exportOptions.endDate) {
      errorGrowl({ message: 'No End Date Specified', type: 'error' });
      return;
    }
    if (exportOptions.endDate < exportOptions.startDate) {
      errorGrowl({ message: 'End Date must be later than Start Date', type: 'error' });
      return;
    }

    try {
      dispatch(
        navigateToPluginPage([
          QrScannerConstants.pluginId,
          QrScannerConstants.exportPageName,
          JSON.stringify(exportOptions),
        ])
      );
    } catch (error) {
      log.error('Failed to export scan log.', error);
      errorGrowl({ message: 'Failed to export scan log.', type: 'error' });
    }
  }

  return (
    <div>
      <div className="header row">
        <h1 className="page-title">QR Scanner Logs</h1>
      </div>

      <div className="module red row">
        <div className="module-header">
          <span className="module-title subtitle"> Export Scan Log </span>
        </div>
        <div className="col-xs-12">
          <div className="module-body">
            <form name="exportScanLogForm" onSubmit={handleExportScans}>
              <div className="row border-top border-bottom content">
                <div className="col-sm-3 no-left-padding">
                  <label>Scanner</label>
                  <select
                    className="form-control"
                    value={exportOptions.scannerId}
                    onChange={(e) =>
                      setExportOptions({ ...exportOptions, scannerId: e.target.value })
                    }
                  >
                    {allScanners.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-4">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={exportOptions.startDate}
                    max={today.toISOString().split('T')[0]}
                    onChange={(e) =>
                      setExportOptions({ ...exportOptions, startDate: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-4">
                  <label>End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={exportOptions.endDate}
                    max={today.toISOString().split('T')[0]}
                    onChange={(e) =>
                      setExportOptions({ ...exportOptions, endDate: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-1 text-right" style={{ paddingTop: '1.5em' }}>
                  <button type="submit" className="btn">
                    Export
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

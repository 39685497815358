import React, { useCallback, useState } from 'react';
import Select from '../../shared/form/components/Select';
import menuItemApi from '../api/menuItemApi';

const IconSelector = (props) => {
  const { form, label, required, fieldName } = props;
  const [showSelector, setShowSelector] = useState(true);
  const menuIconsLoader = useCallback(async () => {
    const iconAssets = await menuItemApi.loadMenuIcons();

    if (iconAssets) {
      return iconAssets.map((asset) => ({
        label: asset.id,
        value: asset.id,
      }));
    }
    setShowSelector(false);
    return [
      {
        label: 'no-icon.svg',
        value: 'no-icon.svg',
      },
    ];
  }, [menuItemApi]);
  const fieldOptions = required
    ? {
        required: 'This field is required.',
      }
    : undefined;

  if (showSelector) {
    return (
      <Select
        label={label}
        fieldName={fieldName}
        fieldWidth="short"
        formContext={form}
        formFieldOptions={fieldOptions}
        optionsLoader={menuIconsLoader}
        placeholder="Select an icon"
      />
    );
  }
  return <></>;
};

export default IconSelector;

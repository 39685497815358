import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import audienceSelector from '../shared/form/components/AudienceSelector/store/index';
import jobs from '../shared/jobs/store/index';
import snapshotChecker from '../shared/snapshotChecker/store/index';
import { reducer as root } from './rootReducer';
const reducers = { audienceSelector, jobs, root, snapshotChecker };
const apis = [];
const store = configureStore({
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    ...apis.map((api) => api.middleware),
  ],
  reducer: {
    ...reducers,
    ...apis.reduce((a, api) => ({ ...a, [api.reducerPath]: api.reducer }), {}),
  },
});
if (apis.length) {
  setupListeners(store.dispatch);
}
export default store;

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/registrations/api';

const loadRecord = async (id) => {
  if (id === 'new') return { title: '', userGroups: [], id: 'new' }; // Assuming this is your EMPTY_MEET equivalent

  try {
    const { data } = await axios.get(`${baseUrl}/getRegistration`, { params: { id } });
    return data.registration;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load registration.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteRegistration`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove registration.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getAllRegistrations`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: rows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load registrations');
  }
};

const saveRecord = async (registration) => {
  const url =
    !registration.id || registration.id === 'new'
      ? `${baseUrl}/createRegistration`
      : `${baseUrl}/updateRegistration`;
  try {
    const { data } = await axios.post(url, registration);
    return data.registration;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save registration.');
  }
};

export const updateRegistrationGroupsVisibility = async (registrationId, shouldHide) => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateRegistrationGroupsVisibility`, {
      registrationId,
      shouldHide,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save registration.');
  }
};

const registrationsApi = {
  loadRecord,
  loadRecords,
  saveRecord,
  updateRegistrationGroupsVisibility,
  removeRecord,
};

export default registrationsApi;

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const theme = createTheme({
  palette: {
    bootstrap: {
      blue: '#345784',
      green: '#155724',
      red: '#a94442',
      yellow: '#ffd90b',
      grey: '#777',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

import { useCallback, useEffect } from 'react';
import { selectNav } from '../../rootSelectors';
export default function useNavigation(navigateToState) {
  const { pluginName, pageName, args, key } = selectNav();
  const navigate = useCallback(() => {
    if (pluginName) {
      navigateToState('main.plugin', {
        pluginName,
        pageName,
        params: args,
      });
    }
  }, [navigateToState, pluginName, pageName, args]);
  useEffect(() => {
    navigate();
  }, [key]);
}

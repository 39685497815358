import { EMPTY_TARGET_AUDIENCE_REVISION } from '../definitions';
import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/target-audience/api';
const loadRecordsWrapped =
  (hideArchived = true) =>
  async (params) => {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    let result;
    try {
      const { data } = await axios.get(`${baseUrl}/getTargetAudiences`, {
        params: { ...otherParams, includeArchived: !hideArchived, sortField, sortReverse },
        timeout: 10_000,
      });
      const { targetAudiencesWithStatus, count, offset } = data;
      result = {
        records: targetAudiencesWithStatus,
        numRecords: count,
        offset,
      };
    } catch (e) {
      log.error(e);
      throw new Error('Failed to load targetAudiences');
    }
    return result;
  };

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_TARGET_AUDIENCE_REVISION;
  try {
    const { data } = await axios.get(`${baseUrl}/getTargetAudience`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    let parsedCondition;
    try {
      parsedCondition = data && data.targetAudience && JSON.parse(data.targetAudience.condition);
      if (parsedCondition) data.targetAudience.condition = parsedCondition;
    } catch {
      parsedCondition = '';
    }
    return data.targetAudience;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load targetAudience.');
  }
};

const saveRecord = async (item) => {
  try {
    //eslint-disable-next-line
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveTargetAudience`,
      { ...revisionProps, id },
      {
        timeout: 10_000,
      }
    );
    return data.targetAudience;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const archiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateTargetAudienceOptions`,
      {
        isArchived: true,
        id,
      },
      {
        timeout: 10_000,
      }
    );
    return data.targetAudience;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const unarchiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateTargetAudienceOptions`,
      {
        isArchived: false,
        id,
      },
      {
        timeout: 10_000,
      }
    );
    return data.targetAudience;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeTargetAudience`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove targetAudience.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreTargetAudience`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to restore targetAudience.');
  }
};

const publishRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishTargetAudience`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
    return data.targetAudience;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish targetAudience.');
  }
};

const targetAudienceApiWrapped = (hideArchived) => {
  const targetAudienceApi = {
    loadRecords: loadRecordsWrapped(hideArchived),
    loadRecord,
    saveRecord,
    removeRecord,
    restoreRecord,
    publishRecord,
    archiveRecord,
    unarchiveRecord,
  };
  return targetAudienceApi;
};

export default targetAudienceApiWrapped;

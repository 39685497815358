import React, { useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import {
  navigateToChildPage,
  navigateToParentPage,
  navigateToPluginPage,
} from '../../../store/rootReducer';
const BackButton = ({ pageName, handleClick, navParams, navToParent = false, navToChild }) => {
  const dispatch = useDispatch();
  const handleOnClick = useCallback(() => {
    if (handleClick) {
      return handleClick();
    } else if (navParams) {
      if (navToParent) {
        dispatch(navigateToParentPage(navParams));
      } else if (navToChild) {
        dispatch(navigateToChildPage(navParams));
      } else {
        dispatch(navigateToPluginPage(navParams));
      }
    } else {
      return undefined;
    }
  }, [handleClick, navToParent, navParams, navToChild, dispatch]);
  const text = useMemo(() => `< Back ${pageName ? `to ${pageName}` : ''}`, [pageName]);
  return handleOnClick ? (
    <div style={{ marginTop: '.75rem' }}>
      <Button bsPrefix="btn-link" variant="link" onClick={handleOnClick}>
        {text}
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default BackButton;

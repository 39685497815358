import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { createFilter } from 'react-select';
import Creatable from 'react-select/async-creatable';
import Spinner from '../../spinner/components/Spinner';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import { validationIncludesRequired } from '../formUtils';
export default function CreatableSelect(props) {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    options,
    optionsLoader,
    isClearable,
  } = props;
  const { formState, register, getValues, setValue } = formContext;
  const { errors } = formState;
  const formValue = getValues()[fieldName];
  let formLabel;

  if (formValue) {
    formLabel = formValue.charAt(0).toUpperCase() + formValue.slice(1);
  }

  const handleChange = useCallback(
    (newValue, actionMeta) => {
      const { action } = actionMeta;

      if (['clear', 'select-option', 'create-option', 'input-change'].includes(action)) {
        setValue(fieldName, action === 'clear' ? null : newValue.value, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [fieldName, setValue]
  );
  const handleOnInputChange = useCallback(
    (inputValue, actionMeta) => {
      const { action } = actionMeta;

      if (['set-value', 'input-change'].includes(action)) {
        setValue(fieldName, inputValue, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [setValue, fieldName]
  );
  const { ref, onBlur } = register(fieldName, formFieldOptions);
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);
  const customStyles = useMemo(
    () => ({
      control: (provided) => ({ ...provided, border: '1px solid #00000080 !important' }),
    }),
    []
  );
  const filterOption = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
      }),
    []
  );
  const {
    requestPending,
    result: selectOptions = [],
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);
  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);
  const loadOptions = useCallback(async () => {
    return selectOptions || [];
  }, [selectOptions]);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, 'creatable', {
        'has-error': !!errors[fieldName],
      })}
    >
      {requestPending ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <label>
            {label}
            {isRequired && <span className="required-annotation">*</span>}
          </label>

          <Creatable
            defaultOptions
            isSearchable
            isClearable={isClearable}
            filterOption={filterOption}
            value={{
              label: formLabel,
              value: formValue,
            }}
            loadOptions={loadOptions}
            onBlur={onBlur}
            onChange={handleChange}
            onInputChange={handleOnInputChange}
            placeholder={placeholder}
            ref={ref}
            styles={customStyles}
          />
        </>
      )}
    </div>
  );
}

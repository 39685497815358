import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/forms/api';

const loadRecordsWrapper = (formId) => async (params) => {
  const {
    sort: { sortField, order },
    ...otherParams
  } = params;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getFormInstances`, {
      params: {
        ...otherParams,
        formId,
        sortField,
        sortReverse: sortField ? order === 'DESC' : undefined,
      },
      timeout: 10_000,
    });
    const { formInstances, count, offset } = data;
    result = {
      records: formInstances,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load form instances');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return {
      name: '',
    };
  }
  try {
    const {
      data: { formInstance },
    } = await axios.get(`${baseUrl}/getFormInstance`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return formInstance;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load formInstance.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeFormInstance`, {
      id,
      timeout: 10_000,
    });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove form instance.');
  }
};

const saveRecordWrapper = (formId) => async (item) => {
  try {
    const {
      data: { formInstance },
    } = await axios.post(`${baseUrl}/saveFormInstance`, { ...item, formId, timeout: 10_000 });
    return formInstance;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save form instance.');
  }
};

function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const getReportStatusWrapper = (formId) => async (formInstanceId) => {
  try {
    const {
      data: { status, link },
    } = await axios.get(`${baseUrl}/getInstanceReportStatus`, {
      params: { formInstanceId, formId },
      timeout: 10_000,
    });
    if (status === 'success') {
      return link;
    }
    await sleep(5000);
    return getReportStatusWrapper(formId)(formInstanceId);
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save form instance.');
  }
};

const generateFormInstanceApi = (formId) => {
  const formInstanceApi = {
    loadRecords: loadRecordsWrapper(formId),
    loadRecord,
    removeRecord,
    saveRecord: saveRecordWrapper(formId),
    getReportStatus: getReportStatusWrapper(formId),
  };
  return formInstanceApi;
};

export default generateFormInstanceApi;

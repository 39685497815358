import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
const CollectionItemTitle = ({ collectionItem, collectionId }) => {
  const dispatch = useDispatch();
  const navigateToChild = useCallback(() => {
    dispatch(
      navigateToPluginPage(['collections', 'collectionItemEditor', collectionId, collectionItem.id])
    );
  }, [dispatch, collectionItem]);
  return (
    <>
      {collectionItem.status === 'removed' ? (
        <span>{collectionItem?.label}</span>
      ) : (
        <a onClick={navigateToChild}>{collectionItem?.label}</a>
      )}
    </>
  );
};

export default CollectionItemTitle;

import React, { useState } from 'react';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import NumberedPageHeader from '../../shared/form/components/NumberedPageHeader';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import sessionApi from '../api/sessionApi';
import { ItrsCourseConstants } from '../definitions';
import AttendeeDetailForm from './AttendeeDetailForm';
import ResourceRequirementForm from './ResourceRequirementForm';
import SessionDetailForm from './SessionDetailForm';

const PAGES = ['Session Details', 'Attendee Details', 'Resource Requirements'];
const FIELDS = [
  [
    'status',
    'startDate',
    'endDate',
    'cutoffDays',
    'name',
    'billingCategory',
    'description',
    'trainingLocation',
    'category',
    'program',
  ],
  ['student', 'instructor', 'assistant'].flatMap((k) => [`${k}Min`, `${k}Max`]),
  ['contact', 'equipment', 'addNeeds', 'instrNeeds', 'roomReservations'],
];
const { pluginId, defaultPageName, defaultPageId } = ItrsCourseConstants;
const ItrsSessionCreatePage = ({ sessionId, courseId }) => {
  const { item, saveItem, loadItem } = useItemPersistence(sessionApi);
  const form = useCmsForm(item);
  useLoadEditorItem(sessionId, loadItem, () => {}, item, ItrsCourseConstants);
  const { setValue, trigger, register } = form;
  const [saveNoValidation, cancel] = useItemEditActions(saveItem, form, ItrsCourseConstants);
  const [idx, setIdx] = useState(0);
  const back = { onClick: () => setIdx((p) => p - 1), label: 'Back' };
  const next = {
    onClick: async () => (await trigger(FIELDS[idx])) && setIdx((p) => p + 1),
    label: 'Continue',
  };

  const save = {
    ...saveNoValidation,
    onClick: async () => {
      setValue('courseId', courseId);
      const isValid = await trigger(FIELDS[idx]);
      if (isValid && saveNoValidation.onClick) {
        await saveNoValidation.onClick();
      }
    },
  };
  const actions = [idx === 2 ? save : next, idx === 0 ? cancel : back];
  const page = PAGES[idx];
  return (
    <>
      <BreadcrumbLink
        pluginId={pluginId}
        parentPageId={defaultPageId}
        parentPageName={defaultPageName}
        parentPageTitle={'Courses'}
      />
      <CmsPage
        title={`${sessionId && sessionId !== 'new' ? 'Update' : 'Add'} Session - Course Name`}
      >
        <NumberedPageHeader headers={PAGES} selectedHeader={page} />
        <form>
          <input type="hidden" value={courseId} {...register('courseId')} />
          {idx === 0 && <SessionDetailForm form={form} />}
          {idx === 1 && <AttendeeDetailForm form={form} />}
          {idx === 2 && <ResourceRequirementForm form={form} />}
        </form>

        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ItrsSessionCreatePage;

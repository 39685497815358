import React, { useState, useEffect } from 'react';
import CmsToolbarSection from '../../shared/cmsPage/components/CmsToolbarSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { AchievementConstants, AchievementInstanceConstants } from '../definitions';
import useAchievementActions from '../hooks/useAchievementActions';
import Certificates from './service/Certificates';
import { getCertificates, getFields } from '../api/service';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const AchievementsCertEditorPage = () => {
  const [certificates, setCertificates] = useState([]);
  const [fields, setFields] = useState([]);
  const { fieldEditorPageId, typeName } = AchievementConstants;
  const { manageFields, manageCerts, add } = useAchievementActions(
    AchievementConstants,
    AchievementInstanceConstants
  );
  const toolbarActions = useArray(manageFields, manageCerts, add);

  useEffect(() => {
    handleGetCertificates();
    handleGetFields();
  }, []);

  const handleGetCertificates = async () => {
    const resp = await getCertificates();
    setCertificates(resp);
  };

  const handleGetFields = async () => {
    const resp = await getFields();
    setFields(resp);
  };
  return (
    <>
      <BreadcrumbLink
        pluginId={AchievementConstants.pluginId}
        parentPageId={AchievementConstants.defaultPageId}
        parentPageName={AchievementConstants.defaultPageName}
        parentPageTitle={'Achievements'}
      />
      <CmsPage title={'Edit Certificates'}>
        <CmsToolbarSection
          pageId={fieldEditorPageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
        />
        <Certificates
          fields={fields}
          certificates={certificates}
          handleGetCertificates={handleGetCertificates}
        />
      </CmsPage>
    </>
  );
};

export default AchievementsCertEditorPage;

(function () {
  angular.module('view-group', ['smart-table']).controller('ViewGroupCtrl', [
    '$scope',
    '$location',
    'growl',
    'groupApiService',
    function ($scope, $location, growl, groupApiService) {
      const urlParams = $location.search();
      $scope.groupName = urlParams.name;
      $scope.isLoading = true;
      function toTitleCase(str) {
        return (
          str &&
          str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        );
      }
      groupApiService.getUsersFromGroup(urlParams.id).then(function (result) {
        $scope.groups = result.data.data;
        $scope.displayedUsers = [$scope.groups].flat();
        $scope.isLoading = false;
        $scope.hasCustomFields = $scope.displayedUsers.some(function (user) {
          return user.customFields && Object.keys(user.customFields).length;
        });
        $scope.fieldHeaders = $scope.hasCustomFields
          ? $scope.displayedUsers
              .flatMap((u) => u.customFields && Object.keys(u.customFields))
              .filter((v, i, a) => v && a.indexOf(v) === i)
          : [];
        $scope.customFieldHeaders = $scope.fieldHeaders.map(toTitleCase);
      }),
        function () {
          growl.error('Failed to view group');
        };
      $scope.cancel = function () {
        $location.path('/main/manage-groups').search({});
      };
      function getFileName() {
        const now = new Date();
        const datetime = `${now.getFullYear()}_${
          now.getMonth() + 1
        }_${now.getDate()}__${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        return `${$scope.groupName}-${datetime}.csv`;
      }
      $scope.buildExportCsv = function () {
        //check for custom fields
        groupApiService.getUsersFromGroup(urlParams.id).then(function (result) {
          const customFieldHeaders = $scope.customFieldHeaders || [];
          const groupUsers = [result.data.data].flat();
          const headers = ['First Name', 'Last Name', 'Email'].concat(customFieldHeaders);
          let csv = `${headers.join(',')}\n`;
          groupUsers.forEach((user) => {
            const row = [
              user.firstName,
              user.lastName,
              user.email,
              ...customFieldHeaders.map(
                (field) => (user.customFields && user.customFields[field.toLowerCase()]) || ''
              ),
            ];
            csv += `${row.join(',')}\n`;
          });
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const csvUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = csvUrl;
          a.download = getFileName();
          document.body.append(a);
          a.click();
          window.URL.revokeObjectURL(csvUrl);
        });
      };
    },
  ]);
})();

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/workflow/api';

const loadRecord = async (id) => {
  if (id === 'new')
    return { context: '', name: '', id: 'new', steps: [{ label: 'Step 1', value: 'step1' }] }; // Assuming this is your EMPTY_MEET equivalent

  try {
    const { data } = await axios.get(`${baseUrl}/getWorkflow`, { params: { id } });
    return data.workflow;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load workflow.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteWorkflow`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove workflow.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { workflows, count },
    } = await axios.get(`${baseUrl}/getAllWorkflows`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: workflows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load workflow');
  }
};

const loadLogs = async (params = {}) => {
  try {
    const { sort = {}, filterArray = [], ...otherParams } = params;
    const { sortField = 'id', order = 'ASC' } = sort || {};
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { logs, count },
    } = await axios.get(`${baseUrl}/getWorkflowLogs`, {
      params: { ...otherParams, sortField, sortReverse, filterArray },
      timeout: 10_000,
    });
    return {
      records: logs,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load workflow');
  }
};

const workflowApi = {
  loadRecord,
  loadRecords,
  removeRecord,
  loadLogs,
};

export const runMachine = async (machineId = 'StartSequence') => {
  try {
    await axios.post(`${baseUrl}/runStateMachine`, { machineId });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to run machine.');
  }
};

export default workflowApi;

const blockList = {
  survey: [
    "mode",
    "cookieName",
    "widthMode",
    "logo",
    "logoWidth",
    "logoHeight",
    "logoFit",
    "showPreviewBeforeComplete",
    "pagePrevText",
    "pageNextText",
    "completeText",
    "showCompletedPage",
    "completedHtml",
    "previewText",
    "editText",
    "startSurveyText",
    "showNavigationButtons",
    "showPrevButton",
    "firstPageIsStarted",
    "goNextPageAutomatic",
    "showProgressBar",
    "progressBarType",
    "questionsOnPageMode",
    "questionTitleLocation",
    "questionDescriptionLocation",
    "showQuestionNumbers",
    "questionTitlePattern",
    "requiredText",
    "questionStartIndex",
    "questionErrorLocation",
    "focusFirstQuestionAutomatic",
    "questionsOrder",
    "maxTextLength",
    "maxOthersLength",
    "autoGrowComment",
    "showPageTitles",
    "showPageNumbers",
    "clearInvisibleValues",
    "textUpdateMode",
    "sendResultOnPageNext",
    "storeOthersAsComment",
    "focusOnFirstError",
    "checkErrorsMode",
    "navigateToUrl",
    "navigateToUrlOnCondition",
    "completedHtmlOnCondition",
    "loadingHtml",
    "completedBeforeHtml",
    "showTimerPanel",
    "showTimerPanelMode",
  ],
  text: [
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "size",
    "requiredErrorText",
    "validators",
    "maxLength",
    "defaultValue",
    "useDisplayValuesInTitle",
    "textUpdateMode",
    "autoComplete",
    "dataList",
    "id",
    "readOnly",
    "valueName",
  ],
  page: [
    "maxTimeToFinish",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "questionsOrder",
    "navigationButtonsVisibility",
    "questionTitleLocation",
    "readOnly",
  ],
  checkbox: [
    "choicesByUrl",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "defaultValueExpression",
    "hideIfChoicesEmpty",
    "choicesVisibleIf",
    "choicesEnableIf",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "colCount",
    "requiredErrorText",
    "validators",
    "id",
    "readOnly",
    "valueName",
  ],
  radiogroup: [
    "choicesByUrl",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "hideIfChoicesEmpty",
    "choicesVisibleIf",
    "choicesEnableIf",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "colCount",
    "requiredErrorText",
    "validators",
    "id",
    "readOnly",
    "valueName",
  ],
  dropdown: [
    "autoComplete",
    "choicesByUrl",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "hideIfChoicesEmpty",
    "choicesVisibleIf",
    "choicesEnableIf",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "colCount",
    "requiredErrorText",
    "validators",
    "id",
    "readOnly",
    "valueName",
  ],
  imagepicker: [
    "contentMode",
    "imageFit",
    "imageHeight",
    "imageWidth",
    "choicesByUrl",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "hideIfChoicesEmpty",
    "choicesVisibleIf",
    "choicesEnableIf",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "colCount",
    "requiredErrorText",
    "validators",
    "id",
    "readOnly",
    "valueName",
  ],
  boolean: [
    "label",
    "showTitle",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "useDisplayValuesInTitle",
    "valueTrue",
    "valueFalse",
    "requiredErrorText",
    "validators",
    "id",
    "readOnly",
    "valueName",
  ],
  signaturepad: [
    "width",
    "height",
    "dataFormat",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "minWidth",
    "maxWidth",
    "valueName",
    "useDisplayValuesInTitle",
    "requiredErrorText",
    "validators",
    // "title",
    "id",
    "readOnly",
    "valueName",
  ],
  matrix: [
    //logic
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "columnsVisibleIf",
    "rowsVisibleIf",
    //logic
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "showHeader",
    "showColumnHeader",
    //validators
    "requiredErrorText",
    "validators",
    "isAllRowRequired",
    "id",
    "readOnly",
    "valueName",
  ],
  matrixdropdown: [
    //general
    "detailPanelMode",
    "totalText",
    //logic
    "visibleIf",
    "enableIf",
    "requiredIf",
    "bindings",
    "defaultValueExpression",
    "columnsVisibleIf",
    "rowsVisibleIf",
    //layout
    "page",
    "startWithNewLine",
    "hideNumber",
    "state",
    "titleLocation",
    "descriptionLocation",
    "indent",
    "width",
    "minWidth",
    "maxWidth",
    "showHeader",
    "showColumnHeader",
    "columnLayout",
    "horizontalScroll",
    "columnColCount",
    "columnMinWidth",
    "rowTitleWidth",
    //validation
    "requiredErrorText",
    "validators",
    "keyDuplicationError",
    "id",
    "readOnly",
    "valueName",
  ],
  html: ["id", "readOnly", "valueName"],
};

export default blockList;

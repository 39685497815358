import { useEffect, useMemo } from 'react';
import generateQuizInstanceApi from '../api/quizInstanceApi';
import createConditionColumn from '../../shared/generalComponents/createConditionColumn';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';

const QuizInstanceColumns = (opts) => [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  createConditionColumn('Audience Condition', 'audienceCondition', 'fa-bullseye', opts),
  createConditionColumn('Graders Condition', 'gradersCondition', 'fa-bullseye', opts),
];

export default function useQuizInstanceConfigurations(quizId) {
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) dispatch(getUserOptions());
  }, [dispatch, opts]);
  const columns = useMemo(() => QuizInstanceColumns(opts), [QuizInstanceColumns, opts]);
  const api = useMemo(() => generateQuizInstanceApi(quizId), [quizId, generateQuizInstanceApi]);
  return {
    columns,
    api,
  };
}

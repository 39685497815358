import React from 'react';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const CsvExportEditorForm = (props) => {
  const { form, actions } = props;
  return (
    <div className="row">
      <div className="col-sm-5 mb-4 no-left-padding">
        You will receive a notice in your email when the report is ready to download. You will also
        receive a notice inside the application. If you would like others to also receive a copy of
        this report, enter the emails below separated by commas.
      </div>
      <form role="form" className="no-left-padding">
        <FormInputTextArea label="Additional Emails" fieldName="otherEmails" formContext={form} />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default CsvExportEditorForm;

import { useCallback, useState } from 'react';
export const useEditPageTitle = (id, name, labelField) => {
  const [pageTitle, setPageTitle] = useState('');
  const onItemChange = useCallback(
    (item) => {
      const add = !item?.id || (item.id === 'new' && id === 'new');
      const newTitle = add ? `Add ${name}` : `Edit ${item?.[labelField] || name}`;
      setPageTitle(newTitle);
    },
    [id, name, labelField, setPageTitle]
  );
  return { pageTitle, onItemChange };
};

import React, { useContext } from 'react';
import { CmsContext } from '../../context/definitions';

const SampleComponent = () => {
  const { cmsConfig } = useContext(CmsContext);
  const userEmail = cmsConfig?.loggedInUser?.email || '';
  return userEmail && <div className="welcome-message">Welcome {userEmail}</div>;
};

export default SampleComponent;

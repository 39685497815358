import React from 'react';
import qrScannerApi from '../api/qrScannerApi';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { QrScannerConstants, scannerTypeObject } from '../definitions';
import useQrScannerActions from '../hooks/useQrScannerActions';

function QrScanner() {
  const { defaultPageId, typeName } = QrScannerConstants;
  const { add, remove, edit } = useQrScannerActions(QrScannerConstants);
  const columns = [
    {
      label: 'Name',
      valueField: 'name',
      sortable: true,
    },
    {
      label: 'Type',
      valueField: 'type',
      sortable: true,
      renderer: (item) => scannerTypeObject[item.type],
    },
  ];
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={'QR Scanner'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={qrScannerApi}
      />
    </CmsPage>
  );
}

export default QrScanner;

import get from 'lodash/get';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import customPageApi from '../api/customPageApi';
import { CustomPageConstants } from '../definitions';

const useCustomPageListActions = () => {
  const dispatch = useDispatch();
  const { typeName, pluginId, defaultPageId } = CustomPageConstants;
  const { add, edit, remove, requestPending } = useItemListActions(
    customPageApi,
    CustomPageConstants
  );
  const addOverride = useMemo(
    () => ({
      ...add,
      onClick: async () => {
        const newCustomPage = await customPageApi.loadRecord('new');
        const navParams = [
          CustomPageConstants.pluginId,
          CustomPageConstants.editorPageName,
          newCustomPage.id || 'new',
        ];
        dispatch(navigateToPluginPage(navParams));
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId]
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (context) => get(context, 'isSubtenantNavigation', false),
      visible: (context) => get(context, 'status', '') !== 'removed',
    }),
    [typeName, pluginId, defaultPageId]
  );

  return useMemo(
    () => ({ add: addOverride, edit, remove: removeOverride, requestPending }),
    [addOverride, edit, removeOverride, requestPending]
  );
};

export default useCustomPageListActions;

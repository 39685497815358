import axios from 'axios';
import Papa from 'papaparse';
export function downloadBlob(blob, filename) {
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.append(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(href);
}

export function downloadJsonToCsv({ fields, data: originalData, filename }) {
  const data = originalData.map((row) => {
    const item = {};
    for (const key in row) {
      const v = row[key];
      item[key] = Array.isArray(v) ? v.join(', ') : v;
    }
    return item;
  });

  const csv = Papa.unparse({ fields, data });
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  return downloadBlob(csvBlob, filename);
}

export async function downloadUrlBlob(url, filename, params = {}) {
  const { data } = await axios({
    method: 'GET',
    params,
    responseType: 'blob',
    timeout: 10_000,
    url,
  });
  return downloadBlob(data, filename);
}

export async function downloadLocalWebFile(filename) {
  await downloadUrlBlob(`files/${filename}`, filename);
}

import React, { useState, useEffect } from 'react';
import log from '../../logger.js';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import useArray from '../../shared/util/hooks/useArray';
import { FormConstants, FormInstanceConstants } from '../definitions';
import { getForm } from '../api/formsApi';
import useFormInstanceActions from '../hooks/useFormInstanceActions';
import useFormInstanceConfigurations from '../hooks/useFormInstanceConfigurations';

const FormInstancesPage = (props) => {
  const { formId } = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { defaultPageId, typeName } = FormInstanceConstants;
  const { columns, api, prepareReport } = useFormInstanceConfigurations(formId);
  const { backToForms, add, edit, editForm } = useFormInstanceActions(
    formId,
    FormInstanceConstants,
    FormConstants
  );
  const { remove, requestPending } = useItemListActions(api, FormInstanceConstants);
  const toolbarActions = useArray(add, editForm);
  const itemActions = useArray(edit, remove, prepareReport);

  useEffect(() => {
    async function getFormData() {
      try {
        setLoading(true);
        const { form: formData } = await getForm(formId);
        setFormData(formData);
      } catch (err) {
        log.error(err);
      } finally {
        setLoading(false);
      }
    }
    getFormData();
  }, [formId]); // eslint-disable-line
  return (
    <CmsPage
      title={`Form Instances${formData && formData.title ? `: ${formData.title}` : ''}`}
      requestPending={loading || requestPending}
    >
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
        backPageAction={backToForms}
      />
    </CmsPage>
  );
};

export default FormInstancesPage;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import collectionApi from '../api/collectionApi';
import { CollectionConstants, defaultTypesSelected } from '../definitions';
import useCollectionListActions from '../hooks/useCollectionListActions';
import CollectionTitle from './CollectionTitle';
import { startCase } from 'lodash';
const CollectionColumns = [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
    renderer: (collection) => <CollectionTitle collection={collection} />,
  },
  {
    label: 'Display Label',
    valueField: 'label',
    sortable: true,
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: false,
    align: 'center',
    renderer: (collection) => <ItemPublishStatus status={collection.status} />,
  },
  {
    label: 'Type',
    valueField: 'type',
    sortable: false,
    renderer: ({ type = '' }) => (
      <div>{['itrs'].includes(type) ? type.toUpperCase() : _.capitalize(type)}</div>
    ),
  },
  {
    label: 'Last Modified',
    valueField: 'lastUpdated',
    sortable: true,
    renderer: (item, field) => <Timestamp date={item[field]} />,
  },
];

const CollectionListPage = () => {
  const { defaultPageId, typeName } = CollectionConstants;
  const { add, edit, remove, restore, publish, requestPending, duplicate, isDuplicating } =
    useCollectionListActions(CollectionConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, publish, remove, restore, duplicate);

  return (
    <CmsPage title="Collections" requestPending={requestPending || isDuplicating}>
      <CmsItemListSection
        itemSort={(items) =>
          items.sort((a, b) => b.isSubtenantNavigation - a.isSubtenantNavigation)
        }
        tableRowCustomClass={(item) => (item.isSubtenantNavigation ? 'static-end' : '')}
        pageId={defaultPageId}
        className="nav-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={CollectionColumns}
        itemApi={collectionApi}
        additionalSearchFields={[
          {
            key: 'types',
            noSelectText: 'Show All',
            placeholder: 'Types',
            multiple: true,
            type: 'select',
            defaultSelected: defaultTypesSelected,
            options: [...defaultTypesSelected, 'itrs'].map((value) => ({
              label: ['itrs'].includes(value) ? value.toUpperCase() : startCase(value),
              value,
            })),
          },
          { key: 'hidden', placeholder: 'Show Hidden', type: 'switch' },
        ]}
      />
    </CmsPage>
  );
};

export default CollectionListPage;

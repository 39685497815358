import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import achievementApiWrapped, { updateAchievementOptions } from '../api/achievementsApi';
import { AchievementConstants } from '../definitions';

export default function useAchievementActions(pageConstants, pageInstanceConstance) {
  const switchStateDefault = false;
  const { typeName, pluginId, defaultPageId, fieldEditorPageName, certEditorPageName } =
    pageConstants;
  const { defaultPageName } = pageInstanceConstance;
  const dispatch = useDispatch();
  const [hideArchived, setHideArchived] = useState(false);
  const achievementApi = achievementApiWrapped(hideArchived);
  const { confirm } = useContext(CmsContext);
  const archiveCheckbox = useMemo(
    () => ({
      useSwitch: true,
      label: 'Hide Archived',
      switchStateDefault,
      onClick: async () => setHideArchived((c) => !c),
    }),
    [setHideArchived, switchStateDefault]
  );
  const add = useMemo(
    () => ({
      label: `+ Add Achievement`,
      onClick: async () => {
        try {
          const newAchievement = await achievementApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              AchievementConstants.pluginId,
              AchievementConstants.editorPageName,
              newAchievement.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId]
  );
  const manageTemplates = useMemo(
    () => ({
      label: `Manage Templates`,
      icon: 'fa solid fa-solid-pdf',
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([
              AchievementConstants.pluginId,
              AchievementConstants.templateManagerPageName,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: defaultPageId, props: 'Failed to open template manager.' })
          );
        }
      },
    }),
    [defaultPageId, dispatch]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          let confirmResponse = true;
          if (!context.isArchived) {
            confirmResponse = await confirm({
              title: 'Achive Achievement',
              message: `Are you sure you want to archive '${context.name}'?`,
            });
          }
          if (confirmResponse) {
            await updateAchievementOptions(context.id, {
              isArchived: !context.isArchived,
            });
            await reload();
            dispatch(
              successGrowl({
                groupId: defaultPageId,
                props: 'Successfully updated achievement.',
              })
            );
          }
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to update achievement.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, confirm]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: async (context) => {
        try {
          const newAchievement = await achievementApi.loadRecord(context.id);
          dispatch(
            navigateToPluginPage([
              AchievementConstants.pluginId,
              AchievementConstants.editorPageName,
              newAchievement.id,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pluginId, defaultPageId]
  );
  const manageInstances = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Instances',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, defaultPageName, context.id]));
      },
    }),
    [dispatch, pluginId, defaultPageId, defaultPageName]
  );
  const manageFields = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Fields',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, fieldEditorPageName, context.id]));
      },
    }),
    [dispatch, pluginId, fieldEditorPageName]
  );
  const manageCerts = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Certificates',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, certEditorPageName, context.id]));
      },
    }),
    [dispatch, pluginId, certEditorPageName]
  );
  const duplicate = useMemo(
    () => ({
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        try {
          const newAchievement = context;
          delete newAchievement.id;
          newAchievement.name += ' copy';
          await achievementApi.saveRecord(newAchievement);
          await reload();
          dispatch(
            successGrowl({
              groupId: defaultPageId,
              props: 'Successfully duplicated achievement.',
            })
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: defaultPageId, props: 'Failed to open achievement creator.' })
          );
        }
      },
    }),
    [dispatch, pluginId, defaultPageId]
  );
  return {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    hideArchived,
    manageInstances,
    manageTemplates,
    manageFields,
    manageCerts,
  };
}

import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import useWysiwygOptions from '../../shared/form/hooks/useWysiwygOptions';
const Wysiwyg = ({ value, setValue, label }) => {
  const { wysiwygOptions } = useWysiwygOptions();
  return (
    <div className="form-group">
      <label>{label}</label>
      {wysiwygOptions && (
        <FroalaEditor config={wysiwygOptions} model={value} onModelChange={setValue} />
      )}
    </div>
  );
};

export default Wysiwyg;

import classNames from 'classnames';
import React from 'react';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

const FormInput = (props) => {
  const {
    label,
    defaultValue,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    fontWeight,
    labelNote,
    type: providedType,
    className: providedClassName,
    onChange: providedOnChange,
    onBlur: providedOnBlur,
    ...inputAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);
  const registeredProps = register(fieldName, formFieldOptions);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ fontWeight: fontWeight }}>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
        {!!labelNote && (
          <span style={{ fontWeight: 'normal', fontSize: '11px' }}>{` (${labelNote})`}</span>
        )}
      </label>
      <input
        defaultValue={defaultValue}
        type={providedType || 'text'}
        className="form-control"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...registeredProps}
        onChange={(e) => {
          registeredProps.onChange(e);
          if (providedOnChange) providedOnChange(e);
        }}
        onBlur={(e) => {
          registeredProps.onBlur(e);
          if (providedOnBlur) providedOnBlur(e);
        }}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInput;

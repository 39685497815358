import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
const useSavedPageState = (pageId) => {
  const dispatch = useDispatch();
  return useMemo(() => {
    const key = `page-state-${pageId}`;
    const result = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);
    return result ? JSON.parse(result) : undefined;
  }, [pageId, dispatch]);
};

export default useSavedPageState;

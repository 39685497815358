import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToChildPage, navigateToPluginPage } from '../../store/rootReducer';
import leadGenEventApi from '../api/leadGenEventApi';
import { LeadGenEventConstants } from '../definitions';
const { pluginId, editorPageName, defaultPageId } = LeadGenEventConstants;
const useLeadGenEventListActions = () => {
  const dispatch = useDispatch();
  const { add, edit, remove, requestPending } = useItemListActions(
    leadGenEventApi,
    LeadGenEventConstants
  );
  const addOverride = useMemo(
    () => ({
      ...add,
      label: 'Add Event',
      onClick: async ({ loaderParams }) => {
        const savedItem = await leadGenEventApi.loadRecord('new');
        if (savedItem) {
          dispatch(
            navigateToChildPage([
              pluginId,
              editorPageName,
              defaultPageId,
              loaderParams,
              savedItem.id,
            ])
          );
        }
      },
    }),
    [add, leadGenEventApi, dispatch]
  );
  const attendeeInfo = useMemo(
    () => ({
      icon: 'fa-solid fa-users',
      label: 'Attendee Info',
      onClick: async ({ id, eventName, ingestType = 'upload' }) => {
        dispatch(
          navigateToPluginPage([
            pluginId,
            LeadGenEventConstants.attendeesPageName,
            id,
            eventName,
            'imports',
            ingestType,
          ])
        );
      },
    }),
    [dispatch]
  );
  const itemActions = [edit, attendeeInfo, remove];
  return useMemo(
    () => ({
      add: addOverride,
      itemActions,
      remove,
      requestPending,
    }),
    [addOverride, itemActions, requestPending]
  );
};

export default useLeadGenEventListActions;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { QuizInstanceConstants } from '../definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import useQuizInstanceActions from '../hooks/useQuizInstanceActions';
import useQuizInstanceConfigurations from '../hooks/useQuizInstanceConfigurations';

const QuizInstancesPage = (props) => {
  const { quizId } = props;
  const { defaultPageId, typeName } = QuizInstanceConstants;
  const { columns, api } = useQuizInstanceConfigurations(quizId);
  const { edit, add, backToQuizzes } = useQuizInstanceActions(quizId, QuizInstanceConstants);
  const { remove, requestPending } = useItemListActions(api, QuizInstanceConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={'Quiz Instances'} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
        backPageAction={backToQuizzes}
      />
    </CmsPage>
  );
};

export default QuizInstancesPage;

import React from 'react';
const Text = ({ label, defaultValue, long, setValue, placeholder, disabled }) => {
  return (
    <div className={`form-group input-${long ? 'long' : 'normal'}`}>
      <label>{label}</label>
      <input
        onChange={(e) => setValue(e.target.value)}
        {...(defaultValue ? { defaultValue } : {})}
        className="form-control"
        placeholder={placeholder || ''}
        maxLength={255}
        disabled={disabled}
      />
    </div>
  );
};

export default Text;

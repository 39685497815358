import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToParentPage } from '../../store/rootReducer';
export default function useCohortMatchesActions({
  pluginId,
  parentPageTitle,
  parentPageName,
  parentPageId,
  parentPageArgs,
}) {
  const dispatch = useDispatch();
  const back = useMemo(
    () => ({
      pageName: parentPageTitle,
      onClick: () => {
        dispatch(
          navigateToParentPage([pluginId, parentPageName, parentPageId, ...(parentPageArgs || [])])
        );
      },
    }),
    [dispatch, parentPageTitle, pluginId, parentPageName, parentPageId, parentPageArgs]
  );
  return {
    back,
  };
}

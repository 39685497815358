import React, { useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import { FormInstanceConstants } from '../definitions';
import useFormInstanceConfigurations from '../hooks/useFormInstanceConfigurations';

const FormInstanceEditor = (props) => {
  const { itemId, onItemChange, formId } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { api } = useFormInstanceConfigurations(formId);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, FormInstanceConstants, formId);
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, FormInstanceConstants);
  return (
    <>
      <div className="row">
        <form role="form">
          <FormInput
            label="Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />
          <div className="row module-footer"></div>
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default FormInstanceEditor;

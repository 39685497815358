import { useCallback, useEffect, useMemo } from 'react';
import generateFormInstanceApi from '../api/formInstanceApi';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';
import { downloadUrlBlob } from '../../shared/util/fileDownload';

const FormInstanceColumns = () => [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
];

export default function useFormInstanceConfigurations(formId) {
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  const api = useMemo(() => generateFormInstanceApi(formId), [formId, generateFormInstanceApi]);

  useEffect(() => {
    if (!opts || _.isEmpty(opts)) {
      dispatch(getUserOptions());
    }
  }, [dispatch, opts]);
  const prepareReport = useCallback(() => {
    return {
      disabled: false,
      icon: 'fa-solid fa-list-tree',
      label: 'Download Report',
      onClick: async (context) => {
        const link = await api.getReportStatus(context.id);
        if (link) {
          await downloadUrlBlob(link, context.name);
        }
      },
    };
  });
  const columns = useMemo(
    () => FormInstanceColumns(opts, prepareReport),
    [FormInstanceColumns, opts, prepareReport]
  );
  return {
    columns,
    api,
    prepareReport: prepareReport(),
  };
}

import React, { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import FormInput from '../../shared/form/components/FormInput';
import Select from '../../shared/form/components/Select';
import { getCohortIngestTypes } from '../api/cohortApi';

const CohortEditorForm = (props) => {
  const { form } = props;
  const [showExternalId, setShowExternalId] = useState(false);
  const ingestType = useWatch({
    control: form.control,
    name: 'ingestType',
  });

  useEffect(() => {
    if (ingestType === 'api' || ingestType === 'both') {
      setShowExternalId(true);
      form.register('externalId', { required: 'External API ID is required.' });
    } else {
      setShowExternalId(false);
      form.unregister('externalId');
    }
  }, [ingestType, form]);

  const loadIngestTypeOptions = useCallback(async () => {
    const options = await getCohortIngestTypes();
    if (options.length === 1) {
      form.setValue('ingestType', options[0].value);
    }
    return options;
  }, [form]);

  return (
    <div className="row ">
      <form role="form" className="no-left-padding" onSubmit={form.handleSubmit(props.onSubmit)}>
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          label="Credentials Prompt"
          fieldName="credentialsPromptText"
          formContext={form}
          placeholder="Please enter your invitation code."
        />
        <Select
          label="Credentials Entry"
          options={[
            { label: 'Manual Entry', value: 'manual' },
            { label: 'Choose From List', value: 'list' },
          ]}
          formContext={form}
          fieldName="credentialsEntry"
        />
        <Select
          label="Data Source"
          optionsLoader={loadIngestTypeOptions}
          formContext={form}
          fieldName="ingestType"
        />
        {showExternalId && (
          <FormInput
            label="External Api Id"
            fieldName="externalId"
            formContext={form}
            placeholder="Enter external cohort ID."
            formFieldOptions={{
              required: 'External API ID is required.',
            }}
          />
        )}
      </form>
    </div>
  );
};

export default CohortEditorForm;

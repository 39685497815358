import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateSimpleCondition } from '../../../../../store';
import { selectPrimaryFieldProps } from '../../../../../store/selectors';

const SelectField = (props) => {
  const { location, fieldName } = props;
  const { options, value } = selectPrimaryFieldProps(fieldName, location);
  const dispatch = useDispatch();
  const onFieldChange = useCallback(
    ({ value: field }) => {
      dispatch(
        updateSimpleCondition({
          location,
          updateRule: {
            field,
            data: [],
          },
          fieldName,
        })
      );
    },
    [dispatch, location, fieldName]
  );
  return (
    <Select
      placeholder={'Type'}
      onChange={onFieldChange}
      getOptionLabel={(field) => field.label}
      getOptionValue={(field) => field.value}
      value={value}
      options={options}
      className="inline-select"
    />
  );
};

export default SelectField;

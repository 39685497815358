import React from 'react';
import moment from 'moment';
import mt from 'moment-timezone';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const Timestamp = ({
  date,
  isDateOnly,
  customFormat,
  showNull = true,
  localTime = false,
  utc = false,
}) => {
  if (!date && !showNull) return <>{''}</>;
  let formatted;
  const mom = localTime ? mt : moment;
  formatted = utc ? mom.utc(date) : mom(date);
  if (localTime) formatted = formatted.tz(timezone);
  formatted = formatted.format(customFormat || `MMM DD, YYYY${isDateOnly ? '' : ' hh:mm A'}`);
  return <>{formatted}</>;
};

export default Timestamp;

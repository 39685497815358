angular.module('patch-http', []).run([
  '$http',
  function ($http) {
    const oldGet = $http.get;
    $http.get = function (...args) {
      const promise = oldGet.apply($http, args);
      return patchHttpPromise(promise);
    };
    const oldPost = $http.post;
    $http.post = function (...args) {
      const promise = oldPost.apply($http, args);
      return patchHttpPromise(promise);
    };
    function patchHttpPromise(promise) {
      promise.success = function (handler) {
        promise.then(function (response) {
          return handler(response.data, response.status, response.headers, response.config);
        });
        return promise;
      };
      promise.error = function (handler) {
        promise.catch(function (response) {
          return handler(response.data, response.status, response.headers, response.config);
        });
        return promise;
      };
      return promise;
    }
  },
]);

export const ManageGroupsConstants = {
  pluginId: 'manage-groups',
  defaultPageName: 'default',
  defaultPageId: 'manage-groups-list-page',
  typeName: 'Group',
  editorPageName: 'addGroup',
  managePageName: 'manageGroupsEditMembers',
  exportPageName: 'manageGroupsCsvExportEditorPage',
};
export const EMPTY_GROUP_REVISION = {
  name: '',
  groupKey: '',
};

import React from 'react';
import ActionButton from '../../actionButton/components/ActionButton';
import ActionSwitch from '../../actionButton/components/ActionSwitch';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';

const Action = ({ action }) => {
  const { disabled, visible } = action;
  const itemDisabled = checkFlagOrFunction(disabled);
  const itemVisible = visible === undefined || checkFlagOrFunction(visible);
  return (
    <div>
      {itemVisible && !action.useSwitch && <ActionButton {...action} disabled={itemDisabled} />}
      {action.useSwitch && (
        <ActionSwitch
          hiddenButtonRef={action.hiddenButtonRef}
          switchStateDefault={action.switchStateDefault}
          variant={action.variant}
          icon={action.icon}
          label={action.label}
          disabled={itemDisabled}
          onClick={() => action.onClick()}
        />
      )}
    </div>
  );
};

export default Action;

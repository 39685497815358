import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectSimpleConditionProps } from '../../../../store/selectors';
import { getPrimaryOptions, getSecondaryOptions } from '../../../../store/thunks';
import AdditionalInputs from './components/additional-inputs';
import MissingWaring from './components/MissingWarning';
import RemoveCondition from './components/RemoveCondition';
import SelectCondition from './components/SelectCondition';
import SelectField from './components/SelectField';
import SelectFieldLogicType from './components/SelectFieldLogicType';
import SelectOption from './components/SelectOption';
import SelectSubOption from './components/SelectSubOption';

const SimpleCondition = (props) => {
  const { location, fieldName } = props;
  const dispatch = useDispatch();
  const {
    field,
    hasQueriedPrimaryOptions,
    hasQueriedSubOptions,
    hasSubOptions,
    optionId,
    primaryOptionName,
    primaryOptionsEndpoint,
    primaryOptionsNotFound,
    primaryOptionsQueryParams,
    subOptionName,
    subOptionsEndpoint,
    subOptionsNotFound,
    subOptionsQueryParams,
  } = selectSimpleConditionProps(fieldName, location);
  useEffect(() => {
    if (!hasQueriedPrimaryOptions && field)
      dispatch(
        getPrimaryOptions({
          endpoint: primaryOptionsEndpoint,
          params: primaryOptionsQueryParams,
          field,
          fieldName,
        })
      );
  }, [
    hasQueriedPrimaryOptions,
    dispatch,
    field,
    primaryOptionsEndpoint,
    primaryOptionsQueryParams,
    fieldName,
  ]);
  useEffect(() => {
    if (hasSubOptions && optionId && !hasQueriedSubOptions && subOptionsEndpoint && field) {
      dispatch(
        getSecondaryOptions({
          endpoint: subOptionsEndpoint,
          field,
          fieldName,
          optionId,
          params: subOptionsQueryParams,
        })
      );
    }
  }, [
    dispatch,
    hasSubOptions,
    hasQueriedSubOptions,
    subOptionsEndpoint,
    subOptionsQueryParams,
    field,
    optionId,
    fieldName,
  ]);
  return (
    <div className="condition">
      <div
        className="form-inline"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <SelectField location={location} fieldName={fieldName} />
        {primaryOptionsNotFound ? (
          <MissingWaring isPrimary primaryOptionName={primaryOptionName} />
        ) : (
          <>
            <SelectOption location={location} fieldName={fieldName} />
            {hasSubOptions && !subOptionsNotFound && (
              <SelectSubOption location={location} fieldName={fieldName} />
            )}
            {hasSubOptions && subOptionsNotFound ? (
              <MissingWaring
                isSecondary
                primaryOptionName={primaryOptionName}
                subOptionName={subOptionName}
              />
            ) : (
              <>
                <SelectFieldLogicType location={location} fieldName={fieldName} />
                <SelectCondition location={location} fieldName={fieldName} />
                <AdditionalInputs location={location} fieldName={fieldName} />
              </>
            )}
          </>
        )}
        <RemoveCondition location={location} fieldName={fieldName} />
      </div>
    </div>
  );
};

export default SimpleCondition;

import React, { useMemo } from 'react';
import { CmsPageContext } from '../definitions';
import MultiSpinner from '../../spinner/components/MultiSpinner';
import { useSpinnerProvider } from '../../spinner/hooks/useSpinnerProvider';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import BackButton from './BackButton';
const CmsPage = (props) => {
  const { title, children, requestPending, backTitle, backClick } = props;
  const { spinnerProvider, spinners } = useSpinnerProvider();
  useSpinner(spinnerProvider, requestPending);
  const pageContext = useMemo(
    () => ({
      spinnerProvider,
    }),
    [spinnerProvider]
  );
  return (
    <>
      {Boolean(backTitle && backClick) && (
        <div className="row">
          <BackButton handleClick={backClick} pageName={backTitle} />
        </div>
      )}
      <div className="header row">
        <h1 className="page-title">
          {title}
          <span className="page-spinner">
            <MultiSpinner spinners={spinners} />
          </span>
        </h1>
      </div>
      <CmsPageContext.Provider value={pageContext}>{children}</CmsPageContext.Provider>
    </>
  );
};

export default CmsPage;

angular.module('config-service', []).service('configService', [
  '$http',
  '$q',
  function ($http, $q) {
    let configDeferred = null;
    function getConfig() {
      if (!configDeferred) {
        configDeferred = $q.defer();
        $http
          .get('/config/config.json')
          .success(function (data) {
            configDeferred.resolve(data);
          })
          .error(function (data) {
            configDeferred.reject(`Failed to get config. Response: ${JSON.stringify(data)}`);
          });
      }
      return configDeferred.promise;
    }
    function reset() {
      configDeferred = null;
    }
    return {
      getConfig,
      reset,
    };
  },
]);

import React, { useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import { QuizInstanceConstants } from '../definitions';
import useQuizInstanceConfigurations from '../hooks/useQuizInstanceConfigurations';

const QuizInstanceEditor = (props) => {
  const { itemId, onItemChange, quizId } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { api } = useQuizInstanceConfigurations(quizId);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, QuizInstanceConstants, quizId);
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, QuizInstanceConstants);
  const { editorPageId } = QuizInstanceConstants;
  return (
    <>
      <div className="row">
        <form role="form">
          <FormInput
            label="Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />
          <div className="row module-footer"></div>
          <AudienceSelector
            formContext={form}
            label="Assigned To"
            fieldName="audienceCondition"
            selectFieldText="Select Audience"
            growlId={editorPageId}
            selectTextOnNewLine
          />
          <AudienceSelector
            formContext={form}
            label="Grader(s)"
            fieldName="gradersCondition"
            growlId={editorPageId}
            selectFieldText="Select Graders"
            selectTextOnNewLine
          />
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default QuizInstanceEditor;

import React, { useState, useEffect } from 'react';
import CmsToolbarSection from '../../shared/cmsPage/components/CmsToolbarSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { AchievementConstants, AchievementInstanceConstants } from '../definitions';
import useAchievementActions from '../hooks/useAchievementActions';
import Fields from './service/Fields';
import { getFields } from '../api/service';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const AchievementsListPage = () => {
  const [fields, setFields] = useState([]);
  const { fieldEditorPageId, typeName } = AchievementConstants;
  const { manageFields, manageCerts, add } = useAchievementActions(
    AchievementConstants,
    AchievementInstanceConstants
  );
  const toolbarActions = useArray(manageFields, manageCerts, add);

  useEffect(() => {
    handleGetFields();
  }, []);

  const handleGetFields = async () => {
    const resp = await getFields();
    setFields(resp);
  };
  return (
    <>
      <BreadcrumbLink
        pluginId={AchievementConstants.pluginId}
        parentPageId={AchievementConstants.defaultPageId}
        parentPageName={AchievementConstants.defaultPageName}
        parentPageTitle={'Achievements'}
      />
      <CmsPage title={'Edit Fields'}>
        <CmsToolbarSection
          pageId={fieldEditorPageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
        />
        <Fields fields={fields} handleGetFields={handleGetFields} />
      </CmsPage>
    </>
  );
};

export default AchievementsListPage;

import React, { useEffect, useState } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { ItrsCourseConstants } from '../definitions';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import itrsApi from '../api/itrsApi';
import { Typography, ButtonGroup, Button } from '@mui/material';
import SessionTable from './SessionTable';
import { navigateToPluginPage } from '../../store/rootReducer';
import { useDispatch } from 'react-redux';

const ItemView = ({ title, value, render }) => {
  return (
    <li>
      <Typography variant="h6">{title}:</Typography>
      <br />
      {render ? (
        <Typography dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <Typography>{value}</Typography>
      )}
    </li>
  );
};

const { loadRecord, removeRecord } = itrsApi;

const handleEdit = (courseId, dispatch) => () => {
  dispatch(
    navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.editorPageId, courseId])
  );
};

const handleDelete = (courseId, dispatch) => async () => {
  await removeRecord(courseId);
  dispatch(navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.defaultPageId]));
};

const ItrsCourseViewPage = ({ courseId }) => {
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const setRecords = async () => {
      const course = await loadRecord(courseId);
      setCourse(course);
    };
    if (courseId) setRecords();
  }, [courseId]);

  const dispatch = useDispatch();

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BreadcrumbLink
        pluginId={ItrsCourseConstants.pluginId}
        parentPageId={ItrsCourseConstants.defaultPageId}
        parentPageName={ItrsCourseConstants.defaultPageName}
        parentPageTitle={'Courses'}
      />
      <CmsPage title={'Course'}>
        <Typography variant="h3">{course.name}</Typography>
        <ButtonGroup>
          <Button onClick={handleEdit(courseId, dispatch)}>Edit</Button>
          <Button onClick={handleDelete(courseId, dispatch)} disabled={!!course?.sessions?.length}>
            Delete
          </Button>
        </ButtonGroup>
        <ul>
          <ItemView title="Course Name" value={course.name} />
          <ItemView title="Course Status" value={course.status} />
          <ItemView title="Course Description" value={course.description} render={true} />
          <ItemView title="Course Summary" value={course.summary} render={true} />
          <ItemView title="Duration" value={course.duration} />
          <ItemView title="Category / Department" value={course.category} />
          <ItemView title="Program(s)" value={course.program} />
          <ItemView title="Enrollment Approval" value="Unknown" />
          <ItemView title="Enrollment Cut-Off" value={course.enrollmentCutOff} />
          <ItemView title="Course earns a Certificate" value={course.certificate} />
          <ItemView title="Course earns Continuing Education Credit (CEU)" value={course.ceu} />
          <ItemView title="Prerequisites" value={course.prereqs} render={true} />
        </ul>
        <Typography variant="h4">Sessions</Typography>
        <SessionTable courseId={course.id} />
      </CmsPage>
    </>
  );
};

export default ItrsCourseViewPage;

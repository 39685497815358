import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Timestamp from '../../shared/itemTable/components/Timestamp';

const DataRow = ({ label, value, mt = false, mb = false }) => {
  if (typeof value === 'string') {
    value = value.trim();
    if (value.length && /^[\sA-Za-z]*$/.test(value)) {
      value = startCase(value);
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'end',
        ...(!mb && { marginTop: '.25rem' }),
        ...(!mt && { marginBottom: '.25rem' }),
      }}
    >
      <div style={{ fontWeight: 'bold' }}>{`${label}:`}</div>
      <div style={{ marginLeft: '1rem' }}>{value}</div>
    </div>
  );
};
export default function LeadGenAttendeeDetailsModal({ handleClick, visible, data }) {
  const {
    LeadGenEvent,
    comments,
    data: moreFields = {},
    dateScanned,
    email,
    name,
    rating,
    scannedBy,
  } = data;

  return (
    <Modal show={visible} animation={false}>
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <div>
            <h6>
              <b>{`Scan Details`}</b>
            </h6>
          </div>
          <Button variant="cancel" onClick={() => handleClick()}>
            <i className="fa-solid fa-xmark" />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <DataRow label="Event" value={get(LeadGenEvent, 'eventName', '')} mb />
          <DataRow label="Date / Time" value={<Timestamp date={dateScanned} />} />
          <DataRow label="Scanned By" value={scannedBy} mt />
        </div>
      </Modal.Body>
      <hr
        className="hr"
        style={{ color: 'solid var(--bs-modal-border-color)', opacity: '.1 !important' }}
      />
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 'wrap' }}>
          <DataRow label="Display Name" value={name} mb />
          <DataRow label="Email Address" value={email} />
          {Object.entries(moreFields).map(([k, v]) => (
            <DataRow label={startCase(k)} value={v} key={k} />
          ))}
          <DataRow label="Rating" value={rating ? `${rating} / 5` : 'No Rating'} />
          <div style={{ fontWeight: 'bold', marginTop: '.25rem' }}>{`Comments`}</div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '.2rem',
              minHeight: '6rem',
            }}
          >
            {comments}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

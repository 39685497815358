import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import useMapsListPageActions from '../hooks/useMapsListPageActions';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import api from '../api/mapsListApi';
import { MapsConstants } from '../definitions';
const MagssListPage = () => {
  const columns = [
    {
      label: 'Title',
      valueField: 'title',
      sortable: true,
      initialSort: 'ASC',
    },
  ];
  const listTitle = 'Maps';
  const constants = MapsConstants;
  const { add, edit, remove } = useMapsListPageActions(api, constants);
  const { defaultPageId, typeName } = constants;
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);

  return (
    <CmsPage title={listTitle}>
      <CmsItemListSection
        typeName={typeName}
        pageId={defaultPageId}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        showTotal={true}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default MagssListPage;

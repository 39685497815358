declare let $: any;

const isValidUrl = (url: string) => {
  if (url.indexOf('cms://') === 0) return true;
  if (!/^(https?:|ftps?:|)\/\//.test(url)) return false;

  url = String(url)
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')
    .replace(/"/g, '%22')
    .replace(/ /g, '%20');

  const b =
    /\(?(?:(https?:|ftps?:|)\/\/)?(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|(?:www.)?(?:[^\W\s]|\.|-)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/gi; // eslint-disable-line

  return b.test(url);
};

export const patchFroala = () => {
  if (!$.FroalaEditor.patched) {
    const origHelpers = $.FroalaEditor.MODULES.helpers;

    $.FroalaEditor.MODULES.helpers = (b: any) => {
      const result = origHelpers(b);
      if (result) {
        result.sanitizeURL = function o(a: any) {
          if (/^(cms:|https?:|ftps?:|)\/\//.test(a)) {
            if (!isValidUrl(a)) return '';
          } else {
            a = encodeURIComponent(a)
              .replace(/%23/g, '#')
              .replace(/%2F/g, '/')
              .replace(/%25/g, '%')
              .replace(/mailto%3A/g, 'mailto:')
              .replace(/file%3A/g, 'file:')
              .replace(/sms%3A/g, 'sms:')
              .replace(/tel%3A/g, 'tel:')
              .replace(/notes%3A/g, 'notes:')
              .replace(/data%3Aimage/g, 'data:image')
              .replace(/webkit-fake-url%3A/g, 'webkit-fake-url:')
              .replace(/%3F/g, '?')
              .replace(/%3D/g, '=')
              .replace(/%26/g, '&')
              .replace(/&amp;/g, '&')
              .replace(/%2C/g, ',')
              .replace(/%3B/g, ';')
              .replace(/%2B/g, '+')
              .replace(/%40/g, '@');
          }
          return a;
        };
      }

      return result;
    };
    $.FroalaEditor.patched = true;
  }
};

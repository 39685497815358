import React, { useContext } from 'react';
import { FILE_KEYS } from '../definitions';
import { ImageContext } from '../store';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import map from 'lodash/map';
const Select = () => {
  const { availableFilesMap, setSelectedFilePath, selectedFilePath } = useContext(ImageContext);
  return (
    <select
      style={{ width: '200px' }}
      className="form-control"
      value={selectedFilePath}
      onChange={(e) => {
        e.preventDefault();
        setSelectedFilePath(e.target.value);
      }}
    >
      {map(availableFilesMap, (file, selectedFilePath) => {
        const display = FILE_KEYS.includes(selectedFilePath)
          ? startCase(camelCase(selectedFilePath))
          : selectedFilePath;
        return (
          <option key={selectedFilePath} value={selectedFilePath}>
            {display}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

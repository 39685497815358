import merge from 'lodash/merge';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import mapsApi from '../api/mapsListApi';
import { navigateToPluginPage } from '../../store/rootReducer';
import { MapsConstants } from '../definitions';
import logger from '../../logger';
import { omit } from 'lodash';
import useKmlFileUrl from './useKmlFileUrl';

export default function useMapData(mapId) {
  const dispatch = useDispatch();
  const mapName = 'maps';
  const [info, setInfo] = useState([]);
  const [markerInfo, setMarkerInfo] = useState([]);
  const [state, setStateFinal] = useState({
    bounds: {},
    center: {
      lat: 39.214_255_554_898_46,
      lng: -76.771_235_312_470_55,
    },
    controls: {},
    kmlMarkers: [],
    kmlOptions: {},
    kmlRegions: [],
    kmlUrl: '',
    markers: [],
    zoom: 10,
  });
  const setState = useCallback(
    (newState) => {
      if (typeof newState === 'function') {
        setStateFinal(newState);
        return;
      }
      setStateFinal(newState);
    },
    [setStateFinal]
  );
  const [kmlDataProcessed, setKmlDataProcessed] = useState('');
  const [originalState, setOriginalState] = useState(state);
  const resetState = useCallback(() => {
    dispatch(navigateToPluginPage([MapsConstants.pluginId, MapsConstants.defaultPageId]));
  }, [dispatch, MapsConstants]);

  const handleSave = useCallback(async () => {
    const processedState = { ...state };
    const newIds = [];
    ['markers', 'kmlRegions', 'kmlMarkers'].forEach((key) => {
      processedState[key] = (processedState[key] || []).map((item) => {
        newIds.push(item.id);
        return omit(item, ['content', 'displayName']);
      });
    });

    const record = await mapsApi.saveRecord({
      id: mapId,
      data: processedState,
      title: processedState.title || 'New Map',
    });
    if (mapId === 'new') {
      dispatch(
        navigateToPluginPage([MapsConstants.pluginId, MapsConstants.editorPageName, record.id])
      );
      return;
    }
    setOriginalState(processedState);
  }, [state, setState, setOriginalState, originalState]);

  useKmlFileUrl(state, setState, kmlDataProcessed, setKmlDataProcessed);
  const getData = useCallback(async () => {
    try {
      if (mapId === 'new') return;
      const { data, info, markerInfo, title } = await mapsApi.loadRecord(mapId);
      if (info) setInfo(info);
      if (markerInfo) setMarkerInfo(markerInfo);
      if (data) {
        const newState = merge({}, { ...state }, { title }, data);
        setState(newState);
        setOriginalState(newState);
      } else {
        logger.error('MAP NOT FOUND: ', mapName);
      }
    } catch (error) {
      logger.error(`Error fetching data for ${mapName}`, error);
    }
  }, [setState, mapName]);

  useEffect(() => {
    getData();
  }, [getData, mapId]);

  const tableConfigs = [
    { stateKey: 'markers', title: 'Marker' },
    { stateKey: 'kmlRegions', title: 'KML Region', hideDelete: true },
    { stateKey: 'kmlMarkers', title: 'KML Marker', hideDelete: true },
  ];
  const hasZoomChanged = state.zoom !== originalState.zoom;
  const hasCenterChanged =
    state.center.lat !== originalState.center.lat || state.center.lng !== originalState.center.lng;
  return {
    hasCenterChanged,
    hasZoomChanged,
    handleSave,
    resetState,
    setState,
    state,
    originalState,
    tableConfigs,
    info,
    markerInfo,
  };
}

import React from 'react';
import AddCompoundCondition from './components/AddCompoundCondition';
import AddSimpleCondition from './components/AddSimpleCondition';
import RemoveCompoundCondition from './components/RemoveCompoundCondition';
import SelectCompoundConditionType from './components/SelectCompoundContitionType';

const CompoundCondition = (props) => {
  const { location, fieldName } = props;
  return (
    <div className="form-inline query-builder-expression">
      <SelectCompoundConditionType location={location} fieldName={fieldName} />
      <AddSimpleCondition location={location} fieldName={fieldName} />
      <AddCompoundCondition location={location} fieldName={fieldName} />
      {!!location && <RemoveCompoundCondition location={location} fieldName={fieldName} />}
    </div>
  );
};

export default CompoundCondition;

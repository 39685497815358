import React, { useState, useEffect } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import appUserApi from '../api/appUserApi';
import { AppUserConstants } from '../definitions';
import useAppUserActions from '../hooks/useAppUserActions';
import classNames from 'classnames';

const AppUsersListPage = () => {
  const [customColumns, setCustomColumns] = useState([]);
  const { defaultPageId, typeName } = AppUserConstants;
  const { add, edit, remove, exportCsv } = useAppUserActions(AppUserConstants);

  useEffect(() => {
    (async () => {
      const { columns } = await appUserApi.customColumns();
      const cols = columns.map((col) => ({
        label: col,
        renderer: (item) => {
          return item && item.customFields && item.customFields[col];
        },
        sortable: true,
        valueField: 'customFields',
      }));
      setCustomColumns(cols);
    })();
  }, []);

  const columns = [
    { initialSort: 'ASC', label: 'Last Name', sortable: true, valueField: 'lastName' },
    { label: 'First Name', sortable: true, valueField: 'firstName' },
    { label: 'Email', sortable: true, valueField: 'email' },
    {
      label: 'User Groups',
      renderer: (item) => {
        const [showTip, setShowTip] = useState(false);
        const val = item.userGroups && item.userGroups.length;
        const className = classNames('fa', 'fa-solid', { 'fa-users-line': Boolean(val) });
        if (!val) return '';
        return (
          <span className={'tooltip-container'} onClick={() => setShowTip(!showTip)}>
            <span className={className} />
            {showTip && (
              <span className={'tooltip-text-click'}>
                {item.userGroups.map((userGroup) => (
                  <div key={userGroup.name}>{userGroup.name}</div>
                ))}
              </span>
            )}
          </span>
        );
      },
      sortable: false,
      valueField: 'userGroups',
    },
  ].concat(customColumns);
  const toolbarActions = useArray(add, exportCsv);
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={'Users'}>
      <CmsItemListSection
        showTotal={true}
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={appUserApi}
        searchPlaceholder={'Search by Email, Last Name, or First Name'}
      />
    </CmsPage>
  );
};

export default AppUsersListPage;

import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
const FormSwitch = (props) => {
  const { fieldName, formContext, switchLabel, updateParent, fontWeight } = props;
  const [checked, setChecked] = useState(true);
  const { getValues } = formContext;
  const loadValue = getValues(fieldName);
  useEffect(() => {
    setChecked(!!loadValue);
  }, [loadValue]);

  const handleChange = (e) => {
    setChecked(e); //eslint-disable-next-line

    formContext.setValue(fieldName, e, {
      shouldDirty: true,
    });
    if (updateParent) updateParent(e);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Switch
        onChange={handleChange}
        checked={checked}
        onColor="#ACC0D0"
        onHandleColor="#245885"
        height={14}
        width={32}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      />
      <label>
        <div style={{ display: 'flex' }}>
          <span
            style={{
              marginLeft: '8px',
              marginTop: '4px',
              fontWeight: fontWeight,
            }}
          >
            {switchLabel}
          </span>
        </div>
      </label>
    </div>
  );
};

export default FormSwitch;

import React from 'react';

// MUI components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

// Third Party Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Project
import UploadFileButton from './UploadFileButton';

import manageAssetsApi from '../api/manageAssetsApi';

/**
 * A dialog window for uploading a file.
 */
export default function UploadFileDialog({ onAssetUploaded, asset, open, setOpen }) {
  // =========================================================================
  // State
  // =========================================================================
  const [file, setFile] = React.useState({
    name: '',
    extension: null,
    fileType: 'file',
  });
  const [error, setError] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [fileType, setFileType] = React.useState('file');
  const [assetGroup, setAssetGroup] = React.useState('default');

  // =========================================================================
  // Methods
  // =========================================================================
  const handleFileUpload = (file) => {
    setFile(file);
    setFileName(file.name);
    setFileType(file.fileType);
  };

  const handleFileName = (event) => {
    setFileName(event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleSubmit = async () => {
    const newAsset = {
      group: assetGroup,
      id: fileName,
      type: fileType,
      instanceMappings: asset?.instanceMappings,
    };
    try {
      await manageAssetsApi.upsertFile(
        file,
        asset ? { ...newAsset, id: asset.id } : newAsset,
        asset ? true : false
      );
      if (onAssetUploaded) {
        onAssetUploaded();
      }
      setOpen(false);
    } catch {
      setError('Error uploading file, please try again.');
    }
  };
  const handleAssetGroup = (event) => {
    setAssetGroup(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // =========================================================================
  // Template
  // =========================================================================
  return (
    <Dialog
      open={open}
      width="700px"
      maxWidth="sm"
      fullWidth={true}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1rem 20px',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          gap: '1.5rem',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <h2 style={{ fontSize: '20px', flex: '10000 1 0%', margin: 0 }}>
          {asset ? 'Replace File "' + asset.id + '"' : 'Upload File'}
        </h2>
        <IconButton style={{ width: 30, height: 30 }} onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </IconButton>
      </Box>
      <Divider />

      <DialogContent
        sx={{
          padding: '10px 0',
          margin: 0,
        }}
      >
        {error && <Alert severity="error">Error uploading file, please try again.</Alert>}
        <Box
          sx={{
            display: 'flex',
            padding: '0 20px',
            flexDirection: 'column',
            paddingTop: '.5rem',
            paddingBottom: '1rem',
            gap: '1rem',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <UploadFileButton onFileSelect={handleFileUpload} />
          <TextField
            required
            value={fileName}
            label="Filename"
            variant="standard"
            onChange={handleFileName}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControl required variant="standard" sx={{ flex: '10000 1 0%' }}>
              <InputLabel id="file-type-select-label">Asset Group</InputLabel>
              <Select
                required
                labelId="asset-group-select-label"
                id="asset-group-select"
                value={assetGroup}
                onChange={handleAssetGroup}
              >
                <MenuItem value={'default'}>Default</MenuItem>
                <MenuItem value={'theme'}>Theme</MenuItem>
                <MenuItem value={'interactivescanner'}>Interactive Scanner</MenuItem>
                <MenuItem value={'menuicon'}>Menu Icon</MenuItem>
              </Select>
            </FormControl>
            <FormControl required variant="standard" sx={{ flex: '10000 1 0%' }}>
              <InputLabel id="file-type-select-label">File Type</InputLabel>
              <Select
                value={fileType}
                onChange={handleFileTypeChange}
                required
                labelId="file-type-select-label"
                id="file-type-select"
              >
                <MenuItem value={'file'}>File</MenuItem>
                <MenuItem value={'image'}>Image</MenuItem>
                <MenuItem value={'css'}>CSS</MenuItem>
                <MenuItem value={'js'}>JavaScript</MenuItem>
                <MenuItem value={'json'}>JSON</MenuItem>
                <MenuItem value={'html'}>HTML</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px' }}
      >
        <Button variant="contained" onClick={handleClose} color="grey">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

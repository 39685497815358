import workflowApi from '../api/workflowApi';

const WorkflowColumns = [
  {
    label: 'Workflow Name',
    valueField: 'machineName',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Plugin Name',
    valueField: 'plugin',
    sortable: true,
  },
];

export default function useWorkflowConfigurations() {
  return {
    columns: WorkflowColumns,
    api: workflowApi,
  };
}

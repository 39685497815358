import React from 'react';

const FormRadioButton = ({
  checked,
  fieldName,
  formContext,
  formFieldOptions,
  label,
  name,
  value,
  shouldTrigger = false,
  labelStyle = {},
  ...inputAttributes
}) => {
  const { register, setValue, trigger } = formContext;
  const registered = register(fieldName, formFieldOptions);

  return (
    <div>
      <input
        type={'radio'}
        className="form-radio-row-input"
        name={name}
        checked={checked}
        value={value}
        {...inputAttributes}
        {...registered}
        onChange={() => {
          setValue(fieldName, value, { shouldValidate: true, shouldDirty: true });
          if (shouldTrigger) trigger(fieldName);
        }}
      />
      <label
        className="form-radio-row-label margin-left"
        style={{ ...labelStyle }}
        htmlFor={inputAttributes.id}
      >
        {label}
      </label>
    </div>
  );
};

export default FormRadioButton;

import isFunction from 'lodash/isFunction';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import { getCmsSurveyUrl, updateSurveyOptions } from '../api/surveysApi';
export default function useSurveyActions(pageConstants) {
  const dispatch = useDispatch();
  const [forceRefresh, setForceRefresh] = useState(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const onFocus = useCallback(async () => {
    if (isFunction(forceRefresh)) await forceRefresh();
  }, [forceRefresh, setForceRefresh]);
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);
  const { confirm } = useContext(CmsContext);
  const archiveCheckbox = useMemo(
    () => ({
      useSwitch: true,
      label: 'Hide Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
    }),
    [includeArchived, setIncludeArchived]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add Form`,
      onClick: async ({ reload }) => {
        try {
          setForceRefresh(() => reload);
          const url = await getCmsSurveyUrl();
          window.open(url);
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open form creator.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, setForceRefresh]
  );
  const refresh = useMemo(
    () => ({
      label: ``,
      icon: 'fa solid fa-arrow-rotate-right',
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to refresh.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          let confirmResponse = true;
          if (!context.isArchived) {
            confirmResponse = await confirm({
              title: 'Achive Form',
              message: `Are you sure you want to archive ${context.title}`,
            });
          }
          if (confirmResponse) {
            await updateSurveyOptions(context.id, {
              isArchived: !context.isArchived,
            });
            await reload();
            dispatch(successGrowl({ groupId: defaultPageId, props: 'Successfully updated form.' }));
          }
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to update form.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, confirm]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        try {
          setForceRefresh(() => reload);
          const url = await getCmsSurveyUrl(context.id, false);
          window.open(url);
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open form editor.' }));
        }
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName, setForceRefresh]
  );
  const manageInstances = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Instances',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, 'surveyInstancesPage', context.id]));
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName]
  );
  const duplicate = useMemo(
    () => ({
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      disabled: (context) => context.status === 'removed',
      onClick: async (context) => {
        try {
          const url = await getCmsSurveyUrl(context.id, true);
          window.open(url);
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open form creator.' }));
        }
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName]
  );
  return {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    includeArchived,
    manageInstances,
    refresh,
  };
}

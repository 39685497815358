import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CsvExportEditorForm from './CsvExportEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { AppUserConstants } from '../definitions';
import useAppUserActions from '../hooks/useAppUserActions';

const CsvExportEditorPage = () => {
  const { createReport, cancel } = useAppUserActions(AppUserConstants);
  const form = useCmsForm();
  return (
    <CmsPage title={`Report Recipients`}>
      <CsvExportEditorForm
        form={form}
        growlId={AppUserConstants.exportPageName}
        actions={[createReport(form), cancel]}
      />
    </CmsPage>
  );
};

export default CsvExportEditorPage;

(function () {
  angular
    .module('tenant-selector', ['ngCookies', 'config-service'])
    .directive('tenantSelector', function () {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modules/sidebar/tenantSelector.html',
        controller: [
          '$rootScope',
          '$scope',
          '$cookies',
          '$timeout',
          'configService',
          function ($rootScope, $scope, $cookies, $timeout, configService) {
            $scope.reload = function () {
              configService.getConfig().then(function (config) {
                $scope.tenantSelectorLabel =
                  config.messages && config.messages['cms.tenantSelectorLabel']
                    ? config.messages['cms.tenantSelectorLabel']
                    : 'Chapter';
                $scope.subtenantSelectorLabel =
                  config.messages && config.messages['cms.subtenantSelectorLabel']
                    ? config.messages['cms.subtenantSelectorLabel']
                    : 'Subtenant';
                $rootScope.tenants = config.accessibleTenants;
                $rootScope.showTenants = $scope.tenants && $scope.tenants.length > 1;
                $rootScope.showSubtenants = false;
                $rootScope.selectedTenant = null;
                $rootScope.selectedSubtenant = null;
                if ($rootScope.tenants) {
                  const selectedTenant = $rootScope.tenants.find(function (tenant) {
                    return tenant.tenantId === config.tenantId;
                  });
                  $rootScope.selectedTenant = selectedTenant;
                  $cookies.put('tenantId', $rootScope.selectedTenant.tenantId);
                  $rootScope.selectedSubtenant =
                    $rootScope.selectedTenant.subtenants[config.subtenantId];
                  $cookies.put('subtenantId', $scope.selectedSubtenant.id);
                  $rootScope.defaultSubtenant = _.values(selectedTenant.subtenants).find(function (
                    subtenant
                  ) {
                    return subtenant.isDefault;
                  });
                  $rootScope.showSubtenants = _.values($scope.selectedTenant.subtenants).length > 1;
                  $rootScope.defaultSubtenantIsSelected =
                    $scope.selectedSubtenant === $scope.defaultSubtenant;
                }
              });
            };
            $scope.setTenant = function (tenant) {
              $cookies.put('tenantId', tenant.tenantId);
              console.log(`Set tenant to : ${$cookies.get('tenantId')}`);
              $timeout(function () {
                location.replace('/');
              });
            };
            $scope.setSubtenant = function (subtenant) {
              $cookies.put('subtenantId', subtenant.id);
              console.log(`Set subtenant to : ${$cookies.get('subtenantId')}`);
              $timeout(function () {
                location.replace('/');
              });
            };
            $scope.sortSubtenants = function (tenant) {
              return Object.values(tenant.subtenants).sort((s1, s2) => {
                const name1 = s1.name.toLocaleLowerCase();
                const name2 = s2.name.toLocaleLowerCase();
                if (name1 === 'default') return -1;
                if (name2 === 'default') return 1;
                return name1.localeCompare(name2);
              });
            };
            $scope.$on('subtenantUpdated', function () {
              configService.reset();
              $scope.reload();
            });
            $scope.reload();
          },
        ],
      };
    });
})();

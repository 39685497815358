import itrsApi from '../api/itrsApi';

const CourseColumns = [
  {
    label: 'Course Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: true,
  },
  {
    label: 'Number of Sessions',
    valueField: 'sessionCount',
    sortable: true,
  },
];

export default function useCourseConfigurations() {
  return {
    columns: CourseColumns,
    api: itrsApi,
  };
}

(function () {
  angular.module('create-asset', ['content-api-service']).controller('EditFileCtrl', [
    '$scope',
    '$log',
    '$timeout',
    'growl',
    'contentApiService',
    'instanceService',
    function ($scope, $log, $timeout, growl, contentApiService, instanceService) {
      $scope.asset = $scope.asset ? angular.copy($scope.asset) : {};
      $scope.types = contentApiService.getTypes();
      $scope.groups = contentApiService.getGroups();
      $scope.replaceMode = !!$scope.replaceMode;
      const activeRevision = $scope.asset ? getActiveRevision($scope.asset) : undefined;
      $scope.asset.group = activeRevision ? activeRevision.group : 'default';
      $scope.modeLabel = $scope.replaceMode ? 'Edit' : 'Add';
      $scope.fileSelected = function (file) {
        if (file && !$scope.replaceMode) {
          $timeout(function () {
            $scope.asset.id = contentApiService.scrubAssetId(file.name);
            $scope.asset.type = contentApiService.getTypeFromExtension(file.name).id;
          });
        }
      };
      $scope.typeUpdated = function () {
        const selectedType = $scope.asset.type;
        if (selectedType != contentApiService.getTypeFromExtension($scope.asset.id).id) {
          $scope.asset.id = replaceExtension(
            $scope.asset.id,
            contentApiService.lookupType(selectedType)
          );
        }
      };
      $scope.cancel = function () {
        $scope.$dismiss();
      };
      $scope.save = function () {
        contentApiService
          .upsertFile($scope.files[0], $scope.asset, $scope.replaceMode)
          .progress(function () {})
          .success(function (data) {
            if (data.status == 'error') growl.error(data.error);
            else {
              $scope.$close(data);
            }
          })
          .error(function (err) {
            growl.error(err.error || 'Asset upload failed.  Please try again later.');
          });
      };
      function replaceExtension(fileName, type) {
        if (type.id != 'file' && !endsWith(fileName, type.extension)) {
          const parts = fileName.split('.');
          if (parts.length == 1) return fileName + type.extension;
          return parts.slice(0, -1).join('.') + type.extension;
        }
        return fileName;
      }
      function endsWith(str, suffix) {
        return str.match(`${suffix}$`) == suffix;
      }
      function getActiveRevision(record) {
        return contentApiService.getRevisionForInstance(
          record,
          instanceService.getActiveInstance().id,
          true
        );
      }
    },
  ]);
})();

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToChildPage } from '../../store/rootReducer';
import { isSystemMenu } from '../menuUtils';
const MenuName = ({ menu }) => {
  const { name } = menu;
  const isSystem = useMemo(() => isSystemMenu(menu), [menu]);
  const dispatch = useDispatch();
  const navigateToChild = useCallback(() => {
    dispatch(
      navigateToChildPage(['menu-manager', 'menuEditorPage', 'menu-list-page', null, menu.id])
    );
  }, [dispatch, menu]);
  return (
    <>
      <a onClick={navigateToChild}>{name}</a>
      {isSystem && <span className="value-detail">(System Menu)</span>}
    </>
  );
};

export default MenuName;

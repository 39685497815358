import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setShowModal } from '../store';

const LinkButton = (props) => {
  const dispatch = useDispatch();
  const {
    isRequired = false,
    selectFieldText = 'Select',
    selectTextOnNewLine = false,
    fieldName,
  } = props;
  const Parent = useCallback(
    ({ children }) =>
      selectTextOnNewLine ? (
        <div
          style={{
            marginLeft: '3rem',
          }}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      ),
    [selectTextOnNewLine]
  );
  return (
    <Parent>
      {!selectTextOnNewLine && '('}
      <span
        className="link-button"
        onClick={() =>
          dispatch(
            setShowModal({
              showModal: true,
              fieldName,
            })
          )
        }
      >
        {selectFieldText}
      </span>
      {!selectTextOnNewLine && ')'}
      {isRequired && <span className="required-annotation">*</span>}
    </Parent>
  );
};

export default memo(LinkButton);

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/new-maps/api';

const loadRecord = async (id) => {
  if (id === 'new')
    return {
      title: '',
      data: {
        bounds: {},
        center: {
          lat: 39.214_255_554_898_46,
          lng: -76.771_235_312_470_55,
        },
        controls: {},
        kmlMarkers: [],
        kmlOptions: {},
        kmlRegions: [],
        kmlUrl: '',
        markers: [],
        zoom: 10,
      },
    };
  try {
    const { data } = await axios.get(`${baseUrl}/getMap`, { params: { id } });
    //splice any objects in data.mapInfo into data.map.kmlRegions
    if (data?.map?.info && data?.map?.data?.kmlRegions) {
      data.map.data.kmlRegions = data.map.data.kmlRegions.map((kr) => {
        const info = data.map.info.find((i) => `${i.id}` === `${kr.name}`);
        return info ? { ...kr, ...info } : kr;
      });
    }
    if (data?.map?.markerInfo && data?.map?.data?.markers) {
      data.map.data.markers = data.map.data.markers.map((kr) => {
        const markerInfo = data.map.markerInfo.find((i) => `${i.id}` === `${kr.name}`);
        return markerInfo ? { ...kr, ...markerInfo } : kr;
      });
    }
    if (data?.map?.info && data?.map?.data?.kmlMarkers) {
      data.map.data.kmlMarkers = data.map.data.kmlMarkers.map((kr) => {
        const markerInfo = data.map.info.find((i) => `${i.id}` === `${kr.name}`);
        return markerInfo ? { ...kr, ...markerInfo } : kr;
      });
    }
    if (data?.map?.data?.center?.latitude) {
      data.map.data.center.lat = data.map.data.center.latitude;
      delete data.map.data.center.latitude;
    }
    if (data?.map?.data?.center?.longitude) {
      data.map.data.center.lng = data.map.data.center.longitude;
      delete data.map.data.center.longitude;
    }
    return data.map;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load map.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteMap`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove map.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getAllMaps`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: rows,
      numRecords: count,
      offset: otherParams?.offset || 0,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load maps');
  }
};

const saveRecord = async (map) => {
  const url = !map.id || map.id === 'new' ? `${baseUrl}/createMap` : `${baseUrl}/updateMap`;
  try {
    const { data } = await axios.post(url, map);
    return data.map;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save map.');
  }
};
const mapsApi = { loadRecord, loadRecords, saveRecord, removeRecord };

export default mapsApi;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkCanPublish } from './store/thunks';

export default function useSnapshotChecker() {
  const [hasChecked, setHasChecked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!hasChecked) {
      dispatch(checkCanPublish({ hasChecked: false }));
      setHasChecked(true);
    }
  }, [dispatch, checkCanPublish, hasChecked, setHasChecked]);
}

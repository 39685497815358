import get from 'lodash/get';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Table from './Table';
import TableEditorProvider from './TableContext';
import useRows from './useRows';
import isEmpty from 'lodash/isEmpty';
import log from '../../logger';

export default function useMuiDndEditorTable({
  form,
  formRowsKey,
  columns: preColumns = [],
  defaultRow = {},
  canDrag = true,
  fontSize = '16px',
}) {
  defaultRow = useMemo(
    () =>
      isEmpty(defaultRow) && !isEmpty(preColumns)
        ? preColumns.filter((c) => c.field).reduce((a, c) => ({ ...a, [c.field]: '' }), {})
        : defaultRow,
    [preColumns, defaultRow]
  );
  const [hasDragged, setHasDragged] = useState(false);
  const [editIdx, setEditIdx] = useState(-1);
  const [editField, setEditField] = useState('');
  const [sort, setSortConfig] = useState({ key: null, dir: 'asc' });
  const [hasLoadedOptions, setHasLoadedOptions] = useState(false);
  const [columns, setColumns] = useState(preColumns);
  useEffect(() => {
    if (!hasLoadedOptions) {
      const load = async () => {
        const newColumns = await Promise.all(
          columns.map(async (col) => {
            if (col.type === 'dropdown' && col.getOptions) {
              try {
                col.options = await col.getOptions();
              } catch (err) {
                col.options = [];
                log.error(err);
              }
            }
            return col;
          })
        );
        setColumns(newColumns);
        setHasLoadedOptions(true);
      };
      load();
    }
  }, [setHasLoadedOptions, setColumns, columns, hasLoadedOptions]);

  const handleFieldClick = useCallback(
    (idx, field) => {
      setEditIdx(idx);
      setEditField(field);
    },
    [setEditIdx, setEditField]
  );
  const { setRow, setRows, deselect, setRowField, localRows } = useRows({
    form,
    formRowsKey,
    handleFieldClick,
  });

  const addRow = () => {
    const idx = Math.max(localRows.length || 0, 0);
    setRow(idx, defaultRow, false, false);
  };

  const requestSort = (key) => {
    const dir = sort.key === key && sort.dir === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, dir });

    const sortedRows = [...localRows].sort((a, b) => {
      if (a[key] < b[key]) return dir === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return dir === 'asc' ? 1 : -1;
      return 0;
    });
    setRows(sortedRows);
  };

  const removeRow = (idx) => {
    setRows(localRows.filter((_, i) => i !== idx));
  };

  const handleReorder = (startIdx, endIdx) => {
    const newRows = [...localRows];
    const [removed] = newRows.splice(startIdx, 1);
    newRows.splice(endIdx, 0, removed);
    setRows(newRows);
  };
  const errors = get(form, `formState.errors.${formRowsKey}`);
  const rows = localRows.map((row, idx) => ({ FIELD_ERRORS: get(errors, `[${idx}]`), ...row }));
  const PreRenderedTable = (
    <TableEditorProvider
      context={{
        canDrag,
        columns,
        deselect,
        editField,
        editIdx,
        handleFieldClick,
        handleReorder,
        hasDragged,
        removeRow,
        requestSort,
        rows,
        setHasDragged,
        setRowField,
        sort,
        fontSize,
      }}
    >
      <Table />
    </TableEditorProvider>
  );
  return { addRow, PreRenderedTable };
}

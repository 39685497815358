import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

function ImageAlert(props) {
  let isMounted = true;
  const [show, setShow] = useState(true);
  const { errors, file, variant } = props;
  useEffect(() => {
    if (file) {
      setTimeout(() => {
        if (isMounted) setShow(false);
      }, 3000);
    }
    return () => {
      isMounted = false;
    };
  }, [file]);

  const customizeErrorMessage = (errors) => {
    return errors
      .map((error) => {
        if (error.code === 'file-too-large') {
          const match = error.message.match(/larger than (\d+) bytes/);
          if (match && match[1]) {
            const maxSizeBytes = Number.parseInt(match[1], 10);
            const maxSizeMB = maxSizeBytes / 1024 / 1024;
            return `File is too large. Maximum size is ${maxSizeMB.toFixed(2)} MB.`;
          }
        }
        return error.message;
      })
      .join(', ');
  };

  return (
    show && (
      <Alert
        style={{
          opacity: 1,
          padding: 5,
        }}
        variant={variant}
        key={file.path}
      >
        <div
          style={{
            fontSize: 12,
          }}
        >
          <strong>{`${variant === 'danger' ? 'Rejcted' : 'Accepted'}`}</strong> {file.path}
        </div>
        {variant === 'danger' && <p>{customizeErrorMessage(errors)}</p>}
      </Alert>
    )
  );
}

export default ImageAlert;

export default function useKmlTableOptions() {
  return {
    filterType: 'textField',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    searchPlaceholder: 'Search KML Regions',
    searchOpen: false,
    textLabels: { body: { noMatch: 'Sorry, no matching regions found' } },
    setRowProps: () => ({ style: { cursor: 'pointer' } }),
    setTableProps: () => ({
      size: 'small',
      sx: {
        '& .MuiTableCell-head': {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
        '& .MuiTableCell-body': {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
    }),
    rows: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
  };
}

import React, { useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useListLoader } from '../../shared/cms/hooks/useListLoader';
import { useListLoaderParams } from '../../shared/cms/hooks/useListLoaderParams';
import CmsListSectionToolbar from '../../shared/cmsPage/components/CmsListSectionToolbar';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import cohortApi from '../api/cohortApi';
import generateCohortMembersApi from '../api/cohortMembersApi';
import cohortMemberColumns from '../constants/cohortMemberColumns';
import { CohortConstants } from '../definitions';
import useCohortEditorActions from '../hooks/useCohortEditorActions';
import useReload from '../hooks/useReload';
import CohortEditorForm from './CohortEditorForm';

const { pluginId, typeName, editorPageId, pageTitle: parentPageTitle } = CohortConstants;

const CohortEditorPage = ({ cohortId }) => {
  const { loadRecords: load } = useMemo(() => generateCohortMembersApi(cohortId), [cohortId]);
  const { item, loadItem, requestPending: pend } = useItemPersistence(cohortApi);
  const { loadItems, loading, records, pagination, sort } = useListLoader(load);

  const requestPending = pend || loading;
  const { loaderParams, onPageSelected, onSearchChange, onSortChange } = useListLoaderParams(
    editorPageId,
    10,
    cohortMemberColumns
  );
  const { reload, reloadPageData } = useReload({ cohortId, loadItem, loadItems, loaderParams });

  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    typeName,
    'name',
    cohortApi,
    CohortEditorForm
  );

  const { back, pageTitle, toolbarActions, SheetModal } = useCohortEditorActions({
    cohortId,
    item,
    loadItem,
    loaderParams,
    parentPageTitle,
    pluginId,
    reload,
    reloadPageData,
    showItemEditorModal,
  });

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-editor">
        <div className="row">
          <CmsListSectionToolbar
            searchHeader="Search by Code, Name, or Email"
            actions={toolbarActions}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            backPageAction={back}
          />
        </div>
        <GrowlContainer groupId={editorPageId} />
        <div className="row">
          <ItemTable
            columns={cohortMemberColumns}
            items={records}
            itemActions={[]}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
          />
        </div>
        <div className="cohort-members-legend">
          <div className="legend-item">
            <i className="fa-regular fa-envelope" /> - authenticated via Email Address
          </div>
          <div className="legend-item">
            <i className="fa-regular fa-ticket" /> - authenticated via Credential Code
          </div>
        </div>
        <div className="cohort-footer">
          <a
            download="cohort-import-template.csv"
            href="/files/cohort-import-template.csv"
            target="_self"
          >
            Download Import Template
          </a>
        </div>
      </CmsPageSection>
      {SheetModal}
      <ItemEditorModal {...editorModalProps} />
    </CmsPage>
  );
};

export default CohortEditorPage;

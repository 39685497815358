import React, { createContext, useEffect, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';
import { initialState, reducer } from './reducer';
import useDispatchFunctions from './useDispatchFunctions';
export const ImageContext = createContext({});
export default function Context(props) {
  const {
    acceptedFileTypes = [],
    children,
    dropzoneSettings = {},
    setFormValue,
    currentAssetId,
    defaultAssetId,
    useCropper,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { initializeAssets, setFiles, setSelectedFilePath, setCroppedImage } = useDispatchFunctions(
    setFormValue,
    dispatch,
    defaultAssetId,
    useCropper
  );
  useEffect(() => {
    if (defaultAssetId || currentAssetId) {
      (async () => {
        await initializeAssets(defaultAssetId, currentAssetId);
      })();
    }
  }, [currentAssetId, defaultAssetId, initializeAssets]);
  const dropzone = useDropzone({
    accept: {
      'image/*':
        acceptedFileTypes.length > 0 ? acceptedFileTypes : ['.png', '.jpg', '.jpeg', '.svg'],
    },
    maxFiles: 1,
    onDrop: setFiles,
    noKeyboard: true,
    preventDropOnDocument: true,
    noClick: true,
    ...dropzoneSettings,
  });
  return (
    //eslint-disable-next-line

    <ImageContext.Provider value={{ ...dropzone, ...state, setSelectedFilePath, setCroppedImage }}>
      {children}
    </ImageContext.Provider>
  );
}

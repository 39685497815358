import React, { useEffect, useCallback } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { CollectionEditorConstants } from '../definitions';
import collectionApi from '../api/collectionApi';
import CollectionEditorForm from './CollectionEditorForm';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';

const CollectionSettingsPage = ({ collectionId }) => {
  const { item, loadItem, saveItem } = useItemPersistence(collectionApi);
  const runLoad = useCallback(async () => {
    await loadItem(collectionId);
  }, [collectionId, loadItem]);

  useEffect(() => {
    if (collectionId) runLoad();
  }, [collectionId, runLoad]);
  const form = useCmsForm(item);

  const actions = useItemEditActions(
    saveItem,
    form,
    CollectionEditorConstants,
    collectionId,
    'collection_id'
  );

  return (
    <CmsPage title={`${collectionId === 'new' ? 'Add' : 'Edit'} Collection`} requestPending={!item}>
      {item && (
        <CmsItemEditSection pageId={CollectionEditorConstants.editorPageId}>
          <CollectionEditorForm
            form={form}
            growlId={CollectionEditorConstants.editorPageId}
            actions={actions}
            collection={item}
          />
        </CmsItemEditSection>
      )}
    </CmsPage>
  );
};

export default CollectionSettingsPage;

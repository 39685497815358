import axios from 'axios';

const baseUrl = '/plugins/quizzes/api';

export const getQuiz = async (id) => {
  const resp = await axios.get(`${baseUrl}/getQuiz`, { params: { id }, timeout: 10_000 });
  return resp.data;
};

export const createQuiz = async (customization = {}, quiz = null) => {
  const resp = await axios.post(`api/v1/quiz/`, { customization, quiz });
  return resp.data;
};

export const listQuizzes = async (accountId) => {
  const resp = await axios.get(`api/v1/quiz`, { params: { accountId } });
  return resp.data;
};

export const updateQuiz = async (id, quiz) => {
  const resp = await axios.put(`${baseUrl}/updateQuiz`, { quiz, quizId: id }, { timeout: 10_000 });
  return resp.data;
};

export const deleteQuiz = async (id) => {
  const resp = await axios.delete(`api/v1/quiz/${id}`);
  return resp.data;
};

export const getUploadUrl = async (fileName, contentType, quizId) => {
  const resp = await axios.get(`api/v1/quiz/image/upload`, {
    params: { contentType, fileName, quizId },
  });
  return resp.data;
};

export const putQuizAttempt = async (id, registrationId, instanceId, answers, otherData) => {
  const resp = await axios.put(`api/v1/quiz/${id}/attempts/${registrationId}`, {
    answers,
    instanceId,
    ...otherData,
  });
  return resp.data;
};

export const getCompleteQuizAttempts = async (id, instanceId) => {
  const resp = await axios.get(`api/v1/quiz/${id}/attempts`, { instanceId });
  return resp.data;
};

export const completeQuizAttempt = async (id, instanceId, answers, otherData) => {
  const resp = await axios.post(`api/v1/quiz/${id}/attempts`, {
    answers,
    instanceId,
    ...otherData,
  });
  return resp.data;
};

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import calendarEventApi from '../api/calendarEventsApi';
import { CalendarEventConstants } from '../definitions';
export default function useCalendarEventActions(pageConstants) {
  const dispatch = useDispatch();
  const { edit, remove, requestPending } = useItemListActions(
    calendarEventApi,
    CalendarEventConstants
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Add Event`,
      onClick: async () => {
        try {
          const newCalendarEvent = await calendarEventApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              CalendarEventConstants.pluginId,
              CalendarEventConstants.editorPageName,
              newCalendarEvent.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, dispatch]
  );

  return {
    add: addOverride,
    edit,
    remove,
    requestPending,
  };
}

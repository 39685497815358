import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../store/rootThunks';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { getAllUserActivity, getUniqueUserActivity, getAllUsersExport } from '../api/reportingApi';

const ReportingPage = () => {
  const dispatch = useDispatch();
  const [selectedReport, setSelectedReport] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 999));
  const { cmsConfig, confirm } = useContext(CmsContext);
  const growlReference = 'reportingGrowl';
  const pageTitle = 'Reports';
  const reports = [
    {
      reportId: 'all_users',
      reportName: 'Users / Groups',
      isExport: true,
      reportGenerator: getAllUsersExport,
      reportButtonLabel: 'Generate Report',
    },
    {
      reportId: 'activity_all',
      reportName: 'Active Users',
      reportGenerator: getAllUserActivity,
      isExport: true,
      dateRange: true,
    },
    {
      reportId: 'activity_unique_user',
      reportName: 'Active Unique Users',
      reportGenerator: getUniqueUserActivity,
      isExport: true,
      dateRange: true,
    },
  ].filter((report) => !report.enabledCheck || report.enabledCheck(cmsConfig));

  const openConfirm = async () => {
    if (!selectedReport) {
      dispatch(
        errorGrowl({ groupId: growlReference, props: 'Please select a report to generate.' })
      );
      return;
    }

    try {
      if (selectedReport.isExport) {
        const confirmed = await confirm({
          title: `Export Report`,
          message: `You will receive a notice in your email when the report is ready to download.`,
        });

        if (confirmed) selectedReport.reportGenerator({ startDate, endDate });
      }
    } catch (e) {
      log.error(e);
      dispatch(
        errorGrowl({ groupId: growlReference, props: 'Unexpected error when generating report.' })
      );
    }
  };
  return (
    <CmsPage title={pageTitle}>
      <form role="form" className="form-inline no-left-padding neg-left-margin-13">
        <select
          className="form-control margin-right"
          onChange={(e) => {
            setSelectedReport(reports.find((r) => r.reportId === e.target.value));
          }}
        >
          <option>Select</option>
          {reports
            .sort((a, b) => a.reportName.localeCompare(b.reportName))
            .map((report) => (
              <option key={report.reportId} value={report.reportId}>
                {report.reportName}
              </option>
            ))}
        </select>

        <button
          className="btn btn-primary btn-xs edit-btn"
          type="button"
          onClick={openConfirm}
          disabled={!selectedReport}
        >
          Generate Report
        </button>
        {selectedReport && selectedReport.dateRange && (
          <div className="top-margin">
            <label className="margin-right">Start Date</label>
            <input
              className="form-control margin-right"
              type="date"
              onChange={(e) => {
                setStartDate(new Date(new Date(e.target.value).setHours(0, 0, 0, 0) + 86_400_000));
                log.info('start date', e.target.value, startDate);
              }}
            />
            <label className="margin-right">End Date</label>
            <input
              className="form-control margin-right"
              type="date"
              onChange={(e) => {
                setEndDate(
                  new Date(new Date(e.target.value).setHours(23, 59, 59, 999) + 86_400_000)
                );
                log.info('end date', e.target.value, endDate);
              }}
            />
          </div>
        )}
      </form>
    </CmsPage>
  );
};

export default ReportingPage;

import { useCallback, useState } from 'react';
export const useSubItemPersistence = (itemApi) => {
  const [subItemRequestPending, setSubItemRequestPending] = useState(false);
  const [item, setItem] = useState(null);
  const { publishRecords } = itemApi;
  const publishItems = useCallback(
    async (itemIds) => {
      setSubItemRequestPending(true);

      try {
        const item = await publishRecords(itemIds);
        setItem(item);
      } finally {
        setSubItemRequestPending(false);
      }
    },
    [publishRecords]
  );
  return {
    subItemRequestPending,
    item,
    publishItems,
  };
};

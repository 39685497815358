/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { CmsContext } from '../../../context/definitions';
import { getWysiwygOptions } from '../../../../common/getWysiwygOptions';
import _ from 'lodash';
const wysiwygListener = {
  onCodeViewChanged(active, editor) {},
  onImageInserted(e, editor) {},
  onInitialized(e, editor) {},
  onVideoInserted(e, editor) {},
};

const useWysiwygOptions = (overrideGrowlCallback) => {
  const [error, setError] = useState(undefined);
  const [wysiwygOptions, setWysiwygOptions] = useState();
  const { cmsConfig } = useContext(CmsContext);
  useEffect(() => {
    const loadOptions = async () => {
      try {
        const wysiwygOptions = await getWysiwygOptions(
          cmsConfig,
          wysiwygListener,
          overrideGrowlCallback || setError
        );
        setWysiwygOptions(wysiwygOptions);
      } catch (error) {
        setError('Failed to load wysiwyg options');
      }
    };

    (async () => loadOptions())();
  }, []);
  return { wysiwygOptions, error };
};

export default useWysiwygOptions;

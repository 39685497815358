import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { conditionToText } from '../cms/cmsUtils';

const createConditionColumn = (label, valueField, faIcon, opts) => {
  return {
    label,
    valueField,
    align: 'center',
    renderer: (item) => {
      const [showTip, setShowTip] = useState(false);
      const val = get(item, valueField, {});
      const className = classNames('fa', 'fa-solid', {
        [faIcon]: !!val,
      });
      const objCondition = typeof val === 'string' ? JSON.parse(val) : val;

      const handleClickAway = () => {
        setShowTip(false);
      };

      return (
        val &&
        !isEmpty(val) && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <span className={'tooltip-container'} onClick={() => setShowTip(!showTip)}>
              <span className={className} />
              {showTip && (
                <span className={'tooltip-text-click'}>{conditionToText(objCondition, opts)}</span>
              )}
            </span>
          </ClickAwayListener>
        )
      );
    },
  };
};

export default createConditionColumn;

import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/lead-generation/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getImports`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { results: records, count: numRecords, offset } = data;
    result = { records, numRecords, offset };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load imports');
  }
  return result;
};

const loadRecord = async (id) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getImport`, {
      params: { id },
      timeout: 10_000,
    });
    return data.import;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load import.');
  }
};

const saveRecord = async (item) => {
  try {
    const { data } = await axios.post(`${baseUrl}/saveImport`, { ...item }, { timeout: 10_000 });
    return data.import;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save import.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeImport`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove import.');
  }
};

export const saveImportData = async (leadGenId, data, shouldErasePrevious) => {
  try {
    await axios.post(`${baseUrl}/saveImportData`, { data, leadGenId, shouldErasePrevious });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save import data.');
  }
};

export default {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};
export const getExternalLeadGenEventMembers = async (leadGenId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getExternalLeadGenEventMembers`, {
      params: { leadGenId },
      timeout: 10_000,
    });
    return { errors: data.errors };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load external lead gen event members.');
  }
};

import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const FormInput = (props) => {
  const {
    label,
    fieldName,
    localTimezone,
    isDateOnly = false,
    placeholder,
    formFieldOptions = {},
    formContext,
    fieldWidth,
    fontWeight,
    className: providedClassName,
    ...inputAttributes
  } = props;

  const { formState, register, setValue, watch } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);
  const { onBlur, name } = register(fieldName, {
    //for some reason this validate prevents it from messing up if one isn't passed in
    validate: () => true,
    ...formFieldOptions,
  });

  const [inputValue, setInputValue] = useState('');
  const [hasSetInitial, setHasSetInitial] = useState(false);

  const value = watch(fieldName);

  useEffect(() => {
    if (value && !hasSetInitial) {
      setInputValue(moment(value).format('YYYY-MM-DDTHH:mm'));
      setHasSetInitial(true);
    }
  }, [value, hasSetInitial]);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ fontWeight: fontWeight }}>
        {`${label}${localTimezone ? ` (${tz})` : ''}`}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <input
        value={inputValue}
        type={isDateOnly ? 'date' : 'datetime-local'}
        className="form-control"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...{ onBlur, name }}
        onChange={async (e) => {
          const v = e.target.value;
          setInputValue(v);
          const localIsoTime = v ? moment(v, 'YYYY-MM-DDTHH:mm').toISOString() : null;
          await setValue(fieldName, localIsoTime, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInput;

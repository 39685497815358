import React, { useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import { AchievementInstanceConstants } from '../definitions';
import useAchievementInstanceConfigurations from '../hooks/useAchievementInstanceConfigurations';
import useServiceData from '../hooks/useServiceData';
import _ from 'lodash';

const AchievementInstanceEditor = (props) => {
  const { itemId, onItemChange, achievement, growlId } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { api } = useAchievementInstanceConfigurations(achievement.id);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, AchievementInstanceConstants, achievement.id);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, AchievementInstanceConstants);
  const { options, requestPending: dataRequestPending } = useServiceData('achievementTemplates');
  const anyRequestPending = dataRequestPending || requestPending;
  useSpinner(spinnerProvider, anyRequestPending);
  return (
    !anyRequestPending && (
      <>
        <div className="row">
          <form role="form">
            <FormInput
              label="Name"
              fieldName="name"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required.',
              }}
            />
            <div className="form-border-bottom form-border-top">
              {_.map(achievement.included, (v, fieldId) => {
                if (!v) return;
                const field = options.fields.find((f) => f.id === fieldId);
                if (!field) throw new Error('No field found for field id ' + fieldId);
                if (field.dynamic) return '';
                const fieldValue = item.included[fieldId] || '';
                return (
                  <div key={fieldId}>
                    <label>
                      {field.displayName} <span className="required-annotation">*</span>
                      <input
                        type={field.type || 'text'}
                        className="form-control"
                        defaultValue={fieldValue}
                        {...form.register(`included.${fieldId}`)}
                      />
                    </label>
                  </div>
                );
              })}
            </div>

            <AudienceSelector
              formContext={form}
              label="Recipient Audience"
              fieldName="recipients"
              growlId={growlId}
            />

            <AudienceSelector
              formContext={form}
              label="Issuer Audience"
              fieldName="issuers"
              growlId={growlId}
            />
          </form>
        </div>
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </>
    )
  );
};

export default AchievementInstanceEditor;

import React from 'react';
import ItemTableCell from './ItemTableCell';
import ItemActionList from './ItemActionList';

const ItemTableRow = (props) => {
  const { item, columns, itemActions } = props;
  const showActionList = itemActions?.length > 0;
  return (
    <>
      {columns.map((column, index) => (
        <ItemTableCell key={index} item={item} column={column} />
      ))}
      {showActionList && (
        <td>
          <ItemActionList item={item} actions={itemActions} />
        </td>
      )}
    </>
  );
};

export default ItemTableRow;

import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import CmsPageSectionFooter from '../../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../../shared/form/components/FormInput';
import FormLinksImage from '../../../shared/form/components/FormLinksImage';
import FormSwitchControlled from '../../../shared/form/components/FormSwitchControlled';
import FormWysiwyg from '../../../shared/form/components/FormWysiwyg';
import SelectSwitch from '../../../shared/form/components/SelectSwitch';
import { getCollectionTypes } from '../../api/collectionItemApi';
import { options, home, links, menu, presets } from './definitions';

const CollectionEditorForm = ({ form, actions, collection, growlId }) => {
  const { setValue, watch } = form;
  const [viewType, setViewType] = useState([]);
  useEffect(() => {
    (async () => {
      const types = await getCollectionTypes();
      setViewType(types);
    })();
    setViewType(viewType);
  }, [setViewType]);
  const us = (chk, field = 'disabled') =>
    useState(get(presets, `${collection}.type.${field}`, []).includes(chk) || false);
  const ucs = (field, def = false) => useState(collection[field] || def);

  const [useImg, setUseImg] = ucs('useImage');
  const [collectionTitleDisplay, setTitleDisplay] = ucs('collectionTitleDisplay');
  const [wysDisplay, setWysDisplay] = ucs('collectionWysDisplay');
  const [currentType, setCurrentType] = ucs('type', 'standard');
  const [filterDate, setFilterDate] = ucs('filterDate');
  const [filterLive, setFilterLive] = ucs('filterLive');
  const [filterPast, setFilterPast] = ucs('filterPast');
  const [toolFavorite, setToolFavorite] = ucs('toolFavorite');
  const [toolSearch, setToolSearch] = ucs('toolSearch');
  const [toolViewSelect, setToolViewSelect] = ucs('toolViewSelect');
  const [displayToolbar, setDisplayToolbar] = ucs('displayToolbar');
  const [filterCategory, setFilterCategory] = ucs('filterCategory');
  const [assignDefaultOptions, setAssignDefaultOptions] = useState([]);

  const [disabledCategoryFilter, setDisabledCategoryFilter] = us('category');
  const [disabledDateFilter, setDisabledDateFilter] = us('dateFilter');
  const [disabledFavs, setDisabledFavs] = us('favs');
  const [disabledImg, setDisabledImg] = us('lockImg');
  const [disabledLiveEventFilter, setDisabledLiveEventFilter] = us('liveEvent');
  const [disabledPastEvents, setDisabledPastEvents] = us('pastEvents');
  const [disabledSearch, setDisabledSearch] = us('search');
  const [disabledToolBar, setDisabledToolBar] = us('toolBar');
  const [disabledView, setDisabledView] = us('lockView');
  const [disabledViewSelector, setDisabledViewSelector] = us('viewSelector');
  const [headerTextHidden, setHeaderTextHidden] = us('headerText', 'hidden');

  const currentUseImage = watch('useImage');

  useEffect(() => {
    if (currentType) updateOptions(currentType);
    if (currentType != 'agenda') {
      setHeaderTextHidden(true);
    }
  }, [currentType]);

  useEffect(() => {
    if (currentType == 'standard' || ('agenda' && currentUseImage === false)) {
      setValue('toolViewSelect', false, { shouldTouch: true });
      setToolViewSelect(false);
    }
  }, [currentUseImage]);

  const updateOptions = (e) => {
    const opts = {
      agenda: { disabledView: false, options },
      home: { options: home },
      icons: { disabledView: false, options },
      links: { options: links },
      menu: { options: menu },
      standard: { disabledView: false, options },
    };

    setAssignDefaultOptions(get(opts, `${e}.options`, options));
    setDisabledView(get(opts, `${e}.disabledView`, true));
  };

  const handler = (value) =>
    ({
      useImage: (e) => setUseImg(e),
      displayToolbar: (e) => setDisplayToolbar(e),
      toolSearch: (e) => setToolSearch(e),
      toolFavorite: (e) => setToolFavorite(e),
      toolViewSelect: (e) => setToolViewSelect(e),
      filterLive: (e) => setFilterLive(e),
      filterCategory: (e) => setFilterCategory(e),
      filterDate: (e) => setFilterDate(e),
      filterPast: (e) => setFilterPast(e),
      collectionTitleDisplay: (e) => setTitleDisplay(e),
      collectionWysDisplay: (e) => setWysDisplay(e),
    }[value] || (() => {}));

  const presetChanged = (e) => {
    updateOptions(e);
    handler('type')(e);
    const preset = presets[e];
    const ds = (type) => get(presets, `${e}.disabled`, []).includes(type);
    const st = (key) => {
      const val = get(presets, `${e}.${key}`);
      setValue(key, val, { shouldTouch: true });
      return val;
    };

    setWysDisplay(st('wysDisplay'));
    setValue('defaultView', preset.defaultView, { shouldTouch: true });
    setValue('label', preset.collectionTitle, { shouldTouch: true });
    setHeaderTextHidden(preset.hidden.includes('headerText'));
    setCurrentType(e);
    setDisabledImg(ds('lockImg'));
    setDisabledView(ds('lockView'));
    setDisabledToolBar(ds('toolBar'));
    setDisabledSearch(ds('search'));
    setDisabledPastEvents(ds('pastEvents'));
    setDisabledDateFilter(ds('dateFilter'));
    setDisabledCategoryFilter(ds('category'));
    setDisabledLiveEventFilter(ds('liveEvent'));
    setDisabledFavs(ds('favs'));
    setDisabledViewSelector(ds('viewSelector'));

    setUseImg(st('useImg'));
    setTitleDisplay(st('collectionTitleDisplay'));
    setDisplayToolbar(st('displayToolbar'));
    setToolSearch(st('toolSearch'));
    setToolFavorite(st('toolFavorite'));
    setToolViewSelect(st('toolViewSelect'));
    setFilterLive(st('filterLive'));
    setFilterCategory(st('filterCategory'));
    setFilterDate(st('filterDate'));
    setFilterPast(st('filterPast'));
  };

  const ToolbarSwitch = (switchProps) => (
    <div className="col col-sm-4">
      <FormSwitchControlled
        checked={switchProps.checked}
        switchLabel={switchProps.label}
        fontWeight={300}
        fieldName={switchProps.field}
        formContext={form}
        disabled={switchProps.disabled}
        updateParent={handler(switchProps.field)}
      />
    </div>
  );
  return (
    <>
      <div className="row">
        <form role="form" className="no-left-padding">
          <div className="col col-sm-12">
            <div className="col col-sm-4">
              <FormInput
                fieldWidth="long"
                label="Name"
                fieldName="name"
                formContext={form}
                formFieldOptions={{
                  required: 'This field is required.',
                }}
              />
            </div>

            <div className="col col-sm-2">
              <SelectSwitch
                label="Type"
                options={viewType}
                fieldWidth="tiny"
                formContext={form}
                fieldName="type"
                updateView={presetChanged}
                formFieldOptions={{
                  required: 'This field is required.',
                }}
              />
            </div>
          </div>

          <div className="col col-sm-12 collection-settings-spacer">
            <p className="collection-settings-spacer-text">Display Settings</p>
          </div>

          <div className="col col-sm-12">
            <div
              className="col-sm-12 no-padding-left"
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: 62,
              }}
            >
              <div style={{ display: 'inline-block', marginRight: 15 }}>
                <FormSwitchControlled
                  checked={collectionTitleDisplay}
                  switchLabel="Collection Title"
                  fontWeight={300}
                  fieldName="collectionTitleDisplay"
                  formContext={form}
                  updateParent={handler('collectionTitleDisplay')}
                />
              </div>

              <div className="col col-sm-3" style={{ display: 'inline-block' }}>
                <FormInput
                  fieldWidth="long"
                  label="Collection Display Screen Title"
                  fontWeight={300}
                  fieldName="label"
                  formContext={form}
                  disabled={!collectionTitleDisplay}
                  formFieldOptions={{
                    required: collectionTitleDisplay ? 'This field is required.' : false,
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'inline-block', marginRight: 15 }}>
              <FormSwitchControlled
                checked={wysDisplay}
                switchLabel="Create WYSIWYG"
                fontWeight={300}
                fieldName="collectionWysDisplay"
                formContext={form}
                updateParent={handler('collectionWysDisplay')}
              />
            </div>

            {wysDisplay && (
              <div className="col col-sm-12">
                <FormWysiwyg
                  tag="textarea"
                  rows="60"
                  label=""
                  formContext={form}
                  fieldName="collectionWys"
                  disabled={!wysDisplay}
                  growlId={growlId}
                />
              </div>
            )}

            {currentType != 'links' && (
              <div className="col col-sm-12 mt-4">
                <FormSwitchControlled
                  checked={useImg}
                  switchLabel="Use Images in Collection"
                  fieldName="useImage"
                  formContext={form}
                  disabled={disabledImg}
                  updateParent={handler('useImage')}
                />
              </div>
            )}

            {currentType === 'links' && (
              <div className="col col-sm-12">
                <FormLinksImage
                  label="BackGround Image"
                  uploadButtonText="Upload"
                  existingAssetIdField="linksAssetId"
                  fieldName="linkAssetUpload"
                  formContext={form}
                  defaultAssetId="collection-links-default.png"
                  useCropper={true}
                  croppedRatio={16 / 9}
                />
              </div>
            )}

            <div className="col col-sm-12">
              <SelectSwitch
                label="Default View"
                fontWeight={300}
                options={assignDefaultOptions}
                fieldWidth="tiny"
                formContext={form}
                fieldName="defaultView"
                disabled={disabledView || !useImg}
                updateView={handler('defaultView')}
              />
            </div>

            {!headerTextHidden && (
              <div className="col col-sm-4">
                <FormInput
                  fieldWidth="long"
                  label="Header Text for Untimed Elements"
                  fontWeight={300}
                  fieldName="untimedHeader"
                  formContext={form}
                  formFieldOptions={{
                    required: 'This field is required.',
                  }}
                />
              </div>
            )}
          </div>

          {currentType != 'links' && (
            <>
              <div className="col col-sm-12 collection-settings-spacer">
                <p className="collection-settings-spacer-text">Toolbar Settings</p>
              </div>

              <div className="col col-sm-12">
                <div className="col col-sm-4 margin-bottom">
                  <FormSwitchControlled
                    checked={displayToolbar}
                    switchLabel="Display Toolbar in Collection"
                    fieldName="displayToolbar"
                    formContext={form}
                    disabled={disabledToolBar}
                    updateParent={handler('displayToolbar')}
                  />
                </div>

                <div className="margin-left-20">
                  <ToolbarSwitch
                    checked={toolSearch}
                    disabled={disabledSearch}
                    field="toolSearch"
                    label="Search"
                  />
                  <ToolbarSwitch
                    checked={filterPast}
                    disabled={disabledPastEvents}
                    field="filterPast"
                    label="Past Event Filter"
                  />
                  <ToolbarSwitch
                    checked={filterDate}
                    disabled={disabledDateFilter}
                    field="filterDate"
                    label="Date Filter"
                  />
                  <ToolbarSwitch
                    checked={filterCategory}
                    disabled={disabledCategoryFilter}
                    field="filterCategory"
                    label="Category Filter"
                  />
                  <ToolbarSwitch
                    checked={filterLive}
                    disabled={disabledLiveEventFilter}
                    field="filterLive"
                    label="Live Event Filter"
                  />
                  <ToolbarSwitch
                    checked={toolFavorite}
                    disabled={disabledFavs}
                    field="toolFavorite"
                    label="Favorites"
                  />
                  <ToolbarSwitch
                    checked={toolViewSelect}
                    disabled={disabledViewSelector || !useImg}
                    field="toolViewSelect"
                    label="View Selector"
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      <div className="row module-footer no-margin-left top-margin-15">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default CollectionEditorForm;

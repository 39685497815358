import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { Controller } from 'react-hook-form';
import { validationIncludesRequired } from '../formUtils';
import useWysiwygOptions from '../hooks/useWysiwygOptions';
import FormFieldError from './FormFieldError';
import { errorGrowl } from '../../../store/rootThunks';
import { useDispatch } from 'react-redux';
const FormWysiwyg = ({ label, tag, fieldName, formContext, formFieldOptions, growlId }) => {
  const isRequired = validationIncludesRequired(formFieldOptions);
  const dispatch = useDispatch();
  const { formState, control } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const setError = useCallback(
    (errorMessage = '') => {
      if (errorMessage) dispatch(errorGrowl({ groupId: growlId, props: errorMessage }));
    },
    [dispatch]
  );

  const { wysiwygOptions, error: loadOptionsError } = useWysiwygOptions(setError);
  useEffect(() => {
    if (loadOptionsError) setError(loadOptionsError);
  }, [loadOptionsError, setError]);
  return (
    <div
      className={classNames('form-group', {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {wysiwygOptions && (
        <div className="reset-this">
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FroalaEditor
                  key={Date.now()}
                  tag={tag}
                  config={wysiwygOptions}
                  model={value}
                  onModelChange={onChange}
                />
              );
            }}
            {...formFieldOptions}
          />
        </div>
      )}
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormWysiwyg;

import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/cohorts/api';
const loadRecordsWrapper = (cohortId, credentialCode) => async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getCohortExplictMembers`, {
      params: {
        cohortId,
        credentialCode,
        ...otherParams,
        sortField,
        sortReverse,
      },
      timeout: 10_000,
    });
    const { cohortMembers, count, offset } = data;
    result = {
      records: cohortMembers,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load cohort members');
  }
  return result;
};

const generateCohortMemberMatchesApi = (cohortId, credentialCode) => ({
  loadRecords: loadRecordsWrapper(cohortId, credentialCode),
});

export default generateCohortMemberMatchesApi;

import axios from 'axios';
import { get } from 'lodash';
import { EMPTY_SCAN_TARGET } from '../definitions';

const baseUrl = '/plugins/qrscanner/api/';

async function getTargets(start, number, params) {
  let sortPredicate, sortReverse, searchPredicate;
  if (params) {
    sortPredicate = params.sort.predicate;
    sortReverse = params.sort.reverse;
    if (params.search.predicateObject) searchPredicate = params.search.predicateObject.name;
  }
  const httpParams = {
    params: {
      start,
      number,
      sortPredicate,
      sortReverse,
      searchPredicate,
    },
  };
  const url = `${baseUrl}getScanTargets`;
  const result = await axios.get(url, httpParams);
  return { records: get(result, 'data.scanTargets', []), numRecords: get(result, 'data.count', 0) };
}

async function getTarget(targetId) {
  if (targetId === 'new') return EMPTY_SCAN_TARGET;
  const url = `${baseUrl}getScanTarget`;
  const result = await axios.get(url, { params: { id: targetId } });
  return result.data.scanTarget;
}

async function saveTarget(target) {
  const url = `${baseUrl}saveScanTarget`;
  const result = await axios.post(url, target);
  return result.data.scanTarget;
}

async function removeTarget(targetId) {
  const url = `${baseUrl}removeScanTarget`;
  const result = await axios.post(url, { id: targetId });
  return result.data.targetId;
}

export default {
  getTargets,
  loadRecord: getTarget,
  loadRecords: getTargets,
  saveRecord: saveTarget,
  getTarget,
  saveTarget,
  removeTarget,
};

(function () {
  angular.module('field-error-service', []).service('fieldErrorService', [
    '$q',
    'growl',
    '$timeout',
    function ($q, growl, $timeout) {
      const currentErrors = [];
      const errorDescriptions = {
        required: 'This field is required.',
      };
      function getErrorDescription(name, key) {
        return errorDescriptions[key]
          ? `${name} ${errorDescriptions[key]}`
          : `${name} has an error: ${key}.`;
      }
      function alertFieldErrors(form, growlOptions) {
        const timeout = currentErrors.length && $('.growl-message').length ? 500 : 0;
        for (const currentError of currentErrors) {
          currentError.destroy();
        }
        $timeout(function () {
          const errors = form ? form.$error : {};
          const $form = $(`[name='${form.$name}']`);
          for (const key in errors) {
            const errorLists = errors[key];
            for (const errorList of errorLists) {
              let name = errorList.$name;
              const labelName =
                $form && $form.length
                  ? $form.find(`label[for='${name}']`).text()
                  : $(`label[for='${name}']`).text();
              name = labelName ? labelName : name;
              currentErrors.push(growl.error(getErrorDescription(name, key), growlOptions));
            }
          }
        }, timeout);
      }
      return {
        alertFieldErrors,
      };
    },
  ]);
})();

angular.module('instance-service', []).service('instanceService', [
  '$rootScope',
  'CMS_INSTANCES',
  function ($rootScope, CMS_INSTANCES) {
    const instances = [];
    for (const instanceId in CMS_INSTANCES) {
      instances.push(CMS_INSTANCES[instanceId]);
    }
    let activeInstance = getInstanceById(window.localStorage.cms_instance) || CMS_INSTANCES.sandbox;
    function setActiveInstance(instance) {
      activeInstance = instance;
      window.localStorage.cms_instance = instance.id;
      $rootScope.$broadcast('cmsInstanceChanged');
    }
    function getActiveInstance() {
      return activeInstance;
    }
    function getInstanceById(instanceId) {
      let result;
      if (instanceId) {
        instances.forEach((instance) => {
          if (instance.id == instanceId) result = instance;
        });
      }
      return result;
    }
    return {
      instances,
      setActiveInstance,
      getActiveInstance,
    };
  },
]);

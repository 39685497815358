import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogic, setLocation, setSavedAudience } from '../../../store';
import { selectAudienceSelectorProps } from '../../../store/selectors';

const SetAudienceSelector = ({ fieldName }) => {
  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const { audiences, audienceId, buildQuery } = selectAudienceSelectorProps(fieldName);
  const createAudience = useCallback(
    (tg) => ({
      fieldName,
      audienceId: tg?.value,
      audienceIndex: tg?.selectedIndex ? tg?.selectedIndex - 1 : undefined,
    }),
    [fieldName]
  );
  const selectAudience = useCallback(
    (e) => dispatch(setSavedAudience(createAudience(e.target))),
    [dispatch, createAudience]
  );
  const selectExistingAudience = useCallback(() => {
    if (selectRef?.current?.value && selectRef?.current?.selectedIndex) {
      dispatch(setSavedAudience(createAudience(selectRef.current)));
    }
    dispatch(setLocation({ location: 'modal.buildQuery', value: false, fieldName }));
  }, [dispatch, createAudience, fieldName]);

  const createNewAudience = useCallback(() => {
    dispatch(resetLogic({ fieldName }));
    dispatch(setLocation({ location: 'modal.buildQuery', value: true, fieldName }));
  }, [dispatch, fieldName]);
  return (
    <div>
      <form role="form" className="form-group">
        <div className="row flex-wrapper">
          <label className="flex-shrink-0 p-0">
            <input
              checked={!buildQuery}
              name="audienceType"
              type={'radio'}
              onChange={selectExistingAudience}
            />
            Select an Existing Audience
          </label>
          <select
            ref={selectRef}
            disabled={buildQuery}
            className="form-control flex-shrink-1 input-short"
            value={audienceId || ''}
            onChange={selectAudience}
          >
            <option key="0" value="0">
              Select
            </option>
            {audiences.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <label>
          <input
            checked={buildQuery}
            name="audienceType"
            type={'radio'}
            onChange={createNewAudience}
          />
          Create a New Audience
        </label>
      </form>
    </div>
  );
};

export default SetAudienceSelector;

import { useMemo } from 'react';
import useLoadSubListItems from '../../shared/cmsPage/hooks/useLoadSubListItems';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import collectionApi from '../api/navbarApi';
import collectionItemApi from '../api/navbarItemApi';
import { CollectionConstants, CollectionEditorConstants } from '../definitions';
export default function useCollectionEditorActions({
  hasItemsPendingPublish,
  loadItem,
  loadItems,
  collectionId,
  pageTitle,
  publishDisabled,
  setHasReordered,
}) {
  const { remove } = useSubItemListActions(
    collectionItemApi,
    collectionId,
    CollectionEditorConstants
  );
  const reload = useLoadSubListItems(
    CollectionEditorConstants.defaultPageId,
    collectionId,
    loadItems
  );
  const publishHandler = usePublishActionHandler(
    CollectionConstants.pluginId,
    CollectionConstants.typeName,
    CollectionConstants.publishPageName,
    CollectionEditorConstants.defaultPageId,
    CollectionEditorConstants.defaultPageName,
    pageTitle,
    CollectionEditorConstants.defaultPageId,
    collectionApi
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (item, ...rest) => item.isHomePage || remove.disabled(item, ...rest),
    }),
    []
  );
  const publish = useMemo(
    () => ({
      disabled: publishDisabled,
      label: 'Publish',
      onClick: async () => {
        await publishHandler(collectionId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(collectionId), reload()]);
        });
      },
      useSnapshotCheck: true,
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      collectionId,
      setHasReordered,
    ]
  );
  const reset = useMemo(
    () => ({
      disabled: false,
      label: 'Reset Snapshot',
      onClick: async () => {
        await collectionApi.resetSnapshot();
      },
    }),
    []
  );

  return { publish, remove: removeOverride, reset };
}

import React from 'react';
import Button from '@mui/material/Button';

export default function FileUpload({ onFileSelect, label = 'Click Here To Upload' }) {
  // Ref to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Function to simulate click on hidden input
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  /**
   * Formats the file size in bytes to human-readable format.
   *
   * @param {*} bytes
   * @param {*} decimals
   * @returns string
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  /**
   * Handles file selection from the input element.
   *
   * This function is triggered when a user selects a file. It extracts the first selected file
   * and passes its information to the `onFileSelect` callback.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered by file selection.
   *
   * The selected file (`File` object) includes the following properties:
   * @property {string} name - The name of the file.
   * @property {number} size - The size of the file in kb.
   * @property {string} type - The MIME type of the file.
   * @property {string} extension - The file extension.
   * @property {number} lastModified - The timestamp indicating the last time the file was modified,
   * represented in milliseconds since the UNIX epoch (00:00:00 UTC on 1 January 1970).
   */
  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    // Add file extension to file object
    file.extension = file.name.split('.').pop();
    file.fileType = file.type.split('/')[0];

    if (['js', 'css', 'json'].includes(file.extension)) {
      file.fileType = file.extension;
    }
    if (!['js', 'css', 'json', 'html'].includes(file.fileType)) {
      file.fileType = 'file';
    }

    file.diplaySize = formatBytes(file.size);

    // Check if onFileSelect is a function before calling it
    if (onFileSelect && typeof onFileSelect === 'function') {
      // Relay file information back to parent component

      onFileSelect(file);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick}>
        {label}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
}

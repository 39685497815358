import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import createConditionColumn from '../../shared/generalComponents/createConditionColumn';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';
import capitalize from 'lodash/capitalize';
export default function usePushColumnsSearch() {
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) dispatch(getUserOptions());
  }, [dispatch, opts]);
  const columns = useMemo(
    () => [
      {
        label: 'Date/Time',
        valueField: 'sendTimestamp',
        renderer: (item) => <span>{moment(item.sendTimestamp).format('MMM DD, yyyy h:mm A')}</span>,
        sortable: true,
      },
      {
        label: 'Subject',
        valueField: 'title',
        sortable: true,
      },
      {
        label: 'Sender',
        valueField: 'sentByEmail',
        renderer: (item) => <span>{item.sentByEmail}</span>,
        sortable: true,
      },
      createConditionColumn('Target Audience', 'audienceExpr', 'fa-bullseye', opts),
      {
        label: 'Status',
        valueField: 'status',
        renderer: (item) => <span>{_.capitalize(item.status)}</span>,
        sortable: true,
      },
    ],
    [opts]
  );
  const additionalSearchFields = useMemo(
    () => [
      {
        options: ['scheduled', 'cancelled', 'delivered', 'sending'].map((value) => ({
          value,
          label: capitalize(value),
        })),
        noSelectText: 'All',
        key: 'status',
        placeholder: 'Status',
        type: 'select',
      },
      { key: 'startDate', placeholder: 'Start Date', type: 'date' },
      { key: 'endDate', placeholder: 'End Date', type: 'date' },
      { key: 'isArchived', placeholder: 'Include Archived', type: 'switch' },
    ],
    []
  );
  return { columns, additionalSearchFields };
}

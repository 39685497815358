import get from 'lodash/get';
import rfc from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { reducerName } from './index';

const getters = (st, fieldName = '') => ({
  gs: (path = '', def = undefined) =>
    get(st, `${reducerName}${fieldName ? `.${fieldName}` : ''}${path ? `.${path}` : path}`, def),
});
const us = (cb) => useSelector(cb, rfc);
export const selectJobsProcessingKey = () =>
  us((st) => {
    const { gs } = getters(st);
    return gs('jobsProcessingKey');
  });
export const selectJobs = () =>
  us((st) => {
    const { gs } = getters(st);
    return Object.entries(gs('jobs', {})).map(([id, job]) => ({ ...job, id }));
  });
export const selectJob = (jobId) =>
  us((st) => {
    const { gs } = getters(st);
    return gs(`jobs.${jobId}`, {});
  });

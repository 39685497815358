import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Box,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const WorkflowTable = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedContexts, setExpandedContexts] = useState({});

  const handleRowClick = (workflowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [workflowId]: !prevState[workflowId],
    }));
  };

  const handleContextClick = (workflowId, index) => {
    setExpandedContexts((prevState) => ({
      ...prevState,
      [`${workflowId}-${index}`]: !prevState[`${workflowId}-${index}`],
    }));
  };

  const formatDateTimeWithMilliseconds = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    return `${formattedDate} ${formattedTime}.${milliseconds}`;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Workflow ID</TableCell>
            <TableCell>Latest State</TableCell>
            <TableCell>Creation Time</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ workflowId, logs, lastUpdatedAt }) => {
            const latestItem = logs.at(-1);
            return (
              <React.Fragment key={workflowId}>
                <TableRow>
                  <TableCell>
                    <IconButton size="small" onClick={() => handleRowClick(workflowId)}>
                      {expandedRows[workflowId] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{workflowId}</TableCell>
                  <TableCell>{latestItem.state}</TableCell>
                  <TableCell>{formatDateTimeWithMilliseconds(lastUpdatedAt)}</TableCell>
                  <TableCell>{latestItem.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expandedRows[workflowId]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          State History
                        </Typography>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Order</TableCell>
                              <TableCell>State</TableCell>
                              <TableCell>Creation Time</TableCell>
                              <TableCell>Context</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {logs.map((item, index) => (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{item.state}</TableCell>
                                  <TableCell>
                                    {formatDateTimeWithMilliseconds(item.createdAt)}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleContextClick(workflowId, index)}
                                    >
                                      {expandedContexts[`${workflowId}-${index}`] ? (
                                        <KeyboardArrowUp />
                                      ) : (
                                        <KeyboardArrowDown />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    colSpan={6}
                                  >
                                    <Collapse
                                      in={expandedContexts[`${workflowId}-${index}`]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="pre"
                                        >
                                          {JSON.stringify(item.context, null, 2)}
                                        </Typography>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkflowTable;

import React from 'react';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import { validationIncludesRequired } from '../formUtils';

const FormInputTextArea = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    ...inputAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <textarea
        className="form-control collectionItem-textarea"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...register(fieldName, formFieldOptions)}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInputTextArea;

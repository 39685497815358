import axios from 'axios';
import log from '../../logger';
import { EMPTY_QRSCANNER } from '../definitions';

const baseUrl = '/plugins/qrscanner/api';

const getScanners = async (params) => {
  const { sort = {}, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getScanners`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { scanners, count, offset } = data;
    result = { numRecords: count, offset, records: scanners };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load scanners');
  }
  return result;
};

const getScanner = async (id) => {
  if (id === 'new') return EMPTY_QRSCANNER;

  try {
    const { data } = await axios.get(`${baseUrl}/getScannerForCms`, {
      params: { id },
      timeout: 10_000,
    });
    return data.scanner;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load scanner.');
  }
};

const removeScanner = async (scannerId) => {
  try {
    await axios.post(`${baseUrl}/removeScanner`, { id: scannerId }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove scanner.');
  }
};

const getConfig = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getConfig`, { timeout: 10_000 });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load scanner config.');
  }
};

const saveRecord = async (item) => {
  try {
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveScanner`,
      { ...revisionProps, id },
      { timeout: 10_000 }
    );
    return data.appUser;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save appUser.');
  }
};

const exportScanLog = async (otherEmails, exportOptions) => {
  exportOptions.otherEmails = otherEmails;
  try {
    const { data } = await axios.post(`${baseUrl}/exportScanLog`, exportOptions, {
      timeout: 10_000,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to retreive scan log.');
  }
};

const qrScannerApi = {
  getConfig,
  getScanner,
  getScanners,
  loadRecord: getScanner,
  loadRecords: getScanners,
  removeScanner,
  saveRecord,
  exportScanLog,
};

export default qrScannerApi;

import React from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useRegistrationConfigurations from '../hooks/useRegistrationConfigurations';
import RegistrationEditor from './RegistrationEditor';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
const RegistrationsEditorPage = (props) => {
  const { registrationId } = props;
  const { constants } = useRegistrationConfigurations();
  const { editorPageId, typeName } = constants;
  const { pageTitle, onItemChange } = useEditPageTitle(registrationId, typeName, 'title');
  return (
    <>
      <BreadcrumbLink
        pluginId={constants.pluginId}
        parentPageId={constants.defaultPageId}
        parentPageName={constants.defaultPageName}
        parentPageTitle={'Registrations'}
      />
      <CmsPage title={pageTitle}>
        <CmsItemEditSection pageId={editorPageId}>
          <RegistrationEditor itemId={registrationId} onItemChange={onItemChange} />
        </CmsItemEditSection>
      </CmsPage>
    </>
  );
};

export default RegistrationsEditorPage;

import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import log from '../../../logger';
import { startWatchingJob } from '../../../shared/jobs/store';
import validateEmails from '../../../shared/util/validateEmails';

const CsvExportModal = ({ visible, setVisible, api, name }) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [emails, setEmails] = useState('');
  const { emailList, isValid } = useMemo(() => {
    let emailList;
    if (!emails) emailList = [];
    else if (emails.includes(',')) {emailList = emails.split(',').map((x) => x.trim());}
    else {emailList = [emails.trim()];}

    return {
      emailList,
      isValid: validateEmails(emailList),
    };
  }, [emails]);
  const createReport = useCallback(async () => {
    try {
      const jobId = await api(emailList);
      dispatch(startWatchingJob({ jobId, name }));
      setError(false);
      setVisible(false);
    } catch (e) {
      setError(true);
      log.error(e);
    }
  }, [visible, setVisible, emailList, name, api]);
  return (
    <Modal show={visible} animation={false}>
      <Modal.Header>
        <Modal.Title>Report Leads</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className={classNames('form-group')}>
            <div className="col-sm-10 mb-4 no-left-padding">
              You will receive a notice in your email when the report is ready to download. You will
              also receive a notice inside the application. If you would like others to also receive
              a copy of this report, enter the emails below separated by commas.
            </div>
            <label>Emails</label>
            <textarea
              className="form-control collectionItem-textarea"
              maxLength={255}
              rows={7}
              placeholder="Enter Emails"
              onChange={(e) => setEmails(e.target.value)}
            />
          </div>
          {error && (
            <div style={{ color: 'darkred', fontSize: '20', fontWeight: 'bolder' }}>
              There was an error attempting to export the csv
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={!isValid} onClick={createReport}>
          {`Create Report`}
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            setEmails('');
            setVisible(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CsvExportModal;

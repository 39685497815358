import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import attendanceApi from '../api/attendanceApi';
import { AttendanceEventConstants } from '../definitions';
import useAttendanceEventListActions from '../hooks/useAttendanceEventListActions';
import popoverListColumn from '../../shared/generalComponents/popoverListColumn';
const AttendanceEventColumns = [
  {
    label: 'Event Name',
    valueField: 'eventName',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: `Date of Event`,
    valueField: 'date',
    sortable: true,
    initialSort: 'ASC',
    renderer: ({ date }) => <Timestamp customFormat={'dddd, MMMM DD, YYYY'} date={date} />,
  },
  popoverListColumn('Group(s)', 'userGroups', 'fa-users', 'name', { align: 'left' }),
  {
    label: 'Attendance',
    valueField: 'attendanceDisplay',
    sortable: false,
  },
];

const AttendanceEventListPage = () => {
  const { defaultPageId, typeName, pageTitle } = AttendanceEventConstants;
  const { add, edit, remove, requestPending, attendeeInfo } = useAttendanceEventListActions();
  const toolbarActions = [add];
  const itemActions = [edit, attendeeInfo, remove];

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="attendance-manager"
        typeName={typeName}
        pageSize={10}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={AttendanceEventColumns}
        itemApi={attendanceApi}
        searchHeader="Search Events"
      />
    </CmsPage>
  );
};

export default AttendanceEventListPage;

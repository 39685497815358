(function () {
  angular
    .module('manage-subtenant-list', ['smart-table', 'subtenant-api-service', 'confirm-dialog'])
    .controller('ManageSubtenantsCtrl', [
      '$scope',
      '$modal',
      '$log',
      '$state',
      '$location',
      '$timeout',
      'growl',
      'subtenantApiService',
      'confirmDialog',
      'configService',
      'messageService',
      function (
        $scope,
        $modal,
        $log,
        $state,
        $location,
        $timeout,
        growl,
        subtenantApiService,
        confirmDialog,
        configService,
        messageService
      ) {
        /************* Initialization ************/
        $scope.subtenants = [];
        $scope.displayedSubtenants = [];
        $scope.numPages = 0;
        $scope.tableColspan = 6;
        $scope.showAffiliation = false;
        configService.getConfig().then(function (config) {
          $scope.showAffiliation = config.enableSubtenantAffiliation;
          if ($scope.showAffiliation) $scope.tableColspan = 10;
        });
        messageService
          .getMessage('cms.manageSubtenants.affiliationLabel')
          .then(function (affiliationLabel) {
            $scope.affiliationLabel = affiliationLabel;
          });
        messageService.getMessage('cms.manageSubtenants.searchGhostText').then(function (message) {
          $scope.searchGhostText = message;
        });
        getSubtenants(0, 10);
        $scope.callServer = function (tableState) {
          $scope.isLoading = true;
          $timeout(function () {
            $scope.subtenants = [];
            $scope.displayedSubtenants = [];
          });
          $scope.tableState = tableState;
          const pagination = tableState.pagination;
          const start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          const number = pagination.number || 10; // Number of entries showed per page.
          getSubtenants(start, number, tableState);
        };
        $scope.reload = function (resetToFirstPage) {
          if (resetToFirstPage) getSubtenants(0, 10);
          else {
            getSubtenants(
              $scope.tableState.pagination.start,
              $scope.tableState.pagination.number,
              $scope.tableState
            );
          }
        };
        $scope.addOrEditSubtenant = function (subtenantToReplace) {
          const options = {
            templateUrl: 'modules/manageSubtenants/editSubtenantModal.html',
            controller: 'EditSubtenantCtrl',
            animation: false,
            backdrop: 'static',
          };
          if (subtenantToReplace) {
            options.scope = $scope.$new();
            options.scope.subtenant = subtenantToReplace;
            options.scope.replaceMode = true;
          }
          const modalInstance = $modal.open(options);
          modalInstance.result.then(
            function () {
              growl.success('Subtenant saved successfully');
              $scope.reload();
            },
            function () {}
          );
        };
        $scope.canEdit = function (subtenant) {
          return !subtenant.isDefault;
        };
        $scope.removeSubtenant = function (subtenant) {
          // TODO: Need to verify that there are no users assigned to this subtenant (and either prevent or confirm and reassign users to primary subtenant).

          confirmDialog({
            title: 'Confirm remove',
            body: 'Are you sure you want to remove this subtenant?',
            confirmText: 'Remove',
            cancelText: 'Cancel',
          }).result.then(function () {
            subtenantApiService
              .removeSubtenant(subtenant)
              .then(function () {
                growl.success('Subtenant removed successfully');
                $scope.reload(true);
              })
              .catch(function (error) {
                growl.error((error && error.message) || 'Failed to remove subtenant');
              });
          });
        };
        function getSubtenants(start, number, tableState) {
          subtenantApiService
            .getSubtenants(start, number, tableState)
            .success(function (data) {
              $scope.subtenants = data.subtenants;
              $scope.displayedSubtenants = [$scope.subtenants].flat();
              setNumberOfPages(data.count);
              $scope.isLoading = false;
            })
            .error(function (data) {
              growl.error('Failed to load subtenants.');
              $log.debug('Failed to get all subtenants. Response: ', data);
            });
        }
        function setNumberOfPages(count) {
          const numberOfPages = (count - (count % 10)) / 10 + 1;
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
          }
        }
      },
    ]);
})();

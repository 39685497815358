import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateRuleDataOption } from '../../../../../store';
import { selectRootOptionOptionsData } from '../../../../../store/selectors';

const SelectFieldOption = (props) => {
  const { location, fieldName } = props;
  const { options, label, value } = selectRootOptionOptionsData(fieldName, location);
  const dispatch = useDispatch();
  const onFieldOptionChange = useCallback(
    (option) => {
      dispatch(
        updateRuleDataOption({
          option,
          location,
          fieldName,
        })
      );
    },
    [dispatch, location, fieldName]
  );
  return (
    <Select
      placeholder={label}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      onChange={onFieldOptionChange}
      value={value}
      options={options}
      className="inline-select"
    />
  );
};

export default SelectFieldOption;

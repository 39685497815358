import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSessionDetails } from '../api/sessionApi';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ItrsCourseConstants, ItrsSessionConstants } from '../definitions';
import { navigateToPluginPage } from '../../store/rootReducer';
import sessionApi from '../api/sessionApi';
import logger from '../../logger';

const enrollmentColumns = [
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Pending Requests', valueField: 'pendingRequests', sortable: true },
  { label: 'Approved', valueField: 'approved', sortable: true },
  { label: 'Total Enrolled', valueField: 'totalEnrolled', sortable: true },
  { label: 'Max. Seats', valueField: 'maxSeats', sortable: true },
  { label: 'Travel Requests Submitted', valueField: 'travelRequestsSubmitted', sortable: true },
  { label: 'Travel Confirmed', valueField: 'travelConfirmed', sortable: true },
];

const trainingRequestsColumns = [
  { label: 'Name', valueField: 'name', sortable: true },
  { label: 'Local Union', valueField: 'localUnion', sortable: true },
  { label: 'Date Submitted', valueField: 'dateSubmitted', sortable: true },
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Status', valueField: 'status', sortable: true },
];

const attendeesColumns = [
  { label: 'Name', valueField: 'name', sortable: true },
  { label: 'Local Union', valueField: 'localUnion', sortable: true },
  { label: 'Status', valueField: 'status', sortable: true },
];

export default function useSessionDetails(id) {
  const {
    data: {
      course = {},
      session = {},
      instructors = [],
      students = [],
      trainingRequests = [],
      enrollments = [],
    } = {},
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['itrs-session-details', id],
    queryFn: () => getSessionDetails(id),
    enabled: !!id,
  });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { startDate, endDate } = session;
  const { dateTitle, fullRange } = useMemo(() => {
    if (!startDate || !endDate) return { dateTile: '', fullRange: '' };
    const s = moment(startDate).utc();
    const e = moment(endDate).utc();
    let title = '';
    if (s.isSame(e, 'day')) {
      // Same day
      title = s.format('MMMM D, YYYY');
    } else if (s.isSame(e, 'month') && s.isSame(e, 'year')) {
      // Same month and year
      title = `${s.format('MMMM')} ${s.format('D')}-${e.format('D')}, ${s.format('YYYY')}`;
    } else if (s.isSame(e, 'year')) {
      // Same year, different months
      title = `${s.format('MMMM D')} - ${e.format('MMMM D')}, ${s.format('YYYY')}`;
    } else {
      // Different years
      title = `${s.format('MMMM D, YYYY')} - ${e.format('MMMM D, YYYY')}`;
    }
    const fullRange = `${s.format('MM/DD/YYYY')} - ${e.format('MM/DD/YYYY')}`;
    return { dateTitle: title, fullRange };
  }, [startDate, endDate]);

  const editSession = useCallback(() => {
    dispatch(
      navigateToPluginPage([
        ItrsSessionConstants.pluginId,
        ItrsSessionConstants.editorPageId,
        course.id,
        id,
      ])
    );
  }, [dispatch, id, course.id]);

  const deleteSession = useCallback(async () => {
    await sessionApi.removeRecord(id);
    dispatch(
      navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.defaultPageId])
    );
  }, [dispatch]);

  const handleApproval = useMemo(
    () => ({
      label: `+ Approve`,
      onClick: async (context) => {
        try {
          await sessionApi.approveTrainingRequest(context.approvalId, 'APPROVE');
          //TODO Refresh data
          queryClient.invalidateQueries({ queryKey: ['itrs-session-details', id] });
        } catch (error) {
          logger.error(error);
        }
      },
    }),
    [id]
  );

  const handleDeny = useMemo(
    () => ({
      label: `- Deny`,
      onClick: async (context) => {
        try {
          await sessionApi.approveTrainingRequest(context.approvalId, 'DENY');
          queryClient.invalidateQueries({ queryKey: ['itrs-session-details', id] });
        } catch (error) {
          logger.error(error);
        }
      },
    }),
    [id]
  );

  return {
    editSession,
    deleteSession,
    handleApproval,
    handleDeny,
    session,
    course,
    dateTitle,
    fullRange,
    enrollmentColumns,
    trainingRequestsColumns,
    attendeesColumns,
    enrollments,
    trainingRequests,
    instructors,
    students,
    isLoading,
    isError,
    error,
  };
}

import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../../context/definitions';
import { errorGrowl } from '../../../store/rootThunks';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { navigateToPluginPage } from '../../../store/rootReducer';
const useSubItemListActions = (subItemApi, parentItemId, pageConstants) => {
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const { confirm } = useContext(CmsContext);
  const dispatch = useDispatch();
  const { removeItem, restoreItem, requestPending } = useItemPersistence(subItemApi);
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: () => {
        dispatch(navigateToPluginPage([pluginId, editorPageName, parentItemId, 'new']));
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, parentItemId]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, editorPageName, parentItemId, context.id]));
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName, parentItemId]
  );
  const remove = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: (context) => context.status === 'removed',
      onClick: async (context) => {
        const confirmation = await confirm({
          title: `Remove ${typeName}`,
          message: `Are you sure you want to remove this ${typeName}?`,
        });
        if (confirmation) {
          try {
            await removeItem(context);
            window.location.reload();
          } catch (e) {
            dispatch(errorGrowl({ groupId: defaultPageId, props: e.message }));
          }
        }
      },
    }),
    [removeItem, typeName, dispatch]
  );
  const restore = useMemo(
    () => ({
      icon: 'fa-solid fa-trash-arrow-up',
      label: 'Restore',
      disabled: (context) => context.status === 'unpublished' || context.status === 'published',
      onClick: async (context) => {
        try {
          await restoreItem(context);
          window.location.reload();
        } catch (e) {
          dispatch(errorGrowl({ groupId: defaultPageId, props: e.message }));
        }
      },
    }),
    [restoreItem, typeName, dispatch]
  );
  return {
    add,
    edit,
    remove,
    restore,
    requestPending,
  };
};

export default useSubItemListActions;

import React from 'react';
import Action from './Action';
import SnapshotCheckAction from './SnapshotCheckAction';
import get from 'lodash/get';
const ActionList = (props) => {
  const { actions = [], className } = props;
  return (
    <div className={`action-buttons flex-align ${className || ''}`}>
      {actions.map((action, index) =>
        get(action, 'useSnapshotCheck') ? (
          <SnapshotCheckAction action={action} key={index} />
        ) : (
          <Action action={action} key={index} />
        )
      )}
    </div>
  );
};

export default ActionList;

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlineIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
const FormCheckBox = (props) => {
  const {
    label,
    fieldName,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    forceCheck,
    preChecked,
    infoTooltip,
    tooltipType = 'info',
    ...inputAttributes
  } = props;
  const { formState, register, setValue } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const [checked, setChecked] = useState(!!preChecked);
  const registered = register(fieldName, formFieldOptions); //eslint-disable-next-line

  if (forceCheck) setValue(fieldName, true);
  useEffect(() => {
    if (preChecked) setChecked(preChecked);
  }, [preChecked]);
  return (
    <div
      className={classNames(
        `form-checkbox-row${forceCheck ? ' form-checkbox-row-disabled' : ''}`,
        providedClassName,
        `input-${inputWidth}`,
        {
          'has-error': !!fieldError,
        }
      )}
    >
      <input
        type={'checkbox'}
        className="form-checkbox-row-input"
        checked={forceCheck || checked}
        {...inputAttributes}
        {...registered}
        onChange={() => {
          if (forceCheck) return;
          setChecked(!checked); //eslint-disable-next-line
          setValue(fieldName, checked, {
            shouldDirty: true,
          });
        }}
        disabled={forceCheck}
      />
      <label className="form-checkbox-row-label" htmlFor={inputAttributes.id}>
        {label}
      </label>
      {!!infoTooltip && (
        <Tooltip
          title={infoTooltip}
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '16px',
                bgcolor: 'rgba(0, 0, 0, 0.87)',
                '& .MuiTooltip-arrow': {
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              },
            },
          }}
        >
          <IconButton size="small" color="primary">
            {tooltipType === 'question' ? <HelpOutlineIcon /> : <InfoOutlineIcon />}
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default FormCheckBox;

import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import scanTargetsApi from '../api/scanTargetsApi';
import scanTargetGroupsApi from '../api/scanTargetGroupsApi';
import ProvidedSelect from '../../shared/form/components/ProvidedSelect';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import FormInput from '../../shared/form/components/FormInput';
import ActionButton from '../../shared/actionButton/components/ActionButton';
import { QrScannerConstants } from '../definitions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const ScanTargetEditor = ({ targetId }) => {
  const [targetGroupsOrdered, setTargetGroupsOrdered] = useState([]);

  const { item, saveItem, loadItem } = useItemPersistence(scanTargetsApi);
  useEffect(() => {
    if (targetId) (async () => await loadItem(targetId))();
  }, [targetId, loadItem]);
  useEffect(() => {
    (async () => {
      const { records } = await scanTargetGroupsApi.getGroups();
      setTargetGroupsOrdered(records);
    })();
  }, []);
  const form = useCmsForm(item);

  const actions = useItemEditActions(saveItem, form, QrScannerConstants);

  return (
    <div>
      <h1>Edit Scan Target</h1>
      <form>
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          placeholder="Enter the target name"
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <ProvidedSelect
          label={'Group'}
          fieldName={'targetGroupId'}
          fieldWidth="short"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
          placeholder="Select a target group"
          selectOptions={targetGroupsOrdered.map((group) => ({
            value: group.id,
            label: group.name,
          }))}
        />

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            minHeight: 62,
          }}
        >
          <FormInput
            label="Value"
            fieldName="value"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />
          <ActionButton
            type="button"
            label={`Generate Unique Value`}
            onClick={() => {
              const newUuid = uuid();
              form.setValue('value', newUuid, { shouldDirty: true, shouldTouch: true });
              form.trigger();
            }}
          />
        </div>
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default ScanTargetEditor;

import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ManageGroupsCsvExportEditorForm from './ManageGroupsCsvExportEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { ManageGroupsConstants } from '../definitions';
import useManangeGroupActions from '../hooks/useManageGroupActions';

const ManageGroupsCsvExportEditorPage = () => {
  const currentURL = window.location.href;
  const parts = currentURL.split('/');
  const number = parts.at(-1);

  const { createReport, cancel } = useManangeGroupActions(ManageGroupsConstants);
  const form = useCmsForm();
  return (
    <CmsPage title={`Report Recipients`}>
      <ManageGroupsCsvExportEditorForm
        form={form}
        growlId={ManageGroupsConstants.exportPageName}
        actions={[createReport(form, number), cancel]}
      />
    </CmsPage>
  );
};

export default ManageGroupsCsvExportEditorPage;

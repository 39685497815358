import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateRuleFieldLogicType } from '../../../../../store';
import { selectRuleFieldLogicData } from '../../../../../store/selectors';
export default function SelectFieldLogicType(props) {
  const { location, fieldName } = props;
  const { options, value, isDisabled } = selectRuleFieldLogicData(fieldName, location);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (field) => {
      dispatch(
        updateRuleFieldLogicType({
          field,
          location,
          fieldName,
        })
      );
    },
    [dispatch, location, fieldName]
  );
  return (
    <Select
      isDisabled={isDisabled}
      placeholder={'Logic Type'}
      getOptionLabel={(field) => field.label}
      getOptionValue={(field) => field.value}
      onChange={handleChange}
      value={isDisabled ? '' : value}
      options={options}
      className="inline-select"
    />
  );
}

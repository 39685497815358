export const TargetAudienceConstants = {
  pluginId: 'target-audience',
  defaultPageName: 'default',
  defaultPageId: 'target-audience-list-page',
  typeName: 'TargetAudience',
  editorPageName: 'targetAudienceEditorPage',
};
export const EMPTY_TARGET_AUDIENCE_REVISION = {
  label: '',
  name: '',
  isArchived: false,
  condition: null,
};

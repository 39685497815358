(function () {
  angular
    .module('manage-group-content', ['smart-table', 'group-api-service', 'confirm-dialog'])
    .controller('ManageGroupsCtrl', [
      '$scope',
      '$log',
      '$location',
      '$timeout',
      'growl',
      'groupApiService',
      'confirmDialog',
      function ($scope, $log, $location, $timeout, growl, groupApiService, confirmDialog) {
        $scope.showGroup = groupApiService.getGroupFilter() || 'Show All';
        $scope.tableLoaded = false;
        $scope.callServer = function (tableState) {
          if ($scope.showSubtenantGroups === undefined) {
            $scope.showSubtenantGroups =
              $scope.defaultSubtenantIsSelected && localStorage.showSubtenantGroups === 'true';
          }
          $scope.isLoading = true;
          $timeout(function () {
            $scope.availableGroups = [];
            $scope.displayedGroups = [];
          });
          $scope.tableState = tableState;
          const pagination = tableState.pagination;
          const start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          const number = pagination.number || 10; // Number of entries showed per page.
          getGroups(start, number, tableState);
        };
        /************* Initialization ************/
        $scope.allGroups = [];
        $scope.availableGroups = [];
        $scope.displayedGroups = [];
        $scope.numPages = 0;
        function getGroups(start, number, tableState) {
          groupApiService
            .getGroups(start, number, tableState, $scope.showSubtenantGroups)
            .success(function (data) {
              $scope.allGroups = data.groups;
              $scope.availableGroups = data.groups;
              $scope.displayedGroups = [$scope.availableGroups].flat();
              setNumberOfPages(data.count);
              $scope.isLoading = false;
              $scope.tableLoaded = true;
            })
            .error(function (data) {
              growl.error('Failed to load groups.');
              $log.debug('Failed to get all groups. Response: ', data);
            });
        }
        function setNumberOfPages(count) {
          const numberOfPages = Math.ceil(count / 10);
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
          }
        }
        function reload(returnToFirstPage) {
          if (returnToFirstPage) $scope.tableState.pagination.start = 0;
          $scope.callServer($scope.tableState);
        }
        $scope.getTypeLabel = function (group) {
          const groupType = $scope.groupTypeMap[group.groupType];
          return groupType ? groupType.label : 'Undefined';
        };
        $scope.showMenu = false;
        $scope.selectedId;
        $scope.toggleEllipsis = function (id) {
          if ($scope.showMenu && $scope.selectedId === id) $scope.showMenu = false;
          else {
            $scope.selectedId = id;
            $scope.showMenu = true;
          }
        };
        $scope.updateList = function () {
          groupApiService.setGroupFilter($scope.showGroup);
          $scope.callServer($scope.tableState);
        };
        $scope.showUsersinGroup = function (group) {
          $location
            .path('/main/manage-groups/view-group')
            .search({ id: group.id, name: group.name });
        };
        $scope.editGroupMembers = function (group) {
          $location
            .path('/main/manage-groups/edit-group-members')
            .search({ id: group.id, name: group.name, key: group.key });
        };

        /************ Upsert Asset ************/
        $scope.addOrEditGroup = function (groupToReplace) {
          const oldPath = $location.url();
          let group = undefined;
          let isOld = false;
          if (groupToReplace) {
            group = {
              id: groupToReplace.id,
              key: groupToReplace.key,
              name: groupToReplace.name,
              defAccess: groupToReplace.defaultAccessible,
            };
            isOld = true;
          }
          $location
            .path('/main/manage-groups/edit-group')
            .state({ group, goBack: oldPath, replaceMode: isOld });
        };
        /*********** Remove override ***********/
        $scope.removeGroup = function (group) {
          confirmDialog({
            title: 'Confirm remove',
            body: 'Are you sure you want to remove this group?',
            confirmText: 'Remove',
            cancelText: 'Cancel',
          }).result.then(function () {
            groupApiService.removeGroup(group).then(
              function () {
                reload(true);
              },
              function () {
                growl.error('Failed to remove group');
              }
            );
          });
        };
        $scope.toggleShowSubtenantGroups = function () {
          $scope.showSubtenantGroups = !$scope.showSubtenantGroups;
          localStorage.showSubtenantGroups = $scope.showSubtenantGroups;
          reload(true);
        };
      },
    ]);
})();

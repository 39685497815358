import React from 'react';
import ActionButton from '../../actionButton/components/ActionButton';

const CmsPageSectionFooter = (props) => {
  const { actions } = props;
  return (
    <div className="col-sm-12 left-margin-22 action-buttons">
      {actions &&
        actions.map((action, index) => (
          <ActionButton
            key={index}
            label={action.label}
            variant={action.variant}
            disabled={action.disabled}
            onClick={action.onClick}
          />
        ))}
    </div>
  );
};

export default CmsPageSectionFooter;

import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import AchievementEditorForm from './AchievementEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { AchievementConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import achievementApiWrapped from '../api/achievementsApi';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const AchievementsEditorPage = (props) => {
  const { achievementId } = props;
  const achievementApi = achievementApiWrapped(true);
  const { item, loadItem, saveItem } = useItemPersistence(achievementApi);
  useEffect(() => {
    if (achievementId) (async () => await loadItem(achievementId))();
  }, [achievementId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, AchievementConstants);
  return (
    <>
      <BreadcrumbLink
        pluginId={AchievementConstants.pluginId}
        parentPageId={AchievementConstants.defaultPageId}
        parentPageName={AchievementConstants.defaultPageName}
        parentPageTitle={'Achievements'}
      />
      <CmsPage title={`${achievementId === 'new' ? 'Add' : 'Edit'} Achievement`}>
        <AchievementEditorForm
          form={form}
          growlId={AchievementConstants.editorPageName}
          actions={actions}
        />
      </CmsPage>
    </>
  );
};

export default AchievementsEditorPage;

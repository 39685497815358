angular.module('admin-api-service', []).service('adminApiService', [
  '$q',
  '$log',
  '$http',
  '$rootScope',
  function ($q, $log, $http, $rootScope) {
    let adminMap = {};
    function getTenantAdmins(start, number, params) {
      let sortPredicate;
      let sortReverse;
      let searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) searchPredicate = searchPredicate.user_email;
      }
      const httpParams = {
        params: {
          start,
          number,
          sortPredicate,
          sortReverse,
          searchPredicate,
        },
      };
      const promise = $http
        .get('/admin-api/getTenantAdmins', httpParams)
        .success(function (data) {
          rebuildAdminMap(data.admins);
        })
        .error(function (data) {
          $log.debug('Failed to get all admins. Response: ', data);
        });
      return promise;
    }
    function rebuildAdminMap(admins) {
      adminMap = {};

      admins.forEach((admin) => {
        adminMap[admin.user_email] = admin;
      });
    }
    function saveAdmin(admin) {
      const data = {
        user_email: admin.user_email,
        role: admin.role,
      };
      const promise = $http.post('/admin-api/upsertAdmin', data);
      promise.then(function (result) {
        adminMap[admin.user_email] = result.data.admin;
        broadcastAdminUpdated(result.data.admin);
      });
      return promise;
    }
    function removeAdmin(admin) {
      const data = {
        user_email: admin.user_email,
      };
      const promise = $http.post('/admin-api/deleteAdmin', data);
      promise.then(function () {
        delete adminMap[admin.user_email];
        broadcastAdminUpdated(admin);
      });
      return promise;
    }
    function resendInvite(admin) {
      const data = {
        user_email: admin.user_email,
      };
      const promise = $http.post('/admin-api/resendInvite', data);
      promise.then(function () {
        broadcastAdminUpdated(admin);
      });
      return promise;
    }
    function getRoles() {
      const deferred = $q.defer();
      $http
        .get('/admin-api/getAdminRoles')
        .success(function (result) {
          deferred.resolve(result.roles);
        })
        .error(function () {
          deferred.reject('Failed to load admin roles');
        });
      return deferred.promise;
    }
    function broadcastAdminUpdated(admin) {
      $rootScope.$broadcast('adminUpdated', admin.user_email);
    }
    return {
      removeAdmin,
      resendInvite,
      saveAdmin,
      getTenantAdmins,
      getRoles,
    };
  },
]);

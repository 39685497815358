import React, { useState, useEffect } from 'react';
import useQrTargetActions from '../hooks/useQrTargetActions'; // Import your custom hook
import QRCode from 'react-qr-code';
import { QrScannerConstants } from '../definitions';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import scanTargetsApi from '../api/scanTargetsApi';
import scanTargetGroupsApi from '../api/scanTargetGroupsApi';

function ScannerTargetInstances() {
  const [targetGroups, setTargetGroups] = useState({});

  const { add, edit, remove, manageGroups } = useQrTargetActions(QrScannerConstants);
  const toolbarActions = useArray(add, manageGroups);
  const itemActions = useArray(edit, remove);
  const { defaultPageId, typeName } = QrScannerConstants;
  useEffect(() => {
    (async () => {
      const { records } = await scanTargetGroupsApi.getGroups();
      const groups = records.reduce((acc, group) => {
        acc[group.id] = group.name;
        return acc;
      }, {});
      setTargetGroups(groups);
    })();
  }, []);

  const columns = [
    {
      label: 'Name',
      valueField: 'name',
      sortable: true,
    },
    {
      label: 'Group',
      valueField: 'targetGroupId',
      sortable: true,
      renderer: (item) => {
        return <>{targetGroups[item.targetGroupId]}</>;
      },
    },
    {
      label: 'Value',
      valueField: 'value',
      sortable: true,
    },
    {
      label: 'QR Code',
      valueField: 'value',
      renderer: (item) => {
        return (
          <>
            <QRCode value={item.value} id={`qrcode-${item.id}`} size={64} />
          </>
        );
      },
    },
  ];

  return (
    <CmsPage title={'QR Scanner Targets'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={scanTargetsApi}
        searchPlaceholder={'Search by name, group, or value'}
      />
    </CmsPage>
  );
}

export default ScannerTargetInstances;

import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/lead-generation/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenResults`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { results, count, offset } = data;
    result = {
      records: results,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load results');
  }
  return result;
};

const loadRecord = async (id) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getResult`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return data.result;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load result.');
  }
};

const saveRecord = async (item) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/saveResult`,
      { ...item },
      {
        timeout: 10_000,
      }
    );
    return data.result;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save result.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeResult`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove result.');
  }
};

const resultApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};

export const getScannedByOptions = async (leadGenId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenSelectScanners`, {
      params: { leadGenId },
      timeout: 10_000,
    });
    return data.scanners;
  } catch (error) {
    log.error(error);
    return [];
  }
};
export const getGroupsOptions = async (leadGenId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenSelectGroups`, {
      params: { leadGenId },
      timeout: 10_000,
    });
    return data.groups;
  } catch (error) {
    log.error(error);
    return [];
  }
};

export const createLeadGenReport =
  (leadGenId) =>
  async (emailList = []) => {
    const { data } = await axios.post(`${baseUrl}/createLeadGenResultsReport`, {
      leadGenId,
      emailList,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return data.job.id;
  };

export const loadEventGroups = async (leadGenId) => {
  const { data } = await axios.get(`${baseUrl}/getGroupsForEvent`, {
    params: { leadGenId },
    timeout: 10_000,
  });
  return data.groups;
};
export default resultApi;

import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import update from 'lodash/update';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { showSnackbar } from '../../store/rootReducer';
import appUserApi from '../api/appUserApi';
export default function useModifiedItemPersistance(appUserId) {
  const { item, loadItem, saveItem } = useItemPersistence(appUserApi);
  const [userGroups, setUserGroups] = useState([]);
  const dispatch = useDispatch();
  const modifiedSave = useCallback(
    async (item) => {
      update(item, 'userGroups', (grps = []) => [
        ...grps,
        ...userGroups.filter((group) => !group.canAssign),
      ]);
      const resp = await saveItem(item, async (error) => {
        if (error?.message === 'Group Has Reached Maximum Occupancy') {
          dispatch(showSnackbar({ message: error?.message }));
        }
        throw error;
      });
      return resp;
    },
    [saveItem, userGroups, dispatch]
  );
  const initLoad = useCallback(async () => {
    if (appUserId) {
      const item = await loadItem(appUserId);
      setUserGroups(get(item, 'userGroups', []));
    }
  }, [loadItem, appUserId, setUserGroups]);

  useEffect(() => {
    if (appUserId) {
      initLoad(appUserId);
    }
  }, [appUserId, initLoad]);
  const modifiedItem = useMemo(
    () =>
      isPlainObject(item)
        ? {
            ...item,
            ...(item.userGroups && { userGroups: item.userGroups.filter((grp) => grp.canAssign) }),
          }
        : item,
    [item]
  );
  return {
    saveItem: modifiedSave,
    item: modifiedItem,
  };
}

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import { ItrsCourseConstants } from '../definitions';
import useSessionDetails from '../hooks/useSessionDetails';
import useArray from '../../shared/util/hooks/useArray';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

const ItrsSessionViewPage = ({ sessionId }) => {
  const theme = useTheme();
  const {
    editSession,
    deleteSession,
    handleApproval,
    handleDeny,
    session,
    course,
    dateTitle,
    fullRange,
    enrollmentColumns,
    trainingRequestsColumns,
    attendeesColumns,
    enrollments,
    trainingRequests,
    instructors,
    students,
  } = useSessionDetails(sessionId);
  const trainingRequestItemActions = useArray(handleApproval, handleDeny);

  return (
    <CmsPage>
      <Box sx={{ padding: theme.spacing(0) }}>
        <BackButton
          pageName="Course"
          navToParent
          navParams={[ItrsCourseConstants.pluginId, 'default', ItrsCourseConstants.pageTitle]}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(2) }}>
          <div>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              {dateTitle}
            </Typography>
          </div>
          <IconButton sx={{ color: theme.palette.primary.main }} onClick={editSession}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ color: theme.palette.primary.main }} onClick={deleteSession}>
            <Delete />
          </IconButton>
        </Box>
        <Detail label="Course" value={`${course.name}`} />
        <Detail label="Start/End Date" value={fullRange} />
        <Detail label="Status" value={session.statusLabel} />
        <Box sx={{ my: theme.spacing(3) }}>
          <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Enrollment Overview
          </Heading>
          <ItemTable clientSort columns={enrollmentColumns} items={enrollments} />
        </Box>

        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Training Requests
          </Heading>
          <ItemTable
            itemActions={trainingRequestItemActions}
            clientSort
            columns={trainingRequestsColumns}
            items={trainingRequests}
          />
        </Box>

        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <Heading>Attendees</Heading>
          <Heading subheading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Instructors
          </Heading>
          <ItemTable clientSort columns={attendeesColumns} items={instructors} />
          <Heading subheading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Students
          </Heading>
          <ItemTable clientSort columns={attendeesColumns} items={students} />
        </Box>
      </Box>
    </CmsPage>
  );
};

export default ItrsSessionViewPage;

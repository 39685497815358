export const CalendarEventConstants = {
  pluginId: 'calendar-events',
  defaultPageName: 'default',
  defaultPageId: 'calendar-events-list-page',
  typeName: 'CalendarEvent',
  editorPageName: 'calendarEventsEditorPage',
};
export const EMPTY_CALENDAR_EVENT = {
  id: '',
  title: '',
  content: '',
  startTime: '',
  endTime: '',
  link: '',
};

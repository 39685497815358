import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../../store/rootReducer';
const ItemPageLink = ({ linkText, pluginId, defaultPageName, params }) => {
  const dispatch = useDispatch();
  const navigateToPage = useCallback(() => {
    dispatch(navigateToPluginPage([pluginId, defaultPageName, params]));
  }, [dispatch, pluginId, defaultPageName, params]);
  return <a onClick={navigateToPage}>{linkText}</a>;
};

export default ItemPageLink;

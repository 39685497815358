import React, { useCallback, useEffect, useState } from 'react';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import MultiSelect from '../../shared/form/components/MultiSelect';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import axios from 'axios';

const CalendarEventEditorForm = (props) => {
  const [groups, setGroups] = useState([]);
  const { form, actions } = props;
  const getData = useCallback(async () => {
    const grps = await axios.get('/user-api/getAllGroups');
    setGroups(get(grps, 'data.groups', []));
  }, [setGroups]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="Event Name"
          fieldName="title"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          label={`Date/Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
          fieldName="startTime"
          formContext={form}
          type="datetime-local"
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInputTextArea label="Description" fieldName="content" formContext={form} rows={5} />
        <FormInput label="Link" fieldName="link" formContext={form} placeholder={'Optional Link'} />
        {!!groups.length && (
          <MultiSelect
            fieldName="userGroups"
            formContext={form}
            label="User Groups"
            labelKey="name"
            options={groups}
            style={{ maxWidth: '510px', width: '510px' }}
            placeholder={'Select User Groups'}
            isOptionEqualToValue={(opt, value) => {
              if (has(opt, 'id')) {
                return opt.id === value.id;
              } else if (has(opt, 'key')) {
                return opt.key === value.key;
              }
              return isEqual(opt, value);
            }}
            formFieldOptions={{
              required: false,
            }}
          />
        )}
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default CalendarEventEditorForm;

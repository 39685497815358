import React, { useEffect, useState } from 'react';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import FormRadioButton from '../../shared/form/components/FormRadioButton';
import RadioButtonGroup from '../../shared/form/components/FormRadioButtonGroup';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import MultiSelect from '../../shared/form/components/MultiSelect';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import itrsApi from '../api/itrsApi';
import { ItrsCourseConstants } from '../definitions';

const ItrsCourseCreatePage = (props) => {
  const { courseId } = props;
  const { pluginId, defaultPageId, defaultPageName } = ItrsCourseConstants;
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(itrsApi);
  const form = useCmsForm(item);
  useLoadEditorItem(courseId, loadItem, () => {}, item, ItrsCourseConstants);
  const { watch, setValue } = form;
  const [categories, setCategories] = useState(null);
  const [programs, setPrograms] = useState(null);

  useEffect(() => {
    const getCats = async () => itrsApi.getCategories();
    const getPrgs = async () => itrsApi.getPrograms();
    Promise.all([getCats(), getPrgs()]).then(([cats, prgs]) => {
      setCategories(cats);
      setPrograms(prgs);
    });
    setValue('status', 'draft');
  }, [courseId]);

  const ceuVal = watch('ceu');
  const certificateVal = watch('certificate');
  const actions = useItemEditActions(saveItem, form, ItrsCourseConstants);

  if (!categories || !programs || requestPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BreadcrumbLink
        pluginId={pluginId}
        parentPageId={defaultPageId}
        parentPageName={defaultPageName}
        parentPageTitle={'ITRS Course'}
      />

      <CmsPage title={item?.name || 'New Course'}>
        <form role="form" className="no-left-padding">
          <RadioButtonGroup
            name="status"
            type="radio"
            form={form}
            label="Course Status"
            options={[
              { value: 'draft', label: 'Draft' },
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]}
            validation={{ required: 'Course status is required.' }}
          />
          <FormInput
            label="Course Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />
          <FormWysiwyg
            tag="textarea"
            label="Course Description"
            formContext={form}
            fieldName="description"
          />
          <FormWysiwyg
            tag="textarea"
            label="Course Summary"
            formContext={form}
            fieldName="summary"
          />
          <FormInput
            label="Duration"
            fieldName="duration"
            formContext={form}
            type="number"
            min="0"
          />
          <MultiSelect
            isSingleSelect={true}
            labelKey="label"
            label="Category / Department"
            options={categories}
            formContext={form}
            placeholder={'Select Category'}
            fieldName="category"
            valueKey={'value'}
            findValueInOptions={true}
            formFieldOptions={{
              required: false,
            }}
          />
          <MultiSelect
            isSingleSelect={true}
            labelKey="label"
            label="Program(s)"
            options={programs}
            formContext={form}
            placeholder={'Select Program'}
            fieldName="program"
            valueKey={'value'}
            findValueInOptions={true}
            formFieldOptions={{
              required: false,
            }}
          />
          <FormInput
            label="Enrollment Cut-Off"
            fieldName="enrollmentCutOff"
            formContext={form}
            type="number"
            min="0"
          />
          Course Earns a Certificate
          <FormRadioButton
            id="cert-yes"
            key="cert-yes"
            name="certificate"
            label="Yes"
            checked={certificateVal === 'yes'}
            fieldName={'certificate'}
            formContext={form}
            type="radio"
            value="yes"
          />
          <FormRadioButton
            id="cert-no"
            key="cert-no"
            name="certificate"
            label="No"
            checked={certificateVal !== 'yes'}
            fieldName={'certificate'}
            formContext={form}
            type="radio"
            value="no"
          />
          Course Earns a Continuing Education Credit (CEU)
          <FormRadioButton
            id="ceu-yes"
            key="ceu-yes"
            name="ceu"
            label="Yes"
            checked={ceuVal === 'yes'}
            fieldName={'ceu'}
            formContext={form}
            type="radio"
            value="yes"
          />
          <FormRadioButton
            id="ceu-no"
            key="ceu-no"
            name="ceu"
            label="No"
            checked={ceuVal !== 'yes'}
            fieldName={'ceu'}
            formContext={form}
            type="radio"
            value="no"
          />
          <FormWysiwyg
            tag="textarea"
            label="Prerequisite(s)"
            formContext={form}
            fieldName="prereqs"
          />
        </form>
        <br />
        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ItrsCourseCreatePage;

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import appUserApi from '../api/appUserApi';
import { AppUserConstants } from '../definitions';
import { startWatchingJob } from '../../shared/jobs/store';
import validateEmails from '../../shared/util/validateEmails';

export default function useAppUserActions(pageConstants) {
  const dispatch = useDispatch();
  const { edit, remove, requestPending } = useItemListActions(appUserApi, AppUserConstants);

  const { defaultPageId } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Add User`,
      onClick: async () => {
        try {
          const newAppUser = await appUserApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              AppUserConstants.pluginId,
              AppUserConstants.editorPageName,
              newAppUser.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [AppUserConstants, defaultPageId, dispatch]
  );
  const exportCsv = useMemo(
    () => ({
      label: `CSV Export`,
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([AppUserConstants.pluginId, AppUserConstants.exportPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [AppUserConstants, defaultPageId, dispatch]
  );
  const createReport = useMemo(
    () => (form) => ({
      label: `Create Report`,
      onClick: async () => {
        const otherEmails = form.getValues('otherEmails');
        try {
          const emailList = otherEmails
            ? otherEmails.includes(',')
              ? otherEmails.split(',').map((x) => x.trim())
              : [otherEmails.trim()]
            : [];
          if (!validateEmails(emailList)) throw new Error('Invalid email address');

          // The appUserApi.createReport call returns the job id
          const {
            job: { id: jobId },
          } = await appUserApi.createReport(emailList);
          dispatch(startWatchingJob({ jobId, name: 'Users Report' }));
          dispatch(
            navigateToPluginPage([AppUserConstants.pluginId, AppUserConstants.defaultPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [AppUserConstants, defaultPageId, dispatch]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      variant: 'default',
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([AppUserConstants.pluginId, AppUserConstants.defaultPageName])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [AppUserConstants, defaultPageId, dispatch]
  );

  return {
    add: addOverride,
    edit,
    remove,
    requestPending,
    exportCsv,
    createReport,
    cancel,
  };
}

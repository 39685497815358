import axios from 'axios';

const baseUrl = '/plugins/qrscanner/api/';
import { EMPTY_SCAN_TARGET_GROUP } from '../definitions';

async function getTargetGroups(start = 0, number = 25, params) {
  let sortPredicate, sortReverse, searchPredicate;
  if (params) {
    sortPredicate = params.sort.predicate;
    sortReverse = params.sort.reverse;
    if (params.search.predicateObject) searchPredicate = params.search.predicateObject.name;
  }
  const httpParams = {
    params: {
      start,
      number,
      sortPredicate,
      sortReverse,
      searchPredicate,
    },
  };
  const url = `${baseUrl}getTargetGroups`;
  const result = await axios.get(url, httpParams);
  return { records: result?.data?.targetGroups || [], count: result?.data?.count || 0 };
}

async function getTargetGroup(targetGroupId) {
  if (targetGroupId === 'new') return EMPTY_SCAN_TARGET_GROUP;
  const url = `${baseUrl}getTargetGroup`;
  const result = await axios.get(url, { params: { id: targetGroupId } });
  return result.data.targetGroup;
}

async function saveTargetGroup(targetGroup) {
  const url = `${baseUrl}saveTargetGroup`;
  const result = await axios.post(url, targetGroup);
  return result.data.targetGroup;
}

async function removeTargetGroup(targetGroupId) {
  const url = `${baseUrl}removeTargetGroup`;
  const result = await axios.post(url, { id: targetGroupId });
  return result.data.targetGroupId;
}

export default {
  getGroups: getTargetGroups,
  getGroup: getTargetGroup,
  saveGroup: saveTargetGroup,
  removeGroup: removeTargetGroup,
  loadRecord: getTargetGroup,
  loadRecords: getTargetGroups,
};

import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'lead-generation';
const editor = 'LeadGenFileUploadPage';
export const LeadGenImportsConstants = createPageConstants(pluginId, 'Lead Generation Imports', {
  defaultDefault: 'leadGenImportsPage',
  editor,
});

export const LeadGenResultsConstants = createPageConstants(pluginId, 'Lead Generation Results', {
  defaultDefault: 'leadGenResultsPage',
  editor,
});
export const LeadGenEventConstants = createPageConstants(
  pluginId,
  'Lead Generation Events',
  {
    defaultDefault: 'leadGenEventPage',
    editor: 'leadGenEventEditorPage',
    attendees: 'leadGenAttendeesPage',
  },
  'Lead Gen Events'
);

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetCount } from '../../../shared/snapshotChecker/store/index';
import { selectCanPublish } from '../../../shared/snapshotChecker/store/selectors';
import ActionButton from '../../actionButton/components/ActionButton';
import ActionSwitch from '../../actionButton/components/ActionSwitch';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';
import SnapshotCheckActionWrapper from './SnapshotCheckActionWrapper';
export default function SnapshotCheckAction({ action }) {
  const dispatch = useDispatch();
  const canPublish = selectCanPublish();
  const handleClick = useCallback(async () => {
    await action.onClick();
    dispatch(resetCount());
  }, [action, dispatch]);
  const itemVisible = action.visible === undefined || checkFlagOrFunction(action.visible);
  const actionProps = {
    disabled: !canPublish || checkFlagOrFunction(action.disabled),
    hiddenButtonRef: action.hiddenButtonRef,
    icon: action.icon,
    label: canPublish ? action.label : 'Processing...',
    onClick: handleClick,
    variant: action.variant,
  };
  return (
    <SnapshotCheckActionWrapper canPublish={canPublish}>
      <div>
        {itemVisible && !action.useSwitch && <ActionButton {...actionProps} />}
        {action.useSwitch && (
          <ActionSwitch {...actionProps} switchStateDefault={action.switchStateDefault} />
        )}
      </div>
    </SnapshotCheckActionWrapper>
  );
}

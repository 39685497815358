(function () {})();
angular
  .module('auth-controllers', [])
  .controller('LoginCtrl', [
    '$http',
    '$scope',
    '$location',
    '$log',
    'configService',
    'navigationService',
    function ($http, $scope, $location, $log, configService, navigationService) {
      $scope.email = '';
      $scope.password = '';
      $scope.loginError = '';
      $scope.login = function () {
        configService.reset();
        $http
          .post('/login', {
            email: $scope.email,
            password: $scope.password,
          })
          .success(function (data) {
            if (data.status == 'success') navigationService.navigateToMain();
            else if (data.loginError) $scope.errorMessage = data.loginError;
            else {
              $scope.errorMessage =
                'Unknown error encountered while logging in.  Please try again later.';
            }
            $log.debug(data);
          })
          .error(function (response, status) {
            $log.debug(`Login error (${status})`, response);
            $scope.errorMessage = 'Error communicating with the server.  Please try again later.';
          });
      };
    },
  ])
  .controller('ChangePasswordCtrl', [
    '$http',
    '$scope',
    '$log',
    function ($http, $scope, $log) {
      $scope.changePassword = function () {
        $http
          .post('/changePassword', {
            password: this.password,
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPasswordConfirm,
          })
          .success(function (data) {
            // Should only get here on error
            if (data && data.error) $scope.errorMessage = data.error;
            $log.debug(data);
          })
          .error(function (data, status) {
            $log.debug(`Change password error (${status})`, data);
            $scope.errorMessage = 'Error communicating with the server.  Please try again later.';
          });
      };
    },
  ])
  .controller('ChangePasswordSuccessCtrl', [
    '$http',
    '$scope',
    'navigationService',
    function ($http, $scope, navigationService) {
      $scope.returnToConsole = function () {
        navigationService.navigateToMain();
      };
    },
  ])
  .controller('ResetPasswordCtrl', [
    '$http',
    '$scope',
    '$log',
    '$transition$',
    function ($http, $scope, $log, $transition$) {
      const stateParams = $transition$.params();
      $scope.resetPassword = function () {
        $http
          .post('/resetPassword', {
            promptId: stateParams.promptId,
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPasswordConfirm,
          })
          .success(function (data) {
            // Should only get here on error
            if (data && data.error) $scope.errorMessage = data.error;
            $log.debug(data);
          })
          .error(function (data, status) {
            $log.debug(`Reset password error (${status})`, data);
            $scope.errorMessage = 'Error communicating with the server.  Please try again later.';
          });
      };
    },
  ])
  .controller('RegisterCtrl', [
    '$http',
    '$scope',
    '$transition$',
    '$log',
    function ($http, $scope, $transition$, $log) {
      const stateParams = $transition$.params();
      $scope.register = function () {
        $http
          .post('/register', {
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPasswordConfirm,
            promptId: stateParams.promptId,
          })
          .success(function (data) {
            // Should only get here on error
            if (data && data.error) $scope.errorMessage = data.error;
            $log.debug(data);
          })
          .error(function (data, status) {
            $log.debug(`Register user error (${status})`, data);
            $scope.errorMessage =
              data.error || 'Error communicating with the server.  Please try again later.';
          });
      };
    },
  ])
  .controller('SuccessCtrl', [function () {}])
  .controller('ForgotPasswordCtrl', [
    '$http',
    '$scope',
    '$log',
    '$location',
    function ($http, $scope, $log, $location) {
      $scope.sendReset = function () {
        $http
          .post('/sendPasswordReset', {
            email: $scope.email,
          })
          .success(function (data) {
            // Should only get here on error
            if (data && data.error) $scope.errorMessage = data.error;
            $log.debug(data);
          })
          .error(function (data, status) {
            $log.debug(`Failed to send reset password email (${status})`, data);
            $scope.errorMessage = 'Error communicating with the server.  Please try again later.';
          });
      };
      $scope.returnToLogin = function () {
        $location.path('/login');
      };
    },
  ])
  .controller('ForgotPasswordSuccessCtrl', [
    '$http',
    '$scope',
    '$transition$',
    function ($http, $scope, $transition$) {
      const stateParams = $transition$.params();
      $scope.email = stateParams.email;
    },
  ]);

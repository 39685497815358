import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createNewCondition } from '../../../../../store';

const AddSimpleCondition = (props) => {
  const { location, fieldName } = props;
  const dispatch = useDispatch();
  const handleAddSimpleCondition = useCallback(() => {
    dispatch(
      createNewCondition({
        location,
        isCompound: false,
        fieldName,
      })
    );
  }, [dispatch, fieldName]);
  return (
    <button
      style={{
        marginLeft: 5,
      }}
      onClick={handleAddSimpleCondition}
      className="btn btn-sm btn-success query-builder-select-condition"
    >
      <span className="glyphicon glyphicon-plus-sign"></span> Add Simple Condition
    </button>
  );
};

export default AddSimpleCondition;

import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CalendarEventEditorForm from './CalendarEventsEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { CalendarEventConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import calendarEventApi from '../api/calendarEventsApi';

const CalendarEventsEditorPage = (props) => {
  const { calendarEventId } = props;
  const { item, loadItem, saveItem } = useItemPersistence(calendarEventApi);
  useEffect(() => {
    if (calendarEventId) (async () => await loadItem(calendarEventId))();
  }, [calendarEventId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, CalendarEventConstants);
  return (
    <CmsPage title={`${calendarEventId === 'new' ? 'Create New' : 'Edit'} Event`}>
      <CalendarEventEditorForm
        form={form}
        growlId={CalendarEventConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default CalendarEventsEditorPage;

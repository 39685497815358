import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'attendance-tracking';
const editor = 'AttendanceFileUploadPage';
export const AttendanceImportsConstants = createPageConstants(pluginId, 'Attendance Imports', {
  defaultDefault: 'attendanceImportsPage',
  editor,
});

export const AttendanceResultsConstants = createPageConstants(pluginId, 'Attendance Results', {
  defaultDefault: 'attendanceResultsPage',
  editor,
});
export const AttendanceEventConstants = createPageConstants(
  pluginId,
  'Attendance Events',
  {
    defaultDefault: 'attendanceEventPage',
    editor: 'attendanceEventEditorPage',
    attendees: 'attendanceEventAttendeePage',
  },
  'Attendance Tracking Events'
);

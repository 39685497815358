import axios from 'axios';
import { EMPTY_GENERAL_FILE } from '../definitions';
import log from '../../logger';
const baseUrl = '/plugins/general-files/api';

const SUPPORTED_FILE_TYPES = new Set([
  'PDF',
  'DOC',
  'DOCX',
  'XLS',
  'XLSX',
  'CSV',
  'TXT',
  'RTF',
  'PPT',
  'PPTX',
  'PPSX',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
  'txt',
  'rtf',
  'ppt',
  'pptx',
  'ppsx',
]);

const loadRecords = async (params) => {
  const { sort, limit, start, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getInternalFiles`, {
      params: { ...otherParams, sortField, sortReverse, number: limit || 10, start: start || 0 },
      timeout: 10_000,
    });
    const { records, numRecords, offset } = data;
    result = {
      records,
      numRecords,
      offset: Number.parseInt(offset, 10),
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load generalFiles');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_GENERAL_FILE;

  try {
    const { data } = await axios.get(`${baseUrl}/getGeneralFile`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    let parsedCondition;
    try {
      parsedCondition = data && data.generalFiles && JSON.parse(data.generalFiles.condition);
      if (parsedCondition) data.generalFiles.condition = parsedCondition;
    } catch {
      parsedCondition = '';
    }
    return data.generalFiles;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load generalFiles.');
  }
};

const saveRecord = async (item) => {
  try {
    const file = item.file[0];
    const fileType = file.name.split('.').pop();
    if (!SUPPORTED_FILE_TYPES.has(fileType)) {
      throw new Error('File type is not supported');
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', item.title);
    await fetch('/plugins/general-files/api/upsertFile', {
      method: 'POST',
      body: formData,
    });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save generalFiles.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeFile`,
      {
        fileId: id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch {
    throw new Error('Failed to remove file.');
  }
};

const publishRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishFile`,
      {
        fileId: id,
      },
      {
        timeout: 10_000,
      }
    );
    return data.generalFiles;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish file.');
  }
};

const unpublishRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/unpublishFile`,
      {
        fileId: id,
      },
      {
        timeout: 10_000,
      }
    );
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish file.');
  }
};

const generalFilesApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  publishRecord,
  unpublishRecord,
};

export default generalFilesApi;

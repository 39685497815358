import React, { useEffect, useState } from 'react';
import * as SurveyCreator from 'survey-creator-react';
import { updateQuiz } from '../../api/quiz.js';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import './CustomPropertyGridWrapper.css';
import log from '../../../logger.js';

export default function CustomPropertyGridWrapper(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState('');

  const model = props.model;
  const saveable = !model.creator.readOnly;

  useEffect(() => {
    if (saveable === false) {
      setLastSavedAt('Survey has been taken. Unable to update');
      return;
    }
  }, [saveable]);

  if (!model) return null;

  const btnSaveSurveyClick = async () => {
    try {
      setIsSaving(true);
      const quiz = await updateQuiz(props.quizId, props.model.creator.JSON);
      props.setModify(false);
      const stringDate = new Date(quiz.quiz.updated).toLocaleTimeString();
      setLastSavedAt(`Last saved at ${stringDate}`);
    } catch (e) {
      log.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <React.Fragment>
      <SurveyCreator.PropertyGridComponent model={model}></SurveyCreator.PropertyGridComponent>

      <div className="grid-wrapper-root">
        <Button
          variant="contained"
          color="primary"
          disabled={!props.isModified || isSaving || !saveable}
          onClick={btnSaveSurveyClick}
          className="save-button"
          sx={{
            width: '88%',
            minHeight: '50px',
            color: 'white',
            backgroundColor: '#113483',
            fontSize: '16px',
            fontWeight: '600',
            textTransform: 'none',
          }}
        >
          {saveable ? 'Save Changes' : 'Read Only'}
        </Button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
        {isSaving && <CircularProgress size={15} />}
      </div>
      <div
        style={{
          fontSize: '.75rem',
          margin: '0 2rem 1rem',
        }}
      >
        {lastSavedAt && lastSavedAt}
      </div>
    </React.Fragment>
  );
}

import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { FILE_KEYS } from '../definitions'; //eslint-disable-next-line
import log from '../../../../../logger';
import DefaultImage from '../../../../../../../img/DefaultLink.png';
export const updateFile = (file, objectProps = {}, isNew = false) => {
  const { name, type, path } = objectProps;
  const updatedFile = new File([file], name, { type: type || get(file, 'type', 'image/*') });
  return assign(updatedFile, {
    path: path || get(file, 'path', name),
    preview: isNew
      ? URL.createObjectURL(updatedFile)
      : get(file, 'preview', URL.createObjectURL(updatedFile)),
  });
};
export const getLocalDefaultTileImage = () => {
  const ext = DefaultImage.split('.').pop();
  let file = new File([DefaultImage], `DEFAULT_FILE`, { type: `image/${ext}` });
  file = assign(file, {
    name: 'DEFAULT_FILE',
    path: `DEFAULT_FILE.${ext}`,
    preview: '/img/DefaultLink.png',
  });
  return file;
};
export const removePreview = (file) => {
  try {
    URL.revokeObjectURL(get(file, 'preview'));
  } catch (e) {
    log.error('unsuccessful attempt to revoke URL', e);
  }
};
export const removeFileMapPreviews = (availableFilesMap = {}) => {
  forEach(omit(availableFilesMap, FILE_KEYS), removePreview);
};
export const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;
/**
 * Returns the new bounding area of a rotated rectangle.
 */

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);
  return {
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
  };
}
export const createImage = async (url) => {
  const {
    data: { type },
  } = await axios.get(url, { responseType: 'blob' });
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve({ image, type }));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
};

export const getCroppedImg = async (
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) => {
  const { image, type } = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;
  const rotRad = getRadianAngle(rotation);
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.drawImage(image, 0, 0);
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.putImageData(data, 0, 0);
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      const extension = type.split('/')[1];
      file = new File([file], `${uuid()}.${extension}`, { type: type });
      resolve({ extension, image: file, type });
    }, type);
  });
};

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import toString from 'lodash/toString';
import React, { useEffect, useMemo, useState } from 'react';
import Spinner from '../../spinner/components/Spinner';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00000080',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00000080',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #00000080 !important',
      borderRadius: '5px',
    },
    '&:hover fieldset': {
      border: '1px solid #00000080 !important',
      borderRadius: '5px',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #00000080 !important',
      borderRadius: '5px',
    },
  },
});

const MultiSelect = ({
  className: providedClassName,
  defaultValue,
  fieldName,
  fieldWidth,
  findValueInOptions = false,
  formContext: { getValues, formState, register, setValue },
  formFieldOptions,
  isSingleSelect = false,
  label,
  labelKey,
  options,
  optionsLoader,
  placeholder,
  sort = false,
  sortTagsKey,
  valueKey,
  filterFunction,
  ...selectAttributes
}) => {
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const value = get(getValues(), fieldName, undefined);
  const [key, setKey] = useState(0);
  const [hasSetDefaultValue, setHasSetDefaultValue] = useState(false);
  const isRequired = validationIncludesRequired(formFieldOptions);
  register(fieldName, formFieldOptions);
  const {
    requestPending,
    result: selectOptions = [],
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);

  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);

  useEffect(() => {
    if (selectOptions.length > 0 && defaultValue && !hasSetDefaultValue) {
      const defaultOption = selectOptions.find((opt) => opt[valueKey] === defaultValue);
      if (defaultOption) {
        setValue(fieldName, defaultOption[valueKey], {
          shouldValidate: true,
          shouldDirty: true,
        });
        setHasSetDefaultValue(true);
      }
    }
  }, [
    selectOptions,
    defaultValue,
    setValue,
    fieldName,
    valueKey,
    hasSetDefaultValue,
    setHasSetDefaultValue,
  ]);

  const currentValue = useMemo(() => {
    if (isSingleSelect) {
      return findValueInOptions ? selectOptions.find((v) => v[valueKey] === value) : value || '';
    } else if (value && Array.isArray(value) && value.length) {
      return sortTagsKey
        ? sortBy(value, [(v) => toString(get(v, sortTagsKey, '')).toLowerCase()], ['asc'])
        : value;
    }
    return [];
  }, [value, findValueInOptions, selectOptions, isSingleSelect, sortTagsKey]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [currentValue]);

  const filteredOpts = filterFunction
    ? (selectOptions || []).filter((val) => filterFunction(val, currentValue))
    : selectOptions || [];
  const opts = sort
    ? sortBy(
        filteredOpts,
        [(v) => toString(labelKey ? get(v, labelKey || '', '') : v).toLowerCase()],
        ['asc']
      )
    : filteredOpts;

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {requestPending ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <Autocomplete
          key={key}
          {...selectAttributes}
          {...(isSingleSelect ? {} : { multiple: true })}
          disableCloseOnSelect={!isSingleSelect}
          sx={{ '& .MuiInputBase-root': { py: '0px' } }}
          options={opts}
          onChange={(e, val) => {
            setValue(fieldName, valueKey ? val[valueKey] : val, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          {...(sortTagsKey && {
            renderTags: (tagValues, getTagProps) =>
              currentValue.map((opt, idx) => {
                return (
                  <Chip key={idx} label={get(opt, labelKey, '')} {...getTagProps({ index: idx })} />
                );
              }),
          })}
          getOptionLabel={(opt) => get(opt, labelKey, '')}
          defaultValue={currentValue}
          renderInput={(params) => <StyledTextField {...params} placeholder={placeholder} />}
        />
      )}

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default MultiSelect;

import get from 'lodash/get';
import { useCallback, useMemo } from 'react';
import { selectLogicValidationProps } from '../../store/selectors';
export default function useLogicValidation(fieldName) {
  const { logic, rootOptions } = selectLogicValidationProps(fieldName);
  const go = (str, def = undefined) => get(rootOptions, str, def);
  const computeLogic = useCallback((logicToCompute) => {
    const { operator, rules = [] } = logicToCompute || {};
    if (!operator || !rules.length) return false;
    let valid = true;
    let i = 0;
    while (i < rules.length && valid) {
      const { logic: ruleLogic, data, field, condition } = get(rules, `[${i}]`, {});
      const gd = (str, def = undefined) => get(data, `[0].${str}`, def);
      if (ruleLogic)
        if (computeLogic(ruleLogic)) {
          const primaryId = gd('id');
          const secondaryId = gd('data[0].id');
          const hasSubOptions = go(`${field}.hasSubOptions`, false);
          const fieldLogicType = gd('logicParams.fieldLogicType');
          valid = Boolean(
            field && primaryId && (!hasSubOptions || secondaryId) && fieldLogicType && condition
          );
        } else {
          valid = false;
        }

      i++;
    }
    return valid;
  }, []);
  const isValid = useMemo(() => {
    if (!logic || !logic.rules || !logic.operator) return false;
    if (!logic.rules.length) return true;
    return computeLogic(logic);
  }, [logic, computeLogic]);
  return isValid;
}

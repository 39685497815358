const pluginId = 'qrscanner';
export const QrScannerConstants = {
  pluginId,
  defaultDefault: 'qrscannersListPage',
  defaultPageName: 'qrscannersListPage',
  editor: 'qrScannerEditorPage',
  targetPage: 'qrScannerTargets',
  targetEditor: 'qrScannerTargetEditor',
  targetGroupEditor: 'qrScannerTargetGroupEditor',
  groupPage: 'qrScannerTargetGroups',
  editorPageName: 'qrScannerEditorPage',
  exportPageName: 'qrScannerLogExport',
};
export const EMPTY_QRSCANNER = {
  name: '',
  type: '',
};
export const EMPTY_SCAN_TARGET = {
  name: '',
  group: '',
  value: '',
};
export const EMPTY_SCAN_TARGET_GROUP = {
  name: '',
};
export const scannerTypeObject = {
  urlBrowserScanner: 'URL Browser Scanner',
  recordingScanner: 'Recording Scanner',
};

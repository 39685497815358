import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const actions = [];
export default function InitializedActions({ children }) {
  const dispatch = useDispatch();
  const initFunction = useCallback(() => {
    actions.forEach((action) => {
      dispatch(action());
    });
  }, [dispatch]);
  useEffect(() => {
    initFunction();
  }, [initFunction]);
  return children;
}

import api from '../api/registrationsApi';
import { RegistrationConstants as constants } from '../definitions';
export default function useRegistrationConfigurations() {
  return {
    listTitle: 'Registrations',
    api,
    constants,
    columns: [
      { label: 'Title', valueField: 'title', sortable: true, initialSort: 'ASC' },
      { label: 'Total Sessions', valueField: 'sessions', sortable: false },
      { label: 'Total Registered', valueField: 'totalRegistered', sortable: false },
    ],
  };
}

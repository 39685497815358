import axios from 'axios';
import assign from 'lodash/assign';
export const getFileAssetPreview = async (assetId) => {
  const { data } = await axios.get(`/preview/asset/${assetId}`, {
    params: {
      instanceName: 'live',
      reload: Date.now(),
    },
    responseType: 'blob',
  });
  return data
    ? assign(
        new File([data], assetId, {
          type: data.type,
        }),
        {
          preview: URL.createObjectURL(data),
          path: assetId,
        }
      )
    : null;
};

export const importRecords = async ({ file, importType, importParams = {} }) => {
  const formData = new FormData();
  formData.append('importData', file, 'importFile.csv');
  formData.append(
    'data',
    JSON.stringify({
      importType,
      importParams,
    })
  );

  try {
    const response = await axios.post('/import-api/importRecords', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Upload progress: ${progress}%`); //eslint-disable-line
      },
    });
    console.log('Data was imported successfully', response.data); //eslint-disable-line
    return response.data;
  } catch (error) {
    console.log('Failed to import data', error); //eslint-disable-line
  }
};

import React from 'react';
import _ from 'lodash';

const CollectionItemTiming = (props) => {
  const { item } = props;
  return (
    <div className="center">
      <span>{_.capitalize(item.timed)}</span>
      <br />
      {item.timed === 'timed' && (
        <span className="font-12px">
          {new Date(item.startTime).toLocaleString()} - {new Date(item.endTime).toLocaleString()}
        </span>
      )}
    </div>
  );
};

export default CollectionItemTiming;

import classNames from 'classnames';
import React, { useMemo } from 'react';
import { downloadJsonToCsv } from '../../util/fileDownload';

const GrowlItem = ({ growl, onDismiss }) => {
  const { type, canDismiss, message, csvDownload } = growl;
  const alertStyle = type === 'error' ? 'alert-danger' : `alert-${type}`;
  const showClose = canDismiss !== false;

  const handleClick = async () => {
    if (csvDownload) {
      const { fields: manualFields, data: stringData, filename } = csvDownload;
      const data = JSON.parse(stringData);
      const fields = manualFields || Object.keys(data[0]).sort();
      downloadJsonToCsv({ fields, data, filename });
    }
  };
  const cursor = useMemo(() => (csvDownload ? 'pointer' : 'default'), [csvDownload]);
  const className = classNames('growl-item alert', alertStyle, { 'alert-dismissable': showClose });
  return (
    <div className={className}>
      {showClose && (
        <button type="button" className="close" onClick={onDismiss}>
          ×
        </button>
      )}
      <div className="growl-message" style={{ cursor }} onClick={handleClick}>
        {message}
      </div>
    </div>
  );
};

export default GrowlItem;

import React from 'react';
import FormInput from '../../shared/form/components/FormInput';

const MenuEditorForm = (props) => {
  const { form } = props;

  return (
    <div className="">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
            pattern: {
              value: /^\S+(\s+\S+)*$/,
              message: 'White Space in not allowed at beginning or end of menu',
            },
          }}
        />
        {/* FIX for browser bug of one input enter submit */}
        <input type="text" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

export default MenuEditorForm;

import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../../context/definitions';
import { navigateToChildPage } from '../../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../../store/rootThunks';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import usePublishActionHandler from './usePublishActionHandler';
const useItemListActions = (itemApi, pageConstants) => {
  const {
    typeName,
    pluginId,
    defaultPageId,
    defaultPageName,
    publishPageName,
    editorPageName,
    pageTitle,
  } = pageConstants;
  const dispatch = useDispatch();
  const { confirm } = useContext(CmsContext);
  const { removeItem, restoreItem, requestPending } = useItemPersistence(itemApi);
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        dispatch(
          navigateToChildPage([pluginId, editorPageName, defaultPageId, loaderParams, 'new'])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        dispatch(
          navigateToChildPage([pluginId, editorPageName, defaultPageId, loaderParams, context.id])
        );
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName]
  );
  const remove = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        const confirmation = await confirm({
          title: `Remove ${typeName}`,
          message: `Are you sure you want to remove this ${typeName}?`,
        });
        if (confirmation) {
          try {
            await removeItem(context);
            dispatch(
              successGrowl({
                groupId: defaultPageId,
                props: `The ${typeName} has been successfully removed!`,
              })
            );
            await reload();
          } catch (e) {
            dispatch(errorGrowl({ groupId: defaultPageId, props: e.message }));
          }
        }
      },
    }),
    [removeItem, typeName, confirm, dispatch]
  );
  const restore = useMemo(() => {
    return restoreItem
      ? {
          icon: 'fa-solid fa-trash-arrow-up',
          label: 'Restore',
          disabled: (context) => context.status === 'unpublished' || context.status === 'published',
          onClick: async (context, { reload }) => {
            try {
              await restoreItem(context);
              dispatch(
                successGrowl({
                  groupId: defaultPageId,
                  props: `The ${typeName} has been successfully restored!`,
                })
              );
              await reload();
            } catch (e) {
              dispatch(errorGrowl({ groupId: defaultPageId, props: e.message }));
            }
          },
        }
      : undefined;
  }, [restoreItem, typeName, dispatch]);
  const publishHandler = usePublishActionHandler(
    pluginId,
    typeName,
    publishPageName,
    defaultPageId,
    defaultPageName,
    pageTitle,
    defaultPageId,
    itemApi
  );
  const publish = useMemo(() => {
    return publishHandler
      ? {
          icon: 'fa-solid fa-upload',
          label: 'Publish',
          disabled: (context) => context.status === 'published',
          onClick: async (context, { reload }) => {
            await publishHandler(
              context.id,
              context.liveItemsHash !== context.sandboxItemsHash,
              reload
            );
          },
        }
      : undefined;
  }, [publishHandler]);
  return {
    add,
    edit,
    remove,
    requestPending,
    restore,
    publish,
  };
};

export default useItemListActions;

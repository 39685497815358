import get from 'lodash/get';
import has from 'lodash/has';
import startCase from 'lodash/startCase';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import TanItemTable from '../../../../../../generalComponents/ReactTable';
import { setLocation } from '../../../store';
import log from '../../../../../../../logger';
import { selectUsersTableProps } from '../../../store/selectors';
import { getUsers } from '../../../store/thunks';
export default function Preview({ fieldName }) {
  log.info({
    fieldName,
  });
  const dispatch = useDispatch();
  const {
    count,
    displayedUsers,
    isLoading,
    logic,
    pageIndex,
    rowsToShow,
    searchText,
    userTypeMap,
  } = selectUsersTableProps(fieldName);
  const setter = useCallback(
    (location) => (value) =>
      void dispatch(
        setLocation({
          location: `preview.${location}`,
          value,
          fieldName,
        })
      ),
    [dispatch, fieldName]
  );
  const queryUsers = useCallback(
    () =>
      void dispatch(
        getUsers({
          fieldName,
        })
      ),
    [dispatch, logic, fieldName]
  );
  useEffect(() => void queryUsers(), [queryUsers]);
  const columns = useMemo(
    () =>
      [
        {
          id: 'accessCode',
        },
        {
          id: 'firstName',
        },
        {
          id: 'lastName',
        },
        {
          id: 'userType',
          accessorFn: (row) => row && get(userTypeMap, `${row.userType}.label`, 'Undefined'),
        },
      ].map((column) => ({
        ...(!has(column, 'accessorKey') && {
          accessorKey: column.id,
        }),
        ...(!has(column, 'header') && {
          header: () => startCase(column.id),
        }),
        ...(!has(column, 'footer') && {
          footer: (props) => props.column.id,
        }),
        ...(!has(column, 'accessorFn') && {
          accessorFn: (row) => row && row[column.id],
        }),
        ...(!has(column, 'enableColumnFilter') && {
          enableColumnFilter: false,
        }),
      })),
    [userTypeMap]
  );
  return (
    <div>
      <TanItemTable
        columns={columns}
        rows={displayedUsers}
        isLoading={isLoading}
        rowsPerPage={rowsToShow}
        startPage={pageIndex}
        setRowCount={setter('rowsToShow')}
        setPageIndex={setter('pageIndex')}
        totalRows={count}
        updateRequested={queryUsers}
        setSearchChanged={setter('searchChanged')}
        setSearchText={setter('searchText')}
        searchText={searchText}
      />
    </div>
  );
}

(function () {
  angular
    .module('manage-admin-list', ['smart-table', 'admin-api-service', 'confirm-dialog'])
    .controller('ManageAdminsCtrl', [
      '$scope',
      '$modal',
      '$log',
      '$state',
      '$location',
      '$timeout',
      'growl',
      'adminApiService',
      'confirmDialog',
      'configService',
      'messageService',
      function (
        $scope,
        $modal,
        $log,
        $state,
        $location,
        $timeout,
        growl,
        adminApiService,
        confirmDialog,
        configService,
        messageService
      ) {
        $scope.callServer = function (tableState) {
          $scope.isLoading = true;
          $timeout(function () {
            $scope.admins = [];
            $scope.displayedAdmins = [];
          });
          $scope.tableState = tableState;
          const pagination = tableState.pagination;
          const start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          const number = pagination.number || $scope.PAGE_SIZE; // Number of entries showed per page.
          getAdmins(start, number, tableState);
        };
        /************* Initialization ************/
        $scope.PAGE_SIZE = 10;
        $scope.admins = [];
        $scope.displayedAdmins = [];
        $scope.numPages = 0;
        $scope.roles = {};
        configService.getConfig().then(function (config) {
          $scope.loggedInUser = config.loggedInUser;
        });
        messageService.getMessage('cms.manageAdmins.searchGhostText').then(function (message) {
          $scope.searchGhostText = message;
        });
        adminApiService
          .getRoles()
          .then(function (roles) {
            $scope.roles = roles;
          })
          .catch(function (error) {
            $log.error(error);
            $scope.roles = [];
            growl.error('Failed to load admin roles.', { referenceId: 'adminGrowl' });
          });

        $scope.getRoleLabel = function (admin) {
          const role = $scope.roles[admin.role];
          return role ? role.label : admin.role;
        };
        $scope.addOrEditAdmin = function (adminToReplace) {
          const options = {
            templateUrl: 'modules/manageAdmins/editAdminModal.html',
            controller: 'EditAdminCtrl',
            animation: false,
            backdrop: 'static',
            scope: $scope.$new(),
          };
          if (adminToReplace) {
            options.scope.admin = adminToReplace;
            options.scope.replaceMode = true;
          }
          const modalInstance = $modal.open(options);
          modalInstance.result.then(
            function () {
              growl.success('Admin saved successfully');
              reload();
            },
            function () {}
          );
        };
        $scope.removeAdmin = function (admin) {
          confirmDialog({
            title: 'Confirm remove',
            body: 'Are you sure you want to remove this admin user?',
            confirmText: 'Remove',
            cancelText: 'Cancel',
          }).result.then(function () {
            adminApiService.removeAdmin(admin).then(
              function () {
                growl.success('User was successfully removed.');
                reload(true);
              },
              function () {
                growl.error('Failed to remove admin');
              }
            );
          });
        };
        $scope.resendInvite = function (admin) {
          confirmDialog({
            title: 'Confirm resend',
            body: 'Are you sure you want to resend invitation email to this user?',
            confirmText: 'Resend',
            cancelText: 'Cancel',
          }).result.then(function () {
            adminApiService.resendInvite(admin).then(
              function () {
                reload(true);
                growl.success('Invitation was sent successfully.');
              },
              function () {
                growl.error('Failed to resend invite');
              }
            );
          });
        };
        $scope.canEditAdmin = function (admin) {
          return (
            $scope.loggedInUser &&
            admin.user_email !== $scope.loggedInUser.email &&
            ($scope.loggedInUser.role === 'super_admin' || admin.role !== 'super_admin')
          );
        };
        $scope.showResendInvite = function (admin) {
          return admin.invitePending && $scope.canEditAdmin(admin);
        };
        function reload(returnToFirstPage) {
          if (returnToFirstPage) $scope.tableState.pagination.start = 0;
          $scope.callServer($scope.tableState);
        }
        function setNumberOfPages(count) {
          const numberOfPages = Math.ceil(count / $scope.PAGE_SIZE);
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
          }
        }
        function getAdmins(start, number, tableState) {
          adminApiService
            .getTenantAdmins(start, number, tableState)
            .success(function (data) {
              $scope.admins = data.admins;
              $scope.displayedAdmins = [$scope.admins].flat();
              setNumberOfPages(data.count);
              $scope.isLoading = false;
            })
            .error(function (data) {
              growl.error('Failed to load admins.');
              $log.debug('Failed to get all admins. Response: ', data);
            });
        }
      },
    ]);
})();

import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/attendance-tracking/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getAttendanceEvents`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { results, count, offset } = data;
    result = { records: results, numRecords: count, offset };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load attendance events');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return { eventName: '', id: 'new', date: undefined, userGroup: {} };
  try {
    const { data } = await axios.get(`${baseUrl}/getAttendanceEvent`, {
      params: { id },
      timeout: 10_000,
    });
    return data.attendanceEvent;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load attendance.');
  }
};

const saveRecord = async ({ id, eventName, date, userGroups = [] }) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/saveAttendanceEvent`,
      { id, date, eventName, userGroups },
      { timeout: 10_000 }
    );
    return data.attendance;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save attendance.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeAttendanceEvent`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove attendance.');
  }
};

export default {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};

export const getAttendanceGroups = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getAttendanceGroups`, {
      timeout: 10_000,
    });
    return data.groups;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load attendances.');
  }
};

export const saveImportData = async (attendanceEventId, data) => {
  try {
    const resp = await axios.post(`${baseUrl}/saveImportData`, { data, attendanceEventId });
    return resp;
  } catch (e) {
    log.error(e.response.data, e.response.error);
    throw new Error('Failed to save import data.');
  }
};

export const updateAttendeeAttendance = async (attendanceId, attendeeId, hasAttended) => {
  const { data } = await axios.post(`${baseUrl}/updateAttendeeAttendance`, {
    attendanceId,
    attendeeId,
    hasAttended,
  });
  return data;
};

export const getAllAttendees = async (attendanceEventId) => {
  const { data } = await axios.get(`${baseUrl}/getAttendanceResults`, {
    params: { attendanceEventId, all: true },
  });
  return data.results;
};

export const getAttendanceResults = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getAttendanceResults`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { results, count, offset } = data;
    result = {
      records: results,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load results');
  }
  return result;
};

import React from 'react';
import Alert from 'react-bootstrap/Alert';
export default function ConditionalAlert(props) {
  const { condition, message, alertType } = props;
  return (
    condition && (
      <Alert
        style={{
          opacity: 1,
        }}
        variant={alertType}
      >
        {message}
      </Alert>
    )
  );
}

import { useCallback, useState } from 'react';

const useApiWithPendingStatus = (apiCallback, initialValue) => {
  const [requestPending, setRequestPending] = useState(false);
  const [result, setResult] = useState(initialValue);
  const callApi = useCallback(async () => {
    if (!apiCallback) return {};
    setRequestPending(true);

    try {
      if (typeof apiCallback === 'function') {
        const apiResult = await apiCallback();
        setResult(apiResult);
      }
    } finally {
      setRequestPending(false);
    }
  }, [apiCallback]);
  return {
    callApi,
    requestPending,
    result,
  };
};

export default useApiWithPendingStatus;

import { useCallback, useState } from 'react';
const EMPTY_MODAL = {
  visible: false,
  confirmArgs: {},
};

const useConfirmModal = () => {
  const [modalProps, setModalProps] = useState(EMPTY_MODAL);
  const confirm = useCallback(
    (args) => {
      return new Promise((resolve) => {
        setModalProps({
          visible: true,
          confirmArgs: args,
          onClose: (result) => {
            setModalProps(EMPTY_MODAL);
            resolve(result);
          },
        });
      });
    },
    [EMPTY_MODAL]
  );
  return {
    confirm,
    confirmProps: modalProps,
  };
};

export default useConfirmModal;

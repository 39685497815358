import { createAsyncThunk } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import { createGrowl } from './rootHelpers';
import { reducerName } from './rootReducer';

const thunk = (name, cb) => createAsyncThunk(`${reducerName}/${name}`, cb);
const growl = (name, type, defaultTtl) =>
  thunk(name, async ({ props, groupId }, thunkApi) => {
    props =
      typeof props === 'string'
        ? { message: props, ...(isNil(defaultTtl) ? {} : { ttlSeconds: defaultTtl }) }
        : props;
    return {
      growl: createGrowl(groupId, type, props, thunkApi),
    };
  });
export const errorGrowl = growl('errorGrowl', 'error');
export const infoGrowl = growl('infoGrowl', 'info');
export const successGrowl = growl('successGrowl', 'success', 10);

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { checkCanPublish } from './thunks';
const reducerName = 'snapshotChecker';
export const pollingInterval = 5000;
export const maxWaitTimeMinutes = 5;
export const snapshotCheckerSlice = createSlice({
  extraReducers(builder) {
    const fulfill = (funcName, cb) => builder.addCase(funcName.fulfilled, cb);
    const rejected = (funcName, cb) => builder.addCase(funcName.rejected, cb);
    fulfill(checkCanPublish, (state, { payload: { canPublish } }) => {
      state.canPublish = canPublish;
      state.checkCount = canPublish ? 0 : state.checkCount + 1;
      state.snapshotPollKey = uuid();
    });
    rejected(checkCanPublish, (state) => {
      state.snapshotPollKey = uuid();
      state.checkCount += 1;
    });
  },
  initialState: { canPublish: false, checkCount: 0, snapshotPollKey: uuid() },
  name: reducerName,
  reducers: {
    addCount: (state) => {
      if (state.checkCount > Math.ceil((maxWaitTimeMinutes * 60 * 1000) / pollingInterval)) {
        state.canPublish = true;
        state.checkCount = 0;
      } else {
        state.checkCount += 1;
      }
    },
    resetCount: (state) => {
      state.checkCount = 0;
      state.canPublish = false;
    },
  },
});

export const { addCount, resetCount } = snapshotCheckerSlice.actions;
export default snapshotCheckerSlice.reducer;

import React, { useContext } from 'react';
import ItemTable from '../../itemTable/components/ItemTable';
import { useListLoaderParams } from '../../cms/hooks/useListLoaderParams';
import { useListLoader } from '../../cms/hooks/useListLoader';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import useLoadListItems from '../hooks/useLoadListItems';
import CmsListSectionToolbar from './CmsListSectionToolbar';

const CmsToolbarSection = (props) => {
  const {
    pageId,
    toolbarActions,
    className,
    backPageAction,
  } = props;
  const wrappedToolbarActions = useWrapPageActions(pageId, toolbarActions);
  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsListSectionToolbar
            actions={wrappedToolbarActions}
            backPageAction={backPageAction}
            showSearchBar={false}
            onSearchChange={() => {}}
          />
        </div>
        <GrowlContainer groupId={pageId} />
      </CmsPageSection>
    </>
  );
};

export default CmsToolbarSection;

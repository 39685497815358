import React, { Fragment, useState, useRef, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';

const ListItemWrapper = ({ children, SnapshotPopover }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {children}
      <Overlay show={show} target={target.current} placement="left" containerPadding={5}>
        {SnapshotPopover}
      </Overlay>
    </div>
  );
};

export default function SnapshotCheckActionWrapper({ children, canPublish, isItem }) {
  if (canPublish) return <Fragment>{children}</Fragment>;
  const SnapshotPopover = useMemo(() => {
    return (
      <Popover id="popover-contained" style={{ borderWidth: 'medium' }}>
        <Popover.Header as="h3" style={{ fontSize: '0.9em', padding: '5px 10px' }}>
          Publish in progress
        </Popover.Header>
        <Popover.Body style={{ padding: '5px 10px' }}>
          {`A previous publish is processing. Please wait until processing a new publish.`}
        </Popover.Body>
      </Popover>
    );
  }, []);
  return isItem ? (
    <ListItemWrapper SnapshotPopover={SnapshotPopover}>{children}</ListItemWrapper>
  ) : (
    <OverlayTrigger trigger={['hover', 'focus']} overlay={SnapshotPopover}>
      {children}
    </OverlayTrigger>
  );
}

(function () {
  'use strict';
  angular.module('preview-service', []).service('previewService', [
    '$q',
    '$timeout',
    'contentApiService',
    'instanceService',
    function ($q, $timeout, contentApiService, instanceService) {
      let previewWindow;
      let currentUrl;
      let currentInstance;
      function getPreviewConfig(scheme) {
        return contentApiService.getPreviewConfig(scheme);
      }
      async function launchPreview(manualUrl, scheme, overrideInstance) {
        const previewConfig = await getPreviewConfig(scheme);
        if (manualUrl) previewConfig.url = manualUrl;
        const checkSnapshot = previewConfig.checkSnapshot;
        if (previewConfig.url !== currentUrl) closePreviewWindow();
        if (previewWindowIsValid()) {
          ensurePreviewReady(previewWindow, checkSnapshot, overrideInstance).then(() => {
            previewWindow.reload();
          });
          previewWindow.focus();
        } else {
          previewWindow = window.open('/appPreview.html', undefined, 'height=800, width=680');
          initPreviewWindow(previewConfig.url, scheme, overrideInstance);
        }
      }
      function closePreviewWindow() {
        if (previewWindowIsValid()) previewWindow.close();
      }
      function previewWindowIsValid() {
        return previewWindow && previewWindow.Location && !previewWindow.closed;
      }
      function initPreviewWindow(manualUrl, scheme, overrideInstance) {
        getPreviewConfig(scheme).then(function (previewConfig) {
          if (manualUrl) previewConfig.url = manualUrl;
          if (previewWindow.setPreviewPath) {
            currentUrl = previewConfig.url;
            currentInstance = overrideInstance || instanceService.getActiveInstance().id;
            ensurePreviewReady(previewWindow, previewConfig.checkSnapshot).then(() => {
              previewWindow.setPreviewPath(previewConfig.url);
            });
          } else {
            $timeout(
              initPreviewWindow.bind(null, previewConfig.url, scheme, overrideInstance),
              1000
            );
          }
        });
      }
      async function ensurePreviewReady(previewWindow, checkSnapshot) {
        if (checkSnapshot) {
          previewWindow.showSpinner();
          await waitForPreviewSnapshot(currentInstance);
          setTimeout(previewWindow.hideSpinner, 250);
        } else {
          previewWindow.hideSpinner();
        }
      }
      async function reloadPreview() {
        if (currentInstance !== 'live' && previewWindowIsValid()) {
          await ensurePreviewReady(previewWindow, true);
          previewWindow.reload();
        }
      }
      async function waitForPreviewSnapshot() {
        let isComputed = await contentApiService.checkSnapshotIsComputedForInstance(
          currentInstance
        );
        while (!isComputed) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          isComputed = await contentApiService.checkSnapshotIsComputedForInstance(currentInstance);
        }
      }
      return {
        launchPreview,
        previewWindowIsValid,
        initPreviewWindow,
        closePreviewWindow,
        reloadPreview,
      };
    },
  ]);
})();

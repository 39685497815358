import axios from 'axios';
import log from '../../logger';
const baseUrl = '/user-api';

export const loadRecords = async (params) => {
  const { sort, groupName, groupId, additionalFieldsSearch, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  const name = groupName.toString();
  const id = Number.parseInt(groupId);
  const inGroupValue = additionalFieldsSearch.inGroup;

  const getLogic = (name, id, inGroup) => {
    const condition = inGroup ? '=' : '!=';
    return {
      operator: 'AND',
      rules: [
        {
          field: 'Group',
          data: [
            {
              label: name,
              id,
              logicParams: {
                condition,
                fieldLogicType: 'memberStatus',
              },
              hasSetDefaultValue: true,
            },
          ],
          condition,
        },
      ],
    };
  };

  const logic = inGroupValue === 'false' ? getLogic(name, id, false) : getLogic(name, id, true);

  let result;
  try {
    const { data } = await axios.post(`${baseUrl}/getAllUsersFromLogic`, {
      ...otherParams,
      order,
      sortReverse,
      sortField,
      limit: 10,
      logic,
      timeout: 10_000,
    });
    const { appUsers, count, offset } = data;
    result = {
      records: appUsers,
      numRecords: count,
      offset,
      logic,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load users');
  }
  return result;
};

const addUser = async (groupId, groupKey, userId, action = 'add') => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateAppUserGroups`, {
      groupId,
      groupKey,
      userId,
      action,
      timeout: 10_000,
    });
    if (data.status === 'success') {
      log.info('success');
    }
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove appUser.');
  }
};

const removeUser = async (groupId, groupKey, userId, action = 'remove') => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateAppUserGroups`, {
      groupId,
      groupKey,
      userId,
      action,
      timeout: 10_000,
    });
    if (data.status === 'success') {
      log.info('success');
    }
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove appUser.');
  }
};

const manageGroupsEditApi = {
  loadRecords,
  addUser,
  removeUser,
};

export default manageGroupsEditApi;

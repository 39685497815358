import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
const CollectionLink = ({ collectionId }) => {
  const dispatch = useDispatch();
  const navigateToLink = useCallback(() => {
    dispatch(navigateToPluginPage(['collections', 'collectionEditorPage', collectionId]));
  }, [dispatch]);
  return <a onClick={navigateToLink}>Collection</a>;
};

export default CollectionLink;

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToChildPage } from '../../store/rootReducer';
export default function useAddRegistration(api, constants) {
  const dispatch = useDispatch();

  const { typeName, pluginId, defaultPageId, editorPageName } = constants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        dispatch(
          navigateToChildPage([pluginId, editorPageName, defaultPageId, loaderParams, 'new'])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        dispatch(
          navigateToChildPage([pluginId, editorPageName, defaultPageId, loaderParams, context.id])
        );
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName]
  );

  const remove = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: false,
      onClick: async ({ id, title }, { reload, confirm }) => {
        const confirmResponse = await confirm({
          title: 'Delete Registration',
          message: `Are you sure you want to delete '${title}'?`,
        });
        if (confirmResponse) {
          await api.removeRecord(id);
          await reload();
        }
      },
    }),
    [api]
  );
  const hideGroups = useMemo(
    () => ({
      icon: 'fa-solid fa-eye-slash',
      label: 'Hide All Option Groups',
      disabled: false,
      onClick: async ({ id }, { reload }) => {
        await api.updateRegistrationGroupsVisibility(id, true);
        await reload();
      },
    }),
    [api]
  );

  const showGroups = useMemo(
    () => ({
      icon: 'fa-solid fa-eye',
      label: 'Show All Option Groups',
      disabled: false,
      onClick: async ({ id }, { reload }) => {
        await api.updateRegistrationGroupsVisibility(id, false);
        await reload();
      },
    }),
    [api]
  );
  const save = useCallback(
    (item) => {
      item.userGroups = item.groups;
      delete item.groups;
      return api.saveRecord(item);
    },
    [api]
  );

  return { add, showGroups, hideGroups, edit, remove, save };
}

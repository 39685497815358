import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';
import createReducer from '../../../../../shared/util/createReducer';
import { v4 as uuid } from 'uuid';
import { FILE_KEYS } from '../definitions';
import { removeFileMapPreviews, updateFile, removePreview } from './helpers';
export const initialState = { availableFilesMap: {}, rejectedFiles: [], selectedFilePath: '' };
export const actions = {
  SET_CROPPED_IMAGE: 'SET_CROPPED_IMAGE',
  SET_FILES: 'SET_FILES',
  SET_INITIAL_ASSETS: 'SET_INITIAL_ASSETS',
  SET_SELECTED_FILE: 'SET_SELECTED_FILE',
};
const { SET_FILES, SET_SELECTED_FILE, SET_INITIAL_ASSETS, SET_CROPPED_IMAGE } = actions;
export const reducer = createReducer({
  [SET_CROPPED_IMAGE]: (state, { croppedImage, setFormValue, type, extension }) => {
    if (croppedImage) {
      removePreview(state.croppedImage);
      const path = uuid();
      croppedImage = updateFile(croppedImage, { name: `${path}.${extension}`, path, type });
      state.croppedImage = croppedImage;
      setFormValue(croppedImage);
    }
  },
  [SET_FILES]: (state, { setFormValue, rejectedFiles, acceptedFiles }) => {
    removeFileMapPreviews(state.availableFilesMap);
    let selectedFile = get(acceptedFiles, '[0]', null);
    const selectedFilePath = get(
      selectedFile,
      'path',
      has(state.availableFilesMap, 'CURRENT_FILE') ? 'CURRENT_FILE' : 'DEFAULT_FILE'
    );
    if (selectedFile) {
      selectedFile = updateFile(selectedFile, { name: selectedFilePath, path: selectedFilePath });
      setFormValue(selectedFile);
    }

    state.availableFilesMap = {
      ...pick(state.availableFilesMap, FILE_KEYS),
      ...(selectedFile && { [selectedFilePath]: selectedFile }),
    };
    state.rejectedFiles = rejectedFiles;
    state.selectedFilePath = selectedFilePath;
  },
  [SET_INITIAL_ASSETS]: (state, { defaultFile, currentFile, path }) => {
    state.selectedFilePath = path;
    state.availableFilesMap = {
      DEFAULT_FILE: defaultFile,
      ...(currentFile && { CURRENT_FILE: currentFile }),
    };
  },
  [SET_SELECTED_FILE]: (state, { setFormValue, selectedFilePath }) => {
    setFormValue(get(state.availableFilesMap, selectedFilePath));
    state.selectedFilePath = selectedFilePath;
  },
});

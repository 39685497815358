angular.module('reporting.service', ['user-api-service']).service('reportingService', [
  '$http',
  '$sce',
  function ($http, $sce) {
    const reports = [
      {
        reportId: 'all_users',
        reportName: 'Users / Groups',
        isExport: true,
        reportGenerator: getAllUsersExport,
        reportButtonLabel: 'Generate Report',
      },
      {
        reportId: 'advanced_reporting',
        reportName: 'Advanced Reporting',
        isIframe: true,
        reportGenerator: getReportingIframeUrl,
        reportButtonLabel: 'Open',
        enabledCheck: (pluginConfig) => !!pluginConfig.quicksightConfig,
      },
      {
        reportId: 'activity_all',
        reportName: 'Active Users',
        reportGenerator: getAllUserActivity,
        isExport: true,
        dateRange: true,
      },
      {
        reportId: 'activity_unique_user',
        reportName: 'Active Unique Users',
        reportGenerator: getUniqueUserActivity,
        isExport: true,
        dateRange: true,
      },
    ];
    function addReport(reportInfo) {
      reports.push(reportInfo);
    }
    function getReports() {
      return reports;
    }
    async function getReportingIframeUrl() {
      return $http.get('/plugins/reporting/api/getQuicksightUrl').then((result) => {
        return $sce.trustAsResourceUrl(result.data.url);
      });
    }
    function getAllUserActivity(exportOptions) {
      const { startDate, endDate } = exportOptions;
      const httpParams = {
        params: {
          startDate,
          endDate,
        },
      };
      return $http.get('/plugins/reporting/api/getAllUserActivity', httpParams).then((result) => {
        return result.data;
      });
    }
    function getUniqueUserActivity(exportOptions) {
      const { startDate, endDate } = exportOptions;
      const httpParams = {
        params: {
          startDate,
          endDate,
        },
      };
      return $http
        .get('/plugins/reporting/api/getUniqueUsersWithActivity', httpParams)
        .then((result) => {
          return result.data;
        });
    }
    function getAllUsersExport() {
      return $http.get('/plugins/reporting/api/getAllUsersExport').then((result) => {
        return result.data;
      });
    }
    return {
      addReport,
      getReports,
    };
  },
]);

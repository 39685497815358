import React, { useEffect, useState, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import ActionList from '../../itemTable/components/ActionList';
import BackButton from './BackButton';
import isEqual from 'lodash/isEqual';
import CmsListSectionToolbarAdditionalFields from './CmsListAdditionalFields';

const CmsListSectionToolbar = (props) => {
  const {
    onSearchChange,
    additionalSearchFields = [],
    actions,
    searchExpr,
    backPageAction,
    showSearchBar,
    searchPlaceholder = 'Search',
    searchHeader = 'Search',
    onAdditionalSearchFieldsChange = () => {},
  } = props;

  const [search, setSearch] = useState(searchExpr || '');
  const [additionalFieldsSearch, setAdditionalFieldsSearch] = useState({});
  const [additionalSearchFieldsIndicator, setAdditionalSearchFieldsIndicator] = useState(0);
  const [debouncedAdditionalFieldsSearch, { cancel: cancelAdditionalFieldsSearch }] = useDebounce(
    additionalFieldsSearch,
    500,
    { equalityFn: isEqual }
  );

  const handleAdditionalSearchFieldsChange = useCallback(
    (...params) => {
      setAdditionalFieldsSearch(...params);
      setAdditionalSearchFieldsIndicator((c) => c + 1);
    },
    [setAdditionalFieldsSearch, setAdditionalSearchFieldsIndicator]
  );

  useEffect(() => {
    onAdditionalSearchFieldsChange(additionalFieldsSearch);
  }, [additionalFieldsSearch, additionalSearchFieldsIndicator, onAdditionalSearchFieldsChange]);

  const [debouncedSearch, { cancel }] = useDebounce(search, 500);
  useEffect(() => {
    onSearchChange(debouncedSearch, debouncedAdditionalFieldsSearch);
    return (...cancelParams) => {
      cancel(...cancelParams);
      cancelAdditionalFieldsSearch(...cancelParams);
    };
  }, [
    onSearchChange,
    debouncedSearch,
    debouncedAdditionalFieldsSearch,
    cancel,
    cancelAdditionalFieldsSearch,
  ]);
  return (
    <>
      <div className="section-toolbar neg-left-margin-13">
        {showSearchBar !== false && (
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', flexGrow: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginLeft: '.5rem',
                marginRight: '.5rem',
              }}
            >
              <div style={{ color: 'grey', fontSize: '11px', marginBottom: '1px' }}>
                {searchHeader}
              </div>
              <input
                className="form-control"
                placeholder={searchPlaceholder}
                value={search}
                autoFocus
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <CmsListSectionToolbarAdditionalFields
              setAdditionalFieldsSearch={handleAdditionalSearchFieldsChange}
              additionalSearchFields={additionalSearchFields}
            />
          </div>
        )}
        <ActionList className={showSearchBar === false ? 'alignRight' : ''} actions={actions} />
      </div>
      {backPageAction && (
        <BackButton handleClick={backPageAction.onClick} pageName={backPageAction.pageName} />
      )}
    </>
  );
};

export default CmsListSectionToolbar;

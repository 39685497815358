import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import FormInput from '../../shared/form/components/FormInput';
import FormInputFile from '../../shared/form/components/FormInputFile';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import { downloadUrlBlob } from '../../shared/util/fileDownload';
import customPageApi from '../api/customPageApi';
import { CustomPageConstants } from '../definitions';
export default function CustomPageEditorPage({ customPageId = 'new' }) {
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    customPageApi,
    customPageId
  );
  const [changeFile, setChangeFile] = useState(false);
  const form = useCmsForm(item);
  const { unregister } = form;

  const actions = useItemEditActions(saveItem, form, CustomPageConstants, customPageId);
  useEffect(() => {
    if (customPageId) (async () => await loadItem(customPageId))();
  }, [customPageId, loadItem]);

  const { pageTitle, onItemChange } = useEditPageTitle(customPageId, 'CustomPage', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const { fileName, signedUrl } = useMemo(() => {
    return { fileName: get(item, 'fileName', ''), signedUrl: get(item, 'signedUrl', '') };
  }, [item]);
  const forceAddFile = customPageId === 'new' || !fileName;
  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-editor">
        <div className="row">
          <BackButton
            pageName="Custom Page"
            navToParent
            navParams={[CustomPageConstants.pluginId, 'default', CustomPageConstants.pageTitle]}
          />
        </div>
        <GrowlContainer groupId={CustomPageConstants.editorPageId} />
        <div className="row ">
          <form role="form" className="no-left-padding">
            <FormInput
              label="Page Route"
              fieldName="name"
              formContext={form}
              formFieldOptions={{ required: 'This field is required.' }}
            />
            {changeFile || forceAddFile ? (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <div>
                  <FormInputFile
                    label="HTML File"
                    accept=".html"
                    multiple={false}
                    fieldName="file"
                    formContext={form}
                    formFieldOptions={{ required: 'This field is required.' }}
                  />
                </div>
                {!forceAddFile && (
                  <div style={{ marginLeft: '1rem' }}>
                    <i
                      className="fas fa-times"
                      onClick={() => {
                        unregister('file');
                        setChangeFile(false);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await downloadUrlBlob(signedUrl, fileName);
                  }}
                >
                  {`Current File: `}
                  <b>{fileName}</b>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  <i
                    className="fas fa-pencil-alt"
                    onClick={() => {
                      setChangeFile(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="col col-lg-12 col-md-12 col-sm-12">
          <div className="row module-footer">
            <CmsPageSectionFooter actions={actions} />
          </div>
        </div>
      </CmsPageSection>
    </CmsPage>
  );
}

import React from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useMeetConfigurations from '../hooks/useMeetConfigurations';
import MeetEditor from './MeetsEditor';

const MeetsEditorPage = (props) => {
  const { meetId, meetType } = props;
  const { constants } = useMeetConfigurations(meetType);
  const { editorPageId, typeName } = constants;
  const { pageTitle, onItemChange } = useEditPageTitle(meetId, typeName, 'title');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <MeetEditor itemId={meetId} onItemChange={onItemChange} meetType={meetType} />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default MeetsEditorPage;

import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import FormFieldError from '../FormFieldError';
import Alerts from './components/Alerts';
import ImageSelector from './components/ImageSelector';
import Select from './components/Select';
import { ImageContext } from './store';

const FormImageComponent = (props) => {
  const {
    label,
    isRequired,
    fieldError,
    className: providedClassName,
    uploadButtonText,
    useCropper,
    croppedRatio,
  } = props;
  const { open } = useContext(ImageContext);
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      open();
    },
    [open]
  );

  return (
    <div
      className={classNames(providedClassName, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ marginTop: '20px' }}>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12">
          <ImageSelector useCropper={useCropper} croppedRatio={croppedRatio} />
        </div>

        <div className="col-lg-8 col-md-12 col-sm-12" style={{ marginTop: '10px' }}>
          <Select />
          <Button style={{ marginTop: '10px', width: '200px' }} variant="primary" onClick={onClick}>
            {uploadButtonText ? uploadButtonText : 'Upload'}
          </Button>
        </div>

        <div style={{ flexBasis: '100%' }}>
          <Alerts />
        </div>
      </div>

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormImageComponent;

import React, { useEffect, useState } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { getQuicksightUserUrl } from '../api/quicksightApi';
import log from '../../logger.js';

const QuicksightReportPage = () => {
  const [embedUrl, setEmbedUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  useEffect(() => {
    getReportUserUrl();
  }, []);

  const getReportUserUrl = async () => {
    try {
      setLoading(true);
      const { data } = await getQuicksightUserUrl();
      setEmbedUrl(data.EmbedUrl);
    } catch (err) {
      log.error('ERROR: ', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CmsPage title={'Advanced Reporting'}>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {embedUrl && <iframe src={embedUrl} style={{ width: '100%', height: '85vh' }}></iframe>}
    </CmsPage>
  );
};

export default QuicksightReportPage;

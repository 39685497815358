import React from 'react';
import { Provider } from 'react-redux';
import CmsReactContext from '../context/components/CmsReactContext';
import InitializedActions from './InitializedActions';
import RootComponents from './rootComponents';
import Theme from './rootComponents/Theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './store';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 10 * 60 * 1000, // once data is stale, it is cached and used for 10 minutes before deletion
      refetchOnWindowFocus: false, // Disable refetch on window focus so cache will be used
      retry: 3,
    },
  },
});
function ReduxStore({ children, ...contextProps }) {
  const { navigateToState } = contextProps;
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Theme>
          <CmsReactContext {...contextProps}>
            <RootComponents navigateToState={navigateToState} />
            <InitializedActions>{children}</InitializedActions>
          </CmsReactContext>
        </Theme>
      </Provider>
    </QueryClientProvider>
  );
}

export default ReduxStore;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { SurveyConstants } from '../definitions';
import useSurveyActions from '../hooks/useSurveyActions';
import useSurveyConfigurations from '../hooks/useSurveyConfigurations';

const SurveysListPage = () => {
  const { defaultPageId, typeName } = SurveyConstants;
  const {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    includeArchived,
    manageInstances,
    refresh,
  } = useSurveyActions(SurveyConstants);
  const { columns, api } = useSurveyConfigurations(includeArchived);
  const toolbarActions = useArray(refresh, archiveCheckbox, add);
  const itemActions = useArray(edit, manageInstances, duplicate, archive);
  return (
    <CmsPage title={'Forms'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default SurveysListPage;

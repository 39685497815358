import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { dismissAllGrowls, dismissGrowl } from '../../../store/rootReducer';
import { selectGroupGrowls } from '../../../store/rootSelectors';
import GrowlItem from './GrowlItem';
const GrowlContainer = (props) => {
  const dispatch = useDispatch();
  const { groupId = 'globalroot' } = props;
  useEffect(() => {
    return () => dispatch(dismissAllGrowls({ groupId }));
  }, [groupId, dispatch]);

  const growls = selectGroupGrowls(groupId);
  const isRoot = groupId === 'globalroot';
  return (
    <div className="growl-container top-right" style={isRoot ? { marginTop: '1rem' } : {}}>
      {(growls || []).map((growl, index) => (
        <GrowlItem
          key={index}
          isRoot={isRoot}
          growl={growl}
          onDismiss={() => dispatch(dismissGrowl({ growl }))}
        />
      ))}
    </div>
  );
};

export default GrowlContainer;

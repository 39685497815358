import { Box, Divider } from '@mui/material';
import React from 'react';
import ActionButton from '../../shared/actionButton/components/ActionButton';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import MultiSelect from '../../shared/form/components/MultiSelect';
import api from '../api/itrsApi';
import useRooms from '../hooks/useRooms';
const ResourceRequirementForm = ({ form }) => {
  const { addRow, PreRenderedTable } = useRooms(form);
  return (
    <>
      Resource Requirements
      <FormInput label="On-Site Contact" fieldName="contact" formContext={form} />
      <Divider />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexGrow: 1,
            flexBasis: '100%',
            mt: 1,
          }}
        >
          <Box> Room Reservations</Box>
          <Box>
            <ActionButton type="button" label={`Add Room +`} onClick={addRow} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            flexBasis: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>{PreRenderedTable}</Box>
        </Box>
      </Box>
      <Divider />
      <MultiSelect
        defaultValue="none"
        fieldName="equipment"
        findValueInOptions={true}
        formContext={form}
        formFieldOptions={{ required: 'Equipment is required' }}
        isSingleSelect={true}
        label="Classroom Equipment"
        labelKey="label"
        optionsLoader={api.getEquipment}
        placeholder={'Select Equipment'}
        sort
        valueKey={'value'}
      />
      <FormInputTextArea label="Additional Needs" fieldName="addNeeds" formContext={form} />
      <FormInputTextArea label="Instructor Needs" fieldName="instrNeeds" formContext={form} />
    </>
  );
};

export default ResourceRequirementForm;

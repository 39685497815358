angular
  .module('common-filters', [])
  .filter('orderObjectBy', [
    function () {
      return function (items, field, reverse) {
        const filtered = [];
        for (const objectKey in items) {
          filtered.push(items[objectKey]);
        }
        filtered.sort(function (a, b) {
          const aValue = a[field] || '';
          const bValue = b[field] || '';
          if (aValue.localeCompare) return aValue.localeCompare(bValue);
          return aValue > bValue ? 1 : -1;
        });
        if (reverse) filtered.reverse();
        return filtered;
      };
    },
  ])
  .filter('tel', function () {
    return function (tel) {
      if (!tel) return '';
      const value = tel.toString().trim().replace(/^\+/, '');
      if (/\D/.test(value)) return tel;
      let city;
      let number;
      switch (value.length) {
        case 1:
        case 2:
        case 3: {
          city = value;
          break;
        }
        default: {
          city = value.slice(0, 3);
          number = value.slice(3);
        }
      }
      if (number) {
        if (number.length > 3) number = `${number.slice(0, 3)}-${number.slice(3, 7)}`;
        return `(${city}) ${number}`.trim();
      }
      return `(${city}`;
    };
  });

import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import useConfirmPublish from '../../shared/cmsPage/hooks/useConfirmPublish';
import useArray from '../../shared/util/hooks/useArray';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import { CollectionEditorConstants, CollectionPublishPageConstants } from '../definitions';
export default function useCollectionItemPublishActions(props) {
  const dispatch = useDispatch();
  const { selectedItems, item, publishItem, collectionId, returnPageName, successGrowlId } = props;
  const { confirm } = useContext(CmsContext);
  const confirmPublish = useConfirmPublish('collection');
  const publish = useMemo(
    () => ({
      label: `Publish Now`,
      onClick: async () => {
        const confirmation = await confirmPublish();
        if (confirmation) {
          try {
            await publishItem(collectionId, selectedItems);
            dispatch(
              navigateToPluginPage([
                CollectionEditorConstants.pluginId,
                returnPageName,
                collectionId,
              ])
            );
            dispatch(
              successGrowl({
                groupId: successGrowlId,
                props: `The collection has been successfully published!`,
              })
            );
          } catch (e) {
            dispatch(
              errorGrowl({ groupId: CollectionEditorConstants.defaultPageId, props: e.message })
            );
          }
        }
      },
      useSnapshotCheck: true,
    }),
    [
      item,
      selectedItems,
      publishItem,
      confirmPublish,
      dispatch,
      confirm,
      CollectionEditorConstants,
      collectionId,
      successGrowlId,
      returnPageName,
    ]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      onClick: () => {
        dispatch(
          navigateToPluginPage([
            CollectionPublishPageConstants.pluginId,
            returnPageName,
            collectionId,
          ])
        );
      },
    }),
    [dispatch, CollectionEditorConstants, collectionId, returnPageName]
  );
  const toolbarActions = useArray(publish, cancel);
  return { toolbarActions };
}

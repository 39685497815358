import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import generalFilesApi from '../api/generalFilesApi';
export default function useGeneralFilesActions(pageConstants) {
  const dispatch = useDispatch();
  const { add, remove, publish, requestPending } = useItemListActions(
    generalFilesApi,
    pageConstants
  );
  const addOverride = useMemo(
    () => ({
      ...add,
      onClick: async () => {
        const newFile = await generalFilesApi.loadRecord('new');
        dispatch(
          navigateToPluginPage([
            pageConstants.pluginId,
            pageConstants.editorPageName,
            newFile.id || 'new',
          ])
        );
      },
    }),
    [dispatch]
  );

  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (context) => context.status === 'published',
    }),
    []
  );

  const unpublish = useMemo(
    () => ({
      icon: 'fa-solid fa-download',
      disabled: (context) => context.status !== 'published',
      label: 'Unpublish',

      onClick: async (item, { reload }) => {
        try {
          await generalFilesApi.unpublishRecord(item.id);
          reload();
        } catch (e) {
          log.error(e);
        }
      },
    }),
    [requestPending]
  );
  return {
    add: addOverride,
    remove: removeOverride,
    publish,
    unpublish,
    requestPending,
  };
}

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import useAdditionalSearchFieldOptions from '../hooks/useAdditionalSearchFieldOptions';

const MultiSelectField = ({ fieldData, set }) => {
  const { key, defaultSelected = [] } = fieldData;
  const searchOptions = useAdditionalSearchFieldOptions(fieldData);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected);
  const defaultString = defaultSelected.sort().join(',');
  const setForm = (value) =>
    set((c) => ({ ...c, [key]: typeof value === 'string' ? value.split(',') : value }));

  useEffect(() => {
    if (defaultString) setForm(defaultString);
  }, [defaultString]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
    set((c) => ({ ...c, [key]: typeof value === 'string' ? value.split(',') : value }));
  };
  const inputStyles = {
    height: '33.5px !important',
    padding: '0px 10px !important',
    display: 'flex',
    alignItems: 'center',
  };
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={
          <OutlinedInput
            sx={{
              '& .MuiInputBase-root': inputStyles,
              '& .MuiOutlinedInput-input': {
                ...inputStyles,
                fontSize: '14px',
                width: '180.5px !important',
                color: 'black',
              },
            }}
          />
        }
        renderValue={(i) => `${i.length} selected`}
        MenuProps={{ PaperProps: { style: { maxHeight: 400, width: 220 } } }}
      >
        {searchOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              p: '4px 0px !important',
              '& .MuiCheckbox-root': { padding: '0px 8px !important' },
            }}
          >
            <Checkbox checked={selectedOptions.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Label = ({ children }) => (
  <div style={{ color: 'grey', fontSize: '11px', marginBottom: '1px' }}>{children}</div>
);

const CheckboxField = ({ fieldData, transform, set }) => {
  const { key } = fieldData;
  return (
    <label>
      <input
        type="checkbox"
        className="form-switch"
        onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.checked) }))}
      />
      {fieldData.placeholder}
    </label>
  );
};

const SwitchField = ({ fieldData, transform, set }) => {
  const { key } = fieldData;
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.checked) }))}
      />
    </div>
  );
};

const TextFieldComponent = ({ set, transform, fieldData }) => {
  const { type, placeholder, key } = fieldData;
  return (
    <input
      style={{ minWidth: '203px' }}
      type={type}
      className="form-control"
      placeholder={`${type.startsWith('date') ? `Select` : 'Enter'} ${placeholder}`}
      onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.value) }))}
    />
  );
};

const SelectField = ({ fieldData, transform, set }) => {
  const { key, noSelectText = 'None', multiple = false } = fieldData;
  const searchOptions = useAdditionalSearchFieldOptions(fieldData);
  return (
    <select
      style={{ minWidth: '203px' }}
      className="form-control"
      multiple={multiple}
      onChange={(e) =>
        set((c) => ({
          ...c,
          [key]: multiple
            ? Array.from(e.target.selectedOptions, (option) => transform(option.value))
            : transform(e.target.value),
        }))
      }
    >
      {!multiple && (
        <option value="" style={{ color: 'grey' }} disabled={searchOptions.length === 0}>
          {noSelectText}
        </option>
      )}
      {searchOptions.map((option, optionIndex) => (
        <option key={optionIndex} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const CmsListAdditionalFields = ({ setAdditionalFieldsSearch: set, additionalSearchFields }) =>
  additionalSearchFields.map((fieldData = {}) => {
    const { type, key, parseBool, multiple } = fieldData;
    const transform = (val) => {
      if (parseBool && val === 'true') return true;
      if (parseBool && val === 'false') return false;
      return val;
    };
    let Component = null;
    switch (type) {
      case 'text':
      case 'date': {
        Component = <TextFieldComponent set={set} transform={transform} fieldData={fieldData} />;
        break;
      }
      case 'select': {
        Component = multiple ? (
          <MultiSelectField set={set} transform={transform} fieldData={fieldData} />
        ) : (
          <SelectField set={set} transform={transform} fieldData={fieldData} />
        );
        break;
      }
      case 'switch': {
        Component = <SwitchField set={set} transform={transform} fieldData={fieldData} />;
        break;
      }
      case 'checkbox': {
        Component = <CheckboxField set={set} transform={transform} fieldData={fieldData} />;
        break;
      }
      default: {
        Component = null;
        break;
      }
    }

    return (
      <div
        key={key}
        style={{
          marginLeft: '.5rem',
          marginRight: '.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Label>{fieldData.placeholder}</Label>
        {Component}
      </div>
    );
  });

export default CmsListAdditionalFields;

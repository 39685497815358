import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import toString from 'lodash/toString';
import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import log from '../../logger';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../shared/form/components/FormInput';
import FormSwitch from '../../shared/form/components/FormSwitch';
import MultiSelect from '../../shared/form/components/MultiSelect';

const AppUserEditorForm = ({ form, actions, appUserId }) => {
  const [groups, setGroups] = useState([]);
  const [subtenants, setSubtenants] = useState([]);
  const [showSubtenants, setShowSubtenants] = useState(false);
  const userId = toString(form.getValues()['id']);
  const getData = useCallback(async () => {
    try {
      const [grps, st] = await Promise.all(
        ['/user-api/getAllGroups', '/subtenant-api/getAllSubtenants'].map((api) => axios.get(api))
      );
      const groupsToSet = get(grps, 'data.groups', []).filter((g) => g.canAssign);
      setGroups(groupsToSet);
      const subs = get(st, 'data.subtenants', []);
      setShowSubtenants(appUserId !== 'new' && subs.some((sub) => !sub.isDefault));
      setSubtenants(subs);
    } catch (err) {
      log.error('Error Loading User Groups', err);
    }
  }, [setGroups, setSubtenants, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="First Name"
          fieldName="firstName"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          label="Last Name"
          fieldName="lastName"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput label="Email Address" fieldName="email" formContext={form} />
        {(!userId || userId === 'new') && (
          <FormSwitch
            switchLabel="Send Email Invite"
            fieldName="sendInvite"
            selectedName="sendInvite"
            formContext={form}
          />
        )}
        {!!groups.length && typeof form.getValues('userGroups')?.length === 'number' && (
          <MultiSelect
            fieldName="userGroups"
            formContext={form}
            label="User Groups"
            labelKey="name"
            options={groups}
            style={{ maxWidth: '510px', width: '510px' }}
            placeholder={'Select User Groups'}
            isOptionEqualToValue={(opt, value) => {
              if (has(opt, 'id')) {
                return opt.id === value.id;
              } else if (has(opt, 'key')) {
                return opt.key === value.key;
              }
              return isEqual(opt, value);
            }}
            formFieldOptions={{
              required: false,
            }}
          />
        )}
        {showSubtenants && subtenants?.length > 0 && (
          <MultiSelect
            isSingleSelect={true}
            labelKey="name"
            label="Subtenants"
            options={subtenants}
            formContext={form}
            placeholder={'Select Subtenants'}
            fieldName="subtenantId"
            valueKey={'id'}
            findValueInOptions={true}
            formFieldOptions={{
              required: false,
            }}
          />
        )}
        {userId && userId !== 'new' && (
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ height: 'auto', margin: '0', maxWidth: 200 }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={userId}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        )}
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default AppUserEditorForm;

import React, { useEffect, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSubItemPersistence } from '../../shared/cms/hooks/useSubItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemPublishSection from '../../shared/cmsPage/components/CmsSubItemPublishSection';
import { usePublishPageTitle } from '../../shared/cmsPage/hooks/usePublishPageTitle';
import useMultiSelect from '../../shared/util/hooks/useMultiSelect';
import menuApi from '../api/menuApi';
import menuItemApi from '../api/menuItemApi';
import { MenuPublishPageConstants } from '../definitions';
import useMenuItemPublishActions from '../hooks/useMenuItemPublishActions';

const MenuItemPublishPage = (props) => {
  const { menuId, successGrowlId, returnPageId, returnPageName, returnPageLabel } = props;
  const { item, loadItem, publishItem, requestPending } = useItemPersistence(menuApi);
  const { subItemRequestPending } = useSubItemPersistence(menuItemApi);
  useEffect(() => {
    if (menuId) (async () => await loadItem(menuId))();
  }, [menuId, loadItem]);
  const { pageTitle, onItemChange } = usePublishPageTitle(menuId, 'Menu', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);
  const pending = requestPending || subItemRequestPending;
  const emptyListMessage = useMemo(() => {
    return item
      ? `There are currently no menu items for '${item.name}.'`
      : `There are currently no menu items.`;
  }, [item]);
  const selectActions = useMultiSelect();
  const { toolbarActions } = useMenuItemPublishActions({
    selectedItems: selectActions.selectedItems,
    item,
    publishItem,
    menuId,
    returnPageName,
    successGrowlId,
  });
  return (
    <CmsPage title={pageTitle} requestPending={pending}>
      <CmsSubItemPublishSection
        parentItemId={menuId}
        parentPageId={returnPageId}
        parentPageName={returnPageName}
        parentPageTitle={returnPageLabel}
        parentPageArgs={[menuId]}
        className="nav-manager"
        toolbarActions={toolbarActions}
        emptyListMessage={emptyListMessage}
        subItemApi={menuItemApi}
        pageConstants={MenuPublishPageConstants}
        selectActions={selectActions}
      />
    </CmsPage>
  );
};

export default MenuItemPublishPage;

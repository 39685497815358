import React, { useCallback, useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CsvExportModal from '../../shared/modal/components/CsvExportModal';
import { createLeadGenReport } from '../api/leadGenResultsApi';
import { LeadGenEventConstants, LeadGenImportsConstants } from '../definitions';
import useLeadGenAttendeesListActions from '../hooks/useLeadGenAttendeesListActions';
import LeadGenAttendeeDetailsModal from './LeadGenAttendeeDetailsModal';
import LeadGenTabSelector from './LeadGenTabSelector';
const LeadGenAttendeesPage = ({ leadGenId, ingestType, eventName, resultType }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showList, setShowList] = useState(true);
  const { typeName } = LeadGenImportsConstants;
  const handleClickShowAttendeeDetails = useCallback(
    (show = false, data = {}) => {
      setDetailsModalData(data);
      setShowDetailsModal(show);
    },
    [setShowDetailsModal, setDetailsModalData]
  );

  const {
    back,
    itemActions,
    columns,
    api,
    SheetModal,
    toolbarActions,
    additionalSearchFields = [],
  } = useLeadGenAttendeesListActions({
    leadGenId,
    ingestType,
    resultType,
    handleClickShowAttendeeDetails,
    setShowExportModal,
    setShowList,
  });

  return (
    <CmsPage title={eventName} backTitle={back.pageName} backClick={back.onClick}>
      <LeadGenTabSelector
        eventName={eventName}
        leadGenId={leadGenId}
        resultType={resultType}
        ingestType={ingestType}
      />
      {!showDetailsModal &&
        (resultType === 'imports' ? (
          SheetModal
        ) : (
          <CsvExportModal
            visible={showExportModal}
            setVisible={setShowExportModal}
            api={createLeadGenReport(leadGenId)}
            name={'Lead Gen Report'}
          />
        ))}
      <LeadGenAttendeeDetailsModal
        handleClick={handleClickShowAttendeeDetails}
        visible={showDetailsModal}
        data={detailsModalData}
      />
      {showList && (
        <CmsItemListSection
          pageId={LeadGenEventConstants.attendeesPageId}
          additiontalLoaderParams={{ leadGenId }}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
          additionalSearchFields={resultType === 'results' ? additionalSearchFields : []}
        />
      )}
    </CmsPage>
  );
};

export default LeadGenAttendeesPage;

import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'quizzes';
export const QuizConstants = createPageConstants(pluginId, 'Quiz', {
  defaultDefault: 'quizzesListPage',
  editor: 'quizBuilderPage',
});
export const QuizInstanceConstants = createPageConstants(pluginId, 'Quiz Instance', {
  default: 'quizInstancesPage',
  editor: 'quizInstanceEditorPage',
});

export const EMPTY_QUIZ = { isArchived: false, name: '' };

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../../store/rootReducer';
const ItemLinkDisplay = ({ objectDetail, objectReference }) => {
  const dispatch = useDispatch();
  const maxLinkLength = 30;
  const hasLink = objectDetail?.editLink;
  const navigateToLink = useCallback(() => {
    if (hasLink) {
      const pluginLink = objectDetail.editLink;
      dispatch(
        navigateToPluginPage([pluginLink.pluginName, pluginLink.pluginPage, ...pluginLink.args])
      );
    }
  }, [dispatch]);
  const link = useMemo(() => {
    return objectDetail?.displayName || 'NA';
  }, [objectReference]);
  return (
    <>
      {hasLink ? (
        <a onClick={navigateToLink}>{link}</a>
      ) : link.length > maxLinkLength ? (
        `${link.substring(0, maxLinkLength)}...`
      ) : (
        link
      )}
    </>
  );
};

export default ItemLinkDisplay;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import generalFilesApi from '../api/generalFilesApi';
import { GeneralFilesConstants } from '../definitions';
import useArray from '../../shared/util/hooks/useArray';
import useGeneralFilesActions from '../hooks/useGeneralFilesActions';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';

const GeneralFilesListPage = () => {
  const { defaultPageId } = GeneralFilesConstants;
  const { add, publish, unpublish, remove } = useGeneralFilesActions(GeneralFilesConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(publish, unpublish, remove);

  const columns = [
    {
      label: 'Name',
      valueField: 'title',
      sortable: true,
      initialSort: 'ASC',
      renderer: (item) => <span>{item.title}</span>,
    },
    {
      label: 'File Extension',
      valueField: 'type',
      sortable: true,
      renderer: (item) => <span>{item.type}</span>,
    },
    {
      label: 'Status',
      valueField: 'status',
      align: 'center',
      sortable: false,
      renderer: (item) => <ItemPublishStatus status={item.status} />,
    },
    {
      label: 'Last Modified',
      valueField: 'lastUpdated',
      sortable: true,
      renderer: (item) => <Timestamp date={item.updated} />,
    },
  ];
  return (
    <CmsPage title={'General Files'}>
      <div className="alert alert-info">
        {`Supported file types are: pdf, doc, docx, xls, xlsx, csv, txt, rtf, ppt, pptx, ppsx`}
      </div>
      <CmsItemListSection
        pageId={defaultPageId}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={generalFilesApi}
      />
    </CmsPage>
  );
};

export default GeneralFilesListPage;

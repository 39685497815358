import isPlainObject from 'lodash/isPlainObject';
import { dismissGrowl } from './rootReducer';
export const createGrowl = (groupId, type, props, thunkApi) => {
  props = isPlainObject(props) ? props : {};
  const growl = {
    groupId,
    id: `${Date.now()}`,
    type,
    ...props,
  };
  const { ttlSeconds } = growl;
  if (ttlSeconds > 0) {
    const ttlMillis = ttlSeconds * 1000;
    growl.expiration = Date.now() + ttlMillis;
    setTimeout(() => thunkApi.dispatch(dismissGrowl({ growl })), ttlMillis);
  }
  return growl;
};

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useCourseConfigurations from '../hooks/useCourseConfigurations';
import useItrsActions from '../hooks/useItrsActions';
import SessionTable from './SessionTable';

const TableWrapper = ({ parentItem, reloadParent }) => {
  return <SessionTable courseId={parentItem.id} reloadParent={reloadParent} hideOptions={true} />;
};

const ItrsCourseListPage = () => {
  const { defaultPageId, typeName } = ItrsCourseConstants;
  const { add, edit, view, remove, addSession } = useItrsActions(ItrsCourseConstants);
  const { columns, api } = useCourseConfigurations();
  const toolbarActions = useArray(add);
  const itemActions = useArray(addSession, edit, view, remove);

  return (
    <CmsPage title={'Courses'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
        NestedComponent={TableWrapper}
      />
    </CmsPage>
  );
};

export default ItrsCourseListPage;

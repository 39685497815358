import pluralize from 'pluralize';
import React, { useMemo } from 'react';
export default function MissingWarning(props) {
  const { isPrimary = false, isSecondary = false, primaryOptionName, subOptionName } = props;
  const label = useMemo(() => {
    let text = '';

    if (isPrimary) {
      text += primaryOptionName ? ` ${pluralize(primaryOptionName)} ` : ' Primary Options ';
    } else if (isSecondary) {
      text += subOptionName
        ? ` ${primaryOptionName} ${pluralize(subOptionName)} `
        : ' Sub Options ';
    }

    text += `not found.`;
    return text;
  }, [isPrimary, isSecondary, primaryOptionName, subOptionName]);
  return <div className="query-builder-warning">{label}</div>;
}

import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { LeadGenEventConstants } from '../definitions';
const { pluginId, attendeesPageName } = LeadGenEventConstants;
export default function LeadGenTabSelector({ leadGenId, eventName, resultType, ingestType }) {
  const dispatch = useDispatch();
  const nav = useCallback(
    (newResultType) => {
      dispatch(
        navigateToPluginPage([
          pluginId,
          attendeesPageName,
          leadGenId,
          eventName,
          newResultType,
          ingestType,
        ])
      );
    },
    [leadGenId, eventName, resultType, ingestType]
  );

  return (
    <ul className="nav nav-tabs" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
      <li className="nav-item">
        <a
          className={classNames('nav-link', { active: resultType === 'imports' })}
          onClick={() => nav('imports')}
          aria-current="page"
          href="#"
        >
          Attendees
        </a>
      </li>
      <li className="nav-item">
        <a
          className={classNames('nav-link', { active: resultType === 'results' })}
          onClick={() => nav('results')}
          href="#"
        >
          Results
        </a>
      </li>
    </ul>
  );
}

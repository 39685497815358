import React from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { FormInstanceConstants } from '../definitions';
import FormInstanceEditor from './FormInstanceEditor';

const FormInstanceEditorPage = (props) => {
  const { formId, formInstanceId } = props;
  const { editorPageId, typeName } = FormInstanceConstants;
  const { pageTitle, onItemChange } = useEditPageTitle(formInstanceId, typeName, 'name');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <FormInstanceEditor itemId={formInstanceId} formId={formId} onItemChange={onItemChange} />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default FormInstanceEditorPage;

import { useMemo } from 'react';
import manageGroupsEditApi from '../../manage-groups/api/manageGroupsEditApi';
import logger from '../../logger';

const useManageUserActions = (passedProps, refreshCallback) => {
  const {
    groupId: rawGroupId,
    groupKey,
    additiontalSearchFieldsValue,
    checkedUsers,
    groupCanAssign,
  } = passedProps;
  const groupId = Number.parseInt(rawGroupId);
  const filteredUsers = useMemo(() => {
    return Object.keys(checkedUsers)
      .filter((userId) => checkedUsers[userId])
      .map((userId) => Number.parseInt(userId));
  }, [checkedUsers]);

  async function addUsersToGroup(groupId, groupKey, filteredUsers, action = 'add') {
    try {
      const results = await Promise.all(
        filteredUsers.map((userId) =>
          manageGroupsEditApi.addUser(groupId, groupKey, userId, action)
        )
      );
      logger.info('results', results);
    } catch (error) {
      logger.error('Error occurred while adding users to group:', error);
    }
  }

  async function removeUsersFromGroup(groupId, groupKey, filteredUsers, action = 'remove') {
    try {
      const results = await Promise.all(
        filteredUsers.map((userId) =>
          manageGroupsEditApi.removeUser(groupId, groupKey, userId, action)
        )
      );
      logger.info('results', results);
      await refreshCallback();
    } catch (error) {
      logger.error('Error occurred while removing users from group:', error);
    }
  }

  return useMemo(() => {
    if (additiontalSearchFieldsValue) {
      return [
        {
          label: 'Remove User',
          disabled:
            !groupCanAssign ||
            Object.keys(checkedUsers).filter((userId) => checkedUsers[userId]).length === 0,
          onClick: async () => {
            await removeUsersFromGroup(groupId, groupKey, filteredUsers);
            await refreshCallback();
          },
        },
      ];
    }
    return [
      {
        label: 'Add User',
        disabled:
          !groupCanAssign ||
          Object.keys(checkedUsers).filter((userId) => checkedUsers[userId]).length === 0,
        onClick: async () => {
          await addUsersToGroup(groupId, groupKey, filteredUsers);
          await refreshCallback();
        },
      },
    ];
  }, [additiontalSearchFieldsValue, groupId, groupKey, filteredUsers]);
};

export default useManageUserActions;

import { EMPTY_COHORT } from '../definitions';
import axios from 'axios';
import log from '../../logger';
import { downloadBlob, downloadJsonToCsv } from '../../shared/util/fileDownload';

const baseUrl = '/plugins/cohorts/api';
const loadRecords = async (params = {}) => {
  const { sort = {}, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getCohorts`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { cohorts, count, offset } = data;
    result = { numRecords: count, offset, records: cohorts };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load cohorts');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_COHORT;

  try {
    const { data } = await axios.get(`${baseUrl}/getCohort`, { params: { id }, timeout: 10_000 });
    return data.cohort;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load cohort.');
  }
};

const saveRecord = async (item) => {
  try {
    const { data } = await axios.post(`${baseUrl}/saveCohort`, { ...item }, { timeout: 10_000 });
    return data.cohort;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save cohort.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeCohort`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove cohort.');
  }
};

export const getPotentiallyDeletedCohortGroups = async (cohortId, groups = []) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/getPotentialDeletedGroups`,
      { cohortId, groups },
      { timeout: 10_000 }
    );
    return data.groups;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get cohort potentially deleted cohort groups.');
  }
};

export const importCohortData = async (cohortId, compressedData) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/importCohortData`,
      { cohortId, compressedData },
      { timeout: 10_000 }
    );
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to import cohort data.');
  }
};
export const getCohortIngestTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCohortIngestTypes`, {
      timeout: 10_000,
    });
    return data.options;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load attendances.');
  }
};
export const getExternalCohortMembers = async (cohortId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getExternalCohortMembers`, {
      params: { cohortId },
      timeout: 10_000,
    });
    return { errors: data.errors };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load external cohort members.');
  }
};

export const downloadCurrentCsv = async (cohortId) => {
  try {
    const resp = await axios.get(`${baseUrl}/downloadCurrentCsv`, {
      params: { cohortId },
      timeout: 10_000,
    });
    const { cohortName, csv } = resp.data;
    const blob = new Blob([csv], { type: 'text/csv' });
    downloadBlob(blob, `cohort_${cohortName}.csv`.replaceAll(' ', '_'));
  } catch (e) {
    log.error(e);
    throw new Error('Failed to import cohort data.');
  }
};

export const downloadApiCsv = async (cohortId) => {
  try {
    const resp = await axios.get(`${baseUrl}/downloadExternalApiCsv`, {
      params: { cohortId },
      timeout: 10_000,
    });
    const data = resp.data.data;
    if (data?.length) {
      const keys = new Set();
      data.forEach((obj) => {
        Object.keys(obj).forEach((k) => {
          keys.add(k);
        });
      });
      downloadJsonToCsv({
        fields: Array.from(keys).sort(),
        data,
        filename: 'External API Data.csv',
      });
    }
  } catch (e) {
    log.error(e);
    throw new Error('Failed to import cohort data.');
  }
};

const cohortApi = { loadRecord, loadRecords, removeRecord, saveRecord };
export default cohortApi;

import { Divider } from '@mui/material';
import React from 'react';
import FormCheckBox from '../../shared/form/components/FormCheckBox';
import FormDateOnly from '../../shared/form/components/FormDateOnly';
import FormInput from '../../shared/form/components/FormInput';
import RadioButtonGroup from '../../shared/form/components/FormRadioButtonGroup';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import MultiSelect from '../../shared/form/components/MultiSelect';
import api from '../api/itrsApi';
import { toNumber } from 'lodash';

const SessionMulti = ({ placeholder, form, label, req, ...props }) => (
  <MultiSelect
    label={label}
    fieldWidth="normal"
    formContext={form}
    placeholder={placeholder || `Select ${label}`}
    valueKey={'value'}
    labelKey="label"
    isSingleSelect={true}
    findValueInOptions={true}
    {...(req && { formFieldOptions: { required: `${label} Is Required` } })}
    {...props}
  />
);
const SessionDetailForm = ({ form }) => {
  return (
    <>
      <RadioButtonGroup
        name="status"
        type="radio"
        form={form}
        label="Session Status"
        options={[
          { value: 'draft', label: 'Draft' },
          { value: 'enrolling', label: 'Enrolling' },
          { value: 'active', label: 'Active' },
          { value: 'cut-off reached', label: 'Cut-Off Reached' },
          { value: 'complete', label: 'Complete' },
        ]}
        validation={{ required: 'Status is required.' }}
      />

      <Divider />
      <FormDateOnly
        label={`Start Date`}
        fieldName="startDate"
        formContext={form}
        fieldWidth="normal"
        formFieldOptions={{ required: 'Start date is required' }}
      />
      <FormDateOnly
        label={`End Date`}
        fieldName="endDate"
        fieldWidth="normal"
        formContext={form}
        formFieldOptions={{ required: 'End date is required' }}
      />
      <FormInput
        label="Enrollment Cut-Off"
        fieldName="cutoffDays"
        formContext={form}
        type="number"
        min={0}
        formFieldOptions={{
          validate: (v) => {
            if (toNumber(v) < 0) return 'Minimum value is 0';
            return true;
          },
        }}
        onBlur={() => form.trigger('cutoffDays')}
      />
      <FormCheckBox
        id={'travelhorizons'}
        label={'Enable Travel Horizons'}
        tooltipType="question"
        infoTooltip="Use this option to set travel arrival and departure dates for a session. If defined, attendee travel dates must be within horizon guidelines or an explanation and additional approval requirements will be required."
        fieldName={`travelHorizons`}
        formContext={form}
        type="checkbox"
      />
      <Divider />
      <FormInput
        label="Session Name"
        fieldName="name"
        formContext={form}
        formFieldOptions={{
          required: 'This field is required.',
        }}
      />
      <SessionMulti
        fieldName="billingCategory"
        form={form}
        defaultValue="none"
        label="Billing Category"
        optionsLoader={api.getBilling}
        req
      />

      <FormWysiwyg
        tag="textarea"
        label="Session Description"
        formContext={form}
        fieldName="description"
      />
      <SessionMulti
        fieldName="trainingLocation"
        form={form}
        label="Training Location"
        optionsLoader={api.getTrainingPrograms}
        req
      />
      <SessionMulti
        fieldName="category"
        form={form}
        label="Category / Department"
        optionsLoader={api.getCategories}
        req
      />
      <SessionMulti
        fieldName="program"
        form={form}
        defaultValue="none"
        label="Program(s)"
        placeholder={'Select Program'}
        optionsLoader={api.getPrograms}
      />
    </>
  );
};

export default SessionDetailForm;

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/forms/api';
export const updateFormOptions = async (id, formOptions) => {
  try {
    await axios.post(`${baseUrl}/updateFormOptions`, { ...formOptions, id, timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove form.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;
    try {
      const {
        data: { forms, count, offset },
      } = await axios.get(`${baseUrl}/getForms`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10_000,
      });
      result = { numRecords: count, offset, records: forms };
    } catch (e) {
      log.error(e);
      throw new Error('Failed to load forms');
    }
    return result;
  };

const generateFormsApi = (includeArchived) => {
  const formsApi = { loadRecords: loadRecordsWrapper(includeArchived) };
  return formsApi;
};

export const loadRecord = async (id) => {
  if (id === 'new') throw new Error('Cannot load new form');

  try {
    const { data } = await axios.get(`${baseUrl}/getForm`, { params: { id }, timeout: 10_000 });
    let parsedInclude;
    try {
      parsedInclude =
        data &&
        data.form &&
        data.form.included &&
        data.form.included.split(',').reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
      data.form.included = parsedInclude ? parsedInclude : {};
    } catch {
      parsedInclude = {};
    }
    return data.form;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load form.');
  }
};

export const formCreate = async (dupeId) => {
  try {
    const endpoint = dupeId ? 'duplicateForm' : 'formCreate';
    const { data } = await axios.post(`${baseUrl}/${endpoint}`, { timeout: 10_000, dupeId });
    return data.formId;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load form.');
  }
};

export const saveInstance = async (formId, name) => {
  try {
    const {
      data: { formInstance },
    } = await axios.post(`${baseUrl}/saveInstance`, { params: { name, formId }, timeout: 10_000 });
    return formInstance;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load form.');
  }
};

export const getForm = async (id) => {
  const resp = await axios.get(`${baseUrl}/getForm`, { params: { id }, timeout: 10_000 });
  return resp.data;
};
export default generateFormsApi;

export const updateForm = async (id, form) => {
  const resp = await axios.put(`${baseUrl}/updateForm`, { form, formId: id }, { timeout: 10_000 });
  return resp.data;
};

(function () {
  angular
    .module('create-user', ['user-api-service', 'angularjs-dropdown-multiselect'])
    .controller('EditUserCtrl', [
      '$scope',
      '$log',
      '$timeout',
      'growl',
      'userApiService',
      'confirmDialog',
      'messageService',
      function ($scope, $log, $timeout, growl, userApiService, confirmDialog, messageService) {
        $scope.user = $scope.user ? angular.copy($scope.user) : {};
        $scope.modeLabel = $scope.replaceMode ? 'Edit' : 'Add';
        $scope.originalSubtenantId = $scope.user.subtenantId;
        $scope.groupSelectSettings = {
          displayProp: 'name',
          enableSearch: true,
          searchField: 'name',
          template:
            '<span class="select-font" ng-class="{disabled: !option.canAssign, fromDefault: option.isDefault}">{{option.name}}</span>',
        };
        $scope.selectionData = {
          selectedGroups: [],
        };
        $scope.hiddenUserGroups = [];
        messageService.getMessage('cms.subtenant.singular').then(function (subtenantLabel) {
          $scope.subtenantLabel = subtenantLabel;
        });
        $scope.subtenants = _.values($scope.selectedTenant.subtenants);
        $scope.showSubtenants =
          $scope.subtenants && $scope.subtenants.length > 1 && !!$scope.user.subtenantId;
        userApiService.getUserConfig().then(function (userConfig) {
          $scope.types = getVisibleTypes(userConfig.userTypes, $scope.selectedSubtenant);
          $scope.showAccessCode = !!userConfig.editableAccessCode || $scope.replaceMode;
          $scope.accessCodeIsReadOnly = !userConfig.editableAccessCode;
          $scope.showEmail = !!userConfig.captureUserEmail;
          $scope.showSendInviteOption =
            $scope.showEmail && !!userConfig.inviteEmailEnabled && !$scope.replaceMode;
          $scope.sendInvite = { selected: $scope.showSendInviteOption };
          $scope.groupsChanged = false;
          $scope.userGroups = (userConfig.userGroups || [])
            .filter(function (userGroup) {
              return userGroup.canAssign;
            })
            .map(function (userGroup) {
              return _.assign({}, userGroup, {
                disabled: !userGroup.canAssign,
              });
            })
            .sort(function (group1, group2) {
              if (group1.name < group2.name) return -1;
              else if (group1.name > group2.name) return 1;
              return 0;
            });
          $scope.userGroupMap = {};
          _.forEach($scope.userGroups, function (userGroup) {
            $scope.userGroupMap[userGroup.id] = userGroup;
          });
          if ($scope.replaceMode) setSelectedUserGroups();
          else if ($scope.types.length > 0) {
            const defaultUserType = getFirstSelectableUserType($scope.types) || $scope.types[0];
            $scope.user.userType = defaultUserType.value;
          }
          $scope.showUserTypes = $scope.types.length > 1;
        });
        $scope.cancel = function () {
          $scope.$dismiss();
        };
        $scope.save = function () {
          if (changingSubtenant()) {
            confirmDialog({
              title: `Changing ${$scope.subtenantLabel}`,
              body: `Are you sure you want to change this user's assigned ${$scope.subtenantLabel}?  This change may affect the groups to which the user belongs as well as the content available to him/her within the app.`,
              confirmText: 'Yes',
              cancelText: 'No',
            }).result.then(function () {
              doSave();
            });
          } else {
            doSave();
          }
          function doSave() {
            $scope.user.userGroups = $scope.selectionData.selectedGroups.concat(
              $scope.hiddenUserGroups
            );
            userApiService
              .saveUser($scope.user)
              .success(function (data) {
                if (data.status === 'error') growl.error(data.error);
                else {
                  // This logic should be done server side, not in the browser.
                  if ($scope.sendInvite.selected && !$scope.replaceMode) {
                    $scope.user = data.appUser;
                    $scope.sendInviteEmail();
                  }
                  $scope.$close(data);
                }
              })
              .error(function (err) {
                growl.error(err.error || 'User upload failed.  Please try again later.');
              });
          }
        };
        const emailListInvalid = function () {
          var mailformat = /^[\w!#$%&*+./=?^`{|}~’-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/;
          const textArea = document.getElementById('emailList');
          if (!textArea.value) return false;
          const emailList = textArea.value.includes(',')
            ? textArea.value.split(',').map((x) => x.trim())
            : [textArea.value.trim()];
          const listInvalid = emailList.reduce(
            (isInvalid, email) => isInvalid || !mailformat.test(email),
            false
          );
          return listInvalid;
        };
        $scope.requestReport = function () {
          if (emailListInvalid()) {
            growl.error('Invalid email list');
            return;
          }
          const textArea = document.getElementById('emailList');
          const emailList = (
            textArea.value.includes(',')
              ? textArea.value.split(',').map((x) => x.trim())
              : [textArea.value.trim()]
          ).filter(Boolean);
          $scope.$close({ otherEmails: JSON.stringify(emailList) });
          return;
        };
        function changingSubtenant() {
          return (
            !!$scope.originalSubtenantId && $scope.user.subtenantId !== $scope.originalSubtenantId
          );
        }
        $scope.onGroupsChanged = function () {
          $scope.groupsChanged = true;
        };
        $scope.sendInviteEmail = function () {
          userApiService
            .sendInviteEmail($scope.user)
            .success(function () {
              growl.success(`Invitation email sent to ${$scope.user.email}`);
            })
            .error(function () {
              growl.error(`Failed to send invitation email to ${$scope.user.email}`);
            });
        };
        function setSelectedUserGroups() {
          const user = $scope.user;
          _.forEach(user.userGroups, function (userGroup) {
            if ($scope.userGroupMap[userGroup.id]) {
              $scope.selectionData.selectedGroups.push(userGroup);
            } else {
              $scope.hiddenUserGroups.push(userGroup);
            }
          });
        }
        function getFirstSelectableUserType(userTypes) {
          for (const userType of userTypes) {
            if (userType.selectable !== false) return userType;
          }

          return null;
        }
        function getVisibleTypes(userTypes, selectedSubtenant) {
          const result = [];
          if (userTypes) {
            userTypes.forEach((type) => {
              const subtenantAvailability = type.subtenantAvailability || 'default';
              const available =
                subtenantAvailability === 'both' ||
                (subtenantAvailability === 'default' && selectedSubtenant.isDefault) ||
                (subtenantAvailability === 'nonDefault' && !selectedSubtenant.isDefault);
              if (type.selectable !== false && available) result.push(type);
            });
          }
          return result;
        }
      },
    ]);
})();

const mailformat = /^[\w!#$%&*+./=?^`{|}~’-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/;

export default function validateEmails(emails = []) {
  let isValid = true;
  for (const email of emails) {
    if (!mailformat.test(email)) {
      isValid = false;
      break;
    }
  }
  return isValid;
}

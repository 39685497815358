import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import FormFieldError from '../FormFieldError';
import Alerts from './components/Alerts';
import ImageSelector from './components/ImageSelector';
import Select from './components/Select';
import { ImageContext } from './store';

const FormImageComponent = (props) => {
  const {
    label,
    isRequired,
    fieldError,
    className: providedClassName,
    uploadButtonText,
    useCropper,
    croppedRatio,
  } = props;
  const { open } = useContext(ImageContext);
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      open();
    },
    [open]
  );

  return (
    <div
      className={classNames('form-group', providedClassName, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      <div
        className="col"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <div
          className="col col-sm-12 col-md-6 col-lg-3"
          style={{ height: '200px', width: '200px', margin: '5px' }}
        >
          <ImageSelector useCropper={useCropper} croppedRatio={croppedRatio} />
        </div>
        <div
          className="col col-sm-12 col-md-6 col-lg-3"
          style={{
            marginLeft: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Select />
          <Button style={{ marginTop: '10px', width: '200px' }} variant="primary" onClick={onClick}>
            {uploadButtonText ? uploadButtonText : 'Upload'}
          </Button>
        </div>
        <div style={{ flexBasis: '100%' }}>
          <Alerts />
        </div>
      </div>

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormImageComponent;

import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import set from 'lodash/set';
import { useTheme } from '@mui/material';

const ActionButton = ({ icon, label, disabled, link, onClick, variant, hiddenButtonRef }) => {
  const theme = useTheme();
  const clickHandler = useCallback(() => {
    return onClick();
  }, [onClick]);
  const props = { style: {} };

  if (hiddenButtonRef) {
    set(props, 'ref', hiddenButtonRef);
    set(props, 'style.display', 'none');
  }
  return link ? (
    <button
      {...props}
      style={{
        fontSize: '14px',
        color: theme.palette.bootstrap.blue,
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        cursor: disabled ? 'not-allowed' : 'pointer',
        display: hiddenButtonRef ? 'none' : 'inline',
      }}
      disabled={disabled}
      onClick={clickHandler}
    >
      {icon && <i className={icon}></i>}
      {label}
    </button>
  ) : (
    <Button {...props} variant={variant} disabled={disabled} onClick={clickHandler}>
      {icon && <i className={icon}></i>}
      {label}
    </Button>
  );
};

export default ActionButton;

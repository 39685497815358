import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

const usePositioning = (state, originalState, setState, isLoaded, mapRef) => {
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  const [hasZoomChanged, setHasZoomChanged] = useState(false);
  const [hasCenterChanged, setHasCenterChanged] = useState(false);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const getCenterObj = () => {
    try {
      const lat = mapRef.current.getCenter().lat();
      const lng = mapRef.current.getCenter().lng();
      if (lat !== undefined && lng !== undefined) {
        return { lat, lng };
      }
      return null;
    } catch {
      return null;
    }
  };

  const onCenterChanged = useCallback(
    _.debounce(() => {
      if (mapRef.current && isUserInteracted) {
        const center = getCenterObj();
        if (center.lat !== state.center.lat || center.lng !== state.center.lng) {
          const newState = { ...state, center };
          setState(newState);
          setHasCenterChanged(true);
        }
        const zoom = mapRef.current.getZoom();
        if (zoom !== state.zoom) {
          const newState = { ...state, zoom };
          setHasZoomChanged(true);
          setState(newState);
        }
        setIsUserInteracted(false);
      }
    }, 50),
    [isUserInteracted, setState]
  );

  const onMapInteraction = useCallback(() => {
    if (!mapRef?.current) return;
    const zoom = mapRef.current.getZoom();
    const center = getCenterObj();
    const zoomChange = zoom !== state.zoom;
    const stateLat = state.center.lat;
    const stateLng = state.center.lng;
    const centerChange = center.lat !== stateLat || center.lng !== stateLng;
    if (mapRef.current && (centerChange || zoomChange)) {
      if (centerChange) {
        const newState = { ...state };
        newState.center = center;
        setState(newState);
        setHasCenterChanged(true);
      }
      if (zoomChange) {
        const newState = { ...state, zoom };
        setHasZoomChanged(true);
        setState(newState);
      }
    }
  }, [mapRef, getCenterObj]);

  const mapCallbacks = useMemo(
    () => ({
      onZoomChanged: onMapInteraction,
      onLoad,
      onDragEnd: onMapInteraction,
    }),
    [onLoad, onCenterChanged, onMapInteraction]
  );

  const updateBounds = useCallback(() => {
    const { LatLngBounds, LatLng } = window?.google?.maps || {};
    if (!isLoaded || !LatLng || !LatLngBounds || !state.markers) return;
    const bounds = new LatLngBounds();
    state.markers.forEach((marker) => {
      const latitude = marker.latitude || marker.lat || marker?.data?.latitude || marker?.data?.lat;
      const longitude =
        marker.longitude || marker.lng || marker?.data?.longitude || marker?.data?.lng;
      bounds.extend(new LatLng(latitude, longitude));
    });
    const count = state.markers.length;
    if (count > 0) {
      if (count > 1) mapRef.current?.fitBounds(bounds);
      const updated = {
        bounds: {
          northeast: {
            latitude: bounds.getNorthEast().lat(),
            longitude: bounds.getNorthEast().lng(),
          },
          southwest: {
            latitude: bounds.getSouthWest().lat(),
            longitude: bounds.getSouthWest().lng(),
          },
        },
        center: {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        },
        zoom: Math.min(mapRef.current?.getZoom() || 12, 12),
      };
      setState((st) => ({ ...st, ...updated }));
    }
  }, [state.markers, setState, isLoaded, mapRef]);

  useEffect(() => {
    const markersChanged = !_.isEqual(state.markers, originalState.markers);
    if (markersChanged) updateBounds();
  }, [state.markers, updateBounds]);
  return { hasZoomChanged, hasCenterChanged, mapCallbacks, mapRef };
};

export default usePositioning;

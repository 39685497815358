angular
  .module('content-api-service', [])
  .service('contentApiService', [
    '$q',
    '$log',
    'Upload',
    '$http',
    '$rootScope',
    'CMS_INSTANCES',
    'instanceService',
    '$cookies',
    function ($q, $log, Upload, $http, $rootScope, CMS_INSTANCES, instanceService, $cookies) {
      const VALID_NAME_CHARS =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.()_-';
      let assetMap = {};
      function getAllAssets() {
        return $http
          .get('/content-api/getAllAssets')
          .success(function (data) {
            rebuildAssetMap(data.assets);
            $log.debug('Successfully got all assets.');
          })
          .error(function (data) {
            $log.debug('Failed to get all assets. Response: ', data);
          });
      }
      function getPreviewConfig(scheme) {
        const deferred = $q.defer();
        const params = {};
        if (scheme) params.previewScheme = scheme;
        $http
          .get('/content-api/getPreviewUrl', { params })
          .success(function (data) {
            deferred.resolve({
              url: `${data.previewUrl}?previewInstance=${instanceService.getActiveInstance().id}`,
              checkSnapshot: data.checkSnapshot,
            });
            $log.debug('Successfully got previewUrl.');
          })
          .error(function (data) {
            deferred.resolve(`/preview?previewInstance=${instanceService.getActiveInstance().id}`);
            $log.debug('Failed to get previewUrl. Response: ', data);
          });
        return deferred.promise;
      }
      function getMetadataForAsset(assetId) {
        const deferred = $q.defer();
        $http
          .get(`/content-api/getAssetMetadata?assetId=${encodeURIComponent(assetId)}`)
          .success(function (data) {
            deferred.resolve(data.asset);
            $log.debug('Successfully got asset');
          })
          .error(function (data) {
            $log.debug('Failed to get asset. Response: ', data);
            deferred.reject(data.error || 'Failed to get asset.');
          });
        return deferred.promise;
      }
      function rebuildAssetMap(assets) {
        assetMap = {};

        assets.forEach((asset) => {
          assetMap[asset.id] = asset;
        });
      }
      function isEmpty(object) {
        for (const key in object) {
          if (object.hasOwnProperty(key)) return false;
        }
        return true;
      }
      function getAssetMetadata(assetId) {
        const deferred = $q.defer();
        if (isEmpty(assetMap)) {
          getMetadataForAsset(assetId).then(deferred.resolve).catch(deferred.reject);
        } else {
          deferred.resolve(assetMap[assetId]);
        }
        return deferred.promise;
      }
      function getAsset(assetId, revisionId = -1, forceText) {
        // If forceText is true, prevent default angular parsing of asset contents (JSON, for example).
        const promise = $http
          .get(
            `/preview/asset/${assetId}?revisionId=${revisionId}&tenantId=${$cookies.get(
              'tenantId'
            )}`,
            {
              transformResponse: forceText
                ? function (value) {
                    return value;
                  }
                : undefined,
            }
          )
          .success(function () {})
          .error(function (data) {
            $log.debug('Failed to get asset. Response: ', data);
          });
        return promise;
      }
      function saveAsset(contents, asset, overwrite) {
        const data = {
          asset,
          overwrite: !!overwrite,
          fileContents: contents,
        };
        const promise = $http.post('/content-api/saveAsset', data);
        promise.then(function (result) {
          assetMap[asset.id] = result.data.asset;
          broadcastAssetUpdated(asset.id);
        });
        return promise;
      }
      function cancelPushNotification(notificationId) {
        return $http.post('/push/cancelPushNotification', {
          notificationId,
        });
      }
      function getPushNotifications(offset, limit) {
        return $http.get('/push/getPushNotifications', {
          params: {
            limit,
            offset,
            sort: { sortField: 'publishTimestamp', order: 'DESC' },
          },
        });
      }
      function checkSnapshotIsComputedForInstance(instance) {
        const deferred = $q.defer();
        $http
          .get(`/content-api/checkSnapshotIsComputed?instanceId=${instance}`)
          .success(function (data) {
            deferred.resolve(data.isComputed);
          })
          .error(function (data) {
            $log.error(`Failed to check computed status of ${instance} snapshot.`, data);
            deferred.resolve(false);
          });
        return deferred.promise;
      }
      function upsertFile(file, asset, overwrite) {
        const promise = Upload.upload({
          url: '/content-api/uploadAsset',
          method: 'POST',
          data: {
            assetData: Upload.rename(file, asset.id),
            data: Upload.json({
              asset,
              overwrite: !!overwrite,
            }),
          },
        });
        promise
          .progress(function (evt) {
            $log.debug(`progress: ${Number.parseInt((100 * evt.loaded) / evt.total)}%`);
          })
          .success(function (data) {
            $log.debug('Asset was added successfully.  Response: ', data);
            assetMap[asset.id] = data.asset;
            broadcastAssetUpdated(asset.id);
          })
          .error(function (err, status) {
            $log.debug(`error : status = ${status}`, err);
          });
        return promise;
      }
      function removeOverride(asset) {
        const data = {
          assetId: asset.id,
        };
        const promise = $http.post('/content-api/removeOverride', data);
        promise.then(function (result) {
          if (result.data.asset) assetMap[result.data.asset.id] = result.data.asset;
          else {
            delete assetMap[result.data.asset.id];
          }
          broadcastAssetUpdated(asset.id);
        });
        return promise;
      }
      function restoreRevision(asset, revisionId) {
        const data = {
          assetId: asset.id,
          revisionId,
        };
        const promise = $http.post('/content-api/restoreRevision', data);
        promise.then(function (result) {
          assetMap[asset.id] = result.data.asset;
          broadcastAssetUpdated(asset.id);
        });
        return promise;
      }
      function publishContent(assetIdsToPublish) {
        const data = {
          fromInstance: CMS_INSTANCES.sandbox.id,
          toInstance: CMS_INSTANCES.live.id,
          assetIds: assetIdsToPublish,
        };
        const promise = $http.post('/content-api/publishContent', data);
        promise.then(function () {
          $rootScope.$broadcast('contentUpdated');
        });
        return promise;
      }
      function exportContent() {
        const promise = $http.get('/content-api/exportContent');
        promise
          .success(function (data) {
            $log.debug('Successfully exported content. Response: ', data);
          })
          .error(function (data) {
            $log.debug('Failed to export content. Response: ', data);
          });
        return promise;
      }
      function scrubAssetId(id) {
        let newId = '';
        for (let i = 0; i < id.length; i++) {
          const ch = id.charAt(i);
          const found = VALID_NAME_CHARS.indexOf(ch);
          newId += found == -1 ? '_' : ch;
        }
        return newId;
      }
      function broadcastAssetUpdated(assetId) {
        $rootScope.$broadcast('assetUpdated', assetId);
      }
      const types = {
        html: {
          id: 'html',
          label: 'Html',
          extension: '.html',
          editor: 'main.edit-html',
        },
        css: {
          id: 'css',
          label: 'CSS',
          extension: '.css',
          editor: 'main.edit-css',
        },
        js: {
          id: 'js',
          label: 'Javascript',
          extension: '.js',
          editor: 'main.edit-js',
        },
        json: {
          id: 'json',
          label: 'JSON',
          extension: '.json',
          editor: 'main.edit-json',
        },
        wysiwyg: {
          id: 'wysiwyg',
          label: 'WYSIWYG',
          extension: '.html',
          editor: 'main.edit-wysiwyg',
        },
        file: {
          id: 'file',
          label: 'File',
          extension: '.*',
          editor: undefined,
        },
      };
      const groups = {
        default: {
          id: 'default',
          label: 'Default',
          selectable: true,
        },
        interactiveScanner: {
          id: 'interactiveScanner',
          label: 'Interactive Scanner',
          selectable: true,
        },
        menuIcon: {
          id: 'menuIcon',
          label: 'Menu Icon',
          selectable: true,
        },
        theme: {
          id: 'theme',
          label: 'Theme',
          selectable: true,
        },
        plugin: {
          id: 'plugin',
          label: 'Plugin',
          selectable: false,
        },
      };
      function getEditor(type = 'file') {
        return lookupType(type).editor;
      }
      function lookupType(typeName) {
        const result = types[typeName];
        if (!result) throw new Error(`Type '${typeName}' not found`);
        return result;
      }
      function getTypeFromExtension(fileName) {
        const parts = fileName.split('.');
        const extension = parts.length > 1 ? `.${parts.at(-1)}` : '.file';
        for (const typeId in types) {
          const type = types[typeId];
          if (type.extension == extension) return type;
        }
        return types.file;
      }
      function getTypes() {
        return types;
      }
      function getGroups() {
        return groups;
      }
      function getRevisionForInstance(record, instance, useLatestAsDefault) {
        let active = record.instanceMappings ? record.instanceMappings[instance] : undefined;
        if (!active && useLatestAsDefault) active = record.latestRevision;
        return active;
      }
      function getUpdatesFrozen() {
        return $http.get('/content-api/getUpdatesFrozen').then(
          function (data) {
            if (data.data && data.data.status == 'success') return data.data.updatesFrozen;
            throw new Error('Unexpected result from getUpdatesFrozen');
          },
          function (data) {
            $log.debug('Failed to get updatesFrozen', data);
          }
        );
      }
      function setUpdatesFrozen(updatesFrozen) {
        const data = {
          updatesFrozen,
        };
        return $http.post('/content-api/setUpdatesFrozen', data);
      }
      return {
        getEditor,
        getAsset,
        getAssetMetadata,
        getMetadataForAsset,
        getPreviewConfig,
        getTypes,
        getGroups,
        lookupType,
        getRevisionForInstance,
        getTypeFromExtension,
        saveAsset,
        upsertFile,
        removeOverride,
        restoreRevision,
        scrubAssetId,
        getAllAssets,
        publishContent,
        exportContent,
        broadcastAssetUpdated,
        getUpdatesFrozen,
        setUpdatesFrozen,
        //schedulePushNotification: schedulePushNotification,
        cancelPushNotification,
        getPushNotifications,
        checkSnapshotIsComputedForInstance,
      };
    },
  ])
  .filter('selectableGroups', function () {
    return function (groups) {
      const result = {};
      for (const key in groups) {
        const group = groups[key];
        if (group.selectable) result[key] = group;
      }
      return result;
    };
  });

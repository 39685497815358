import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
export default function createPageConstants(pluginId, typeName, pages, pageTitle) {
  return {
    pluginId,
    typeName,
    ...((pageTitle || typeName) && {
      pageTitle: pageTitle || typeName,
    }),
    ...map(pages, (value, key) => {
      let defaultValues = {};
      if (key.includes('Default')) {
        key = key.replace('Default', '');
        defaultValues = {
          [`${key}PageName`]: 'default',
        };
      }
      return {
        [`${key}PageId`]: kebabCase(value),
        [`${key}PageName`]: value,
        ...defaultValues,
      };
    }).reduce((a, values) => ({ ...a, ...values }), {}),
  };
}

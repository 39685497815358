import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToChildPage } from '../../store/rootReducer';
export default function useQuizInsanceActions(
  quizId,
  { pluginId, editorPageName, defaultPageId, typeName }
) {
  const dispatch = useDispatch();
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            quizId,
            'new',
          ])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, quizId]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            quizId,
            context.id,
          ])
        );
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName, quizId]
  );
  const backToQuizzes = useMemo(
    () => ({
      pageName: 'Quizzes',
      onClick: () => {
        dispatch(
          navigateToChildPage([
            pluginId,
            'default',
            'quizzes-list',
            {
              search: '',
              limit: 25,
              offset: 0,
              sort: {
                sortField: 'title',
                order: 'ASC',
              },
            },
          ])
        );
      },
    }),
    [dispatch, pluginId]
  );
  return {
    backToQuizzes,
    add,
    edit,
  };
}

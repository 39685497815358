import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import targetAudienceApiWrapped from '../../../../../target-audience/api/targetAudienceApi';
import log from '../../../../../logger';
import { setSavedAudience } from './index';
const reducerName = 'audienceSelector';
export const gs = (thunkAPI, path = '', def = undefined) =>
  get(thunkAPI.getState(), `${reducerName}${path ? `.${path}` : path}`, def);
export const getUserOptions = createAsyncThunk(
  `${reducerName}/getUserOptions`,
  async (_, thunkAPI) => {
    try {
      if (isEmpty(gs(thunkAPI, 'rootOptions'))) {
        const { data } = await axios.get('/audience-selector/getRootOptions');
        const disableSavedAudiences = get(data, 'disableSavedAudiences', false) || false;
        const rootOptions = keyBy(
          get(data, 'options', []).map((option) => ({
            ...option,
            fieldsMap: keyBy(
              get(option, 'fields', []).map((field) => ({
                ...field,
                conditionsMap: keyBy(get(field, 'conditions', []), 'value'),
                additionalFieldsMap: keyBy(get(field, 'additionalFields', []), 'fieldKey'),
              })),
              'value'
            ),
          })),
          'name'
        );
        return {
          options: rootOptions,
          ignore: false,
          disableSavedAudiences,
        };
      }
      return {
        ignore: true,
      };
    } catch (e) {
      log.error('Error getting user groups', e);
      return [];
    }
  }
);
export const getUsers = createAsyncThunk(
  `${reducerName}/getUsers`,
  async ({ fieldName }, thunkAPI) => {
    try {
      const { logic, preview } = gs(thunkAPI, fieldName, {});
      const { pageIndex, searchText, rowsToShow } = preview;
      const { data } = await axios.post(`/user-api/getAllUsersFromLogic`, {
        logic,
        start: pageIndex * rowsToShow,
        number: rowsToShow,
        searchPredicate: searchText,
        withPushEndpoints: false,
      });
      const { appUsers = [], count } = data;
      //eslint-disable-next-line
      const preUsers = new Array((pageIndex + 0) * rowsToShow).fill({});
      const postUsers = Array.from({ length: count - appUsers.length - preUsers.length }).fill({});
      const allUsers = concat(preUsers, appUsers, postUsers);
      return {
        displayedUsers: allUsers,
        count,
        fieldName,
      };
    } catch (e) {
      log.error('Error getting users', e);
      throw e;
    }
  }
);
export const getAudienceRecords = createAsyncThunk(
  `${reducerName}/getAudienceRecords`,
  async () => {
    try {
      const api = targetAudienceApiWrapped(false);
      const { records } = await api.loadRecords({
        sort: {
          sortField: 'name',
          order: 'ASC',
        },
        offset: 0,
        limit: 1000,
      });
      return {
        records,
      };
    } catch (e) {
      log.error('Error getting user groups', e);
      return [];
    }
  }
);
export const getPrimaryOptions = createAsyncThunk(
  `${reducerName}/getPrimaryOptions`,
  async ({ endpoint, params, field, fieldName }, thunkAPI) => {
    if (gs(thunkAPI, `rootOptions.${field}.hasQueried`)) {
      return {
        ignore: true,
      };
    }
    try {
      const { data } = await axios.get(endpoint, {
        params,
      });
      return {
        items: data.items,
        loc: `rootOptions.${field}`,
        fieldName,
      };
    } catch (e) {
      log.error('Error getting primary options', e);
      throw e;
    }
  }
);
export const getSecondaryOptions = createAsyncThunk(
  `${reducerName}/getSecondaryOptions`,
  async ({ endpoint, params, field, optionId, fieldName }, thunkAPI) => {
    if (optionId && !gs(thunkAPI, `rootOptions.${field}.opts.${optionId}.hasQueried`)) {
      try {
        const { data } = await axios.get(endpoint, {
          params,
        });
        return {
          items: data.items,
          loc: `rootOptions.${field}.opts.${optionId}`,
          fieldName,
        };
      } catch (e) {
        log.error('Error getting secondary options', e);
        throw e;
      }
    } else {
      return {
        ignore: true,
      };
    }
  }
);
export const saveAndPublishAudience = createAsyncThunk(
  `${reducerName}/saveAndPublishAudience`,
  async ({ fieldName }, thunkAPI) => {
    try {
      const { logic, newAudienceName } = gs(thunkAPI, fieldName, {});
      const { saveRecord, publishRecord } = targetAudienceApiWrapped(false);
      const savedAudience = await saveRecord({
        label: newAudienceName,
        name: newAudienceName,
        isArchived: false,
        condition: logic,
      });
      const publishedAudience = await publishRecord(savedAudience.target_audience_id);
      await thunkAPI.dispatch(
        setSavedAudience({
          audience: publishedAudience,
          fieldName,
        })
      );
      return {
        savedAudience,
        publishedAudience,
      };
    } catch (e) {
      log.error('Error saving audience', e);
      throw e;
    }
  }
);

angular
  .module('input-directives', ['common-filters', 'message-service'])
  .directive('photo-upload', [
    function () {
      return {
        restrict: 'A',
        link(scope, element, attrs) {
          element.on('click', function (e) {
            e.preventDefault();
            window.location = attrs.href;
          });
        },
      };
    },
  ])
  .directive('required', [
    '$compile',
    function () {
      return {
        restrict: 'C',
        link(scope, element) {
          const span = $('<span class="asterick">*</span>');
          const tagName = element[0].tagName;
          if (element[0].tagName === 'LABEL') $(element).append(span);
          else if (tagName === 'INPUT' || tagName === 'SELECT') $(element).after(span);
        },
      };
    },
  ])
  .directive('phoneInput', [
    '$filter',
    '$browser',
    function ($filter, $browser) {
      return {
        require: 'ngModel',
        link($scope, $element, $attrs, ngModelCtrl) {
          const listener = function () {
            const value = $element.val().replace(/\D/g, '');
            $element.val($filter('tel')(value, false));
          };
          // This runs when we update the text field
          ngModelCtrl.$parsers.push(function (viewValue) {
            return viewValue.replace(/\D/g, '').slice(0, 10);
          });
          // This runs when the model gets updated on the scope directly and keeps our view in sync
          ngModelCtrl.$render = function () {
            $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
          };
          $element.bind('change', listener);
          $element.bind('keydown', function (event) {
            const key = event.keyCode;
            // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
            // This lets us support copy and paste too
            if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
              return;
            }
            $browser.defer(listener); // Have to do this or changes don't get picked up properly
          });
          $element.bind('paste cut', function () {
            $browser.defer(listener);
          });
        },
      };
    },
  ])
  .directive('removeImg', [
    function () {
      return {
        restrict: 'A',
        transclude: true,
        scope: { removeImg: '&' },
        link(scope, element) {
          $(element).addClass('remove-img-container');
        },
        template:
          '<ng-transclude></ng-transclude>' +
          '<span class="glyphicon glyphicon-minus remove-img" ng-click="removeImg()"></span>',
      };
    },
  ]);

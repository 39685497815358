import { EMPTY_COLL_ITEM_REVISION } from '../definitions';
import axios from 'axios';
import log from '../../logger';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import moment from 'moment-timezone';

const baseUrl = '/plugins/collections/api';
const loadRecords = async (collectionId) => {
  let result = [];
  try {
    if (collectionId) {
      const { data } = await axios.get(`${baseUrl}/getCollectionItems`, {
        params: {
          collectionId,
        },
        timeout: 10_000,
      });
      const { collectionItems } = data;
      result = collectionItems;
    }
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collection items');
  }
  return result;
};

const loadRecord = async (id, parentItemId) => {
  if (id === 'new') return { ...EMPTY_COLL_ITEM_REVISION, collection_id: parentItemId };
  try {
    const {
      data: { collectionItem: item },
    } = await axios.get(`${baseUrl}/getCollectionItem`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    let parsedCondition;

    try {
      parsedCondition = item && JSON.parse(item.condition);
      if (parsedCondition) item.condition = parsedCondition;
    } catch {
      parsedCondition = '';
    }
    return { ...item, collection_id: parentItemId };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collection item.');
  }
};

const saveRecord = async (item) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const formData = new FormData();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    forEach({ ...omit(item, 'id'), id: item.collection_item_id }, (v, k) => {
      if (k === 'tileAssetUpload') formData.append(k, v);
      else if (v && typeof v === 'object') {
        const value = JSON.stringify(v);
        formData.append(k, value);
      } else if (item.timed === 'timed' && (k === 'startTime' || k === 'endTime')) {
        const format = 'YYYY-MM-DDTHH:mm';
        const value = moment.tz(v, format, timezone);
        formData.append(k, value.valueOf());
      } else if (v) formData.append(k, v);
    });
    const { data } = await axios.post(`${baseUrl}/saveCollectionItem`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10_000,
    });
    return data.collectionItem;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save collection item.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeCollectionItem`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove collection item.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreCollectionItem`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to restore collection.');
  }
};

const publishRecords = async (itemIds) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishCollectionItems`,
      {
        itemIds,
      },
      {
        timeout: 10_000,
      }
    );
    const { collectionItems } = data;
    return collectionItems;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish collection items.');
  }
};

const navbarItemApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  publishRecords,
};
export default navbarItemApi;

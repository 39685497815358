import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import attendanceApi from '../api/attendanceApi';
import { AttendanceEventConstants } from '../definitions';
const { pluginId, editorPageName, attendeesPageName } = AttendanceEventConstants;
const useAttendanceEventAttendeeListActions = () => {
  const dispatch = useDispatch();
  const { add, edit, remove, requestPending } = useItemListActions(
    attendanceApi,
    AttendanceEventConstants
  );
  const addOverride = useMemo(
    () => ({
      ...add,
      label: 'Add Event',
      onClick: async () => {
        try {
          const { id } = (await attendanceApi.loadRecord('new')) || {};
          if (id) dispatch(navigateToPluginPage([pluginId, editorPageName, id]));
        } catch {
          log.error('Failed to add event');
        }
      },
    }),
    [add, attendanceApi, dispatch]
  );
  const attendeeInfo = useMemo(
    () => ({
      icon: 'fa-solid fa-users',
      label: 'Attendee Info',
      onClick: async ({ id }) => dispatch(navigateToPluginPage([pluginId, attendeesPageName, id])),
    }),
    [dispatch]
  );
  return useMemo(
    () => ({
      add: addOverride,
      edit,
      remove,
      requestPending,
      attendeeInfo,
    }),
    [addOverride, edit, remove, attendeeInfo, requestPending]
  );
};

export default useAttendanceEventAttendeeListActions;

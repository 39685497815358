angular.module('subtenant-api-service', []).service('subtenantApiService', [
  '$q',
  '$log',
  '$http',
  '$rootScope',
  function ($q, $log, $http, $rootScope) {
    let subtenantMap = {};
    function getSubtenants(start, number, params) {
      let sortPredicate;
      let sortReverse;
      let searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) searchPredicate = searchPredicate.name;
      }
      const httpParams = {
        params: {
          start,
          number,
          sortPredicate,
          sortReverse,
          searchPredicate,
        },
      };
      const promise = $http
        .get('/subtenant-api/getSubtenants', httpParams)
        .success(function (data) {
          rebuildSubtenantMap(data.subtenants);
        })
        .error(function (data) {
          $log.debug('Failed to get all subtenants. Response: ', data);
        });
      return promise;
    }
    function getAllSubtenants() {
      const promise = $http
        .get('/subtenant-api/getAllSubtenants')
        .success(function (data) {
          rebuildSubtenantMap(data.subtenants);
        })
        .error(function (data) {
          $log.debug('Failed to get all subtenants. Response: ', data);
        });
      return promise;
    }
    function rebuildSubtenantMap(subtenants) {
      subtenantMap = {};

      subtenants.forEach((subtenant) => {
        subtenantMap[subtenant.id] = subtenant;
      });
    }
    function getSubtenantMetadata(id) {
      return subtenantMap[id];
    }
    function saveSubtenant(subtenant) {
      const data = {
        id: subtenant.id,
        name: subtenant.name,
        affiliation: subtenant.affiliation,
      };
      const promise = $http.post('/subtenant-api/upsertSubtenant', data);
      promise.then(function (result) {
        subtenantMap[subtenant.id] = result.data.subtenant;
        broadcastSubtenantUpdated(result.data.subtenant);
      });
      return promise;
    }
    function removeSubtenant(subtenant) {
      const deferred = $q.defer();
      const data = {
        id: subtenant.id,
      };
      $http
        .post('/subtenant-api/deleteSubtenant', data)
        .then(function () {
          delete subtenantMap[subtenant.id];
          broadcastSubtenantUpdated(subtenant);
          deferred.resolve();
        })
        .catch(function (result) {
          deferred.reject(
            new Error(
              (result && result.data && result.data.error) || 'An unknown error has occured.'
            )
          );
        });
      return deferred.promise;
    }
    function broadcastSubtenantUpdated(subtenant) {
      $rootScope.$broadcast('subtenantUpdated', subtenant.id);
    }
    return {
      removeSubtenant,
      saveSubtenant,
      getSubtenantMetadata,
      getAllSubtenants,
      getSubtenants,
    };
  },
]);

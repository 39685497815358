import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/template/api';
export const updateTemplateItemOptions = async (id, templateItemOptions) => {
  try {
    await axios.post(`${baseUrl}/updateTemplateItemOptions`, {
      ...templateItemOptions,
      id,
      timeout: 10_000,
    });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove templateItem.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;
    try {
      const {
        data: { templateItems, count, offset },
      } = await axios.get(`${baseUrl}/getTemplateItems`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10_000,
      });
      result = { numRecords: count, offset, records: templateItems };
    } catch (e) {
      log.error(e);
      throw new Error('Failed to load templateItems');
    }
    return result;
  };

const generateTemplateItemsApi = (includeArchived) => {
  const templateItemsApi = {
    loadRecords: loadRecordsWrapper(includeArchived),
    loadRecord,
    saveRecord,
  };
  return templateItemsApi;
};

export const loadRecord = async (id) => {
  if (id === 'new') throw new Error('Cannot load new templateItem');

  try {
    const { data } = await axios.get(`${baseUrl}/getTemplateItem`, {
      params: { id },
      timeout: 10_000,
    });
    let parsedInclude;
    try {
      parsedInclude =
        data &&
        data.templateItem &&
        data.templateItem.included &&
        data.templateItem.included.split(',').reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
      data.templateItem.included = parsedInclude ? parsedInclude : {};
    } catch {
      parsedInclude = {};
    }
    return data.templateItem;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load templateItem.');
  }
};

const saveRecord = async (item) => {
  try {
    if (item.id) return updateTemplateItem(item.id, item);
    return templateItemCreate();
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

export const templateItemCreate = async () => {
  try {
    const endpoint = 'templateItemCreate';
    const { data } = await axios.post(`${baseUrl}/${endpoint}`, { timeout: 10_000 });
    return data.templateItemId;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load templateItem.');
  }
};

export const getTemplateItem = async (id) => {
  const resp = await axios.get(`${baseUrl}/getTemplateItem`, { params: { id }, timeout: 10_000 });
  return resp.data;
};
export default generateTemplateItemsApi;

export const updateTemplateItem = async (id, templateItem) => {
  const resp = await axios.put(
    `${baseUrl}/updateTemplateItem`,
    { templateItem, templateItemId: id },
    { timeout: 10_000 }
  );
  return resp.data;
};

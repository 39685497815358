import React, { useCallback, useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import { errorGrowl } from '../../store/rootThunks';
import { privacyApi } from '../api/privacyApi';
import { EMPTY_PURGE_USER_DATA_REQUEST } from '../definitions';
import { useDispatch } from 'react-redux';
import { successGrowl } from '../../store/rootThunks';
const PrivacyPage = () => {
  const dispatch = useDispatch();
  const form = useCmsForm(EMPTY_PURGE_USER_DATA_REQUEST);
  const { formState, handleSubmit } = form;
  const { confirm } = useContext(CmsContext);
  const { purgeUserData } = privacyApi;
  const submitPurgeRequest = useCallback(
    async (requestData) => {
      const result = await confirm({
        title: 'Purge User Data',
        message:
          'Are you sure you want to purge data for this user?  All information associated with this user will be either deleted or anonymized.  This operation cannot be undone.',
      });

      if (result) {
        try {
          const appUserId = await purgeUserData(requestData);
          dispatch(
            successGrowl({
              groupId: 'purge-user',
              props: `User data has been successfully purged.  User id is ${appUserId}.`,
            })
          );
        } catch {
          dispatch(
            errorGrowl({
              groupId: 'purge-user',
              props:
                'Failed to purge user data.  Please double check the email address and try again.',
            })
          );
        }
      }
    },
    [confirm, dispatch, purgeUserData]
  );
  const actions = useMemo(() => {
    return [
      {
        label: 'Purge',
        onClick: handleSubmit(submitPurgeRequest),
        disabled: !formState.isValid,
      },
    ];
  }, [formState, handleSubmit]);
  return (
    <CmsPage title="Privacy">
      <CmsPageSection title="Purge Deleted User">
        <GrowlContainer groupId="purge-user" />
        <div className="row">
          <form role="form" className="no-left-padding">
            <FormInput
              label="Email"
              fieldName="email"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required.',
              }}
            />
          </form>
        </div>
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </CmsPageSection>
    </CmsPage>
  );
};

export default PrivacyPage;

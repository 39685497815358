import React from 'react';
import FormInput from '../../shared/form/components/FormInput';
import { startCase } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { toNumber } from 'lodash';

const Row = ({ name, form }) => {
  const minFieldName = `${name}Min`;
  const maxFieldName = `${name}Max`;
  const min = 0;
  const max = 10_000_000;

  const validate = (isMin) => (v) => {
    if (['', null, undefined].includes(v)) return true;
    v = toNumber(v);
    if (v < min) return 'Minimum value is 0';
    if (v > max) return 'Maximum value is 10,000,000';
    let pair = form.getValues()[isMin ? maxFieldName : minFieldName];
    if (!['', null, undefined].includes(pair)) {
      pair = toNumber(pair);
      if (isMin && v > pair) return 'Minimum cannot be greater than Maximum';
      if (!isMin && v < pair) return 'Maximum cannot be less than Minimum';
    }
    return true;
  };

  const inpProps = { label: '', fieldWidth: 'short', formContext: form, type: 'number', min, max };
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={2}>
        <Typography variant="body1">{startCase(name)}</Typography>
      </Grid>
      {[minFieldName, maxFieldName].map((fieldName, idx) => (
        <Grid item xs={5} key={fieldName}>
          <FormInput
            {...inpProps}
            fieldName={fieldName}
            formFieldOptions={{
              validate: validate(idx === 0),
              setValueAs: (v) => (v && typeof v === 'string' && !Number.isNaN(v) ? toNumber(v) : v),
            }}
            onChange={() => {
              form.trigger(fieldName);
            }}
            onBlur={() => {
              form.trigger(fieldName);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Title = ({ children, xs = 5 }) => (
  <Grid item xs={xs}>
    <Typography variant="subtitle1" gutterBottom>
      {children}
    </Typography>
  </Grid>
);

const AttendeeDetailForm = ({ form }) => {
  const names = ['student', 'instructor', 'assistant'];

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Attendee Details
      </Typography>
      <Grid container spacing={1} direction="column">
        <Grid item container spacing={1}>
          <Title xs={2}>Attendee Type</Title>
          <Title>Minimum Seats</Title>
          <Title>Maximum Seats</Title>
        </Grid>
        {names.map((name) => (
          <Row key={name} name={name} form={form} />
        ))}
      </Grid>
    </>
  );
};

export default AttendeeDetailForm;

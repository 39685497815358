import React from 'react';
import FormInput from '../../shared/form/components/FormInput';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const TargetAudienceEditorForm = (props) => {
  const { form, growlId, actions } = props;
  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <AudienceSelector
          formContext={form}
          label="Available To"
          fieldName="condition"
          growlId={growlId}
          isRequired={true}
          requireNew={true}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default TargetAudienceEditorForm;

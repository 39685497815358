import axios from 'axios';
import { useCallback, useEffect } from 'react';
import log from '../../logger';
import { parseKmlData } from '../helpers';

export default function useKmlFileUrl(state, setState, kmlDataProcessed, setKmlDataProcessed) {
  const url = state.kmlUrl;
  const getKmlData = useCallback(async () => {
    try {
      const { data } = await axios.get(url);
      const parsedData = parseKmlData(data, state);
      const { kmlRegions, kmlMarkers } = parsedData;
      setKmlDataProcessed(url);
      const newState = { ...state, kmlRegions, kmlMarkers };
      setState(newState);
    } catch (err) {
      log.error(err);
    }
  }, [setKmlDataProcessed, url, setState]);

  useEffect(() => {
    if (url && url !== kmlDataProcessed) getKmlData();
  }, [url, kmlDataProcessed, getKmlData]);
}

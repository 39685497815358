import classNames from 'classnames';
import get from 'lodash/get';
import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const popoverListColumn = (label, valueField, faIcon, optSelector, options = {}) => {
  return {
    label,
    valueField,
    align: 'center',
    renderer: (item) => {
      const [showTip, setShowTip] = useState(false);
      const val = get(item, `${valueField}`, [])
        .map((o) => (optSelector ? o[optSelector] : o))
        .join('\n');
      const className = classNames('fa', 'fa-solid', { [faIcon]: !!val });

      const handleClickAway = () => {
        setShowTip(false);
      };

      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          <span className={'tooltip-container'} onClick={() => setShowTip(!showTip)}>
            <span className={className} />
            {showTip && <span className={'tooltip-text-click'}>{val}</span>}
          </span>
        </ClickAwayListener>
      );
    },
    ...options,
  };
};

export default popoverListColumn;

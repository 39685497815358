import axios from 'axios';
import { CmsConfig, CmsOptionFlags, CmsWysiwygOptions } from './cmsDefinitions';
import { WysiwygListener, addWysiwygEvents, CustomFroalaErrorCallback } from './froalaEvents';
import { patchFroala } from './froalaUtils';
import { wysiwygConfigDefaults } from './wysiwygConfigDefaults';
patchFroala();

const loadWysiwygOptions = async () => {
  try {
    const { data } = await axios.get(`/preview/asset/wysiwygOptions.json?instanceName=sandbox`, {
      timeout: 10000,
    });
    return data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load wysiwygOptions.json');
  }
};

export const getWysiwygOptions = async (
  cmsConfig: CmsConfig,
  wysiwygListener: WysiwygListener,
  customFroalaErrorCallback: CustomFroalaErrorCallback | boolean | undefined | null = undefined
) => {
  const cmsConfigWysiwygOptions = cmsConfig.wysiwyg || {};
  const prunedDefaultOptions = addOrRemoveLegacyOptions(
    wysiwygConfigDefaults,
    cmsConfig.optionFlags
  );
  try {
    const wysiwygOptions = await loadWysiwygOptions();

    const mergedOptions = {
      ...prunedDefaultOptions,
      ...cmsConfigWysiwygOptions,
      ...wysiwygOptions,
    };
    return addWysiwygEvents(mergedOptions, wysiwygListener, customFroalaErrorCallback);
  } catch (e) {
    return true;
  }
};

export const addOrRemoveLegacyOptions = (
  options: CmsWysiwygOptions,
  cmsOptionFlags: CmsOptionFlags
) => {
  if (!cmsOptionFlags || !cmsOptionFlags.useInsertGroupButton) {
    return {
      ...options,
      toolBarButtons: removeInsertGroupButton(options.toolbarButtons),
      toolBarButtonsMD: removeInsertGroupButton(options.toolbarButtonsMD),
      toolBarButtonsSM: removeInsertGroupButton(options.toolbarButtonsSM),
      toolBarButtonsXS: removeInsertGroupButton(options.toolbarButtonsXS),
    };
  } else {
    return options;
  }
};

function removeInsertGroupButton(buttons: string[]) {
  return buttons.filter((val: string) => val !== 'insertGroupButton');
}

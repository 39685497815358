import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateRuleSubOption } from '../../../../../store';
import { selectSubOptionOptionsData } from '../../../../../store/selectors';

const SelectSubOption = (props) => {
  const { location, fieldName } = props;
  const dispatch = useDispatch();
  const { options, label, isDisabled, value } = selectSubOptionOptionsData(fieldName, location);
  const onSubOptionChange = (subOption) => {
    dispatch(
      updateRuleSubOption({
        location,
        subOption,
        fieldName,
      })
    );
  };

  useEffect(() => {
    onSubOptionChange({ ...value, queryParams: value.logicParams });
  }, [value]);

  return (
    <Select
      className="inline-select"
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      isDisabled={isDisabled}
      onChange={onSubOptionChange}
      options={options}
      placeholder={label}
      value={value}
    />
  );
};

export default SelectSubOption;

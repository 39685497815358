import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useItrsActions from '../hooks/useItrsActions';
import useSessionConfigurations from '../hooks/useSessionConfigurations';

const SessionTable = ({ courseId, hideOptions, reloadParent }) => {
  const { defaultPageId, typeName } = ItrsCourseConstants;
  const { addSession, editSession, viewSession, removeSession } = useItrsActions(
    ItrsCourseConstants,
    reloadParent
  );
  const { columns, api } = useSessionConfigurations();
  const toolbarActions = useArray(addSession);
  const itemActions = useArray(editSession, viewSession, removeSession);

  return (
    <CmsItemListSection
      pageId={defaultPageId}
      typeName={typeName}
      toolbarActions={hideOptions ? [] : toolbarActions}
      itemActions={itemActions}
      columns={columns}
      itemApi={api}
      additiontalLoaderParams={{ courseId: courseId }}
      showSearchBar={!hideOptions}
    />
  );
};

export default SessionTable;

import React from 'react';
import FormRadioButton from './FormRadioButton';

const RadioButtonGroup = ({
  form,
  label,
  name,
  options,
  type = 'radio',
  validation = {},
  vertical = true,
}) => {
  const {
    formState: { errors },
    watch,
  } = form;
  const selectedValue = watch(name);
  const err = errors?.[name];
  const msg = err?.message;
  const { required } = validation;
  return (
    <div
      style={{
        display: 'flex',
        ...(vertical
          ? { flexDirection: 'column', alignItems: 'flex-start' }
          : { flexDirection: 'row', justifyContent: 'flex-start' }),
      }}
    >
      <label className={err ? 'error' : ''}>{`${label}${required ? ' *' : ''}`}</label>
      {options.map(({ key, label: optLabel, value, id }) => (
        <FormRadioButton
          checked={selectedValue === value}
          fieldName={name}
          formContext={form}
          formFieldOptions={validation}
          id={id || value}
          key={key || value}
          label={optLabel}
          name={name}
          shouldTrigger
          type={type}
          value={value}
          labelStyle={{ fontWeight: '100' }}
        />
      ))}

      {err && <p style={{ color: '#dc3545', fontSize: '.875em', marginTop: '.25rem' }}>{msg}</p>}
    </div>
  );
};

export default RadioButtonGroup;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { removeCondition } from '../../../../../store';

const RemoveCompoundCondition = (props) => {
  const { location, fieldName } = props;
  const dispatch = useDispatch();
  const handleRemoveCondition = useCallback(() => {
    dispatch(
      removeCondition({
        location,
        fieldName,
      })
    );
  }, [dispatch, location, fieldName]);
  return (
    <button
      style={{
        marginLeft: 5,
      }}
      onClick={handleRemoveCondition}
      className="btn btn-sm red"
    >
      <i className="fa fa-times" />
    </button>
  );
};

export default RemoveCompoundCondition;

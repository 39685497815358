import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import logger from '../../../logger';
import { createAward, deleteAward } from '../api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Awards({ awards, achievements, fields, handleGetAwards }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [achievementId, setAchievmentId] = useState('');
  const [userId, setUserId] = useState('');

  const handleCreateAward = async () => {
    await createAward({
      userId,
      achievementId,
      recordData,
    });
    setShowAddModal(false);
    handleGetAwards();
  };

  const handleDeleteAward = async (rowsDeleted) => {
    for (const i in rowsDeleted.data) {
      try {
        const idx = rowsDeleted.data[i].dataIndex;
        await deleteAward(awards[idx].id);
      } catch (e) {
        logger.error(e);
        return false;
      }
    }
  };

  const columns = [
    {
      name: 'accountId',
      label: 'Account',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'userId',
      label: 'userId',
    },
    {
      name: 'achievementId',
      label: 'achievementId',
    },
    {
      name: 'certificateKey',
      label: 'certificateKey',
      options: {
        customBodyRenderLite: (idx) => {
          const value = awards[idx].certificateKey;
          return <a href={value}>Link</a>;
        },
      },
    },
    {
      name: 'recordData',
      label: 'recordData',
      options: {
        customBodyRenderLite: (idx) => {
          const value = awards[idx].recordData;
          return (
            <div>
              {value &&
                Object.keys(value).map((r) => (
                  <div key={r}>
                    {r}:{value[r]}
                  </div>
                ))}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    onRowsDelete: handleDeleteAward,
  };

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  const handleAchievementSelect = ({ target }) => {
    setAchievmentId(target.value);
    const ach = achievements.find(({ id }) => target.value === id);
    const rd = {};
    for (const a in ach.fields) {
      rd[ach.fields[a]] = '';
    }
    setRecordData(rd);
  };

  const achievement = achievements.find(({ id }) => achievementId === id);

  return (
    <Box className="Awards">
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Award
        </Button>
      </Box>
      <MUIDataTable title={'Awards'} data={awards} columns={columns} options={options} />

      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="user-id"
            label="User Id"
            value={userId}
            sx={{ width: '100%' }}
            onChange={({ target }) => setUserId(target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="certificate-select">Certificate</InputLabel>
            <Select value={achievementId} label="Achievement" onChange={handleAchievementSelect}>
              {achievements.map(({ id, displayName }) => (
                <MenuItem key={id} value={id}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {achievement &&
            achievement.fields
              .filter((f) => {
                const f2 = fields.find(({ id }) => id === f);
                return f2.dynamic;
              })
              .map((field) => {
                const f2 = fields.find(({ id }) => id === field);
                return (
                  <TextField
                    id="user-id"
                    key={field}
                    label={f2.displayName}
                    value={recordData[field]}
                    sx={{ width: '100%' }}
                    onChange={({ target }) =>
                      setRecordData((rd) => ({ ...rd, [field]: target.value }))
                    }
                  />
                );
              })}

          <Button onClick={handleCreateAward} sx={{ mt: 2 }} variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Awards;

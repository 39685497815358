import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import values from 'lodash/values';
import startCase from 'lodash/startCase';
import log from '../../../logger';
import update from 'lodash/update';
import { errorGrowl, successGrowl } from '../../../store/rootThunks';
import { reducerName } from './index';

const COMPLETE = 'completed';
const PEND = 'pending';
const FAIL = 'failed';

const thunk = (name, cb) => createAsyncThunk(`${reducerName}/${name}`, cb);
export const getJobs = thunk(`getJobs`, async (params, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  try {
    const jobs = cloneDeep(get(getState(), `${reducerName}.jobs`, {}));
    const ids = values(jobs)
      .filter((job) => [COMPLETE, PEND].includes(job.status))
      .map((job) => job.id);

    const { data } = await axios.get('job-api/getJobsStatuses', {
      params: { ids },
    });

    get(data, 'jobs', []).forEach((job) => {
      const current = get(jobs, job.id);
      if (!current) {
        update(jobs, job.id, (c = {}) => ({ ...c, ...job }));
      } else if (current.status !== job.status) {
        const growl = {
          groupId: 'globalroot',
          props: startCase(`${current.name || `Job: ${job.id}`} Has ${job.status}`),
        };
        if (job.status === COMPLETE) dispatch(successGrowl(growl));
        else if (job.status === FAIL) dispatch(errorGrowl(growl));
        set(jobs, `${job.id}.status`, job.status);
      }
    });
    const continueChecking = values(jobs).some(({ status }) => ![COMPLETE, FAIL].includes(status));
    return { jobs, continueChecking };
  } catch (e) {
    log.error('Error getting jobs', e);
    throw e;
  }
});

import React from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { QuizInstanceConstants } from '../definitions';
import QuizInstanceEditor from './QuizInstanceEditor';

const QuizInstanceEditorPage = (props) => {
  const { quizId, quizInstanceId } = props;
  const { editorPageId, typeName } = QuizInstanceConstants;
  const { pageTitle, onItemChange } = useEditPageTitle(quizInstanceId, typeName, 'name');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <QuizInstanceEditor itemId={quizInstanceId} quizId={quizId} onItemChange={onItemChange} />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default QuizInstanceEditorPage;

import get from 'lodash/get';
import React from 'react';
import useItemActionListVisible from '../hooks/useItemActionListVisible';
import ItemAction from './ItemAction';
import SnapshotCheckItemAction from './SnapshotCheckItemAction';
const ItemActionList = (props) => {
  const { item, actions } = props;
  const { ref, isVisible, setIsVisible } = useItemActionListVisible(false);

  const ellipsisClick = () => {
    setIsVisible((isVisible) => !isVisible);
  };

  return (
    <div ref={ref} className="action-buttons pull-right">
      <div
        className="action-ellipsis fa-regular fa-ellipsis-vertical"
        onClick={ellipsisClick}
      ></div>
      <div className={isVisible ? 'action-menu' : 'hidden'}>
        {actions.map((action, index) => {
          const Comp = get(action, 'useSnapshotCheck', false)
            ? SnapshotCheckItemAction
            : ItemAction;
          return <Comp key={index} item={item} action={action} setIsVisible={setIsVisible} />;
        })}
      </div>
    </div>
  );
};

export default ItemActionList;

import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { QrScannerConstants } from '../definitions';
import useQrTargetGroupActions from '../hooks/useQrTargetGroupActions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../shared/form/components/FormInput';

const QrScannerTargetGroupEditorPage = () => {
  const { createGroup, cancel } = useQrTargetGroupActions(QrScannerConstants);
  const form = useCmsForm();
  const actions = [createGroup(form), cancel];
  return (
    <CmsPage title={`Add Target Group`}>
      <form role="form" className="no-left-padding">
        <FormInput label="Name" fieldName="name" formContext={form} />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </CmsPage>
  );
};

export default QrScannerTargetGroupEditorPage;

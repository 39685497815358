import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import { navigateToChildPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import { getConfigUrl } from '../api/meetsApi';
export default function useAddMeet(meetType, pageConstants) {
  const dispatch = useDispatch();
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            'new',
            meetType,
          ])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            context.id,
            meetType,
          ])
        );
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName]
  );
  const configure = useMemo(
    () => ({
      label: 'Configure',
      icon: 'fa-solid fa-gear',
      onClick: async (context) => {
        try {
          const url = await getConfigUrl(context.id);
          window.open(url);
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({
              groupId: defaultPageId,
              props: 'Failed to open meet configuration editor.',
            })
          );
        }
      },
    }),
    [pluginId, defaultPageId, dispatch, editorPageName]
  );
  return { add, edit, configure };
}

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToChildPage } from '../../store/rootReducer';
export default function useAchievementInsanceActions(achievementId, pageConstants) {
  const dispatch = useDispatch();
  const { pluginId, editorPageName, defaultPageId, typeName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            achievementId,
            'new',
          ])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, achievementId]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        dispatch(
          navigateToChildPage([
            pluginId,
            editorPageName,
            defaultPageId,
            loaderParams,
            achievementId,
            context.id,
          ])
        );
      },
    }),
    [dispatch, pluginId, defaultPageId, editorPageName, achievementId]
  );
  const backToAchievements = useMemo(
    () => ({
      pageName: 'Achievements',
      onClick: () => {
        dispatch(
          navigateToChildPage([
            pluginId,
            'default',
            'achievements-list',
            {
              search: '',
              limit: 25,
              offset: 0,
              sort: {
                sortField: 'title',
                order: 'ASC',
              },
            },
          ])
        );
      },
    }),
    [dispatch, pluginId]
  );
  return {
    backToAchievements,
    add,
    edit,
  };
}

import { ClickAwayListener, Paper, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TableBodyRow from './TableBodyRow';
import { useTableEditorContext } from './TableContext';
import TableHeader from './TableHeader';
const EditorTable = () => {
  const { deselect, rows, handleReorder, setHasDragged, canDrag = true } = useTableEditorContext();
  const onDragEnd = (result) => {
    if (!result.destination) return;
    setHasDragged(true);
    handleReorder(result.source.index, result.destination.index);
  };

  return (
    <ClickAwayListener onClickAway={deselect}>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table aria-label="registration options">
              <TableHeader />
              <Droppable droppableId="droppableTableBody">
                {(provided) => (
                  <TableBody
                    sx={{
                      '& .MuiTableBody-root': {
                        borderTop: '1px solid #212529',
                        borderBottom: '1px solid #212529',
                      },
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {(rows || []).map((row, rowIdx) => (
                      <Draggable key={row.draggableId} draggableId={row.draggableId} index={rowIdx}>
                        {(provided) => (
                          <TableBodyRow
                            provided={canDrag ? provided : {}}
                            row={row}
                            rowIdx={rowIdx}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </div>
    </ClickAwayListener>
  );
};

export default EditorTable;

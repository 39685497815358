import React from 'react';
import useJobs from '../../shared/jobs/useJobs';
import useNavigation from './hooks/useNavigation';
import useSnapshotChecker from '../../shared/snapshotChecker/useSnapshotChecker';
import Snackbar from './Snackbar';
export default function RootComponents({ navigateToState }) {
  useJobs();
  useNavigation(navigateToState);
  useSnapshotChecker();
  return (
    <>
      <Snackbar />
    </>
  );
}

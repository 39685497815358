import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateCompoundConditionType } from '../../../../../store';
import { selectCompoundSelectProps } from '../../../../../store/selectors';
import { operators } from '../../../../definitions';

const SelectCompoundConditionType = (props) => {
  const { location, fieldName } = props;
  const dispatch = useDispatch();
  const { operator, canSelectCompoundType } = selectCompoundSelectProps(fieldName, location);
  const onSelectChange = useCallback(
    ({ name: type }) => {
      dispatch(
        updateCompoundConditionType({
          location,
          type,
          fieldName,
        })
      );
    },
    [dispatch, location, fieldName]
  );
  const value = useMemo(
    () => operators.find((o) => o.name === operator) || '',
    [operator, operators]
  );
  return (
    <Select
      isDisabled={!canSelectCompoundType}
      value={value}
      onChange={onSelectChange}
      placeholder="Operator"
      options={operators}
      getOptionLabel={(field) => field.label}
      getOptionValue={(field) => field.name}
      className="inline-select"
    />
  );
};

export default SelectCompoundConditionType;

import get from 'lodash/get';
import moment from 'moment';
import { useMemo } from 'react';
import generateAchievementsApi from '../api/achievementsApi';
const AchievementColumns = [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Last Modified',
    valueField: 'updated',
    sortable: true,
    renderer: (item) => moment(item.updated).format('MMMM DD, YYYY  hh:mm A'),
  },
  {
    label: 'Instances',
    valueField: 'instances',
    sortable: true,
  },
  {
    label: 'Archived',
    valueField: 'isArchived',
    sortable: true,
    renderer: (item) => (get(item, `isArchived`, false) ? 'Yes' : 'No'),
  },
];
export default function useAchievementConfigurations(hideArchived) {
  const columns = useMemo(
    () =>
      AchievementColumns.filter(({ valueField }) => !hideArchived || valueField !== 'isArchived'),
    [AchievementColumns, hideArchived]
  );
  const api = useMemo(
    () => generateAchievementsApi(hideArchived),
    [hideArchived, generateAchievementsApi]
  );
  return {
    columns,
    api,
  };
}

import React, { useCallback } from 'react';
import Select from 'react-select';

const SelectInput = (props) => {
  const { fieldKey, label, value, setInput, options = [], isDisabled } = props;
  const handleSetInputValue = useCallback(
    (option) => void setInput(fieldKey, option),
    [setInput, fieldKey]
  );
  return (
    <Select
      placeholder={label}
      onChange={handleSetInputValue}
      getOptionLabel={(field) => field.label}
      getOptionValue={(field) => field.value}
      value={isDisabled ? '' : [value]}
      options={options}
      isDisabled={isDisabled}
      className="inline-select"
    />
  );
};

export default SelectInput;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
const CollectionTitle = ({ collection }) => {
  const dispatch = useDispatch();
  const navigateToChild = useCallback(() => {
    dispatch(navigateToPluginPage(['collections', 'collectionEditorPage', collection.id]));
  }, [collection, dispatch]);
  return (
    <>
      {collection.status === 'removed' ? (
        <span>{collection.name}</span>
      ) : (
        <>
          <a onClick={navigateToChild}>{collection?.name}</a>
          {!!collection.isSubtenantNavigation && (
            <i
              style={{ marginLeft: '5px', color: '#2c2e3a' }}
              className="fa fa-star"
              aria-hidden="true"
            />
          )}
        </>
      )}
    </>
  );
};

export default CollectionTitle;

import axios from 'axios';
import log from '../../logger';
import { EMPTY_GROUP_REVISION } from '../definitions';
const baseUrl = '/user-api';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getGroups`, {
      params: { ...otherParams, sortField, sortReverse, limit: 10 },
      timeout: 10_000,
    });
    const { groups: records, count: numRecords, offset } = data;
    result = { records, numRecords, offset };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load groups');
  }
  return result;
};

const loadRecord = async (groupId) => {
  if (groupId === 'new') return EMPTY_GROUP_REVISION;
  try {
    const { data } = await axios.get(`${baseUrl}/getGroupById`, {
      params: { groupId },
      timeout: 10_000,
    });
    return data.group;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load appUser.');
  }
};

const saveRecord = async (item) => {
  try {
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/upsertGroup`,
      { ...revisionProps, id },
      {
        timeout: 10_000,
      }
    );
    return data.group;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save group.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/deleteGroup`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove group.');
  }
};

const createReport = async (otherEmails, groupId) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/getAllUsers?otherEmails=${JSON.stringify(otherEmails)}&groupId=${groupId}`,
      {
        timeout: 10_000,
      }
    );
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to create report.');
  }
};

const manageGroupsApi = {
  loadRecords,
  saveRecord,
  removeRecord,
  loadRecord,
  createReport,
};

export default manageGroupsApi;

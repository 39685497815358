import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import manageGroupsApi from '../api/manageGroupsApi';
import { ManageGroupsConstants } from '../definitions';
import { startWatchingJob } from '../../shared/jobs/store';
import validateEmails from '../../shared/util/validateEmails';
import has from 'lodash/has';
export default function useManageGroupActions(pageConstants, groupId) {
  const dispatch = useDispatch();
  const { edit, remove, requestPending } = useItemListActions(
    manageGroupsApi,
    ManageGroupsConstants
  );

  const { defaultPageId } = pageConstants;

  const editOverride = useMemo(
    () => ({
      ...edit,
      visible: (item) => {
        let isVisible = true;
        if (typeof edit?.visible === 'function') {
          isVisible = edit.visible(item);
        } else if (has(edit, 'visible')) {
          isVisible = edit.visible;
        }
        if (isVisible) isVisible = item?.groupType !== 'registration';
        if (isVisible) isVisible = item?.canAssign;
        return isVisible;
      },
    }),
    [edit]
  );
  const addOverride = useMemo(
    () => ({
      label: `+ Add Group`,
      onClick: async () => {
        try {
          const newGroup = await manageGroupsApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              ManageGroupsConstants.pluginId,
              ManageGroupsConstants.editorPageName,
              newGroup.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [ManageGroupsConstants, defaultPageId, dispatch]
  );

  const exportCsv = useMemo(
    () => ({
      label: `CSV Export`,
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([
              ManageGroupsConstants.pluginId,
              ManageGroupsConstants.exportPageName,
              groupId,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [ManageGroupsConstants, defaultPageId, dispatch, groupId]
  );

  const createReport = useMemo(
    () => (form, number) => ({
      label: `Create Report`,
      onClick: async () => {
        const groupId = Number.parseInt(number);
        const otherEmails = form.getValues('otherEmails');
        try {
          const emailList = otherEmails
            ? otherEmails.includes(',')
              ? otherEmails.split(',').map((x) => x.trim())
              : [otherEmails.trim()]
            : [];
          if (!validateEmails(emailList)) throw new Error('Invalid email address');

          const {
            job: { id: jobId },
          } = await manageGroupsApi.createReport(emailList, groupId);
          dispatch(startWatchingJob({ jobId, name: 'Groups Report' }));
          dispatch(
            navigateToPluginPage([
              ManageGroupsConstants.pluginId,
              ManageGroupsConstants.defaultPageName,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [ManageGroupsConstants, defaultPageId, dispatch, groupId]
  );

  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      variant: 'default',
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([
              ManageGroupsConstants.pluginId,
              ManageGroupsConstants.defaultPageName,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [ManageGroupsConstants, defaultPageId, dispatch]
  );

  const manageMembers = useMemo(
    () => ({
      icon: 'fa-solid fa-users',
      label: 'View Members',
      onClick: (item) => {
        dispatch(
          navigateToPluginPage([
            ManageGroupsConstants.pluginId,
            ManageGroupsConstants.managePageName,
            item.id,
            item.name,
            item.key,
            item.canAssign,
          ])
        );
      },
    }),
    [ManageGroupsConstants, dispatch]
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      visible: (item) => {
        let isVisible = true;
        if (typeof remove?.visible === 'function') {
          isVisible = remove.visible(item);
        } else if (has(remove, 'visible')) {
          isVisible = remove.visible;
        }
        if (isVisible) isVisible = item?.groupType !== 'registration';
        if (isVisible) isVisible = item?.canAssign;
        return isVisible;
      },
    }),
    [remove]
  );
  const hideGroup = useMemo(
    () => ({
      icon: (item) => `fa-solid fa-eye${item.hidden ? '' : `-slash`}`,
      label: (item) => (item.hidden ? 'Show' : 'Hide'),
      onClick: async (item, { reload }) => {
        await manageGroupsApi.saveRecord({ ...item, hidden: !item.hidden });
        await reload();
      },
    }),
    []
  );
  return {
    add: addOverride,
    edit: editOverride,
    remove: removeOverride,
    requestPending,
    cancel,
    hideGroup,
    manageMembers,
    createReport,
    exportCsv,
  };
}

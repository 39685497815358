angular.module('group-api-service', []).service('groupApiService', [
  '$log',
  '$http',
  '$rootScope',
  function ($log, $http, $rootScope) {
    let groupMap = {};
    let groupFilter = 'Show All';
    function getGroups(start, number, params, includeSubtenantGroups) {
      let sortPredicate;
      let sortReverse;
      let searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) searchPredicate = searchPredicate.name;
      }
      const httpParams = {
        params: {
          start,
          number,
          sortPredicate,
          sortReverse,
          searchPredicate,
          includeSubtenantGroups,
          groupType: groupFilter,
        },
      };
      const promise = $http
        .get('/user-api/getGroups', httpParams)
        .success(function (data) {
          rebuildGroupMap(data.groups);
        })
        .error(function (data) {
          $log.debug('Failed to get all groups. Response: ', data);
        });
      return promise;
    }
    function rebuildGroupMap(groups) {
      groupMap = {};

      groups.forEach((appGroup) => {
        groupMap[appGroup.id] = appGroup;
      });
    }
    function getGroupMetadata(id) {
      return groupMap[id];
    }
    function saveGroup(group, isNew) {
      const data = {
        id: group.id,
        key: group.key,
        name: group.name,
        defaultAccessible: group.defaultAccessible,
        isNew: !!isNew,
      };
      const promise = $http.post('/user-api/upsertGroup', data);
      promise.then(function (result) {
        groupMap[group.id] = result.data.group;
        broadcastGroupUpdated(result.data.group);
      });
      return promise;
    }
    function removeGroup(group) {
      const data = {
        id: group.id,
      };
      const promise = $http.post('/user-api/deleteGroup', data);
      promise.then(function () {
        delete groupMap[group.id];
        broadcastGroupUpdated(group);
      });
      return promise;
    }
    function broadcastGroupUpdated(group) {
      $rootScope.$broadcast('groupUpdated', group.id);
    }
    function getUsersFromGroup(groupId) {
      const data = {
        groupId,
      };
      const promise = $http
        .post('/user-api/getUsersFromGroup', data)
        .success(function (data) {
          return data;
        })
        .error(function (data) {
          $log.debug('Failed to get all appUsers from group. Response: ', data);
        });
      return promise;
    }
    function updateAppUserGroups(groupId, groupKey, userId, action) {
      const data = {
        groupId,
        groupKey,
        userId,
        action,
      };
      const promise = $http
        .post('/user-api/updateAppUserGroups', data)
        .success(function (data) {
          return data;
        })
        .error(function (data) {
          $log.debug('Failed to update app Users group. Response: ', data);
        });
      return promise;
    }
    function getUsers(params) {
      let sortPredicate;
      let sortReverse;
      let searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) searchPredicate = searchPredicate.name;
      }
      const httpParams = {
        params: {
          start: 0,
          number: 10_000,
          sortPredicate,
          sortReverse,
          searchPredicate,
        },
      };
      const promise = $http
        .get('/user-api/getUsers', httpParams)
        .success(function (data) {
          return data;
          // rebuildUserMap(data.appUsers);
        })
        .error(function (data) {
          $log.debug('Failed to get all appUsers. Response: ', data);
        });
      return promise;
    }
    function saveUser(user) {
      const data = {
        id: user.id,
        accessCode: user.accessCode,
        userType: user.userType,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroups: user.userGroups,
        subtenantId: user.subtenantId,
      };
      const promise = $http.post('/user-api/upsertUser', data);
      promise.then(function (result) {
        return result;
      });
      return promise;
    }
    function setGroupFilter(newFilter) {
      groupFilter = newFilter;
    }
    function getGroupFilter() {
      return groupFilter;
    }
    return {
      removeGroup,
      saveGroup,
      getGroupMetadata,
      getGroups,
      getUsersFromGroup,
      getUsers,
      saveUser,
      setGroupFilter,
      getGroupFilter,
      updateAppUserGroups,
    };
  },
]);

import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import useArray from '../../shared/util/hooks/useArray';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import pushNotificationApi, {
  cancelPushNotification,
  editPushNotification,
} from '../api/pushNotificationApi';
import { PushNotificationConstants } from '../definitions';
const { removeRecord, loadRecord } = pushNotificationApi;
export default function usePushNotificationActions(pageConstants, hiddenButton) {
  const [autoRefresh, setAutoRefresh] = useState(true);
  const dispatch = useDispatch();
  const nav = useCallback((...params) => dispatch(navigateToPluginPage(...params)), [dispatch]);
  const err = useCallback(
    (e, props) => {
      log.error(e);
      dispatch(errorGrowl({ groupId: defaultPageId, props }));
    },
    [dispatch, defaultPageId]
  );
  const refresh = useMemo(
    () => ({
      visible: !autoRefresh,
      label: autoRefresh ? 'Hide me' : 'Refresh',
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          err(e, 'Failed to refresh.');
        }
      },
    }),
    [autoRefresh, setAutoRefresh, err]
  );
  const autoRefreshButton = useMemo(
    () => ({
      label: autoRefresh ? 'Auto Refresh is ON' : 'Auto Refresh is OFF',
      onClick: async () => void setAutoRefresh((current) => !current),
    }),
    [autoRefresh, setAutoRefresh]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Schedule Notification`,
      onClick: async () => {
        try {
          const newPushNotification = await loadRecord('new');
          nav([
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            newPushNotification.id || 'new',
          ]);
        } catch (e) {
          err(e, 'Failed to add.');
        }
      },
    }),
    [typeName, pluginId, defaultPageId, editorPageName, dispatch, nav]
  );
  const view = useMemo(
    () => ({
      label: `View`,
      onClick: async (item) => {
        try {
          const newPushNotification = await loadRecord(item.id);
          nav([
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            newPushNotification.id || 'new',
          ]);
        } catch (e) {
          err(e, 'Failed to view.');
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      disabled: ({ status = '' }) => status.toLowerCase() !== 'scheduled',
      onClick: async (item, { reload }) => {
        try {
          await cancelPushNotification(item.id);
          await reload();
        } catch (e) {
          err(e, 'Failed to cancel.');
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      label: `Edit`,
      disabled: ({ status = '' }) => status.toLowerCase() !== 'scheduled',
      onClick: async ({ id }) => {
        try {
          nav([PushNotificationConstants.pluginId, PushNotificationConstants.editorPageName, id]);
        } catch (e) {
          err(e, 'Failed to edit.');
        }
      },
    }),
    [typeName, err, nav, pluginId, defaultPageId, editorPageName]
  );
  const copy = useMemo(
    () => ({
      label: `Copy`,
      onClick: async (item) => {
        try {
          nav([
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            'new',
            true,
            item.id,
          ]);
        } catch (e) {
          err(e, 'Failed to copy.');
        }
      },
    }),
    [typeName, nav, err, pluginId, defaultPageId, editorPageName]
  );

  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      onClick: async (item, { reload }) => {
        try {
          await editPushNotification(item.id, { isArchived: !item.isArchived });
          await reload();
        } catch (e) {
          err(e, 'Failed to copy.');
        }
      },
    }),
    [typeName, pluginId, err, defaultPageId, editorPageName]
  );
  const del = useMemo(
    () => ({
      label: 'Delete',
      onClick: async (item, { reload }) => {
        try {
          await removeRecord(item);
          await reload();
        } catch (e) {
          err(e, 'Failed to delete');
        }
      },
    }),
    [err]
  );

  return {
    toolbarActions: useArray(addOverride, refresh, autoRefreshButton, hiddenButton),
    itemActions: useArray(view, edit, copy, archive, cancel, del),
    autoRefresh,
  };
}

export const GeneralFilesConstants = {
  pluginId: 'general-files',
  defaultPageName: 'default',
  defaultPageId: 'general-files-list-page',
  typeName: 'GeneralFiles',
  editorPageName: 'generalFilesEditorPage',
};

export const EMPTY_GENERAL_FILE = {
  title: '',
};

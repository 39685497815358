import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateRuleLogicParamsField } from '../../../../../../store';
import { selectAdditionalFieldsProps } from '../../../../../../store/selectors';
import DateInput from './components/DateInput';
import NumberInput from './components/NumberInput';
import SelectInput from './components/SelectInput';

const AdditionalInputs = (props) => {
  const { location, fieldName } = props;
  const { additionalFields, isDisabled } = selectAdditionalFieldsProps(fieldName, location);
  const dispatch = useDispatch();
  const setInput = useCallback(
    (field, value) =>
      void dispatch(
        updateRuleLogicParamsField({
          field,
          value,
          location,
          fieldName,
        })
      ),
    [location, dispatch, fieldName]
  );
  return additionalFields.map((field) => {
    const { fieldKey: key, type, ruleValue: value } = field;
    const prps = { ...field, setInput, value, isDisabled, fieldName };
    return (
      <React.Fragment key={key}>
        {type === 'number' && <NumberInput {...prps} />}
        {type === 'date' && <DateInput {...prps} />}
        {type === 'select' && <SelectInput {...prps} />}
      </React.Fragment>
    );
  });
};

export default memo(AdditionalInputs);

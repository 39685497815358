import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
export default function useRefreshActionCallback(defaultPageId) {
  const dispatch = useDispatch();
  const hiddenButtonRef = useRef();
  const hiddenButton = useMemo(
    () => ({
      label: 'hidden',
      hiddenButtonRef,
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          dispatch(errorGrowl({ groupId: defaultPageId, props: e }));
        }
      },
    }),
    [defaultPageId, dispatch]
  );
  const refreshCallback = useCallback(() => {
    if (hiddenButtonRef.current) {
      //eslint-disable-next-line
      hiddenButtonRef.current.click();
    }
  }, []);
  return {
    hiddenButton,
    refreshCallback,
  };
}

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToChildPage } from '../../store/rootReducer';
import cohortApi from '../api/cohortApi';
import { CohortConstants } from '../definitions';
const useCohortListActions = (showCohortEditorModal) => {
  const dispatch = useDispatch();
  const { add, edit, remove, requestPending } = useItemListActions(cohortApi, CohortConstants);
  const addOverride = useMemo(
    () => ({
      ...add,

      onClick: async ({ loaderParams }) => {
        const newCohort = await cohortApi.loadRecord('new');
        const savedItem = await showCohortEditorModal(newCohort);
        if (savedItem) {
          dispatch(
            navigateToChildPage([
              CohortConstants.pluginId,
              CohortConstants.editorPageName,
              CohortConstants.defaultPageId,
              loaderParams,
              savedItem.id,
            ])
          );
        }
      },
    }),
    [add, cohortApi, dispatch, showCohortEditorModal]
  );
  return useMemo(
    () => ({
      add: addOverride,
      edit,
      remove,
      requestPending,
    }),
    [addOverride, edit, remove, requestPending]
  );
};

export default useCohortListActions;

import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import targetAudienceApiWrapped from '../api/targetAudienceApi';
import { TargetAudienceConstants } from '../definitions';
export default function useTargetAudienceActions(pageConstants) {
  const switchStateDefault = false;
  const dispatch = useDispatch();
  const [hideArchived, setHideArchived] = useState(switchStateDefault);
  const targetAudienceApi = targetAudienceApiWrapped(hideArchived);
  const { confirm } = useContext(CmsContext);
  const { edit, remove, restore, publish, requestPending } = useItemListActions(
    targetAudienceApi,
    TargetAudienceConstants
  );
  const archiveCheckbox = useMemo(
    () => ({
      useSwitch: true,
      label: 'Hide Archived',
      switchStateDefault,
      onClick: async () => setHideArchived((c) => !c),
    }),
    [hideArchived, setHideArchived, switchStateDefault]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Add Audience`,
      onClick: async () => {
        try {
          const newTargetAudience = await targetAudienceApi.loadRecord('new');
          dispatch(
            navigateToPluginPage([
              TargetAudienceConstants.pluginId,
              TargetAudienceConstants.editorPageName,
              newTargetAudience.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, dispatch]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          if (context.isArchived) {
            const confirmResponse = await confirm({
              title: 'Unarchive Target Audience',
              message: `Are you sure you want to unarchive ${context.name}`,
            });
            if (confirmResponse) await targetAudienceApi.unarchiveRecord(context.id);
          } else {
            const confirmResponse = await confirm({
              title: 'Archive Target Audience',
              message: `Are you sure you want to archive ${context.name}`,
            });
            if (confirmResponse) await targetAudienceApi.archiveRecord(context.id);
          }

          await reload();
          dispatch(
            successGrowl({ groupId: defaultPageId, props: 'Successfully updated targetAudience.' })
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: defaultPageId, props: 'Failed to update targetAudience.' })
          );
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId, editorPageName, confirm, dispatch]
  );
  const duplicate = useMemo(() => {
    return {
      ...addOverride,
      label: 'Duplicate',
      icon: 'fa-solid fa-clone',
      onClick: async (context) => {
        //eslint-disable-next-line
        const { id, ...duplicateAudience } = context;
        duplicateAudience.name = `${duplicateAudience.name} (copy)`;
        duplicateAudience.condition = JSON.parse(duplicateAudience.condition);
        const newTargetAudience = await targetAudienceApi.saveRecord(duplicateAudience);
        log.info('newTargetAudience', newTargetAudience);

        try {
          const duplicatedTargetAudience = await targetAudienceApi.loadRecord(
            newTargetAudience.target_audience_id
          );
          log.info('duplicatedTargetAudience', duplicatedTargetAudience);
          dispatch(
            navigateToPluginPage([
              TargetAudienceConstants.pluginId,
              TargetAudienceConstants.editorPageName,
              duplicatedTargetAudience.target_audience_id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to add.' }));
        }
      },
    };
  }, [addOverride, typeName, dispatch, pluginId, defaultPageId, editorPageName, dispatch]);
  return {
    add: addOverride,
    duplicate,
    archive,
    archiveCheckbox,
    edit,
    remove,
    restore,
    publish,
    requestPending,
    hideArchived,
  };
}

import axios from 'axios';
import { EMPTY_ACHIEVEMENT_INSTANCE } from '../definitions';
import log from '../../logger';
const baseUrl = '/plugins/achievements/api';
const processAudience = (achievementInstance) => {
  achievementInstance.issuers = JSON.parse(achievementInstance.issuers);
  achievementInstance.recipients = JSON.parse(achievementInstance.recipients);
  achievementInstance.included = JSON.parse(achievementInstance.included);
  return achievementInstance;
};

const loadRecordsWrapper = (achievementId) => async (params) => {
  const {
    sort: { sortField, order },
    ...otherParams
  } = params;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getAchievementInstances`, {
      params: {
        ...otherParams,
        achievementId,
        sortField,
        sortReverse: sortField ? order === 'DESC' : undefined,
      },
      timeout: 10_000,
    });
    const { achievementInstances, count, offset } = data;
    result = {
      records: achievementInstances.map(processAudience),
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load achievements');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_ACHIEVEMENT_INSTANCE;

  try {
    const {
      data: { achievementInstance },
    } = await axios.get(`${baseUrl}/getAchievementInstance`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return processAudience(achievementInstance);
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load achievementInstance.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeAchievementInstance`, {
      id,
      timeout: 10_000,
    });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove achievement.');
  }
};

const saveRecordWrapper = (achievementId) => async (item) => {
  try {
    const {
      data: { achievementInstance },
    } = await axios.post(`${baseUrl}/saveAchievementInstance`, {
      ...item,
      achievementId,
      timeout: 10_000,
    });
    return processAudience(achievementInstance);
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save achievement.');
  }
};

const generateAchievementInstanceApi = (achievementId) => {
  const achievementInstanceApi = {
    loadRecords: loadRecordsWrapper(achievementId),
    loadRecord,
    removeRecord,
    saveRecord: saveRecordWrapper(achievementId),
  };
  return achievementInstanceApi;
};

export default generateAchievementInstanceApi;

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
const useLoadListItems = (pageId, loadItems, loaderParams) => {
  const dispatch = useDispatch();
  const reload = useCallback(
    async (loaderParams) => {
      try {
        await loadItems(loaderParams);
      } catch (e) {
        dispatch(errorGrowl({ groupId: pageId, props: e.message }));
      }
    },
    [loadItems, pageId, dispatch]
  );
  useEffect(() => {
    (async () => reload(loaderParams))();
  }, [reload, loaderParams]);
  return reload;
};

export default useLoadListItems;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import useArray from '../../shared/util/hooks/useArray';
import { MeetConstants } from '../definitions';
import useMeetActions from '../hooks/useMeetActions';
import useMeetConfigurations from '../hooks/useMeetConfigurations';

const MeetsListPage = (props) => {
  const { defaultPageId, typeName } = MeetConstants;
  const { meetType } = props;
  const { columns, constants, listTitle, api } = useMeetConfigurations(meetType);
  const { add, edit, configure } = useMeetActions(meetType, constants);
  const { remove, requestPending } = useItemListActions(api, constants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove, configure);
  return (
    <CmsPage title={listTitle} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default MeetsListPage;

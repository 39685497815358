import _ from 'lodash';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { logicFromSavedAudience } from '../../../store/helpers';
import { selectNewAudienceName } from '../../../store/selectors';
import { saveAndPublishAudience } from '../../../store/thunks';
import { setLocation } from '../../../store';

const SaveFooter = (props) => {
  const { onSave, fieldName } = props;
  const dispatch = useDispatch();
  const newAudienceName = selectNewAudienceName(fieldName);
  const saveAudience = useCallback(async () => {
    const saved = await dispatch(
      saveAndPublishAudience({
        fieldName,
      })
    );

    const audience = _.get(saved, 'payload.publishedAudience');

    const logic = audience && logicFromSavedAudience(audience);
    dispatch(
      setLocation({
        location: 'modal.buildQuery',
        value: false,
        fieldName,
      })
    );
    onSave(logic);
  }, [dispatch, onSave, fieldName]);
  const goBack = useCallback(() => {
    dispatch(
      setLocation({
        location: 'modal.showSave',
        value: false,
        fieldName,
      })
    );
  }, [dispatch, fieldName]);
  return (
    <div className="form-button-section">
      <Button variant="primary" onClick={saveAudience} disabled={!newAudienceName}>
        Save Audience
      </Button>
      <Button variant="cancel" onClick={goBack}>
        Back
      </Button>
    </div>
  );
};

export default SaveFooter;

import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import manageAssetsApi from '../api/manageAssetsApi';

export default function AddFileDialog({ onAssetUploaded, asset, open, setOpen }) {
  // =========================================================================
  // State
  // =========================================================================
  const [fileType, setFileType] = React.useState('');
  const [assetGroup, setAssetGroup] = React.useState('default');
  const [content, setContent] = React.useState('/* Start typing your file here */');
  const [filename, setFilename] = React.useState('');
  const [error, setError] = React.useState(undefined);

  // =========================================================================
  // Methods
  // =========================================================================
  const fetchAsset = async () => {
    if (asset && asset.id) {
      try {
        const response = await manageAssetsApi.getAsset(asset.id, asset.latestRevisionId);
        setFileType(asset.type);
        setContent(response.data);
        setFilename(asset.id);
      } catch {
        setError('Failed to load asset content.');
      }
    }
  };

  const handleFileName = (event) => {
    setFilename(event.target.value);
  };

  const handleFileType = (event) => {
    setFileType(event.target.value);
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSubmit = async () => {
    try {
      await manageAssetsApi.saveAsset(
        content,
        {
          group: assetGroup,
          id: filename,
          type: fileType,
          instanceMappings: asset.instanceMappings,
        },
        false
      );
      if (onAssetUploaded) {
        onAssetUploaded();
      }
      setOpen(false);
    } catch {
      setError('Error uploading file, please try again.');
    }
    setOpen(false);
  };

  const handleAssetGroup = (event) => {
    setAssetGroup(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // =========================================================================
  // Watcher
  // =========================================================================
  useEffect(() => {
    fetchAsset();
  }, [asset]);

  // =========================================================================
  // Template
  // =========================================================================
  return (
    <Dialog
      open={open}
      maxWidth="80vw"
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleSubmit();
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1rem 20px',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          gap: '1.5rem',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <h2 style={{ fontSize: '20px', flex: '10000 1 0%', margin: 0 }}>
          {asset ? 'Edit File "' + asset.id + '"' : 'Add File'}
        </h2>
        <IconButton style={{ width: 30, height: 30 }} onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </IconButton>
      </Box>
      <Divider />
      {error && <Alert severity="error">Error uploading file, please try again.</Alert>}
      <Box
        sx={{
          display: 'flex',
          padding: '0 20px',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '.5rem',
          paddingBottom: '1rem',
          gap: '1.5rem',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <FormControl required variant="standard" sx={{ flex: '10000 1 0%' }}>
          <InputLabel id="file-type-select-label">Asset Group</InputLabel>
          <Select
            required
            labelId="asset-group-select-label"
            id="asset-group-select"
            value={assetGroup}
            onChange={handleAssetGroup}
          >
            <MenuItem value={'default'}>Default</MenuItem>
            <MenuItem value={'interactivescanner'}>Interactive Scanner</MenuItem>
            <MenuItem value={'menuicon'}>Menu Icon</MenuItem>
            <MenuItem value={'theme'}>Theme</MenuItem>
            <MenuItem value={'plugin'}>Plugin</MenuItem>
          </Select>
        </FormControl>
        <FormControl required variant="standard" sx={{ flex: '10000 1 0%' }}>
          <InputLabel id="file-type-select-label">File Type</InputLabel>
          <Select
            required
            labelId="file-type-select-label"
            id="file-type-select"
            value={fileType}
            onChange={handleFileType}
          >
            <MenuItem value={'css'}>CSS</MenuItem>
            <MenuItem value={'js'}>JavaScript</MenuItem>
            <MenuItem value={'json'}>JSON</MenuItem>
            <MenuItem value={'html'}>HTML</MenuItem>
          </Select>
        </FormControl>
        <TextField
          required
          label="Filename"
          value={filename}
          variant="standard"
          style={{ flex: '10000 1 0%' }}
          onChange={handleFileName}
        />
      </Box>
      <DialogContent
        sx={{
          padding: 0,
          margin: 0,
        }}
      >
        <Editor
          height="600px"
          width="800px"
          theme="vs-dark"
          language={fileType}
          options={{
            padding: {
              top: 10,
            },
            selectOnLineNumbers: true,
            minimap: {
              enabled: false,
            },
          }}
          value={content}
          onChange={handleEditorChange}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={handleClose} color="grey">
          Cancel
        </Button>
        <Button variant="contained" type="submit" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

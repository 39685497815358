import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';

const loadRecord = async (id) => {
  if (id === 'new') return { title: '', userGroups: [], id: 'new' }; // Assuming this is your EMPTY_MEET equivalent

  try {
    const { data } = await axios.get(`${baseUrl}/getCourse`, { params: { id } });
    if (data.course) {
      data.course.ceu = data.course.ceu ? 'yes' : 'no';
      data.course.certificate = data.course.certificate ? 'yes' : 'no';
    }
    return data.course;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load course.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteCourse`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove course.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getAllCourses`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: rows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load course');
  }
};

const saveRecord = async (course) => {
  course.ceu = course.ceu === 'yes';
  course.certificate = course.certificate === 'yes';
  const url =
    !course.id || course.id === 'new' ? `${baseUrl}/saveCourse` : `${baseUrl}/updateCourse`;
  try {
    const { data } = await axios.post(url, course);
    return data.course;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save course.');
  }
};

const getCategories = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCategories`);
    return data.categories;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getPrograms = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getPrograms`);
    return data.programs;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getBilling = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getBillingCategories`);
    return data.billingCategories;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getTrainingPrograms = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getTrainingLocations`);
    return data.trainingLocations;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const getEquipment = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getEquipment`);
    return data.equipment;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

export const getRoomTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getRoomTypes`);
    return data.roomTypes || [];
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get categories');
  }
};

const itrsApi = {
  loadRecord,
  loadRecords,
  saveRecord,
  removeRecord,
  getCategories,
  getPrograms,
  getBilling,
  getTrainingPrograms,
  getEquipment,
};

export default itrsApi;

import React, { useMemo } from 'react';

const ItemPublishStatus = (props) => {
  const { status } = props;
  const statusLabel = useMemo(() => {
    switch (status) {
      case 'pending': {
        return (
          <div className="popover-wrapper">
            <a href="#">
              <div className="publish-status-yellow"></div>
            </a>
            <div className="popover-content">
              <p className="popover-message">Pending</p>
            </div>
          </div>
        );
      }

      case 'published': {
        return (
          <div className="popover-wrapper">
            <a href="#">
              <div className="publish-status-green"></div>
            </a>
            <div className="popover-content">
              <p className="popover-message">Published</p>
            </div>
          </div>
        );
      }

      case 'removed': {
        return (
          <div className="popover-wrapper">
            <a href="#">
              <div className="publish-status-yellow"></div>
            </a>
            <div className="popover-content">
              <p className="popover-message">Removed</p>
            </div>
          </div>
        );
      }

      case 'unpublished': {
        return (
          <div className="popover-wrapper">
            <a href="#">
              <div className="publish-status-red"></div>
            </a>
            <div className="popover-content">
              <p className="popover-message">Unpublished</p>
            </div>
          </div>
        );
      }

      default: {
        return status;
      }
    }
  }, [status]);
  return <>{statusLabel}</>;
};

export default ItemPublishStatus;

import React, { useMemo } from 'react';
import ActionButton from '../../actionButton/components/ActionButton';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';

const ItemAction = ({ action, item, setIsVisible }) => {
  const { disabled, visible } = action;
  const itemDisabled = checkFlagOrFunction(disabled, item);
  const itemVisible = visible === undefined || checkFlagOrFunction(visible, item);
  const label = useMemo(
    () => (typeof action.label === 'function' ? action.label(item) : action.label),
    [action, item]
  );
  const icon = useMemo(
    () => (typeof action.icon === 'function' ? action.icon(item) : action.icon),
    [action, item]
  );

  return (
    itemVisible && (
      <ActionButton
        icon={icon}
        label={label}
        disabled={itemDisabled}
        onClick={() => {
          setIsVisible(false);
          action.onClick(item);
        }}
      />
    )
  );
};

export default ItemAction;

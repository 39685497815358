import createPageConstants from '../shared/util/createPageConstants';
export const MeetConstants = createPageConstants('meets', 'Meet', {
  defaultDefault: 'meetsList',
  editor: 'meetsEditor',
});
export const EMPTY_MEET = {
  title: '',
  type: '',
};
export const meetTypeOptions = {
  meeting: 'Meeting',
  broadcast: 'Broadcast',
  vod: 'Video on Demand',
};
export const meetTypeTitles = {
  meeting: 'Meetings',
  broadcast: 'Broadcasts',
  vod: 'Videos on Demand',
};

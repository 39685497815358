import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionEditorConstants } from '../definitions';
export default function useCollectionEditorItemActions(collectionId) {
  const dispatch = useDispatch();
  const { typeName, pluginId, editorPageName } = CollectionEditorConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: () => {
        dispatch(navigateToPluginPage([pluginId, editorPageName, collectionId, 'new']));
      },
    }),
    [typeName, dispatch, pluginId, editorPageName, collectionId]
  );
  const { edit, remove, restore, requestPending } = useSubItemListActions(
    collectionItemApi,
    collectionId,
    CollectionEditorConstants
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (c) => c?.baseCollectionType === 'itrs-courses',
    }),
    [remove, pluginId]
  );
  return {
    add,
    edit,
    remove: removeOverride,
    restore,
    requestPending,
  };
}

import classNames from 'classnames';
import React, { useEffect } from 'react';
import Spinner from '../../spinner/components/Spinner';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

const Select = ({
  label,
  fieldName,
  placeholder,
  formFieldOptions,
  formContext: { getValues, formState, register },
  fieldWidth,
  className: providedClassName,
  options,
  optionsLoader,
  ...selectAttributes
}) => {
  const { errors } = formState;
  const {
    requestPending,
    result: selectOptions,
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);
  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const value = getValues()[fieldName];
  const isRequired = validationIncludesRequired(formFieldOptions);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {requestPending ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <select
          className="form-control"
          {...register(fieldName, formFieldOptions)}
          {...selectAttributes}
          defaultValue={value || ''}
        >
          {placeholder && (
            <option key="placeholder" value="" disabled>
              {placeholder}
            </option>
          )}
          {selectOptions &&
            selectOptions.map((opt) => {
              return (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              );
            })}
        </select>
      )}

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default Select;

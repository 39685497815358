import React, { useEffect, useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { AchievementInstanceConstants, AchievementConstants } from '../definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import useAchievementInstanceActions from '../hooks/useAchievementInstanceActions';
import useAchievementInstanceConfigurations from '../hooks/useAchievementInstanceConfigurations';
import mainApi from '../api/achievementsApi';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const AchievementInstancesPage = (props) => {
  const [main, setMain] = useState({
    name: 'Achievement',
  });
  const { achievementId } = props;
  const { defaultPageId, typeName } = AchievementInstanceConstants;
  const { columns, api } = useAchievementInstanceConfigurations(achievementId);
  const { edit, add, backToAchievements } = useAchievementInstanceActions(
    achievementId,
    AchievementInstanceConstants
  );
  const { requestPending } = useItemListActions(api, AchievementInstanceConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit);
  useEffect(() => {
    if (achievementId) {
      (async () => {
        setMain(await mainApi(true).loadRecord(achievementId));
      })();
    }
  }, [achievementId]);
  return (
    <>
      <BreadcrumbLink
        pluginId={AchievementConstants.pluginId}
        parentPageId={AchievementConstants.defaultPageId}
        parentPageName={AchievementConstants.defaultPageName}
        parentPageTitle={'Achievements'}
      />
      <CmsPage title={`Instances of '${main.name}'`} requestPending={requestPending}>
        <CmsItemListSection
          pageId={defaultPageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
          backPageAction={backToAchievements}
        />
      </CmsPage>
    </>
  );
};

export default AchievementInstancesPage;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import get from 'lodash/get';
import log from '../../../logger';
const reducerName = 'snapshotChecker';

export const checkCanPublish = createAsyncThunk(
  `${reducerName}/checkCanPublish`,
  async ({ hasChecked }, thunkApi) => {
    const resp = { canPublish: false };
    try {
      await new Promise((r) => setTimeout(r, hasChecked ? 5000 : 5));
      const { data } = await axios.get('content-api/checkCanPublish');
      resp.canPublish = get(data, 'canPublish', false);
    } catch (e) {
      log.error('Error getting check publishes', e);
    } finally {
      thunkApi.dispatch(checkCanPublish({ hasChecked: true }));
    }
    return resp;
  }
);

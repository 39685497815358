import React, { useState } from 'react';
import classNames from 'classnames';

const defaultColumnRenderer = (item, field) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const value = item[field];

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  if (typeof value !== 'string' || value.length <= 51) {
    return <>{value}</>;
  }

  return (
    <>
      {isTruncated ? value.slice(0, 50) : value}
      <span
        onClick={toggleTruncate}
        style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
      >
        {isTruncated ? '...' : '<-'}
      </span>
    </>
  );
};

const ItemTableCell = (props) => {
  const { item, column } = props;
  const { renderer, valueField, className: columnClassName } = column;
  const columnRenderer = renderer || defaultColumnRenderer;
  const align = column.align || 'left';
  return (
    <td className={classNames('table-cell', align, columnClassName)}>
      {columnRenderer(item, valueField)}
    </td>
  );
};

export default ItemTableCell;

import get from 'lodash/get';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { read, utils } from 'xlsx';
import log from '../../../logger';
import Spinner from '../../spinner/components/Spinner';
import useCsvReader from '../../util/hooks/usCsvReader';

const blobToCsv = (blob, fileName) => {
  let csvFile;
  try {
    csvFile = new File([blob], fileName, { type: 'text/csv' });
  } catch (err) {
    log.error(err);
    csvFile = blob;
  }
  return csvFile;
};

export default function useSheetModalUpload({
  title,
  bodyText,
  onSave: onSaveCallback,
  onSaveButtons,
  onClear: onClearCallback,
  onConfirm: onConfirmCallback,
  sheetProps,
}) {
  const [originalFile, setOriginalFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [saveDataError, setSaveDataError] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmText, setConfirmText] = useState(null);
  const [isRunningConfirmCallback, setIsRunningConfirmCallback] = useState(false);
  const saveCallbackRef = useRef();
  const {
    fileData,
    compressedFileData,
    processFile,
    errors = [],
    onClear,
    isValid,
    processedFile,
  } = useCsvReader(sheetProps);
  const closeHandler = useCallback(async () => {
    onClear();
    if (onClearCallback && typeof onClearCallback === 'function') await onClearCallback();
    setVisible(false);
  }, [setVisible, onClearCallback, onClear]);

  const handleInputChange = useCallback(
    (event) => {
      const file = get(event, 'target.files[0]');
      setOriginalFile(file);
      if (file) {
        if (file.name.endsWith('.xlsx')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const csvData = utils.sheet_to_csv(workbook.Sheets[sheetName]);
            processFile(new Blob([csvData], { type: 'text/csv' }), event);
          };
          reader.readAsBinaryString(file);
        } else {
          processFile(file, event);
        }
      } else {
        event.target.value = null;
      }
    },
    [processFile, setOriginalFile]
  );

  const onSave = useCallback(
    async (saveCallback) => {
      try {
        setIsConfirming(false);
        setConfirmText(null);
        setProcessing(true);
        setSaveDataError(false);
        await saveCallback({
          compressedData: compressedFileData,
          csv: blobToCsv(processedFile, originalFile.name),
          data: fileData,
          file: originalFile,
        });
        closeHandler();
      } catch (err) {
        log.error(err);
        log.error('Error saving data', { fileData, originalFile, processedFile });
        setSaveDataError(true);
      } finally {
        setProcessing(false);
      }
    },
    [
      closeHandler,
      compressedFileData,
      fileData,
      onConfirmCallback,
      originalFile,
      processedFile,
      setConfirmText,
      setIsConfirming,
      setProcessing,
      setSaveDataError,
    ]
  );

  const onConfirmSave = useCallback(
    async (onClickCallback, skipConfirmation = false) => {
      if (onConfirmCallback && !skipConfirmation) {
        setIsConfirming(true);
        setConfirmText('Loading Confirmation...');
        setIsRunningConfirmCallback(true);
        saveCallbackRef.current = onClickCallback;
        try {
          const confirmMessage = await onConfirmCallback({
            csv: blobToCsv(processedFile, originalFile.name),
            data: fileData,
            file: originalFile,
          });
          if (typeof confirmMessage === 'string') {
            setConfirmText(confirmMessage);
          } else {
            setIsRunningConfirmCallback(false);
            setIsConfirming(false);
            await onSave(onClickCallback);
          }
        } catch (err) {
          log.error(err);
          setIsConfirming(false);
        } finally {
          setIsRunningConfirmCallback(false);
        }
      } else {
        await onSave(onClickCallback);
      }
    },
    [
      closeHandler,
      fileData,
      onConfirmCallback,
      onSave,
      originalFile,
      processedFile,
      setConfirmText,
      setIsConfirming,
      setIsRunningConfirmCallback,
    ]
  );

  const SheetModal = useMemo(() => {
    return (
      <Modal show={visible} animation={false}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ ...(isConfirming && { display: 'none' }) }}>
            <div>{bodyText || 'Upload File'}</div>
            {processing && <Spinner />}
            <div>
              <input
                disabled={processing}
                type="file"
                className="form-control"
                accept={'.xlsx, .csv'}
                onChange={handleInputChange}
              />
            </div>
            {[...errors, ...(saveDataError ? ['Error Saving Data'] : [])].map((error, idx) => {
              let msg = error?.message ? error?.message : error;
              if (typeof msg === 'string') msg = msg.split('\n');
              return (
                <div className="alert alert-danger" role="alert" key={`${error}-${idx}`}>
                  {Array.isArray(msg) ? msg.map((line, i) => <div key={i}>{line}</div>) : msg}
                </div>
              );
            })}
          </div>
          <div style={{ ...(!isConfirming && { display: 'none' }) }}>
            <div>
              {confirmText &&
                confirmText.split('\n').map((line, i, lines) => (
                  <React.Fragment key={i}>
                    <span
                      style={{
                        color: line.includes('Please remove duplicates and try again.')
                          ? 'red'
                          : 'inherit',
                      }}
                    >
                      {line}
                    </span>
                    {i < lines.length - 1 && <br />}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isConfirming ? (
            <Button
              disabled={isRunningConfirmCallback}
              onClick={() => {
                setIsConfirming(false);
                onConfirmSave(saveCallbackRef.current, true);
              }}
            >
              Ok
            </Button>
          ) : (
            <>
              {onSaveCallback ? (
                <Button
                  variant={'success'}
                  disabled={!isValid}
                  onClick={() => onConfirmSave(onSaveCallback, false)}
                >
                  Save
                </Button>
              ) : (
                (onSaveButtons || []).map(({ variant = 'success', text, onClick }, idx) => (
                  <Button
                    key={idx}
                    variant={variant}
                    disabled={!isValid}
                    onClick={() => onConfirmSave(onClick, false)}
                  >
                    {text}
                  </Button>
                ))
              )}
            </>
          )}
          <Button variant="cancel" onClick={closeHandler}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }, [visible, closeHandler, processing, onSaveButtons, onSaveCallback]);
  return { SheetModal, close: closeHandler, open: () => setVisible(true) };
}

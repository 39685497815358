(function () {
  angular.module('head-controller', ['message-service']).controller('HeadCtrl', [
    '$scope',
    'messageService',
    function ($scope, messageService) {
      messageService.getMessage('cms.appName').then(function (appName) {
        $scope.appName = appName;
      });
    },
  ]);
})();

import { deleteCertificate, createCertificate } from '../../api/service';
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import log from '../../../logger';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Certificates({ certificates, fields, handleGetCertificates }) {
  const [activeFields, setFields] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [pdf, setPdf] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDeleteCertificate = async (rowsDeleted) => {
    for (const i in rowsDeleted.data) {
      try {
        const idx = rowsDeleted.data[i].dataIndex;
        await deleteCertificate(certificates[idx].id);
      } catch (e) {
        log.error(e);
        return false;
      }
    }
  };

  const handleCreateCertificate = async () => {
    const requiredFields = activeFields.map((f) => f.id);
    await createCertificate({
      displayName,
      fields: requiredFields,
      pdfData: JSON.parse(pdf),
    });
    handleAddClose();
    handleGetCertificates();
  };

  const columns = [
    {
      name: 'displayName',
      label: 'Name',
    },
    {
      name: 'fields',
      label: 'Fields',
      options: {
        customBodyRenderLite: (idx) => {
          const value = certificates[idx].Fields;
          return (
            <div>
              {value.map((f, i, ar) => {
                const field = f && f.id ? f : fields.find((fi) => fi.id === f);
                return (
                  <span key={field.id}>
                    {field.displayName}
                    {i + 1 < ar.length ? ',' : ''}{' '}
                  </span>
                );
              })}
            </div>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: '',
      label: 'Has PDF',
      options: {
        customBodyRenderLite: (idx) => {
          const value = certificates[idx].pdfData;
          return <div>{value.length > 0 ? 'True' : 'False'}</div>;
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    onRowsDelete: handleDeleteCertificate,
  };

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  const handleFieldChange = (event) => {
    if (event.target.checked) {
      const addField = fields.find((f) => f.id === event.target.name);
      setFields([...activeFields, addField]);
      return;
    }
    setFields(activeFields.filter((af) => af.id !== event.target.name));
  };

  return (
    <Box sx={{ maxWidth: '1200px', m: 'auto' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Certificate
        </Button>
      </Box>
      <MUIDataTable
        title={'Certificates'}
        data={certificates}
        columns={columns}
        options={options}
      />
      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="display Name"
            label="Name"
            value={displayName}
            sx={{ width: '100%' }}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Select Fields</FormLabel>
            <FormGroup>
              {fields.map((field) => (
                <FormControlLabel
                  key={field.id}
                  control={
                    <Checkbox
                      checked={!!activeFields.find((af) => af.id === field.id)}
                      onChange={handleFieldChange}
                      name={field.id}
                    />
                  }
                  label={field.displayName}
                />
              ))}
            </FormGroup>
          </FormControl>
          <TextField
            id="pdf-data"
            label="pdf data"
            multiline
            maxRows={10}
            value={pdf}
            sx={{ width: '100%' }}
            onChange={(e) => setPdf(e.target.value)}
          />
          <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleCreateCertificate}
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
            <Button onClick={handleAddClose} sx={{ mt: 2 }} variant="contained">
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

export default Certificates;

import React from 'react';

const OrderedItemTableRowSelector = () => {
  return (
    <td className="reorder">
      <i className="far fa-ellipsis-v reorder-icon" />
      <i className="far fa-ellipsis-v reorder-icon" />
    </td>
  );
};

export default OrderedItemTableRowSelector;

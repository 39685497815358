import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSubItemsLoader } from '../../shared/cms/hooks/useSubItemsLoader';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemListSection from '../../shared/cmsPage/components/CmsSubItemListSection';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import ItemLinkDisplay from '../../shared/itemTable/components/ItemLinkDisplay';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import collectionApi from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionConstants, CollectionEditorConstants } from '../definitions';
import useCollectionEditorActions from '../hooks/useCollectionEditorActions';
import useCollectionEditorItemActions from '../hooks/useCollectionEditorItemActions';
import CollectionEditorForm from './CollectionEditorForm';
import CollectionItemTiming from './CollectionItemTiming';
import CollectionItemTitle from './CollectionItemTitle';

const timedSort = (a, b) =>
  new Date(a.startTime) - new Date(b.startTime) || new Date(a.endTime) - new Date(b.endTime);

const timedFilter = (i) => i.timed === 'timed';

const CollectionEditorPage = (props) => {
  const { collectionId } = props;
  const [hasReordered, setHasReordered] = useState(false);
  const CollectionItemColumns = [
    {
      label: 'Name',
      valueField: 'label',
      renderer: (collectionItem) => (
        <CollectionItemTitle collectionId={collectionId} collectionItem={collectionItem} />
      ),
    },
    {
      label: 'Timed/Untimed',
      valueField: 'timed',
      align: 'center',
      renderer: (item) => <CollectionItemTiming item={item} />,
    },
    {
      label: 'Status',
      valueField: 'status',
      align: 'center',
      renderer: (item) => <ItemPublishStatus status={item.status} />,
    },
    {
      label: 'Type',
      valueField: 'objectReference',
      align: 'center',
      renderer: (item) => <div>{_.capitalize(item?.objectDetail?.plugin)}</div>,
    },
    {
      label: 'Link',
      align: 'center',
      valueField: 'objectReference',
      renderer: (item) => (
        <ItemLinkDisplay objectReference={item.objectReference} objectDetail={item.objectDetail} />
      ),
    },
    {
      label: 'Last Modified',
      valueField: 'lastUpdated',
      sortable: true,
      align: 'center',
      renderer: (item, field) => <Timestamp date={item[field]} />,
    },
  ];

  const {
    add,
    edit,
    remove,
    restore,
    requestPending: itemActionRequestPending,
  } = useCollectionEditorItemActions(collectionId);

  const {
    item,
    loadItem,
    reorderItems,
    requestPending: loadItemPending,
  } = useItemPersistence(collectionApi);

  const reorderItemsWrapper = useCallback(
    async (parentId, itemIds) => {
      setHasReordered(true);
      await reorderItems(parentId, itemIds);
    },
    [setHasReordered, reorderItems]
  );

  useEffect(() => {
    if (collectionId) (async () => await loadItem(collectionId))();
  }, [collectionId, loadItem]);

  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    CollectionConstants.typeName,
    'name',
    collectionApi,
    CollectionEditorForm
  );

  const emptyListMessage = useMemo(() => {
    return item
      ? `There are currently no collection items for '${item.name}.'`
      : `There are currently no collection items.`;
  }, [item]);

  const { loadRecords } = collectionItemApi;
  const { loadItems, records } = useSubItemsLoader(loadRecords);
  const requestPending = itemActionRequestPending || loadItemPending;
  const { pageTitle, onItemChange } = useEditPageTitle(collectionId, 'Collection', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);
  const hasItemsPendingPublish = records.some((r) => r && r.status && r.status !== 'published');
  const hasSettingsPendingPublish = item && item.status && item.status !== 'published';
  const publishDisabled = !hasSettingsPendingPublish && !hasItemsPendingPublish && !hasReordered;
  const itemName = get(item, 'name', 'this collection');
  const alert = {
    condition: !publishDisabled,
    message: `You have unpublished changes to ${itemName} which may include name, label and/or sort order. Select Publish Collection to begin the publish process.`,
    alertType: 'info',
  };
  const { publish, editSettings } = useCollectionEditorActions({
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    collectionId,
    pageTitle,
    publishDisabled,
    setHasReordered,
    showItemEditorModal,
    reorderItemsWrapper,
  });
  const toolbarActions = useArray(publish, editSettings, add);
  const itemActions = useArray(edit, remove, restore);
  const { pluginId, parentPageId, parentPageName } = CollectionEditorConstants;
  const separateTimed = item && item.type === 'agenda';
  const priorityFilter = separateTimed ? timedFilter : undefined;
  const prioritySort = separateTimed ? timedSort : undefined;
  return (
    <>
      <BreadcrumbLink
        pluginId={pluginId}
        parentPageId={parentPageId}
        parentPageName={parentPageName}
        parentPageTitle={'Collections'}
      />
      <CmsPage title={pageTitle} requestPending={requestPending}>
        <CmsSubItemListSection
          parentItemId={collectionId}
          parentPageTitle="Collections"
          className="nav-manager"
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          alert={alert}
          reorder={reorderItemsWrapper}
          emptyListMessage={emptyListMessage}
          columns={CollectionItemColumns}
          subItemApi={collectionItemApi}
          pageConstants={CollectionEditorConstants}
          showBreadCrumb={false}
          priorityFilter={priorityFilter}
          prioritySort={prioritySort}
        />
        <ItemEditorModal {...editorModalProps} item={item} />
      </CmsPage>
    </>
  );
};

export default CollectionEditorPage;

import { TableRow } from '@mui/material';
import React, { Fragment } from 'react';
import TableCellRemove from './TableCellRemove';
import TableCellTextField from './TableCellTextField';
import { useTableEditorContext } from './TableContext';
import TableCellDropDown from './TableCellDropDown';
export default function TableBodyRow({ row, provided, rowIdx }) {
  const { columns } = useTableEditorContext();
  return (
    <TableRow
      ref={provided.innerRef}
      sx={{
        '&:nth-of-type(odd)': { backgroundColor: 'rgb(242,242,242)' },
        '& .MuiTableCell-root': {
          py: 0,
          border: 'none',
        },
        '& .MuiTableCell-body': {
          padding: '8px',
        },
      }}
      {...(provided?.draggableProps || {})}
      {...(provided?.dragHandleProps || {})}
    >
      {columns.map((col, colIndex) => (
        <Fragment key={col.name + colIndex}>
          {col.isRemove ? (
            <TableCellRemove rowIdx={rowIdx} confirm={col.confirm} />
          ) : col.inputType === 'dropdown' ? (
            <TableCellDropDown
              setCursor={row.setCursor}
              viewOnly={col.viewOnly}
              colIndex={colIndex}
              idx={rowIdx}
              error={row?.FIELD_ERRORS?.[col.field]}
              cellAlign={col.cellAlign || 'left'}
              cellWidth={`${col.width}%`}
              field={col.field}
              options={col.options || []}
              value={row[col.field]}
              defaultViewValue={col.defaultViewValue}
            />
          ) : (
            <TableCellTextField
              setCursor={row.setCursor}
              viewOnly={col.viewOnly}
              colIndex={colIndex}
              idx={rowIdx}
              error={row?.FIELD_ERRORS?.[col.field]}
              cellAlign={col.cellAlign || 'left'}
              cellWidth={`${col.width}%`}
              field={col.field}
              inputType={col.inputType || 'text'}
              tableCellProps={col.tableCellProps || {}}
              textFieldProps={col.textFieldProps || {}}
              value={row[col.field]}
              defaultViewValue={col.defaultViewValue}
              hidden={col.hidden}
            />
          )}
        </Fragment>
      ))}
    </TableRow>
  );
}

import get from 'lodash/get';
import { useMemo } from 'react';
import generateMeetsApi from '../api/meetsApi';
import { MeetConstants, meetTypeOptions, meetTypeTitles } from '../definitions';
const MeetColumns = [
  {
    label: 'Title',
    valueField: 'title',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Presenter Group',
    valueField: 'group',
    sortable: true,
    renderer: (item) => get(item, `group.name`, ''),
  },
];
export default function useMeetConfigurations(meetType) {
  const constants = useMemo(
    () => ({
      ...MeetConstants,
      typeName: get(meetTypeOptions, meetType, ''),
      defaultPageName: meetType,
    }),
    [meetType, MeetConstants]
  );
  const columns = useMemo(
    () => MeetColumns.filter(({ valueField }) => !(meetType === 'vod' && valueField == 'group')),
    [MeetColumns, meetType]
  );
  const api = useMemo(() => generateMeetsApi(meetType), [meetType, generateMeetsApi]);
  const listTitle = get(meetTypeTitles, meetType);
  return {
    constants,
    columns,
    listTitle,
    api,
  };
}

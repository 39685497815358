import React from 'react';
import classNames from 'classnames';

const ItemTableHeaderCell = (props) => {
  const { column, sortOrder, onClick } = props;
  const { sortable, label, className: columnClassName } = column;
  const clickHandler = sortable ? onClick : undefined;
  let indicatorClass = '';

  if (sortOrder && sortOrder !== 'NONE') {
    indicatorClass = sortOrder === 'ASC' ? 'st-sort-ascent' : 'st-sort-descent';
  }

  const align = column.align || 'left';
  const className = classNames(
    'table-header',
    align,
    { 'no-pointer': !sortable || !clickHandler },
    indicatorClass,
    columnClassName
  );
  return (
    <th className={className} onClick={clickHandler}>
      {label}
    </th>
  );
};

export default ItemTableHeaderCell;

import React, { useEffect } from 'react';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../shared/form/components/FormInput';
const ManageGroupsEditorForm = ({ form, actions }) => {
  const { setValue, watch } = form;
  const watchNameField = watch('name');

  useEffect(() => {
    if (watchNameField && watchNameField.length > 0) {
      setValue('key', watchNameField);
    }
  }, [watchNameField]);

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          label="Group Key"
          fieldName="key"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          label="Group Memo"
          fieldName="subtitle"
          formContext={form}
          formFieldOptions={{
            maxLength: {
              value: 24,
              message: 'Group Memo cannot be more than 24 characters',
            },
          }}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default ManageGroupsEditorForm;

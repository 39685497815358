import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conditionToText } from '../../../../cms/cmsUtils';
import { getUserOptions } from '../store/thunks';
import _ from 'lodash';

const ConditionToText = (props) => {
  const { condition } = props;
  const [conditionText, setText] = React.useState('');
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, `audienceSelector.rootOptions`, {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) dispatch(getUserOptions());
  }, [dispatch, opts]);
  useEffect(() => {
    setText(conditionToText(condition, opts));
  }, [condition, conditionToText, opts]);
  return <div className="value-description">{conditionText}</div>;
};

export default ConditionToText;

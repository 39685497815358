import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
const MenuItemTitle = ({ collectionItem, collectionId }) => {
  const { label } = collectionItem;
  const dispatch = useDispatch();
  const navigateToChild = useCallback(() => {
    dispatch(
      navigateToPluginPage(['menu-navbar', 'navbarItemEditorPage', collectionId, collectionItem.id])
    );
  }, [dispatch]);

  return (
    <>
      <a onClick={navigateToChild}>{label}</a>
      {collectionItem.position === 0 && (
        <i
          style={{ marginLeft: '5px', color: '#2c2e3a' }}
          className="fa fa-home"
          aria-hidden="true"
        ></i>
      )}
    </>
  );
};

export default MenuItemTitle;

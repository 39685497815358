(function () {
  angular.module('confirm-dialog', []).service('confirmDialog', [
    '$rootScope',
    '$modal',
    function ($rootScope, $modal) {
      return function (options) {
        const modalScope = $rootScope.$new();
        for (const key in options) {
          if (options.hasOwnProperty(key)) modalScope[key] = options[key];
        }
        return $modal.open({
          templateUrl: 'shared/confirmModal.html',
          animation: false,
          scope: modalScope,
        });
      };
    },
  ]);
})();

import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ScanLogExportEditorForm from './ScanLogExportEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { QrScannerConstants } from '../definitions';
import useQrScannerExports from '../hooks/useQrScannerExports';
import log from '../../logger';

const QrScannerLogExport = ({ exportOptions }) => {
  const { createReport, cancel } = useQrScannerExports(QrScannerConstants);
  const form = useCmsForm();
  let options;
  try {
    options = JSON.parse(exportOptions);
  } catch (e) {
    log.error('QrScannerLogExport exportOptions parse error', e);
  }
  return (
    <CmsPage title={`Report Recipients`}>
      <ScanLogExportEditorForm
        form={form}
        growlId={QrScannerConstants.exportPageName}
        actions={[createReport(form, options), cancel]}
      />
    </CmsPage>
  );
};

export default QrScannerLogExport;

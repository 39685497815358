(function () {
  angular
    .module('new-wysiwyg-editor', ['smart-table', 'content-api-service', 'confirm-dialog'])

    .controller('newWysiwygHtmlFileController', [
      '$rootScope',
      '$scope',
      '$modal',
      '$log',
      'growl',
      'contentApiService',
      '$state',
      '$transition$',
      'confirmDialog',
      'instanceService',
      '$timeout',
      function (
        $rootScope,
        $scope,
        $modal,
        $log,
        growl,
        contentApiService,
        $state,
        $transition$,
        confirmDialog,
        instanceService,
        $timeout
      ) {
        const stateParams = $transition$.params();
        let originalfileId;
        let originalGroup;
        let originalContents;
        let originalTitle;
        let overwrite = false;
        $scope.group = {};
        $scope.revision = {};
        $scope.$on('froala.codeView.changed', function (event, on) {
          $timeout(function () {
            $scope.saveDisabled = on;
          });
        });
        $scope.$on('froalaEditor.initialized', function () {
          if (!$scope.editable) {
            $timeout(function () {
              $scope.wysiwygOptions.froalaEditor('edit.off');
            });
          }
        });
        $scope.$on('froalaEditor.imageInserted', function (e, event, editor) {
          $timeout(function () {
            $scope.contents.text = editor.html.get();
          });
        });
        $scope.$on('froalaEditor.videoInserted', function (e, event, editor) {
          $timeout(function () {
            $scope.contents.text = editor.html.get();
          });
        });
        initializeAssetData();
        function initializeAssetData() {
          overwrite = false;
          $scope.fileId = {
            text: '',
          };
          $scope.contents = {
            text: '',
          };
          $scope.title = {
            partOne: '',
            partTwo: '',
          };
          $scope.group = { text: 'default' };
          if (stateParams.assetId) {
            overwrite = true;
            $scope.fileId = {
              text: stateParams.assetId,
            };
            contentApiService.getAssetMetadata(stateParams.assetId).then(
              function (assetMetadata) {
                $scope.asset = angular.copy(assetMetadata);
                $scope.revision = angular.copy(getActiveRevision(assetMetadata));
                contentApiService
                  .getAsset(stateParams.assetId, $scope.revision.id, true)
                  .success(function (data) {
                    initializeTitle(data);
                    const scrubbbedData = initializeWysiwygContent(data);
                    $scope.contents = {
                      text: scrubbbedData,
                    };
                    originalContents = angular.copy($scope.contents);
                  })
                  .error(function (data) {
                    $log.debug('Failed to install default content. Response: ', data);
                  });
                originalGroup = $scope.revision.group;
                $scope.group = { text: originalGroup };
              },
              function () {
                growl.error(
                  'An error occured while trying to load the data.  Please try again later'
                );
              }
            );
          }
          $scope.editable =
            stateParams.editable === undefined
              ? $state.current.data.defaultEditable === 1
              : stateParams.editable === 1;
          $scope.groups = contentApiService.getGroups();
          originalfileId = angular.copy($scope.fileId);
          originalContents = angular.copy($scope.contents);
          originalTitle = angular.copy($scope.title);
        }
        $scope.save = function (closeWhenSaved) {
          const asset = {};
          if (!$scope.fileId || !$scope.fileId.text) {
            growl.error('No Id Specified');
            return;
          }
          if (!$scope.group || !$scope.group.text) {
            growl.error('No Group Specified');
            return;
          }
          if (!$scope.title.partOne) {
            growl.error('No Header Title Part One Specified');
            return;
          }
          if (!$scope.needsSave()) {
            if (closeWhenSaved) $scope.close();
            else {
              growl.error('No changes to save');
            }
            return;
          }
          function endsWith(str, suffix) {
            return str.match(`${suffix}$`) === suffix;
          }
          asset.type = 'file';
          if ($scope.assetType) {
            asset.type = $scope.assetType;
            if (asset.type == 'wysiwyg' && !endsWith($scope.fileId.text, '.html')) {
              $scope.fileId.text += '.html';
            } else if (
              asset.type != 'menu' &&
              asset.type != 'page' &&
              asset.type != 'wysiwyg' &&
              !endsWith($scope.fileId.text, `.${asset.type}`)
            ) {
              $scope.fileId.text += `.${asset.type}`;
            }
          }
          asset.id = contentApiService.scrubAssetId($scope.fileId.text);
          asset.group = $scope.group.text;
          asset.latestRevision = $scope.revision;
          if ($scope.asset && $scope.asset.instanceMappings) {
            asset.instanceMappings = $scope.asset.instanceMappings;
          }
          const scrubbedContents = finalizeWyswygContent(
            $scope.wysiwygOptions.froalaEditor('html.get')
          );
          contentApiService
            .saveAsset(scrubbedContents, asset, overwrite)
            .success(function (data) {
              $log.debug('Successfully saved asset. Response: ', data);
              if (closeWhenSaved) $scope.close(true);
              else {
                growl.success('Asset saved successfully');
                initializeAssetData();
              }
            })
            .error(function (data) {
              $log.debug('Failed to save. Response: ', data);
              growl.error(data.error);
            });
        };
        $scope.reset = function () {
          $scope.fileId = angular.copy(originalfileId);
          $scope.contents = angular.copy(originalContents);
          $scope.title = angular.copy(originalTitle);
        };
        $scope.needsSave = function () {
          return (
            !angular.equals(
              $scope.wysiwygOptions.froalaEditor('html.get'),
              originalContents.text
            ) ||
            !angular.equals($scope.fileId, originalfileId) ||
            $scope.group.text != originalGroup ||
            !angular.equals($scope.title, originalTitle)
          );
        };
        $scope.close = function (forceClose) {
          if (!forceClose && $scope.needsSave()) {
            $rootScope.$new();
            confirmDialog({
              title: 'Close Without Saving?',
              body: 'You have unsaved changes.  Are you sure you want to close without saving?',
              confirmText: 'Close Without Saving',
              cancelText: 'Continue Editing',
            }).result.then(function () {
              $state.go('main.manage-assets');
            });
          } else {
            $state.go('main.manage-assets');
          }
        };
        $scope.saveAndClose = function () {
          $scope.save(true);
        };
        $scope.setFileType = function (type) {
          if (type) $scope.assetType = type;
        };
        $scope.applyTemplate = function () {};
        function getActiveRevision(asset) {
          return contentApiService.getRevisionForInstance(
            asset,
            instanceService.getActiveInstance().id,
            true
          );
        }
        function finalizeWyswygContent(content) {
          const el = $(content).clone();
          if (!el.hasClass('wysiwyg-page')) {
            const retVal = `<div class="wysiwyg-page"><dynamic-header split-title-part-one="${$scope.title.partOne}" split-title-part-two="${$scope.title.partTwo}" go-home="goHome()" go-back="goBack()"></dynamic-header><link rel="stylesheet" href="cms://wysiwygPageStyles.css"/><ion-content class="has-subheader main-content has-header"><div class="reset-this"><div class="wysiwyg_pageContainer has-dynamic-header wysiwyg-content fr-view" style="width:100%;">${content}</ion-content></div></div></div>`;
            return retVal;
          }
          return content;
        }
        function initializeTitle(content) {
          const el = $(content).find('dynamic-header');
          if (el.length !== 0) {
            $scope.title.partOne = el.attr('split-title-part-one');
            $scope.title.partTwo = el.attr('split-title-part-two');
            $scope.title.partOne =
              $scope.title.partOne && $scope.title.partOne !== 'undefined'
                ? $scope.title.partOne
                : '';
            $scope.title.partTwo =
              $scope.title.partTwo && $scope.title.partTwo !== 'undefined'
                ? $scope.title.partTwo
                : '';
          }
          originalTitle = angular.copy($scope.title);
        }
        function initializeWysiwygContent(content) {
          const el = $(content).clone().find('.wysiwyg_pageContainer');
          if (el.length !== 0) return el.html();
          return content;
        }
      },
    ]);
})();

import startCase from 'lodash/startCase';
import React, { useCallback, useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { AttendanceEventConstants, AttendanceImportsConstants } from '../definitions';
import useAttendanceEventAttendeeListActions from '../hooks/useAttendanceEventAttendeeListActions';
const AttendanceEventAttendeePage = ({ attendanceEventId }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showList, setShowList] = useState(true);
  const { typeName } = AttendanceImportsConstants;
  const handleClickShowAttendeeDetails = useCallback(
    (show = false) => {
      setShowDetailsModal(show);
    },
    [setShowDetailsModal]
  );

  const {
    back,
    eventName,
    importTimestamp,
    itemActions,
    columns,
    api,
    SheetModal,
    toolbarActions,
  } = useAttendanceEventAttendeeListActions({
    attendanceEventId,
    handleClickShowAttendeeDetails,
    setShowList,
  });

  return (
    <CmsPage title={startCase(eventName)} backTitle={back.pageName} backClick={back.onClick}>
      {!showDetailsModal && SheetModal}
      {showList && (
        <CmsItemListSection
          searchHeader="Search by Name or Email"
          pageId={AttendanceEventConstants.attendeesPageId}
          pageSize={25}
          additiontalLoaderParams={{ attendanceEventId }}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
          tableFooter={{
            left: (
              <div style={{ color: 'grey', fontSize: '15px', fontStyle: 'italic' }}>
                {importTimestamp
                  ? `Date of Last Import: ${importTimestamp}`
                  : 'No Import for this Event'}
              </div>
            ),
          }}
        />
      )}
    </CmsPage>
  );
};

export default AttendanceEventAttendeePage;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateRuleCondition } from '../../../../../store';
import { selectRuleConditionData } from '../../../../../store/selectors';
import get from 'lodash/get';

const SelctCondition = (props) => {
  const { location, fieldName } = props;
  const { value, options, isDisabled } = selectRuleConditionData(fieldName, location);
  const dispatch = useDispatch();
  const onConditionChange = useCallback(
    ({ value: condition }) => {
      dispatch(
        updateRuleCondition({
          condition,
          location,
          fieldName,
        })
      );
    },
    [dispatch, location, fieldName]
  );
  const defaultOptionValue =
    value ||
    get(
      options.find((option) => option.isDefault),
      'value',
      ''
    );
  return (
    <Select
      isDisabled={isDisabled}
      placeholder={'Condition'}
      getOptionLabel={(cnd) => cnd.name}
      getOptionValue={(cnd) => cnd.value}
      onChange={onConditionChange}
      value={isDisabled ? '' : defaultOptionValue}
      options={options}
      className="inline-select"
    />
  );
};

export default SelctCondition;

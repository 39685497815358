import { useCallback } from 'react';
import { getFileAssetPreview } from '../../../../../shared/api';
import { getLocalDefaultTileImage, updateFile } from './helpers';
import { actions } from './reducer';
import log from '../../../../../logger';
import { getCroppedImg } from './helpers';
const { SET_FILES, SET_SELECTED_FILE, SET_INITIAL_ASSETS, SET_CROPPED_IMAGE } = actions;
export default function useDispatchFunctions(setFormValue, dispatch, defaultAssetId, useCropper) {
  const setSelectedFilePath = useCallback(
    (selectedFilePath) => {
      dispatch({ payload: { selectedFilePath, setFormValue }, type: SET_SELECTED_FILE });
    },
    [dispatch, SET_SELECTED_FILE, setFormValue]
  );
  const setFiles = useCallback(
    (acceptedFiles = [], rejectedFiles = []) => {
      dispatch({
        payload: { acceptedFiles, rejectedFiles, setFormValue, useCropper },
        type: SET_FILES,
      });
    },
    [dispatch, SET_FILES, setFormValue, useCropper]
  );
  const getCurrentAsset = useCallback(
    async (assetId) => {
      let file;
      if (assetId && assetId !== 'default' && assetId !== defaultAssetId) {
        try {
          file = await getFileAssetPreview(assetId);
          if (file) {
            file = updateFile(file, { name: `CURRENT_FILE-${assetId}`, path: 'CURRENT_FILE' });
          }
        } catch (e) {
          log.error('Error getting asset', e);
        }
      }
      return file;
    },
    [getFileAssetPreview, updateFile, defaultAssetId]
  );
  const getDefaultAsset = useCallback(
    async (defaultAssetId) => {
      let file;
      if (defaultAssetId) {
        try {
          file = await getFileAssetPreview(defaultAssetId);
          if (file) {
            file = updateFile(file, { name: 'DEFAULT_FILE', path: 'DEFAULT_FILE' });
          }
        } catch {
          log.info('No default asset found getting asset');
        }
      }
      if (!file) file = getLocalDefaultTileImage();
      return file;
    },
    [getFileAssetPreview, getLocalDefaultTileImage, updateFile]
  );
  const initializeAssets = useCallback(
    async (defaultAssetId, assetId) => {
      const defaultFile = await getDefaultAsset(defaultAssetId);
      const currentFile = await getCurrentAsset(assetId);
      setFormValue(currentFile ? currentFile : defaultFile, false);
      dispatch({
        payload: {
          currentFile,
          defaultFile,
          path: currentFile ? 'CURRENT_FILE' : 'DEFAULT_FILE',
          useCropper,
        },
        type: SET_INITIAL_ASSETS,
      });
    },
    [getCurrentAsset, getDefaultAsset, dispatch, setFormValue, useCropper]
  );
  const setCroppedImage = useCallback(
    async (src, croppedAreaPixes, rotation = 0) => {
      const {
        image: croppedImage,
        type,
        extension,
      } = await getCroppedImg(src, croppedAreaPixes, rotation);
      dispatch({
        payload: { croppedImage, extension, setFormValue, type },
        type: SET_CROPPED_IMAGE,
      });
    },
    [getCroppedImg, SET_CROPPED_IMAGE, setFormValue]
  );
  return { initializeAssets, setCroppedImage, setFiles, setSelectedFilePath };
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import log from '../../logger';

const postProcessing = {
  none: (raw) => raw,
  achievementTemplates: (raw) =>
    raw.map((t) => ({
      requiredFields: t.Fields.map((f) => f.id),
      label: t.displayName,
      value: t.id,
    })),
};

const useServiceData = (postProcessor = 'none') => {
  const [templates, setTemplates] = useState([]);
  const [fields, setFields] = useState([]);
  const [requestPending, setRequestPending] = useState(true);
  const certSuffix = '/getCertificates';
  const fieldSuffix = '/getFields';

  useEffect(() => {
    const baseUrl = '/plugins/achievements/api';
    const urlCert = `${baseUrl}${certSuffix}`;
    const urlField = `${baseUrl}${fieldSuffix}`;
    try {
      (async () => {
        const serviceData = await Promise.all([
          axios.get(urlCert, {
            timeout: 10_000,
          }),
          axios.get(urlField, {
            timeout: 10_000,
          }),
        ]);
        try {
          const templatesRaw = serviceData[0].data.certificates;
          const fields = serviceData[1].data.fields;
          const processed = postProcessing[postProcessor](templatesRaw);
          setTemplates(processed);
          setFields(fields);
          setRequestPending(false);
        } catch (e) {
          log.error(e);
          throw new Error('Failed to process service data');
        }
      })();
    } catch (e) {
      log.error(e);
      throw new Error('Failed to retreive service data');
    }
  }, [postProcessor]);
  const options = {
    templates,
    fields,
  };
  return {
    options,
    requestPending,
  };
};

export default useServiceData;

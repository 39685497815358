import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import useArray from '../../shared/util/hooks/useArray';
import { SurveyInstanceConstants } from '../definitions';
import useSurveyInstanceActions from '../hooks/useSurveyInstanceActions';
import useSurveyInstanceConfigurations from '../hooks/useSurveyInstanceConfigurations';

const SurveyInstancesPage = (props) => {
  const { surveyId } = props;
  const { defaultPageId, typeName } = SurveyInstanceConstants;
  const { columns, api } = useSurveyInstanceConfigurations(surveyId);
  const { backToSurveys, add, edit } = useSurveyInstanceActions(surveyId, SurveyInstanceConstants);
  const { remove, requestPending } = useItemListActions(api, SurveyInstanceConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={'Form Instances'} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
        backPageAction={backToSurveys}
      />
    </CmsPage>
  );
};

export default SurveyInstancesPage;

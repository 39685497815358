(function () {
  angular.module('edit-group', []).controller('EditGroupCtrl', [
    '$scope',
    '$log',
    '$timeout',
    '$location',
    'growl',
    'groupApiService',
    function ($scope, $log, $timeout, $location, growl, groupApiService) {
      $scope.group = $location.state().group;
      $scope.replaceMode = $location.state().replaceMode;
      $scope.group = $scope.group ? angular.copy($scope.group) : {};
      $scope.modeLabel = $scope.replaceMode ? 'Edit' : 'Add';
      $scope.cancel = function () {
        $location.path('/main/manage-groups').search({});
      };
      $scope.nameUpdated = function () {
        if (!$scope.replaceMode) {
          $timeout(function () {
            $scope.group.key = computeKey($scope.group.name);
          });
        }
      };
      $scope.save = function () {
        groupApiService
          .saveGroup($scope.group, !$scope.replaceMode)
          .success(function (data) {
            if (data.status == 'error') growl.error(data.error);
            else {
              $location.path($location.state().goBack);
            }
          })
          .error(function (err) {
            growl.error(err.error || 'Group update failed.  Please try again later.');
          });
      };
      function computeKey(groupName) {
        return groupName.toLowerCase().replace(new RegExp('\\W', 'g'), '_');
      }
    },
  ]);
})();

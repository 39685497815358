import _ from 'lodash';
_.mixin({
  getDuplicates: (array = [], key, mapCallback) => {
    let arr = array || [];
    if (key) {
      arr = arr.map((v) => {
        const value = _.get(v, key);
        return mapCallback ? mapCallback(value) : value;
      });
    }
    return _.uniq(arr.filter((v, idx, self) => self.indexOf(v) !== idx)).sort();
  },
});

import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import useRegistrationActions from '../hooks/useRegistrationActions';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import useRegistrationConfigurations from '../hooks/useRegistrationConfigurations';
const RegistrationsListPage = () => {
  const { columns, listTitle, api, constants } = useRegistrationConfigurations();
  const { add, edit, remove, showGroups, hideGroups } = useRegistrationActions(api, constants);
  const { defaultPageId } = constants;
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, showGroups, hideGroups, remove);

  return (
    <CmsPage title={listTitle}>
      <CmsItemListSection
        pageId={defaultPageId}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default RegistrationsListPage;

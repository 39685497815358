import { useCallback, useState } from 'react';
export const usePublishPageTitle = (id, typeName, labelField) => {
  const [pageTitle, setPageTitle] = useState('');
  const onItemChange = useCallback(
    (item) => {
      const itemName = item && item[labelField];
      const newTitle = itemName ? `Publish '${itemName}'` : `Publish ${typeName}`;
      setPageTitle(newTitle);
    },
    [id, typeName, labelField, setPageTitle]
  );
  return {
    pageTitle,
    onItemChange,
  };
};

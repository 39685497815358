import React from 'react';
import CmsPageSection from './CmsPageSection';
import GrowlContainer from '../../growl/components/GrowlContainer';

const CmsItemEditSection = (props) => {
  const { className, pageId, children } = props;
  return (
    <CmsPageSection className={className} isDetail={true}>
      <GrowlContainer groupId={pageId} />
      {children}
    </CmsPageSection>
  );
};

export default CmsItemEditSection;

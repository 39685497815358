import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { resetCount } from '../../../shared/snapshotChecker/store/index';
import { selectCanPublish } from '../../../shared/snapshotChecker/store/selectors';
import ActionButton from '../../actionButton/components/ActionButton';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';
import SnapshotCheckActionWrapper from './SnapshotCheckActionWrapper';

export default function SnapshotCheckItemAction({ action, item, setIsVisible }) {
  const { disabled, visible } = action;
  const dispatch = useDispatch();
  const canPublish = selectCanPublish();
  const handleClick = useCallback(async () => {
    setIsVisible(false);
    await action.onClick(item);
    dispatch(resetCount());
  }, [action, dispatch, item, setIsVisible]);
  const itemDisabled = checkFlagOrFunction(disabled, item);
  const itemVisible = visible === undefined || checkFlagOrFunction(visible, item);
  const label = useMemo(
    () =>
      canPublish
        ? typeof action.label === 'function'
          ? action.label(item)
          : action.label
        : 'Processing...',
    [action, item, canPublish]
  );

  return (
    itemVisible && (
      <SnapshotCheckActionWrapper canPublish={canPublish} isItem>
        <ActionButton
          icon={action.icon}
          label={label}
          disabled={!canPublish || itemDisabled}
          onClick={() => handleClick()}
        />
      </SnapshotCheckActionWrapper>
    )
  );
}

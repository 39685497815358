import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import * as yup from 'yup';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import useRegistrationConfigurations from './useRegistrationConfigurations';
import { RegistrationConstants as RC } from '../definitions';

const useRegistrationEditorSetup = (itemId, onItemChange) => {
  const { spinnerProvider } = useContext(CmsPageContext);
  const { constants, api } = useRegistrationConfigurations();
  const { item, loadItem, saveItem, requestPending, version } = useItemPersistence(
    api,
    undefined,
    undefined,
    true
  );

  const form = useCmsForm(item, {
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required('A title is required.'),
        userGroups: yup.array().of(
          yup.object().shape({
            name: yup.string().required('Name is required and cannot be empty.'),
            maximumOccupancy: yup
              .number()
              .required('Required')
              .typeError('Must be a number')
              .min(0, `Can't be negative`),
            subtitle: yup.string().max(24, 'Max 24 characters').nullable(true),
          })
        ),
      })
    ),
  });

  const actions = useItemEditActions(saveItem, form, constants, undefined, undefined, {
    refreshPageParams: {
      pluginId: RC.pluginId,
      pageName: RC.editorPageName,
    },
  });
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, constants, version);

  return { form, actions };
};

export default useRegistrationEditorSetup;

import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/quicksight/api';

export const getQuicksightUrl = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getQuicksightUrl`);
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get quicksight url');
  }
};

export const getQuicksightUserUrl = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getQuicksightUserUrl`);
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get quicksight url');
  }
};

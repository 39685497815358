import React from 'react';
import classNames from 'classnames';

const CmsPageSection = (props) => {
  const { title, className, isDetail, children } = props;
  return (
    <div
      className={classNames('module', 'row', className, {
        red: !isDetail,
      })}
    >
      {title && (
        <div className="module-header">
          <span className="module-title">{title}</span>
        </div>
      )}
      <div className="col-sm-12">
        <div className="module-body">{children}</div>
      </div>
    </div>
  );
};

export default CmsPageSection;

import classNames from 'classnames';
import React from 'react';
import ItemPageLink from '../../shared/itemTable/components/ItemPageLink';
import { CohortConstants } from '../definitions';
const cohortMemberColumns = [
  {
    label: '',
    valueField: '',
    sortable: false,
    className: 'cohort-member-status',
    renderer: (cohortMember) => (
      <>
        <i
          className={classNames('fa-regular', 'fa-envelope', {
            enabled: cohortMember.hasImplicitUser,
          })}
        />
        <i
          className={classNames('fa-regular', 'fa-ticket', {
            enabled: cohortMember.hasExplicitUsers,
          })}
        />
      </>
    ),
  },
  {
    label: 'Credential Code',
    valueField: 'credentialCode',
    className: 'cohort-credential-code',
    sortable: true,
    renderer: (cohortMember) => (
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
        {cohortMember.hasExplicitUsers ? (
          <ItemPageLink
            linkText={cohortMember.credentialCode}
            pluginId={CohortConstants.pluginId}
            defaultPageName={CohortConstants.itemsPageName}
            params={[cohortMember.cohortId, cohortMember.credentialCode]}
          />
        ) : (
          <>{cohortMember.credentialCode}</>
        )}
      </div>
    ),
  },
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
  },
  {
    label: 'Invitee Email Address',
    valueField: 'email',
    sortable: true,
  },
  {
    label: 'Groups',
    valueField: 'groupNames',
    sortable: false,
  },
];

export default cohortMemberColumns;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ActionList from '../../itemTable/components/ActionList';
import { navigateToParentPage } from '../../../store/rootReducer';
const CmsSubItemListSectionToolbar = (props) => {
  const {
    actions,
    pluginId,
    parentPageId,
    parentPageName,
    parentPageTitle,
    parentPageArgs,
    showBreadCrumb,
  } = props;
  const dispatch = useDispatch();
  const navigateToParent = useCallback(() => {
    dispatch(
      navigateToParentPage([pluginId, parentPageName, parentPageId, ...(parentPageArgs || [])])
    );
  }, [dispatch, parentPageId, parentPageName, parentPageArgs]);
  return (
    <div className="section-toolbar">
      {showBreadCrumb == false ? (
        <></>
      ) : (
        <span className="header-link">
          <a onClick={navigateToParent}>&lt; Back to {parentPageTitle}</a>
        </span>
      )}
      <ActionList className={showBreadCrumb === false ? 'alignRight' : ''} actions={actions} />
    </div>
  );
};

export default CmsSubItemListSectionToolbar;

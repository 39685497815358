angular.module('data-record-service', []).service('dataRecordService', [
  '$http',
  '$q',
  'Upload',
  '$log',
  '$rootScope',
  function ($http, $q, Upload, $log, $rootScope) {
    const baseUrl = '/data-api/';
    function getDataRecord(group, key) {
      const deferred = $q.defer();
      if (group) {
        let url = `${baseUrl}get?group=${group}&timestamp=${Date.now()}`;
        if (key) url += `&key=${key}`;
        $http.get(url).then(
          function (result) {
            deferred.resolve(result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }
    function upsertTemporaryFile(file, assetId) {
      const url = `${baseUrl}uploadTemporaryFiles`;
      const promise = Upload.upload({
        url,
        method: 'POST',
        data: {
          assetData: Upload.rename(file, assetId),
        },
      });
      promise
        .progress(function (evt) {
          $log.debug(`progress: ${Number.parseInt((100 * evt.loaded) / evt.total)}%`);
        })
        .success(function (data) {
          $log.debug('File was added successfully.  Response: ', data);
          $rootScope.$broadcast('fileUploaded');
        })
        .error(function (err, status) {
          $log.debug(`error : status = ${status}`, err);
        });
      return promise;
    }
    function upsertFileAndUpdateDataRecord(
      files,
      assetIds,
      overwrite,
      pluginName,
      group,
      key,
      data,
      version
    ) {
      let url = `${baseUrl}uploadFileAndUpdateData`;
      const fileOverwrite = overwrite ? true : false;
      let assets = [];
      let assetData = [];
      if (angular.isArray(files)) {
        if (angular.isArray(files) && assetIds.length === files.length) {
          url = `${baseUrl}uploadFilesAndUpdateData`;
          for (const [i, file] of files.entries()) {
            const asset = {
              type: 'file',
              group: `pluginData/${pluginName}`,
              id: assetIds[i],
            };
            assets.push(asset);
            assetData.push(Upload.rename(file, assetIds[i]));
          }
        } else {
          throw new Error('List of files and filenames do not match');
        }
      } else {
        assets = {
          type: 'file',
          group: `pluginData/${pluginName}`,
          id: assetIds,
        };
        assetData = Upload.rename(files, assetIds);
      }
      const promise = Upload.upload({
        url,
        method: 'POST',
        data: {
          assetData,
          data: Upload.json({
            asset: assets,
            overwrite: fileOverwrite,
            group,
            value: data,
            key,
            version,
          }),
        },
      });
      promise
        .progress(function (evt) {
          $log.debug(`progress: ${Number.parseInt((100 * evt.loaded) / evt.total)}%`);
        })
        .success(function (data) {
          $log.debug('Asset was added successfully.  Response: ', data);
          $rootScope.$broadcast('dataUpdated');
        })
        .error(function (err, status) {
          $log.debug(`error : status = ${status}`, err);
        });
      return promise;
    }
    function updateDataRecord(group, key, data, version, suppressAppSync) {
      const deferred = $q.defer();
      if (group) {
        const url = `${baseUrl}update`;
        const dataToSend = {
          group,
          value: data,
          version,
          suppressAppSync,
        };
        if (key) dataToSend.key = key;
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was updated successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }
    function addDataRecord(group, key, data, suppressAppSync) {
      const deferred = $q.defer();
      if (group) {
        const url = `${baseUrl}create`;
        const dataToSend = {
          group,
          value: data,
          suppressAppSync,
        };
        if (key) dataToSend.key = key;
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was added successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplised for getDataRecord');
      }
      return deferred.promise;
    }
    function removeDataRecord(group, key) {
      const deferred = $q.defer();
      if (group) {
        const url = `${baseUrl}remove`;
        const dataToSend = {
          group,
        };
        if (key) dataToSend.key = key;
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was removed successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }
    return {
      getDataRecord,
      removeDataRecord,
      addDataRecord,
      updateDataRecord,
      upsertFileAndUpdateDataRecord,
      upsertTemporaryFile,
    };
  },
]);

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobs } from '../../shared/jobs/store/thunks';
import { selectJobsProcessingKey } from './store/selectors';
export default function useJobs() {
  const jobsProcessingKey = selectJobsProcessingKey();
  const dispatch = useDispatch();
  const recheckJobs = useCallback(async () => {
    await new Promise((r) => setTimeout(r, 5000));
    dispatch(getJobs());
  }, [dispatch]);

  useEffect(() => {
    recheckJobs();
  }, [jobsProcessingKey, recheckJobs]);
}

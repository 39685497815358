import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/lead-generation/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenEvents`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { results, count, offset } = data;
    result = {
      records: results,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load lead gen events');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new')
    return { eventName: '', id: 'new', startDate: undefined, endDate: undefined, location: '' };
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenEvent`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return data.leadGenEvent;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load leadGenEvent.');
  }
};

const saveRecord = async (item) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/saveLeadGenEvent`,
      { ...item },
      {
        timeout: 10_000,
      }
    );
    return data.leadGenEvent;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save leadGenEvent.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeLeadGenEvent`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove leadGenEvent.');
  }
};

export default {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};

export const getLeadGenGroups = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenGroups`, {
      timeout: 10_000,
    });
    return data.groups;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load leadGenEvents.');
  }
};

export const getLeadGenIngestTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getLeadGenIngestTypes`, {
      timeout: 10_000,
    });
    return data.options;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load attendances.');
  }
};

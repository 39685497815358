import React, { useMemo } from 'react';
import ConfirmModal from '../../shared/modal/components/ConfirmModal';
import useConfirmModal from '../../shared/modal/hooks/useConfirmModal';
import { CmsContext } from '../definitions';
import { setConfig } from '../../store/rootReducer';
import { useDispatch } from 'react-redux';
const CmsReactContext = ({
  children,
  showLinkSearch,
  showAudienceModal,
  showImportDialog,
  notifyContentUpdated,
  cmsConfig,
  selectedSubtenant,
  selectedTenant,
  addReport,
  getReports,
}) => {
  const { confirm, confirmProps } = useConfirmModal();
  const dispatch = useDispatch();
  const cmsContext = useMemo(() => {
    dispatch(setConfig({ config: cmsConfig }));
    return {
      cmsConfig,
      selectedSubtenant,
      selectedTenant,
      confirm,
      showLinkSearch,
      showAudienceModal,
      notifyContentUpdated,
      showImportDialog,
      addReport,
      getReports,
    };
  }, [
    cmsConfig,
    selectedSubtenant,
    selectedTenant,
    showLinkSearch,
    showAudienceModal,
    showImportDialog,
    addReport,
    getReports,
    dispatch,
  ]);

  return (
    <CmsContext.Provider value={cmsContext}>
      {children}
      <ConfirmModal {...confirmProps} />
    </CmsContext.Provider>
  );
};

export default CmsReactContext;

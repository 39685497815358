import get from 'lodash/get';
import range from 'lodash/range';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useSheetModalUpload from '../../shared/cmsPage/hooks/useSheetModalUpload';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import { downloadLocalWebFile } from '../../shared/util/fileDownload';
import { navigateToParentPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import leadGenImportsApi, {
  getExternalLeadGenEventMembers,
  saveImportData,
} from '../api/leadGenImportsApi';
import leadGenResultsApi from '../api/leadGenResultsApi';
import { LeadGenEventConstants } from '../definitions';

import { getGroupsOptions, getScannedByOptions } from '../api/leadGenResultsApi';
const { pluginId, defaultPageId, parentPageTitle } = LeadGenEventConstants;

const ImportsCol = [
  { initialSort: 'ASC', label: 'ID', sortable: true, valueField: 'id' },
  { initialSort: 'ASC', label: 'Display Name', sortable: true, valueField: 'name' },
  { initialSort: 'ASC', label: 'Email Address', sortable: true, valueField: 'email' },
];

const ResultsCol = [
  ...ImportsCol,
  { initialSort: 'ASC', label: 'Scanned By', sortable: false, valueField: 'scannedBy' },
  {
    initialSort: 'ASC',
    label: 'Group',
    renderer: (item) => <span>{get(item, 'group.name', '')}</span>,
    sortable: false,
    valueField: 'userGroup',
  },
  {
    initialSort: 'ASC',
    label: 'Rating',
    renderer: (item) => {
      const count = toNumber(item.rating) || 0;
      return count === 0 ? (
        <span>No Rating</span>
      ) : (
        <div
          style={{ display: 'flex', flexGrow: 1, flexWrap: 'nowrap', justifyContent: 'flex-start' }}
        >
          {range(1, 6).map((i) => (
            <i
              key={`star-${i}`}
              className="fa-solid fa-star"
              style={{ color: i <= count ? 'gold' : 'grey' }}
            />
          ))}
        </div>
      );
    },
    sortable: true,
    valueField: 'rating',
  },
  {
    initialSort: 'ASC',
    label: 'Date/Time',
    renderer: (item) => <Timestamp date={item.dateScanned} />,
    sortable: true,
    valueField: 'dateScanned',
  },
];

const useLeadGenAttendeesListActions = ({
  leadGenId,
  resultType,
  handleClickShowAttendeeDetails,
  setShowExportModal,
  setShowList,
  ingestType = 'upload',
}) => {
  const dispatch = useDispatch();
  const downloadTemplate = useMemo(
    () => ({
      label: 'Download Template',
      onClick: async () => {
        await downloadLocalWebFile('lead-gen-import-template.csv');
      },
    }),
    []
  );
  const onSaveImportHandler = useCallback(
    async (data, clear = false) => {
      await saveImportData(leadGenId, data, clear);
      setShowList(false);
      await new Promise((r) => setTimeout(r, 50));
      setShowList(true);
    },
    [setShowList, leadGenId]
  );
  const { SheetModal, open } = useSheetModalUpload({
    onClear: () => {},
    onSaveButtons: [
      {
        onClick: async ({ compressedData }) => {
          await onSaveImportHandler(compressedData, false);
        },
        text: 'Upload - Add/Edit Existing Leads',
        variant: 'success',
      },
      {
        onClick: async ({ compressedData }) => {
          await onSaveImportHandler(compressedData, true);
        },
        text: 'Upload - Clear Existing Leads',
        variant: 'danger',
      },
    ],
    sheetProps: {
      fieldValidators: { id: 'truthy' },
      ignoreNoHeaderColumns: true,
      requiredHeaders: ['id', 'name', 'email'],
      transformRow: ({ id, email, name, ...rest }) => ({
        data: rest,
        email,
        id,
        leadGenId,
        name,
      }),
      uniqueFields: ['id'],
      uniqueHeaders: true,
    },
    title: 'Import Leads',
  });

  const exportResults = useMemo(
    () => ({ label: 'Export Results', onClick: async () => setShowExportModal(true) }),
    []
  );

  const back = useMemo(
    () => ({
      onClick: () => {
        dispatch(navigateToParentPage([pluginId, 'default', defaultPageId]));
      },
      pageName: 'Lead Geneneration Events',
    }),
    [parentPageTitle, dispatch, pluginId]
  );

  const additionalSearchFields = useMemo(
    () => [
      {
        getOptions: () => getScannedByOptions(leadGenId),
        key: 'scannedByAppUserId',
        placeholder: 'Scanned By',
        type: 'select',
      },
      {
        getOptions: () => getGroupsOptions(leadGenId),
        key: 'groupId',
        placeholder: 'Group',
        type: 'select',
      },
      { key: 'startDate', placeholder: 'Start Date', type: 'date' },
      { key: 'endDate', placeholder: 'End Date', type: 'date' },
    ],
    [leadGenId]
  );

  const viewDetails = useMemo(
    () => ({
      icon: 'fa-solid fa-magnifying-glass',
      label: 'View Details',
      onClick: async (item) => {
        handleClickShowAttendeeDetails(true, item);
      },
    }),
    [dispatch, handleClickShowAttendeeDetails]
  );

  const getExternalMembers = useCallback(
    async ({ reload }) => {
      const { errors = [] } = await getExternalLeadGenEventMembers(leadGenId);
      const count = errors?.length || 0;
      if (count) {
        dispatch(
          errorGrowl({
            groupId: LeadGenEventConstants.attendeesPageId,
            props: {
              message: `Found ${count} lead gen error${
                count > 1 ? 's' : ''
              } - click to download report`,
              csvDownload: {
                data: JSON.stringify(errors),
                filename: 'external-lead-gen-errors.csv',
              },
            },
          })
        );
      } else {
        await reload();
      }
    },
    [leadGenId, dispatch]
  );

  const { itemActions, columns, api, toolbarActions } = useMemo(() => {
    const obj =
      resultType === 'results'
        ? {
            api: leadGenResultsApi,
            columns: ResultsCol,
            itemActions: [viewDetails],
            toolbarActions: [exportResults],
          }
        : {
            api: leadGenImportsApi,
            columns: ImportsCol,
            itemActions: [],
            toolbarActions: [downloadTemplate],
          };

    if (resultType !== 'results') {
      if (ingestType === 'upload' || ingestType === 'both') {
        obj.toolbarActions.push({
          label: 'Import Members',
          onClick: open,
        });
      }
      if (ingestType === 'api' || ingestType === 'both') {
        obj.toolbarActions.push({
          label: 'External Import',
          onClick: getExternalMembers,
        });
      }
    }
    return obj;
  }, [resultType, open, ingestType, getExternalMembers, viewDetails]);

  return useMemo(
    () => ({
      SheetModal,
      additionalSearchFields,
      api,
      back,
      columns,
      itemActions,
      toolbarActions,
    }),
    [columns, back, itemActions, exportResults]
  );
};

export default useLeadGenAttendeesListActions;

import React from 'react';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import { validationIncludesRequired } from '../formUtils';

const FormInputFile = (props) => {
  const {
    label,
    defaultValue,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    fontWeight,
    className: providedClassName,
    ...inputAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ fontWeight: fontWeight }}>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <input
        defaultValue={defaultValue}
        type="file"
        className="form-control"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...register(fieldName || 'file', formFieldOptions)}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInputFile;

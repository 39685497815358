import { useCallback, useMemo, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';
const useWrapPageActions = (pageId, actions, reload, loaderParams) => {
  const { confirm } = useContext(CmsContext);
  const wrapHandler = useCallback(
    (handler) => {
      return (...args) => {
        handler(...args, {
          loaderParams,
          reload: () => reload(loaderParams),
          confirm,
        });
      };
    },
    [pageId, confirm, reload, loaderParams]
  );
  return useMemo(() => {
    return actions.map((a) => ({ ...a, onClick: a.onClick ? wrapHandler(a.onClick) : undefined }));
  }, [actions, wrapHandler]);
};

export default useWrapPageActions;

import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import PushNotificationEditorForm from './PushNotificationEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { PushNotificationConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import pushNotificationApi from '../api/pushNotificationApi';

const PushNotificationsEditorPage = (props) => {
  const { pushNotificationId, allowEdit, baseNotificationId } = props;
  const { item, loadItem, saveItem } = useItemPersistence(pushNotificationApi);
  useEffect(() => {
    if (pushNotificationId === 'new' && baseNotificationId)
      (async () => await loadItem(baseNotificationId))();
    else if (pushNotificationId) (async () => await loadItem(pushNotificationId))();
  }, [pushNotificationId, baseNotificationId, loadItem]);
  const form = useCmsForm(item);
  const viewOnly = pushNotificationId !== 'new' && !allowEdit;
  const rawActions = useItemEditActions(saveItem, form, PushNotificationConstants);
  const actions = viewOnly ? rawActions.filter((a) => a.label === 'Cancel') : rawActions;
  const titleDescription =
    pushNotificationId === 'new' && !baseNotificationId ? 'Add' : viewOnly ? 'View' : 'Copy';
  return (
    <CmsPage
      className="push-notification-editor-page"
      title={`${titleDescription} Push Notification`}
    >
      <PushNotificationEditorForm
        viewOnly={viewOnly}
        form={form}
        growlId={PushNotificationConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default PushNotificationsEditorPage;

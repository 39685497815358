import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { setLocation } from '../../../store';

const SaveOptionFooter = (props) => {
  const { onSave, fieldName } = props;
  const dispatch = useDispatch();
  const saveAudience = useCallback(
    () =>
      void dispatch(
        setLocation({
          location: 'modal.showSave',
          value: true,
          fieldName,
        })
      ),
    [dispatch, fieldName]
  );
  return (
    <div className="form-button-section">
      <Button variant="primary" onClick={saveAudience}>
        Yes, Save Audience
      </Button>
      <Button variant="cancel" onClick={onSave}>
        No Thanks
      </Button>
    </div>
  );
};

export default SaveOptionFooter;

import axios from 'axios';
import log from '../../logger';
import get from 'lodash/get';
import { downloadUrlBlob } from '../../shared/util/fileDownload';
const baseUrl = '/plugins/custom-pages/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getCustomPages`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { customPages, count, offset } = data;
    result = { numRecords: count, offset, records: customPages };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load customPages');
  }
  return result;
};

const saveRecord = async (item) => {
  try {
    const file = get(item, 'file[0]');
    const formData = new FormData();
    formData.append('customPageFile', file);
    const { data } = await axios.post(`${baseUrl}/upsertCustomPage`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { id: item.id, includesFile: !!file, isNew: item.id === 'new', name: item.name },
      timeout: 10_000,
    });
    return data.customPage;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save custom page.');
  }
};

const loadRecord = async (id) => {
  if (id === 'new') return { file: '', id: 'new', name: '' };
  try {
    const { data } = await axios.get(`${baseUrl}/getCustomPage`, {
      params: { id },
      timeout: 10_000,
    });
    return data.customPage;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load custom page.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/deleteCustomPage`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove customPage.');
  }
};

export const downloadCustomPage = async (item) => {
  try {
    await downloadUrlBlob(`${baseUrl}/downloadCustomPage`, item.name, { id: item.id });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove custom page.');
  }
};

export default { loadRecord, loadRecords, removeRecord, saveRecord };

import isFunction from 'lodash/isFunction';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import { updateQuizOptions, quizCreate } from '../api/quizzesApi';
export default function useQuizActions(pageConstants, pageInstanceConstance) {
  const dispatch = useDispatch();
  const { typeName, pluginId, defaultPageId } = pageConstants;
  const { defaultPageName } = pageInstanceConstance;
  const [forceRefresh, setForceRefresh] = useState(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const onFocus = useCallback(async () => {
    if (isFunction(forceRefresh)) await forceRefresh();
  }, [forceRefresh, setForceRefresh]);
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);
  const { confirm } = useContext(CmsContext);
  const archiveCheckbox = useMemo(
    () => ({
      label: 'Hide Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
      useSwitch: true,
    }),
    [includeArchived, setIncludeArchived]
  );
  const add = useMemo(
    () => ({
      label: `+ Add Quiz`,
      onClick: async () => {
        try {
          const newQuizId = await quizCreate();
          dispatch(
            navigateToPluginPage([pageConstants.pluginId, pageConstants.editorPageName, newQuizId])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open quiz creator.' }));
        }
      },
    }),
    [typeName, pluginId, dispatch, defaultPageId, setForceRefresh]
  );
  const refresh = useMemo(
    () => ({
      icon: 'fa solid fa-arrow-rotate-right',
      label: ``,
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to refresh.' }));
        }
      },
    }),
    [typeName, pluginId, dispatch, defaultPageId]
  );
  const archive = useMemo(
    () => ({
      icon: 'fa-solid fa-box-archive',
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      onClick: async (context, { reload }) => {
        try {
          let confirmResponse = true;
          if (!context.isArchived) {
            confirmResponse = await confirm({
              message: `Are you sure you want to archive ${context.title}`,
              title: 'Achive Quiz',
            });
          }
          if (confirmResponse) {
            await updateQuizOptions(context.id, { isArchived: !context.isArchived });
            await reload();
            dispatch(successGrowl({ groupId: defaultPageId, props: 'Successfully updated quiz.' }));
          }
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to update quiz.' }));
        }
      },
    }),
    [typeName, pluginId, defaultPageId, confirm, dispatch]
  );
  const edit = useMemo(
    () => ({
      disabled: (context) => context.status === 'removed',
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      onClick: async (context) => {
        try {
          dispatch(
            navigateToPluginPage([pageConstants.pluginId, pageConstants.editorPageName, context.id])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open quiz editor.' }));
        }
      },
    }),
    [pluginId, defaultPageId, dispatch, setForceRefresh]
  );
  const manageInstances = useMemo(
    () => ({
      disabled: (context) => context.status === 'removed',
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Instances',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, defaultPageName, context.id]));
      },
    }),
    [dispatch, pluginId, defaultPageId, defaultPageName]
  );
  const duplicate = useMemo(
    () => ({
      disabled: (context) => context.status === 'removed',
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      onClick: async (context, { reload }) => {
        try {
          await quizCreate(context.id);
          await reload();
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to duplicate quiz.' }));
        }
      },
    }),
    [pluginId, dispatch, defaultPageId]
  );
  return {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    includeArchived,
    manageInstances,
    refresh,
  };
}

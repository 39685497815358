import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'forms';
export const FormConstants = createPageConstants(pluginId, 'Form', {
  defaultDefault: 'formsListPage',
  editor: 'formBuilderPage',
});
export const FormInstanceConstants = createPageConstants(pluginId, 'Form Instance', {
  default: 'formInstancesPage',
  editor: 'formInstanceEditorPage',
});

import reduce from 'lodash/reduce';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';

const NumberInput = (props) => {
  const { fieldKey, validation = {}, label, setInput, value, isDisabled } = props;
  const [currentValue, setCurrentValue] = useState('');
  useEffect(() => void setCurrentValue(toString(value)), [value]);
  const inputProps = useMemo(
    () =>
      reduce(
        validation,
        (a, v, k) => ({
          ...a,
          ...(k === 'min' && {
            min: v,
          }),
          ...(k === 'max' && {
            max: v,
          }),
        }),
        {}
      ),
    [validation]
  );
  const handleSetInputValue = useCallback(() => {
    setInput(fieldKey, toNumber(currentValue));
  }, [setInput, fieldKey, currentValue, setCurrentValue]);
  const handleChange = useCallback((e) => void setCurrentValue(e.target.value), []);
  return (
    <FormControl
      onBlur={handleSetInputValue}
      value={isDisabled ? '' : currentValue}
      type="number"
      disabled={isDisabled}
      onChange={handleChange}
      placeholder={label}
      {...inputProps}
    />
  );
};

export default memo(NumberInput);

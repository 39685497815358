export const options = [
  { label: 'Grid View', value: 'grid' },
  { label: 'List View', value: 'list' },
];

export const home = [{ label: 'Grid View', value: 'grid' }];
export const links = [{ label: 'Links View', value: 'links' }];
export const menu = [{ label: 'Menu View', value: 'menu' }];

export const presets = {
  home: {
    defaultView: 'grid',
    useImg: true,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: [
      'category',
      'dateFilter',
      'favs',
      'liveEvent',
      'lockImg',
      'lockView',
      'pastEvents',
      'search',
      'viewSelector',
    ],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: true,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    collectionTitleDisplay: false,
    wysDisplay: false,
  },
  standard: {
    defaultView: 'grid',
    useImg: true,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: ['pastEvents', 'dateFilter', 'liveEvent'],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: false,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    collectionTitleDisplay: false,
    wysDisplay: false,
  },
  agenda: {
    defaultView: 'list',
    useImg: true,
    hidden: [],
    disabled: ['lockView'],
    collectionTitle: 'Title',
    displayToolbar: true,
    toolSearch: true,
    toolFavorite: false,
    toolViewSelect: true,
    filterLive: true,
    filterCategory: false,
    filterDate: true,
    filterPast: true,
    collectionTitleDisplay: false,
    wysDisplay: false,
  },
  links: {
    defaultView: 'links',
    useImg: false,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: [
      'category',
      'dateFilter',
      'toolBar',
      'favs',
      'liveEvent',
      'lockImg',
      'lockView',
      'pastEvents',
      'search',
      'viewSelector',
    ],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: false,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    collectionTitleDisplay: false,
    wysDisplay: false,
  },
  icons: {
    defaultView: 'grid',
    useImg: true,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: [
      'category',
      'dateFilter',
      'favs',
      'liveEvent',
      'lockImg',
      'lockView',
      'pastEvents',
      'search',
      'toolBar',
      'viewSelector',
    ],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: false,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    collectionTitleDisplay: false,
    wysDisplay: true,
  },
  menu: {
    useImage: false,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: [
      'category',
      'dateFilter',
      'toolBar',
      'favs',
      'liveEvent',
      'lockImg',
      'lockView',
      'pastEvents',
      'search',
      'viewSelector',
    ],
    displayToolbar: false,
    toolSearch: false,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    collectionTitleDisplay: false,
    wysDisplay: true,
  },
};

import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'menu-manager';
export const MenuConstants = createPageConstants(
  pluginId,
  'Menu',
  {
    defaultDefault: 'menuListPage',
    editor: 'menuEditorPage',
    publish: 'menuItemPublishPage',
  },
  'Menus'
);
export const MenuEditorConstants = createPageConstants(pluginId, 'Menu Item', {
  default: 'menuEditorPage',
  editor: 'menuItemEditorPage',
  parentDefault: 'menuListPage',
  publish: 'menuItemPublishPage',
});
export const MenuPublishPageConstants = createPageConstants(pluginId, 'Menu', {
  default: 'menuItemPublishPage',
});
export const EMPTY_MENU_REVISION = {
  name: '',
  label: '',
};
export const EMPTY_MENU_ITEM_REVISION = {
  label: '',
};

import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { exitPreview } from '../../../store';

const PreviewFooter = ({ fieldName }) => {
  const dispatch = useDispatch();
  const handleBack = useCallback(
    () =>
      void dispatch(
        exitPreview({
          fieldName,
        })
      ),
    [dispatch, fieldName]
  );
  return (
    <Button variant="primary" onClick={handleBack}>
      Back
    </Button>
  );
};

export default PreviewFooter;

import { createSlice } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';
import invoke from 'lodash/invoke';
import log from '../../../../../logger';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import toNumber from 'lodash/toNumber';
import update from 'lodash/update';
import { useDispatch } from 'react-redux';
import { conditions, defaultLogic, operators } from '../query-builder/definitions';
import { logicFromSavedAudience, updateLogic } from './helpers';
import {
  getAudienceRecords,
  getPrimaryOptions,
  getSecondaryOptions,
  getUserOptions,
  getUsers,
  saveAndPublishAudience,
} from './thunks';
export const reducerName = 'audienceSelector';
const initialState = {
  rootOptions: {},
  disableSavedAudiences: true,
  audiences: [],
};
const fieldInitialState = {
  audienceId: '0',
  logic: {
    operator: 'AND',
    rules: [],
  },
  newAudienceName: '',
  oldLogic: undefined,
  previewUsers: [],
  modal: {
    buildQuery: false,
    existingAudienceValid: true,
    requireNew: false,
    showModal: false,
    showPreview: false,
    showSave: false,
    showSaveOption: false,
  },
  preview: {
    count: 0,
    displayedUsers: [],
    isLoading: false,
    pageIndex: 0,
    rowsToShow: 10,
    searchChanged: false,
    searchText: '',
    updating: true,
    userTypeMap: {},
    users: [],
  },
};
const wrapper = (callback) => {
  return (state, action) => {
    const fieldName = get(action, 'payload.fieldName');
    const callbacks = {
      ...(fieldName && {
        setLogic: (str = '', val) => set(state, `${fieldName}.logic${str ? `.${str}` : str}`, val),
        getLogic: (str = '', def = undefined) =>
          get(state, `${fieldName}.logic${str ? `.${str}` : str}`, def),
        updateLogic: (str = '', cb) =>
          update(state, `${fieldName}.logic${str ? `.${str}` : str}`, cb),
        setFieldState: (str = '', val) => set(state, `${fieldName}${str ? `.${str}` : str}`, val),
        removeLogicFields: (str = '', fields) => {
          omit(
            state,
            fields.map((f) => `${fieldName}.logic${str ? `.${str}` : str}.${f}`)
          );
        },
        updateModal: (keyValuePairs = {}) => {
          update(state, `${fieldName}.modal`, (current = {}) => ({ ...current, ...keyValuePairs }));
        },
        updatePreview: (keyValuePairs = {}) => {
          update(state, `${fieldName}.preview`, (current = {}) => ({
            ...current,
            ...keyValuePairs,
          }));
        },
        processLogic: (updateLocation = '', newLogic = undefined) => {
          const updatedLogic = updateLogic(
            newLogic ? newLogic : get(state, `${fieldName}.logic`, {}),
            get(state, 'rootOptions', {}),
            updateLocation
          );
          set(state, `${fieldName}.logic`, updatedLogic);
          return updatedLogic;
        },
      }),
    };
    return callback(state, action.payload, callbacks, action);
  };
};

export const audienceSelectorSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFieldInitState: wrapper((st, { fieldName, requireNew, logic }, { processLogic }) => {
      if (!has(st, fieldName)) st[fieldName] = fieldInitialState;
      processLogic('', logic && !requireNew ? logic : defaultLogic);
    }),
    resetLogic: wrapper((st, { fieldName }, { processLogic }) => {
      processLogic('', get(st, `${fieldName}.oldLogic`, defaultLogic));
    }),
    exitPreview: wrapper((st, payload, { updateModal }) => {
      updateModal({
        showPreview: false,
        preview: fieldInitialState.preview,
      });
    }),
    createNewCondition: wrapper(
      (
        st,
        { location, isCompound },
        { updateLogic, getLogic, processLogic, removeLogicFields }
      ) => {
        const condition = isCompound
          ? {
              logic: {
                operator: operators[0].name,
                rules: [],
              },
            }
          : {
              condition: conditions[0].value,
              field: first(Object.keys(st.rootOptions).sort((a, b) => a.localeCompare(b))),
              data: [],
            };
        set(condition, 'isNew', true);
        const loc = location ? `${location}.rules` : 'rules';
        updateLogic(loc, (c = []) =>
          [...c, condition].sort((a, b) => {
            a = has(a, 'logic');
            b = has(b, 'logic');
            if ((!a && !b) || (a && b)) return 0;
            return a ? 1 : -1;
          })
        );
        const setterLocation = `${loc}[${findIndex(getLogic(loc, []), 'isNew')}]`;
        removeLogicFields(setterLocation, [`isNew`]);
        processLogic(setterLocation);
      }
    ),
    updateCompoundConditionType: wrapper((st, { location, type }, { processLogic, setLogic }) => {
      setLogic(location ? `${location}.operator` : 'operator', type);
      processLogic(location);
    }),
    removeCondition: wrapper((st, { location, fieldName }, { processLogic }) => {
      if (location.endsWith('.logic')) {
        location = location.split('.logic').slice(0, -1).join('.logic');
      }

      const idx = toNumber(location.substr(-2, 1));
      invoke(st, `${fieldName}.logic.${location.slice(0, -3)}.splice`, idx, 1);
      processLogic(location);
    }),
    updateSimpleCondition: wrapper(
      (st, { location, updateRule }, { processLogic, updateLogic }) => {
        updateLogic(location, (c = {}) => (c.field === updateRule.field ? c : updateRule));
        processLogic(location);
      }
    ),
    updateRuleDataOption: wrapper((st, { location, option }, { updateLogic, processLogic }) => {
      option = omitBy(option, isUndefined);
      const { id, queryParams = {} } = option;
      updateLogic(`${location}.data[0]`, (c = {}) => ({
        ...(c.id === id && c),
        ...option,
        logicParams: { ...(c.id === id && c.logicParams), ...queryParams },
      }));
      processLogic(location);
    }),
    updateRuleSubOption: wrapper(
      (st, { location, subOption }, { removeLogicFields, updateLogic, setLogic, processLogic }) => {
        const { queryParams: p = {} } = subOption;
        // console.log('updateRuleSubOption', { location, subOption });
        removeLogicFields(`${location}.data[0].logicParams`, ['selectAllSubOptions']);
        updateLogic(`${location}.data[0].logicParams`, (c = {}) => ({ ...c, ...p }));
        setLogic(`${location}.data[0].data`, [omitBy(subOption, isUndefined)]);
        processLogic(location);
        // console.log('LOGIC updateRuleSubOption', getLogic(`${location}.data[0].logicParams`, 'no logic'))
      }
    ),
    updateRuleFieldLogicType: wrapper(
      (st, { location, field }, { removeLogicFields, setLogic, processLogic }) => {
        setLogic(`${location}.data[0].logicParams.fieldLogicType`, field.value);
        removeLogicFields(location, ['condition']);
        removeLogicFields(`${location}.data[0].logicParams`, ['condition']);
        processLogic(location);
      }
    ),
    updateRuleLogicParamsField: wrapper(
      (st, { location, field, value }, { setLogic, processLogic }) => {
        setLogic(`${location}.data[0].logicParams.${field}`, value);
        processLogic(location);
      }
    ),
    updateRuleCondition: wrapper((st, { location, condition }, { setLogic, processLogic }) => {
      setLogic(`${location}.condition`, condition);
      setLogic(`${location}.data[0].logicParams.condition`, condition);
      processLogic(location);
    }),
    setSavedAudience: wrapper(
      (
        st,
        { audienceId, audienceIndex, audience },
        { setFieldState, processLogic, updateModal }
      ) => {
        setFieldState(`audienceId`, audienceId || audience.target_audience_id);
        if (!isNumber(audienceIndex) && audience) {
          audienceIndex = st.audiences.push(audience) - 1;
        }
        if (isNumber(audienceIndex)) {
          updateModal({
            existingAudienceValid: true,
          });

          try {
            const savedAudienceLogic = logicFromSavedAudience(
              get(st, `audiences[${audienceIndex}]`, {})
            );
            processLogic('', savedAudienceLogic);
          } catch (e) {
            log.error('Error Parsing Logic', e);
          }
        } else {
          updateModal({
            existingAudienceValid: false,
          });
          processLogic('', defaultLogic);
        }
      }
    ),
    setShowModal: wrapper(
      (
        st,
        { showModal, savingLogic = false, fieldName },
        { updateModal, processLogic, setFieldState }
      ) => {
        if (showModal === false && !savingLogic) processLogic('', st.oldLogic);
        if (!get(st, `${fieldName}.modal.showModal`) && showModal) {
          updateModal({
            showPreview: false,
            showSave: false,
            showSaveOption: false,
          });
          const rules = get(st, `${fieldName}.oldLogic.rules`, []);
          const { field, condition, data = [] } = get(rules, '[0]', {}) || {};
          const savedAudienceId = get(data, '[0].id');
          if (
            field === 'Saved Audience' &&
            rules.length === 1 &&
            condition === '=' &&
            savedAudienceId
          ) {
            setFieldState('audienceId', savedAudienceId);
            updateModal({
              buildQuery: false,
            });
          } else {
            updateModal({
              buildQuery: true,
            });
          }
        }

        updateModal({
          showModal,
        });
      }
    ),
    setOldLogic: wrapper((st, { oldLogic: logic }, { processLogic, setFieldState }) => {
      setFieldState('oldLogic', processLogic('', logic));
    }),
    setLocation: wrapper((st, { location, value }, { processLogic, setFieldState }) => {
      setFieldState(location, value);
      if (location.includes('logic')) processLogic(location);
    }),
  },
  extraReducers(builder) {
    const fulfill = (funcName, cb) => builder.addCase(funcName.fulfilled, cb);
    const pending = (funcName, cb) => builder.addCase(funcName.pending, cb);
    const rejected = (funcName, cb) => builder.addCase(funcName.rejected, cb);
    const stndRejected = (functions) =>
      functions.forEach((f) =>
        rejected(f, (st, { error, type }) => {
          log.info(`Error in ${type}\n\n`);
          log.info({
            error,
          });
        })
      );

    const optsCallback = wrapper((st, { items, loc, ignore }, { processLogic }) => {
      if (!ignore) {
        set(st, `${loc}.hasQueried`, true);
        items.forEach((itm) => set(st, `${loc}.opts.${itm.id}`, itm));
        processLogic('');
      }
    });
    fulfill(getPrimaryOptions, optsCallback);
    fulfill(getSecondaryOptions, optsCallback);
    fulfill(
      getUserOptions,
      wrapper((st, { options, ignore, disableSavedAudiences }) => {
        if (!ignore) {
          if (typeof disableSavedAudiences === 'boolean') {
            st.disableSavedAudiences = disableSavedAudiences;
          }
          if (options) st.rootOptions = options;
        }
      })
    );
    pending(getUsers, (st, { fieldName }) => {
      set(st, `${fieldName}.preview.loading`, false);
    });
    fulfill(
      getUsers,
      wrapper((st, { displayedUsers, count }, { updatePreview }) => {
        updatePreview({
          count,
          loading: false,
          update: false,
          searchChanged: false,
          displayedUsers,
        });
      })
    );
    rejected(getUsers, (st, { error, type, fieldName }) => {
      log.info(`Error in ${type}\n\n`);
      log.info({
        error,
      });
      set(st, `${fieldName}.preview.loading`, false);
    });
    fulfill(getAudienceRecords, (st, { payload: { records = [] } }) => {
      st.audiences = records;
    });
    fulfill(saveAndPublishAudience, (st, { payload }) => {
      log.info('SAVE AND PUBLISH AUDIENCE', payload);
    });
    stndRejected([
      getAudienceRecords,
      getPrimaryOptions,
      getSecondaryOptions,
      getUserOptions,
      saveAndPublishAudience,
    ]);
  },
});
window.audienceBuilder = (opts) => {
  log.info('Audience Builder', opts);

  const dispatch = useDispatch();
  return dispatch(setShowModal(opts));
};
export const {
  createNewCondition,
  exitPreview,
  removeCondition,
  resetLogic,
  setFieldInitState,
  setLocation,
  setOldLogic,
  setSavedAudience,
  setShowModal,
  updateCompoundConditionType,
  updateRuleCondition,
  updateRuleDataOption,
  updateRuleFieldLogicType,
  updateRuleLogicParamsField,
  updateRuleSubOption,
  updateSimpleCondition,
} = audienceSelectorSlice.actions;
export default audienceSelectorSlice.reducer;

import { Box, Divider } from '@mui/material';
import React, { Fragment, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useMapData from '../hooks/useMapData';
import GoogleMapComponent from './GoogleMapComponent';
import KmlImports from './KmlImports';
import MapsTable from './MapsTable';

const MapsDetailPage = ({ mapName = 'combined-map', mapId }) => {
  const mapRef = useRef(null);
  const {
    state,
    originalState,
    setState,
    tableConfigs,
    resetState,
    handleSave,
    info,
    markerInfo,
    hasCenterChanged,
    hasZoomChanged,
  } = useMapData(mapId);
  if (mapId === 'new' && !state.title) {
    setState({ ...state, title: 'New Map' });
  }
  return (
    <CmsPage title="Maps">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1rem;',
          }}
        >
          <label style={{ minWidth: '6em', fontSize: '18px', fontWeight: '700' }}>Map Title:</label>
          <input
            type="text"
            className="form-control"
            value={state.title || ''}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
        </Box>
        <GoogleMapComponent
          mapRef={mapRef}
          setState={setState}
          state={state}
          originalState={originalState}
          mapName={mapName}
          mapId={mapId}
          hasCenterChanged={hasCenterChanged}
          hasZoomChanged={hasZoomChanged}
        />
        {tableConfigs.map((tbl, index) => (
          <Fragment key={`${index}-${tbl.title}`}>
            {index === 1 && (
              <Box>
                <Divider sx={{ borderBottomWidth: 2, my: '2rem', color: 'black' }} />
                <KmlImports mapRef={mapRef} state={state} setState={setState} mapName={mapName} />
              </Box>
            )}
            <MapsTable
              {...tbl}
              state={state}
              setState={setState}
              mapName={mapName}
              info={info}
              markerInfo={markerInfo}
              mapId={mapId}
            />
          </Fragment>
        ))}
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="cancel" onClick={resetState}>
            Cancel
          </Button>
        </Box>
      </Box>
    </CmsPage>
  );
};

export default MapsDetailPage;

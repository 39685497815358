import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { WorkflowConstants } from '../definitions';

export default function useWorkflowActions(pageConstants) {
  const { pluginId } = pageConstants;
  const dispatch = useDispatch();

  const logs = useMemo(
    () => ({
      label: 'View Logs',
      onClick: ({ machineName }) => {
        dispatch(navigateToPluginPage([pluginId, WorkflowConstants.logsPageId, machineName]));
      },
    }),
    [dispatch, pluginId, WorkflowConstants]
  );

  return {
    logs,
  };
}

export const CohortConstants = {
  pageTitle: 'Cohorts',
  pluginId: 'cohorts',
  defaultPageName: 'default',
  defaultPageId: 'cohort-list',
  editorPageName: 'cohortEditor',
  editorPageId: 'cohort-editor',
  itemsPageName: 'cohortMatchesPage',
  itemsPageId: 'cohort-matches-page',
  typeName: 'Cohort',
};
export const EMPTY_COHORT = {
  name: '',
};

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/quizzes/api';
export const updateQuizOptions = async (id, quizOptions) => {
  try {
    await axios.post(`${baseUrl}/updateQuizOptions`, { ...quizOptions, id, timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove quiz.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;
    try {
      const {
        data: { quizzes, count, offset },
      } = await axios.get(`${baseUrl}/getQuizzes`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10_000,
      });
      result = { numRecords: count, offset, records: quizzes };
    } catch (e) {
      log.error(e);
      throw new Error('Failed to load quizzes');
    }
    return result;
  };

const generateQuizzesApi = (includeArchived) => {
  const quizzesApi = { loadRecords: loadRecordsWrapper(includeArchived) };
  return quizzesApi;
};

export const loadRecord = async (id) => {
  if (id === 'new') throw new Error('Cannot load new quiz');

  try {
    const { data } = await axios.get(`${baseUrl}/getQuiz`, { params: { id }, timeout: 10_000 });
    let parsedInclude;
    try {
      parsedInclude =
        data &&
        data.quiz &&
        data.quiz.included &&
        data.quiz.included.split(',').reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
      data.quiz.included = parsedInclude ? parsedInclude : {};
    } catch {
      parsedInclude = {};
    }
    return data.quiz;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load quiz.');
  }
};

export const quizCreate = async (dupeId) => {
  try {
    const endpoint = dupeId ? 'duplicateQuiz' : 'quizCreate';
    const { data } = await axios.post(`${baseUrl}/${endpoint}`, { timeout: 10_000, dupeId });
    return data.quizId;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load quiz.');
  }
};

export const saveInstance = async (quizId, name) => {
  try {
    const {
      data: { quizInstance },
    } = await axios.post(`${baseUrl}/saveInstance`, { params: { name, quizId }, timeout: 10_000 });
    return quizInstance;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load quiz.');
  }
};
export default generateQuizzesApi;

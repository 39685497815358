import React, { useState } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { MenuEditorConstants } from '../definitions';
import MenuItemEditor from './MenuItemEditor';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const MenuItemEditorPage = (props) => {
  const { editorPageId } = MenuEditorConstants;
  const { menuId, itemId } = props;
  const { pageTitle, onItemChange } = useEditPageTitle(itemId, 'Menu Item', 'label');
  const [parentPageTitle, setParentPageTitle] = useState('');
  const { pluginId } = MenuEditorConstants;
  return (
    <>
      <BreadcrumbLink
        parentElement={`Menu: `}
        pluginId={pluginId}
        parentPageTitle={parentPageTitle}
        parentPageName={'menuEditorPage'}
        parentPageId={'menu-editor-page'}
        parentPageArgs={[menuId]}
      />
      <CmsPage title={`${pageTitle} for ${parentPageTitle}`}>
        <CmsItemEditSection pageId={editorPageId}>
          <MenuItemEditor
            menuId={menuId}
            itemId={itemId}
            onItemChange={onItemChange}
            growlId={editorPageId}
            changeTitle={setParentPageTitle}
          />
        </CmsItemEditSection>
      </CmsPage>
    </>
  );
};

export default MenuItemEditorPage;

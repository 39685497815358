(function () {
  angular.module('sidebar-controller', ['config-service']).controller('SideBarCtrl', [
    '$state',
    '$scope',
    'configService',
    '$sce',
    'pluginService',
    '$log',
    'navigationService',
    '$location',
    function (
      $state,
      $scope,
      configService,
      $sce,
      pluginService,
      $log,
      navigationService,
      $location
    ) {
      let url;
      $scope.init = function () {
        url = $location.path();
      };

      configService.getConfig().then(function (config) {
        $scope.sideMenuItems = [];
        let dividerName = '';
        setupPreview(config);
        for (let i = 0; i < config.sideMenu.length; i++) {
          const menuItem = angular.copy(config.sideMenu[i]);

          if (menuItem.navigation && menuItem.navigation.state) {
            menuItem.uiSref = menuItem.navigation.state;
            if (menuItem.navigation.stateParams) {
              menuItem.uiSref += `(${JSON.stringify(menuItem.navigation.stateParams)})`;
            }
          }
          $scope.sideMenuItems.push(menuItem);

          if (menuItem.isDivider) {
            dividerName = menuItem.text;
          }
          if (!$scope.sideMenuItems[i].isDivider) {
            $scope.sideMenuItems[i].menuParent = dividerName;
          }
        }

        const urlCut1 = url.replace('/main/plugin/', '');
        const urlCut2 = urlCut1.split('/')[0];
        const urlCut3 = url.replace('/main/plugin/' + urlCut2, '');
        const urlCut4 = urlCut3.split('/').join('');

        switch (urlCut2) {
          case '': {
            switch (urlCut4) {
              case 'main': {
                $scope.idSelectedItem = $scope.sideMenuItems[1];
                break;
              }
              case 'mainmanage-groups': {
                sideMenuSearch('manage-groups');
                break;
              }
            }
            break;
          }
          case urlCut2: {
            switch (urlCut4) {
              case 'newsletter': {
                sideMenuSearch('newsletter');
                break;
              }
              case 'default': {
                for (let o = 0; o < $scope.sideMenuItems.length; o++) {
                  if (
                    typeof $scope.sideMenuItems[o].uiSref == 'string' &&
                    $scope.sideMenuItems[o].uiSref.includes(urlCut2) &&
                    $scope.sideMenuItems[o].uiSref.includes(urlCut4)
                  ) {
                    $scope.idSelectedItem = $scope.sideMenuItems[o];
                  }
                }
                break;
              }
            }
            break;
          }
        }

        $scope.navigateToMenuItem = function (menuItem) {
          $scope.idSelectedItem = menuItem;
          navigationService.navigateToMenuItem(menuItem);
        };
      });

      function setupPreview(config) {
        $scope.showPreview = config.canPreviewAppInCMS && !config.cmsOptions.previewInMenu;
        $scope.previewSchemes = config.schemes;
      }

      $scope.titleClick = function (menuItem) {
        const currentMenu = menuItem.text;
        const currentMenuTitle = document.getElementById(currentMenu);
        const lastMenuChild = currentMenuTitle.lastElementChild;
        lastMenuChild.classList.toggle('spin');
        const selectedItems = document.getElementsByClassName(currentMenu);
        for (const selectedItem of selectedItems) {
          selectedItem.classList.toggle('hide');
        }
      };

      function sideMenuSearch(menuName) {
        for (let x = 0; x < $scope.sideMenuItems.length; x++) {
          if (
            typeof $scope.sideMenuItems[x].uiSref == 'string' &&
            $scope.sideMenuItems[x].uiSref.includes(menuName)
          ) {
            $scope.idSelectedItem = $scope.sideMenuItems[x];
          }
        }
      }
    },
  ]);
})();

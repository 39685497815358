import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
const MenuItemTitle = ({ menuItem, menuId }) => {
  const { label } = menuItem;
  const dispatch = useDispatch();
  const navigateToChild = useCallback(() => {
    dispatch(navigateToPluginPage(['menu-manager', 'menuItemEditorPage', menuId, menuItem.id]));
  }, [dispatch, menuId, menuItem]);
  return (
    <>
      <a onClick={navigateToChild}>{label}</a>
    </>
  );
};

export default MenuItemTitle;

import { useCallback, useState } from 'react';

const useItemEditorModal = (typeName, labelField, itemApi, FormComponent) => {
  const defaultProps = {
    typeName,
    labelField,
    visible: false,
    itemApi,
    FormComponent,
  };
  const [editorModalProps, setEditorModalProps] = useState(defaultProps);
  const showItemEditorModal = useCallback(
    (item, allowClean) => {
      return new Promise((resolve) => {
        setEditorModalProps((prevState) => ({
          ...prevState,
          visible: true,
          item,
          onClose: (item) => {
            setEditorModalProps(defaultProps);
            resolve(item);
          },
          allowClean,
        }));
      });
    },
    [defaultProps, setEditorModalProps]
  );
  return {
    showItemEditorModal,
    editorModalProps,
  };
};

export default useItemEditorModal;

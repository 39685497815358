import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
const useLoadSubListItems = (pageId, parentItemId, loadSubItems) => {
  const dispatch = useDispatch();
  const reload = useCallback(async () => {
    try {
      await loadSubItems(parentItemId);
    } catch (e) {
      dispatch(errorGrowl({ groupId: pageId, props: e.message }));
    }
  }, [loadSubItems, pageId, parentItemId, dispatch]);
  useEffect(() => {
    (async () => reload())();
  }, [reload]);
  return reload;
};

export default useLoadSubListItems;

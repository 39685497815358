import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { AppUserConstants } from '../definitions';
import useModifiedItemPersistance from '../hooks/useModifiedItemPersistance';
import AppUserEditorForm from './AppUserEditorForm';
const AppUsersEditorPage = ({ appUserId }) => {
  const { item, saveItem } = useModifiedItemPersistance(appUserId);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, AppUserConstants);
  return (
    <CmsPage title={`${appUserId === 'new' ? 'Add' : 'Edit'} User`}>
      <AppUserEditorForm
        appUserId={appUserId}
        form={form}
        growlId={AppUserConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default AppUsersEditorPage;

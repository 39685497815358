import React, { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

const LinkAccordionToggle = (props) => {
  const { eventKey, label } = props;
  const { activeEventKey } = useContext(AccordionContext);
  const onClick = useAccordionButton(eventKey);
  const linkLabel = activeEventKey === eventKey ? `Hide ${label}` : label;
  return (
    <div className="link-accordion-toggle">
      <a onClick={onClick}>{linkLabel}</a>
    </div>
  );
};

export default LinkAccordionToggle;

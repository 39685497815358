import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'custom-plugins/iuoe/itrs';
export const ItrsCourseConstants = createPageConstants(pluginId, 'ITRS Course', {
  defaultDefault: 'itrsCourseListPage',
  editor: 'itrsCourseCreatePage',
  view: 'itrsCourseViewPage',
});
export const ItrsSessionConstants = createPageConstants(pluginId, 'ITRS Session', {
  default: 'itrsSessionViewPage',
  editor: 'itrsSessionCreatePage',
  view: 'itrsSessionViewPage',
});

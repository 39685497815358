import { useJsApiLoader } from '@react-google-maps/api';
import { selectMapConfig } from '../../../../store/rootSelectors';
import { useState } from 'react';
export default function useLoadMaps(id = 'default-cmb-google-map') {
  const {
    key: googleMapsApiKey,
    libraries: librariesString = 'places',
    apiVersion: version = 'weekly',
  } = selectMapConfig();
  const [libraries] = useState(librariesString.split(','));

  const { isLoaded, loadError } = useJsApiLoader({
    id,
    googleMapsApiKey,
    version,
    libraries: libraries,
    fields: ['geometry', 'formatted_address'],
  });

  return { isLoaded, loadError, googleMapsApiKey };
}

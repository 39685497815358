import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import log from '../../logger';
import { EMPTY_SORT } from '../../shared/itemTable/definitions';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
const useMigrateMenusAction = (pageId, menuApi) => {
  const dispatch = useDispatch();
  const [canMigrateCheckPending, setCanMigrateCheckPending] = useState(false);
  const [migrationPending, setMigrationPending] = useState(false);
  const [canMigrate, setCanMigrate] = useState(false);
  const { loadRecords, checkCanMigrate, migrate } = menuApi;
  const { selectedSubtenant, confirm } = useContext(CmsContext);
  const processMigrate = useCallback(async () => {
    setCanMigrateCheckPending(true);
    let canMigrate = false;
    try {
      canMigrate = await checkCanMigrate();
    } catch (e) {
      log.error(e);
    } finally {
      setCanMigrateCheckPending(false);
    }

    setCanMigrate(canMigrate);
  }, [selectedSubtenant, checkCanMigrate, setCanMigrate]);

  useEffect(() => {
    processMigrate();
  }, [processMigrate]);
  const confirmMigrate = useCallback(async () => {
    const { numRecords } = await loadRecords({
      limit: 1,
      offset: 0,
      sort: EMPTY_SORT,
    });
    let warning;
    if (numRecords > 0) {
      warning =
        'This action will remove all menus previously created in this module and replace them with those defined in the legacy navigation manager.  This operation cannot be undone.';
    }
    return await confirm({
      title: 'Migrate Menus',
      message: 'Are you sure you want to migrate menus from the legacy menu manager?',
      warning,
    });
  }, [loadRecords]);
  const migrateMenus = useMemo(
    () => ({
      label: 'Migrate Menus',
      visible: true,

      onClick: async ({ reload }) => {
        const confirmation = await confirmMigrate();
        if (confirmation) {
          try {
            setMigrationPending(true);
            await migrate();
            dispatch(
              successGrowl({
                groupId: pageId,
                props: 'Menus have been successfully migrated.',
              })
            );
            reload();
          } catch (e) {
            log.error(e);
            dispatch(errorGrowl({ groupId: pageId, props: 'Failed to migrate menus' }));
          } finally {
            setMigrationPending(false);
          }
        }
      },
    }),
    [confirmMigrate, migrate, canMigrate, pageId, dispatch, confirm]
  );
  return {
    migrateMenus,
    requestPending: migrationPending || canMigrateCheckPending,
  };
};

export default useMigrateMenusAction;

import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import { validationIncludesRequired } from '../formUtils';

const SelectSwitch = ({
  label,
  fieldName,
  placeholder,
  formFieldOptions,
  formContext,
  fieldWidth,
  className: providedClassName,
  options,
  updateView,
  fontWeight,
}) => {
  const { formState, control } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ fontWeight }}>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      <Controller
        name={fieldName}
        control={control}
        rules={formFieldOptions}
        render={({ field }) => (
          <select
            className="form-control"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateView(e.target.value);
            }}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {(options || [])?.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        )}
      />

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default SelectSwitch;

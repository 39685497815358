import { Box } from '@mui/material';
import { GoogleMap, KmlLayer, Marker } from '@react-google-maps/api';
import React from 'react';
import { upsertMarkerInfo } from '../../api/mapsApi';
import MapStats from '../MapStats';
import AddMarker from './components/AddMarker';
import useLoadMaps from './hooks/useLoadMaps';
import usePositioning from './hooks/usePositioning';

const GoogleMapComponent = ({
  mapRef,
  state,
  originalState,
  setState,
  hasCenterChanged,
  hasZoomChanged,
  mapId,
}) => {
  const { isLoaded, loadError, googleMapsApiKey } = useLoadMaps();
  const { mapCallbacks } = usePositioning(state, originalState, setState, isLoaded, mapRef);

  const onAddMarker = async (newMarker) => {
    newMarker.data = JSON.parse(JSON.stringify(newMarker));
    await upsertMarkerInfo(mapId, newMarker);
    setState((st) => ({
      ...st,
      markers: [...st.markers, newMarker],
    }));
  };

  const lat = state.center.lat;
  const lng = state.center.lng;

  const onKmlClick = () => {};

  return isLoaded ? (
    <Box sx={{ width: '100%' }}>
      <AddMarker
        markers={state.markers || []}
        onAddMarker={onAddMarker}
        googleMapsApiKey={googleMapsApiKey}
        options={{
          types: ['cities'],
          fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
        }}
      />
      <GoogleMap
        mapContainerClassName="angular-google-map-container"
        center={{ lat, lng }}
        zoom={state.zoom}
        {...mapCallbacks}
      >
        {state.markers.map((marker) => {
          const lat = marker.latitude || marker.lat || marker?.data?.latitude || marker?.data?.lat;
          const lng =
            marker.longitude || marker.lng || marker?.data?.longitude || marker?.data?.lng;
          return <Marker key={`${lat}|${lng}`} position={{ lat, lng }} />;
        })}
        {state.kmlUrl && (
          <KmlLayer
            url={state.kmlUrl}
            onClick={(e) => {
              onKmlClick({ ...e.featureData, lat: e.latLng.lat(), lng: e.latLng.lng() });
            }}
          />
        )}
      </GoogleMap>
      <MapStats hasCenterChanged={hasCenterChanged} hasZoomChanged={hasZoomChanged} state={state} />
    </Box>
  ) : loadError ? (
    <div>{`Error: ${loadError?.message}`}</div>
  ) : (
    <div>Loading...</div>
  );
};

export default GoogleMapComponent;

import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import customPageApi from '../api/customPageApi';
import { CustomPageConstants } from '../definitions';
import useCustomPageListActions from '../hooks/useCustomPageListActions';
const CustomPageColumns = [
  {
    initialSort: 'ASC',
    label: 'Page Route',
    renderer: (customPage) => customPage.name,
    sortable: true,
    valueField: 'name',
  },
];

const CustomPageListPage = () => {
  const { defaultPageId, typeName } = CustomPageConstants;
  const { add, edit, remove, requestPending } = useCustomPageListActions(CustomPageConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, remove);

  return (
    <CmsPage title="CustomPages" requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="nav-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={CustomPageColumns}
        itemApi={customPageApi}
      />
    </CmsPage>
  );
};

export default CustomPageListPage;

import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';
import FormInput from './FormInput';

const LinkFinder = (props) => {
  const { label, fieldName, formContext, required, defaultValue, allowEmbeddedMenus, disabled } =
    props;
  const formFieldOptions = required
    ? {
        required: 'This field is required.',
      }
    : {};
  const { showLinkSearch } = useContext(CmsContext);
  const findLink = useCallback(async () => {
    const currentValue = formContext.getValues()[fieldName];
    const { returnVal } = await showLinkSearch(currentValue, allowEmbeddedMenus);
    formContext.setValue(fieldName, returnVal || currentValue, {
      shouldDirty: !!returnVal,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [formContext, fieldName, showLinkSearch, allowEmbeddedMenus]);
  return (
    <div className="link-finder">
      <FormInput
        label={label}
        fieldName={fieldName}
        defaultValue={defaultValue}
        formContext={formContext}
        formFieldOptions={formFieldOptions}
        disabled={disabled}
      />
      <i className="fa fa-search clickable" onClick={findLink} />
    </div>
  );
};

export default LinkFinder;

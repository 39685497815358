(function () {
  angular.module('auth-routes', []).config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('login', {
          url: '/login',
          templateUrl: 'modules/auth/templates/login.html',
          controller: 'LoginCtrl',
        })
        .state('changePassword', {
          url: '/changePassword',
          templateUrl: 'modules/auth/templates/changePassword.html',
          controller: 'ChangePasswordCtrl',
        })
        .state('changePasswordSuccess', {
          url: '/changePasswordSuccess',
          templateUrl: 'modules/auth/templates/changePasswordSuccess.html',
          controller: 'ChangePasswordSuccessCtrl',
        })
        .state('resetPassword', {
          url: '/resetPassword/:promptId',
          templateUrl: 'modules/auth/templates/resetPassword.html',
          controller: 'ResetPasswordCtrl',
        })
        .state('resetPasswordSuccess', {
          url: '/resetPasswordSuccess',
          templateUrl: 'modules/auth/templates/resetPasswordSuccess.html',
          controller: 'SuccessCtrl',
        })
        .state('register', {
          url: '/register/:promptId',
          templateUrl: 'modules/auth/templates/register.html',
          controller: 'RegisterCtrl',
        })
        .state('registerSuccess', {
          url: '/registerSuccess',
          templateUrl: 'modules/auth/templates/registerSuccess.html',
          controller: 'SuccessCtrl',
        })
        .state('forgotPassword', {
          url: '/forgotPassword',
          templateUrl: 'modules/auth/templates/forgotPassword.html',
          controller: 'ForgotPasswordCtrl',
        })
        .state('forgotPasswordSuccess', {
          url: '/forgotPasswordSuccess/:email',
          templateUrl: 'modules/auth/templates/forgotPasswordSuccess.html',
          controller: 'ForgotPasswordSuccessCtrl',
        });
    },
  ]);
})();

import { useCallback, useMemo, useState } from 'react';
export const useSpinnerProvider = () => {
  const [spinners, setSpinners] = useState(new Set());
  const dismiss = useCallback(
    (spinnerId) => {
      setSpinners((prevState) => new Set(Array.from(prevState).filter((s) => s !== spinnerId)));
    },
    [setSpinners]
  );
  const add = useCallback(
    (spinnerId) => {
      setSpinners((prevState) => {
        const newSet = new Set(Array.from(prevState));
        newSet.add(spinnerId);
        return newSet;
      });
    },
    [setSpinners]
  );
  const spinnerProvider = useMemo(
    () => ({
      add,
      dismiss,
    }),
    [add, dismiss]
  );
  return {
    spinnerProvider,
    spinners,
  };
};

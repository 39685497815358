import { createSlice } from '@reduxjs/toolkit';
import update from 'lodash/update';
import { v4 as uuid } from 'uuid';
import { getJobs } from './thunks';
import set from 'lodash/set';
export const reducerName = 'jobs';

export const jobsSelectorSlice = createSlice({
  name: reducerName,
  initialState: { jobs: {}, jobsProcessingKey: uuid() },
  reducers: {
    updateJob: (state, { payload: { job } }) => {
      update(state, `jobs.${job.id}`, (c = {}) => ({ ...c, ...job }));
    },
    startWatchingJob: (state, { payload: { jobId, name = '' } }) => {
      set(state, `jobs.${jobId}`, { id: jobId, status: 'pending', name });
      state.jobsProcessingKey = uuid();
    },
  },
  extraReducers(builder) {
    const fulfill = (funcName, cb) => builder.addCase(funcName.fulfilled, cb);
    fulfill(getJobs, (state, { payload: { continueChecking, jobs } }) => {
      state.jobs = jobs;
      state.jobsProcessingKey = continueChecking ? uuid() : state.jobsProcessingKey;
    });
  },
});

export const { updateJob, startWatchingJob } = jobsSelectorSlice.actions;
export default jobsSelectorSlice.reducer;

import pako from 'pako';
import get from 'lodash/get';
export function compressCsvJsonCollection(collectionArray = []) {
  const headers = Object.keys(get(collectionArray, '[0]', {}));
  const rows = collectionArray.map((row) => headers.map((header) => row[header]));
  return pako.deflate(JSON.stringify({ headers, rows }));
}

export function inflateCsvJsonCollection(compressed) {
  const { headers, rows } = JSON.parse(pako.inflate(compressed, { to: 'string' }));
  return rows.map((row) => row.reduce((a, v, idx) => ({ ...a, [headers[idx]]: v }), {}));
}

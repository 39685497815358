import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import update from 'lodash/update';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import manageGroupsApi from '../api/manageGroupsApi';

export default function useModifiedItemPersistance(groupId) {
  const { item, loadItem, saveItem } = useItemPersistence(manageGroupsApi);
  const [userGroups, setUserGroups] = useState([]);
  const modifiedSave = useCallback(
    (item) => {
      update(item, 'userGroups', (grps = []) => [
        ...grps,
        ...userGroups.filter((group) => !group.canAssign),
      ]);
      return saveItem(item);
    },
    [saveItem, userGroups]
  );
  const initLoad = useCallback(async () => {
    if (groupId) {
      const item = await loadItem(groupId);
      setUserGroups(get(item, 'userGroups', []));
    }
  }, [loadItem, groupId, setUserGroups]);

  useEffect(() => {
    if (groupId) {
      initLoad(groupId);
    }
  }, [groupId, initLoad]);
  const modifiedItem = useMemo(
    () =>
      isPlainObject(item)
        ? {
            ...item,
            ...(item.userGroups && { userGroups: item.userGroups.filter((grp) => grp.canAssign) }),
          }
        : item,
    [item]
  );
  return {
    saveItem: modifiedSave,
    item: modifiedItem,
  };
}

import React, { useContext } from 'react';
import ItemTable from '../../itemTable/components/ItemTable';
import { useListLoaderParams } from '../../cms/hooks/useListLoaderParams';
import { useListLoader } from '../../cms/hooks/useListLoader';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import useLoadListItems from '../hooks/useLoadListItems';
import CmsListSectionToolbar from './CmsListSectionToolbar';

const CmsItemListSection = ({
  additionalSearchFields = [],
  additiontalLoaderParams = {},
  backPageAction,
  className,
  columns,
  itemActions,
  itemApi,
  itemSort,
  onAdditionalSearchFieldsChange,
  pageId,
  pageSize,
  searchHeader,
  searchPlaceholder,
  showSearchBar,
  showTotal = false,
  tableRowCustomClass,
  tableFooter = {},
  toolbarActions,
  NestedComponent = null,
}) => {
  const { spinnerProvider } = useContext(CmsPageContext);
  const { loadRecords } = itemApi;
  const { loadItems, loading, records, pagination, sort } = useListLoader(loadRecords);
  useSpinner(spinnerProvider, loading);
  const { loaderParams, onSearchChange, onPageSelected, onSortChange } = useListLoaderParams(
    pageId,
    pageSize,
    columns,
    additiontalLoaderParams
  );
  const reload = useLoadListItems(pageId, loadItems, loaderParams);
  const wrappedToolbarActions = useWrapPageActions(pageId, toolbarActions, reload, loaderParams);
  const wrappedItemActions = useWrapPageActions(pageId, itemActions, reload, loaderParams);
  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsListSectionToolbar
            additionalSearchFields={additionalSearchFields}
            actions={wrappedToolbarActions}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            backPageAction={backPageAction}
            showSearchBar={showSearchBar}
            searchHeader={searchHeader}
            searchPlaceholder={searchPlaceholder}
            onAdditionalSearchFieldsChange={onAdditionalSearchFieldsChange}
          />
        </div>
        <GrowlContainer groupId={pageId} />
        <div className="row">
          <ItemTable
            reload={() => reload(loaderParams)}
            itemSort={itemSort}
            showTotal={showTotal}
            tableRowCustomClass={tableRowCustomClass}
            columns={columns}
            items={records}
            itemActions={wrappedItemActions}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
            tableFooter={tableFooter}
            NestedComponent={NestedComponent}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsItemListSection;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import logger from '../../../logger';
import { createCertificate, deleteCertificate } from '../api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Certificates({ certificates, fields, handleGetCertificates }) {
  const [activeFields, setFields] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [pdf, setPdf] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDeleteCertificate = async (rowsDeleted) => {
    for (const i in rowsDeleted.data) {
      try {
        const idx = rowsDeleted.data[i].dataIndex;
        await deleteCertificate(certificates[idx].id);
      } catch (e) {
        logger.error(e);
        return false;
      }
    }
  };

  const handleCreateCertificate = async () => {
    const requiredFields = activeFields.map(({ id }) => id);
    await createCertificate({
      displayName,
      fields: requiredFields,
      pdfData: JSON.parse(pdf),
    });
    handleAddClose();
    handleGetCertificates();
  };

  const columns = [
    {
      name: 'accountId',
      label: 'Account',
    },
    {
      name: 'displayName',
      label: 'Name',
    },
    {
      name: 'fields',
      label: 'Fields',
      options: {
        customBodyRenderLite: (idx) => {
          const value = certificates[idx].fields;
          return (
            <div>
              {value.map((f, i, { length }) => {
                const field = fields.find(({ id }) => id === f);
                if (!field) return <span key={f}>{f} </span>;
                return (
                  <span key={f}>
                    {field.displayName}
                    {i + 1 < length ? ',' : ''}{' '}
                  </span>
                );
              })}
            </div>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: '',
      label: 'Has PDF',
      options: {
        customBodyRenderLite: (idx) => {
          const value = certificates[idx].pdfData;
          return <div>{value.length > 0 ? 'True' : 'False'}</div>;
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    onRowsDelete: handleDeleteCertificate,
  };

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  const handleFieldChange = ({ target }) => {
    if (target.checked) {
      const addField = fields.find(({ id }) => id === target.name);
      setFields([...activeFields, addField]);
      return;
    }
    setFields(activeFields.filter(({ id }) => id !== target.name));
  };

  return (
    <Box sx={{ maxWidth: '1200px', m: 'auto' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Certificate
        </Button>
      </Box>
      <MUIDataTable
        title={'Certificates'}
        data={certificates}
        columns={columns}
        options={options}
      />
      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="display Name"
            label="Name"
            value={displayName}
            sx={{ width: '100%' }}
            onChange={({ target }) => setDisplayName(target.value)}
          />
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Select Fields</FormLabel>
            <FormGroup>
              {fields.map((field) => (
                <FormControlLabel
                  key={field.id}
                  control={
                    <Checkbox
                      checked={!!activeFields.find(({ id }) => id === field.id)}
                      onChange={handleFieldChange}
                      name={field.id}
                    />
                  }
                  label={field.displayName}
                />
              ))}
            </FormGroup>
          </FormControl>
          <TextField
            id="pdf-data"
            label="pdf data"
            multiline
            maxRows={10}
            value={pdf}
            sx={{ width: '100%' }}
            onChange={({ target }) => setPdf(target.value)}
          />
          <Button
            onClick={handleCreateCertificate}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Certificates;

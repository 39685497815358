import has from 'lodash/has';
import React from 'react';
import { selectFromLogic } from '../../../store/selectors';
import CompoundCondition from './compound-condition';
import SimpleCondition from './simple-condition';

const QueryBuilderSection = (props) => {
  const { location, fieldName } = props;
  const rules = selectFromLogic(fieldName, `${location ? `${location}.rules` : 'rules'}`, []);
  return (
    <div>
      <CompoundCondition fieldName={fieldName} location={location} />
      {(rules || []).map((rule, idx) => {
        const loc = `${location}${location ? '.rules' : 'rules'}[${idx}]`;
        return (
          <div key={`${idx}`} className="condition">
            {has(rule, 'logic') ? (
              <div className="group-conditions">
                <QueryBuilderSection fieldName={fieldName} location={`${loc}.logic`} />
              </div>
            ) : (
              <div className="group-conditions">
                <SimpleCondition fieldName={fieldName} location={loc} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QueryBuilderSection;

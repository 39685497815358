import React, { useContext, useEffect, useState } from 'react';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import { MenuEditorConstants } from '../definitions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import menuItemApi from '../api/menuItemApi';
import IconSelector from './IconSelector';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import Accordion from 'react-bootstrap/Accordion';
import LinkAccordionToggle from '../../shared/form/components/LinkAccordionToggle';
import LinkFinderMenu from '../../shared/form/components/LinkFinderMenu';
import menuApi from '../api/menuApi';

const MenuItemEditor = (props) => {
  const { itemId, menuId, growlId, onItemChange, changeTitle } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const [canEmbedMenu, setCanEmbedMenu] = useState(false);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(menuItemApi, menuId);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, MenuEditorConstants, menuId);
  const defaultPage = 'cms://default';
  const {
    item: menu,
    loadItem: loadMenu,
    requestPending: menuRequestPending,
  } = useItemPersistence(menuApi);
  useEffect(() => {
    if (menuId) (async () => await loadMenu(menuId))();
  }, [menuId, loadMenu]);
  useEffect(() => {
    setCanEmbedMenu(menu && menu.key.endsWith('sideMenu'));

    if (menu) changeTitle(menu.name);
  }, [menu, setCanEmbedMenu]);
  useSpinner(spinnerProvider, requestPending || menuRequestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, MenuEditorConstants);
  return (
    <>
      <div className="neg-left-margin-13">
        <form role="form">
          <FormInput
            label="Title (Label)"
            fieldName="label"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />

          <IconSelector form={form} label="Icon" fieldName="image_asset_id" required={true} />

          <LinkFinderMenu
            formContext={form}
            label="Link Item to"
            fieldName="objectReference"
            defaultValue={defaultPage}
            allowEmbeddedMenus={canEmbedMenu}
            required={true}
          />

          <AudienceSelector
            formContext={form}
            label="Available To "
            fieldName="condition"
            growlId={growlId}
          />
          <Accordion style={{ borderColor: 'white' }}>
            <Accordion.Item eventKey="0" style={{ borderColor: 'white' }}>
              <LinkAccordionToggle label="Advanced" eventKey="0" />
              <Accordion.Body>
                <FormInput
                  label="Min Build"
                  fieldName="minBuild"
                  fieldWidth="short"
                  formContext={form}
                  type="number"
                  formFieldOptions={{
                    pattern: {
                      value: /\d+/,
                      message: 'This field must be a valid build number.',
                    },
                  }}
                />
                <FormInput
                  label="Max Build"
                  fieldName="maxBuild"
                  fieldWidth="short"
                  formContext={form}
                  type="number"
                  formFieldOptions={{
                    pattern: {
                      value: /\d+/,
                      message: 'This field must be a valid build number.',
                    },
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default MenuItemEditor;

import React, { forwardRef, useImperativeHandle, useEffect, useState, useCallback } from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPencil, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { alpha, styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import manageAssetsApi from '../api/manageAssetsApi';
import _ from 'lodash';
import ConfirmDialog from './ConfirmDialog';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`.${gridClasses.columnHeader}`]: {
    fontWeight: '700 !important',
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const BasicMenu = ({ row, fetchAssets, onReplace, onEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmTitle, setConfirmTitle] = React.useState(null);
  const [confirmDescription, setConfirmDescription] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [onConfirm, setOnConfirm] = React.useState(null);

  const [removeSuccess, setRemoveSuccess] = React.useState(false);

  const handleConfirm = (title, description, onConfirm) => {
    setAnchorEl(null);
    setConfirmTitle(title);
    setConfirmDescription(description);
    setOnConfirm(() => onConfirm);
    setConfirmOpen(true);
  };

  const handleReplace = useCallback(() => {
    handleClose(); // Close the menu
    onReplace(row); // Trigger the callback with the asset ID
  }, [row.id, onReplace]);

  const handleEdit = useCallback(() => {
    handleClose(); // Close the menu
    onEdit(row); // Trigger the callback with the asset ID
  }, [row.id, onReplace]);

  const handleSnackClose = () => {
    setRemoveSuccess(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmRemove = useCallback(() => {
    handleConfirm('Remove Asset', 'Are you sure you want to remove this asset?', () => {
      manageAssetsApi.removeAsset(row).then(() => {
        setRemoveSuccess(true);
        setAnchorEl(null);
        fetchAssets();
      });
    });
  }, [handleConfirm]);

  return (
    <div>
      <Snackbar
        open={removeSuccess}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          Asset was successfully removed. Remember to publish your changes!
        </Alert>
      </Snackbar>
      <ConfirmDialog
        key={`${confirmTitle}-${confirmDescription}`}
        open={confirmOpen}
        description={confirmDescription}
        title={confirmTitle}
        setOpen={setConfirmOpen}
        onConfirm={onConfirm}
      />
      <IconButton
        style={{ width: 30, height: 30 }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faEllipsisV} size="sm" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {row.editable && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faPencil} />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        <MenuItem onClick={handleReplace}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUpload} />
          </ListItemIcon>
          <ListItemText primary="Upload (Replace)" />
        </MenuItem>
        <MenuItem onClick={confirmRemove}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTrash} />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </MenuItem>
      </Menu>
    </div>
  );
};

const AssetTable = forwardRef(
  (
    {
      search,
      instance,
      type,
      group,
      showPlugins,
      status,
      selectedRows,
      setSelectedRows,
      page,
      onReplace,
      onEdit,
    },
    ref
  ) => {
    // Initialize state to hold assets
    const [assets, setAssets] = useState([]);
    const [sortModel, setSortModel] = React.useState({ field: 'id', sort: 'asc' });
    const [rowCount, setRowCount] = React.useState(0);
    const [error, setError] = React.useState(null);

    const [paginationModel, setPaginationModel] = React.useState({
      page: page || 0,
      pageSize: 15,
    });
    const handleSortModelChange = React.useCallback((sortModel) => {
      setSortModel(sortModel[0]);
    }, []);

    const makeGroupPretty = (group) => {
      group = group.split('/');
      if (group.length > 1) {
        return (group[0] = 'Plugin: ' + _.upperFirst(group[1]));
      }

      return _.upperFirst(group[0]);
    };

    // Define an async function to fetch assets
    const fetchAssets = async (updPagination) => {
      const pageModel = updPagination || paginationModel;
      try {
        const { data, pagination } = await manageAssetsApi.getAssets({
          search,
          instance,
          type,
          group,
          page: pageModel.page + 1,
          limit: pageModel.pageSize,
          showPlugins: showPlugins ? 1 : 0,
          orderBy: sortModel.field,
          orderDirection: sortModel.sort,
          status,
        }); // Call getAssets with desired parameters

        if (rowCount !== pagination.rowCount) {
          setPaginationModel({
            ...pageModel,
            page: 0,
          });
        }
        setRowCount(pagination.rowCount); // Update rowCount state with total count of assets
        const transformedAssets = data?.map((asset) => ({
          id: asset.id, // Assuming asset.id is the unique identifier
          previewUrl: asset.latestRevision.previewPath,
          type: asset.type,
          group: makeGroupPretty(asset.latestRevision.group),
          status: asset.status,
          editable: ['json', 'js', 'css', 'html'].includes(asset.type),
          latestRevisionId: asset.latest_revision_id,
          instanceMappings: asset.instanceMappings,
          // Add more fields as necessary
        }));
        setAssets(transformedAssets); // Update state with fetched assets
      } catch (error) {
        setError(error); // Update state with error
      }
    };

    const handlePagintionChange = (value) => {
      setPaginationModel(value);
      fetchAssets(value);
    };

    const columns = [
      {
        field: 'id',
        flex: 1,
        headerName: 'File Name',
        disableColumnMenu: true,
        renderCell: (params) => (
          <a href={params.row.previewUrl} target="_blank" rel="noreferrer">
            {params.row.id}
          </a>
        ),
      },
      { field: 'type', flex: 1, headerName: 'Type', disableColumnMenu: true },
      { field: 'group', flex: 1, headerName: 'Group', disableColumnMenu: true },
      { field: 'status', flex: 1, headerName: 'Status', disableColumnMenu: true },
      {
        field: 'col4',
        width: 100,
        align: 'right',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <BasicMenu
            row={params.row}
            fetchAssets={fetchAssets}
            onReplace={onReplace}
            onEdit={onEdit}
          />
        ),
      },
    ];

    const handleErrorClose = () => {
      setError(null);
    };

    useImperativeHandle(ref, () => ({
      fetchAssets,
    }));

    useEffect(() => {
      fetchAssets(); // Call the fetch function
    }, [search, instance, type, group, showPlugins, sortModel, status]); // Empty dependency array means this effect runs once on mount

    return (
      <div style={{ width: '100%' }}>
        <Snackbar
          open={error}
          autoHideDuration={4000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key={'errorMessage'}
        >
          <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        <StripedDataGrid
          rows={assets}
          columns={columns}
          autoHeight={true}
          rowCount={rowCount}
          sortingOrder={['desc', 'asc']}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pageSizeOptions={[15, 25, 50, 75]}
          paginationModel={paginationModel}
          sortingMode="server"
          paginationMode="server"
          onPaginationModelChange={handlePagintionChange}
          onSortModelChange={handleSortModelChange}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          isRowSelectable={(params) => params.row.status !== 'Active'}
          checkboxSelection
        />
      </div>
    );
  }
);

AssetTable.displayName = 'AssetTable';

export default AssetTable;

import { useEffect, useState } from 'react';
import { getRoomTypes } from '../api/itrsApi';
import useMuiDndEditorTable from '../../shared/useMuiDndEditorTable';
const cols = [
  {
    name: 'Id',
    field: 'id',
    width: 0,
    hidden: true,
  },
  {
    name: 'Room Type',
    field: 'roomType',
    width: 40,
    inputType: 'dropdown',
    options: [],
  },
  {
    name: 'Start Date/Time',
    field: 'startDate',
    inputType: 'datetime-local',
    textFieldProps: { inputProps: { maxLength: 0, placeholder: 'Start Date/Time' } },
    width: 20,
  },
  {
    name: 'End Date/Time',
    inputType: 'datetime-local',
    textFieldProps: { inputProps: { min: 0, placeholder: 'End Date/Time' } },
    field: 'endDate',
    width: 20,
  },

  {
    name: '',
    noSort: true,
    isRemove: true,
    width: 5,
    confirm: {
      title: `Remove Room`,
      message: `Are you sure you want to remove this room?`,
    },
  },
];
const useRoomColumns = (form) => {
  const [columns, setColumns] = useState(cols);
  useEffect(() => {
    const load = async () => {
      const options = await getRoomTypes();
      setColumns((newCols) => {
        newCols = [...newCols];
        newCols[1].options = options;
        return newCols;
      });
    };
    load();
  }, [setColumns]);
  const { addRow, PreRenderedTable } = useMuiDndEditorTable({
    canDrag: false,
    fontSize: '1rem',
    form,
    columns,
    formRowsKey: 'roomReservations',
  });
  return { addRow, PreRenderedTable };
};

export default useRoomColumns;

import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import GeneralFilesEditorForm from './GeneralFilesEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { GeneralFilesConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import generalFilesApi from '../api/generalFilesApi';

const GeneralFilesEditorPage = (props) => {
  const { generalFilesId } = props;
  const { item, loadItem, saveItem } = useItemPersistence(generalFilesApi);
  useEffect(() => {
    if (generalFilesId) (async () => await loadItem(generalFilesId))();
  }, [generalFilesId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, GeneralFilesConstants);
  return (
    <CmsPage title={`${generalFilesId === 'new' ? 'Add' : 'Replace'} File`}>
      <div className="alert alert-info">
        {`Supported file types are: pdf, doc, docx, xls, xlsx, csv, txt, rtf, ppt, pptx, ppsx`}
      </div>
      <GeneralFilesEditorForm
        form={form}
        growlId={GeneralFilesConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default GeneralFilesEditorPage;

import React, { useState, useEffect } from 'react';
import qrScannerApi from '../api/qrScannerApi';
import ProvidedSelect from '../../shared/form/components/ProvidedSelect';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import FormInput from '../../shared/form/components/FormInput';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { QrScannerConstants, scannerTypeObject } from '../definitions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const QrScannerEditorPage = ({ qrscannerId }) => {
  const [config, setConfig] = useState({});
  const [params, setParams] = useState([]);

  // Define options for Select components here based on fetched data

  const { item, saveItem, loadItem } = useItemPersistence(qrScannerApi);
  const form = useCmsForm(item);
  useEffect(() => {
    if (qrscannerId) (async () => await loadItem(qrscannerId))();
  }, [qrscannerId, loadItem]);
  useEffect(() => {
    (async () => {
      const { config = {} } = await qrScannerApi.getConfig();
      setConfig(config.scannerTypes || {});
    })();
  }, []);
  useEffect(() => {
    if (item?.type) {
      const newParams = config[item?.type]?.parameters || [];
      setParams(newParams);
    }
  }, [item, config]);

  const actions = useItemEditActions(saveItem, form, QrScannerConstants);
  return (
    <CmsPage title={`${qrscannerId === 'new' ? 'Add' : 'Edit'} Scanner`}>
      <form>
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <ProvidedSelect
          label={'Type'}
          fieldName={'type'}
          fieldWidth="long"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
          placeholder="Select a scanner type"
          selectOptions={Object.keys(config || {}).map((type) => ({
            value: type,
            label: scannerTypeObject[type],
          }))}
          selectChange={(selected) => {
            const newParams = config[selected.value]?.parameters || [];
            setParams(newParams);
            form.setValue('type', selected.value);
          }}
        />
        {params.map(({ name, label, type, required, options, prompt, instruction }) => (
          <div key={name}>
            {type === 'text' ? (
              <FormInput
                label={label}
                type={type}
                fieldName={`paramValues.${name}`}
                formContext={form}
                placeholder={prompt}
                formFieldOptions={{
                  required,
                }}
              />
            ) : type === 'select' ? (
              <ProvidedSelect
                label={label}
                fieldName={`paramValues.${name}`}
                fieldWidth="long"
                formContext={form}
                formFieldOptions={{
                  required,
                }}
                placeholder="Select a scanner type"
                selectOptions={options.map(({ id, label }) => ({ value: id, label }))}
                selectChange={(selected) => {
                  form.setValue(`paramValues.${name}`, selected.value);
                }}
              />
            ) : null}
            {instruction && <div className="field-instruction">{instruction}</div>}
          </div>
        ))}
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </CmsPage>
  );
};

export default QrScannerEditorPage;

import 'ui-select/dist/select.css';
import 'ng-tags-input/build/ng-tags-input.css';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './css/main.css';
import './css/simple-sidebar.css';

import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
if (process.env.TELEMETRY_ENABLED !== 'false') {
  initializeFaro({
    // Mandatory, the URL of the Grafana Cloud collector with embedded application key.
    // Copy from the configuration page of your application in Grafana.
    url: process.env.TELEMETRY_URL,
    apiKey: process.env.TELEMETRY_KEY,

    // Mandatory, the identification label(s) of your application
    app: {
      name: 'cmb-cms-web',
      version: '0.3.0',
      environment: 'dev',
    },

    instrumentations: [
      // Don't forget to add the other instrumentations as well
      ...getWebInstrumentations(),

      new TracingInstrumentation({
        // Optional, if you want to replace the default W3C Trace Context Propagator
        propagator: new B3Propagator(),

        instrumentations: [
          new XMLHttpRequestInstrumentation({
            propagateTraceHeaderCorsUrls: [/https:\/\/.*\.combobulate\.(io|com).*/],
          }),
          new FetchInstrumentation({
            propagateTraceHeaderCorsUrls: [/https:\/\/.*\.combobulate\.(io|com).*/],
          }),
          new DocumentLoadInstrumentation(),
          new UserInteractionInstrumentation(),
        ],
      }),
    ],
  });
}

// Unfortunate hack to ensure that dynamically loaded CMS modules have access
// to the same jQuery instance as webpack modules.  Note the 'j' + 'Query' is used to prevent the
// webpack ProvidePlugin from replacing window.jQuery or window['jQuery'] with generated code.
//eslint-disable-next-line
const $ = require('jquery');
//eslint-disable-next-line
// @ts-ignore
window['j' + 'Query'] = $;
//eslint-disable-next-line
const moment = require('moment');
//eslint-disable-next-line
(window as any).moment = moment;

import './app';

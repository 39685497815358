import { EMPTY_COLLECTION_REVISION } from '../definitions';
import log from '../../logger';
import { defaultTypesSelected } from '../definitions';
import axios from 'axios';
const baseUrl = '/plugins/collections/api';
const loadRecords = async (params) => {
  const { sort, additionalFieldsSearch = {}, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    if (!additionalFieldsSearch.types) additionalFieldsSearch.types = defaultTypesSelected;
    const { data } = await axios.get(`${baseUrl}/getCollections`, {
      params: {
        ...otherParams,
        additionalFieldsSearch,
        sortField,
        sortReverse,
      },
      timeout: 10_000,
    });
    const { collections, count, offset } = data;
    result = {
      records: collections,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collections');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_COLLECTION_REVISION;

  try {
    const { data } = await axios.get(`${baseUrl}/getCollection`, {
      params: { id },
      timeout: 10_000,
    });
    return data.collection;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collection.');
  }
};

const saveRecord = async (item) => {
  try {
    //eslint-disable-next-line
    const formData = new FormData();
    //eslint-disable-next-line
    const { linkAssetUpload, id, ...rest } = item;
    formData.append('collectionProps', JSON.stringify({ ...rest, id: item.collection_id }));
    formData.append('linkAssetUpload', linkAssetUpload);
    const { data } = await axios.post(`${baseUrl}/saveCollection`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 10_000,
    });
    return data.collection;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save collection.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeCollection`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove collection.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/restoreCollection`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to restore collection.');
  }
};

const reorderRecords = async (parentId, itemIds) => {
  try {
    await axios.post(
      `${baseUrl}/reorderCollectionItems`,
      { itemIds, id: parentId },
      { timeout: 10_000 }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to reorder collection.');
  }
};

const publishRecord = async (id, itemIds = []) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishCollection`,
      { id, itemIds },
      { timeout: 10_000 }
    );
    return data.collection;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish collection.');
  }
};

const duplicateRecord = async (collectionId) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/duplicateCollection`,
      { collectionId },
      { timeout: 10_000 }
    );
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to duplicate collection.');
  }
};

const collectionApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  reorderRecords,
  publishRecord,
  duplicateRecord,
};
export const loadCategories = async (collectionId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCategorySuggestions`, {
      params: { collectionId },
      timeout: 10_000,
    });
    return data.categories;
  } catch {
    log.info('Failed to get categories');
  }
};
export default collectionApi;

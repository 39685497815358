import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import leadGenEventApi from '../api/leadGenEventApi';
import { LeadGenEventConstants } from '../definitions';
import useLeadGenEventListActions from '../hooks/useLeadGenEventListActions';
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' ');
const LeadGenEventColumns = [
  {
    label: 'Event Name',
    valueField: 'eventName',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: `Start Date (${tz})`,
    valueField: 'startDate',
    sortable: true,
    initialSort: 'ASC',
    renderer: ({ startDate }) => <Timestamp localTime utc showNull={false} date={startDate} />,
  },
  {
    label: `End Date (${tz})`,
    valueField: 'endDate',
    sortable: true,
    initialSort: 'ASC',
    renderer: ({ endDate }) => <Timestamp localTime utc showNull={false} date={endDate} />,
  },
  {
    label: 'Location',
    valueField: 'location',
    sortable: true,
    initialSort: 'ASC',
  },
];

const LeadGenEventListPage = () => {
  const { defaultPageId, typeName, pageTitle } = LeadGenEventConstants;
  const { add, requestPending, itemActions } = useLeadGenEventListActions();
  const toolbarActions = useArray(add);

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="leadGenEvent-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={LeadGenEventColumns}
        itemApi={leadGenEventApi}
      />
    </CmsPage>
  );
};

export default LeadGenEventListPage;

import axios from 'axios';

//Achievements Endpoints
export const createAchievement = async (acheivementData) => {
  const resp = await axios.post(`/achievements/`, acheivementData);
  return resp.data;
};

export const getAchievements = async () => {
  const resp = await axios.get(`/achievements/`);
  return resp.data;
};
export const getAchievement = async (id) => {
  const resp = await axios.get(`/achievements/${id}`);
  return resp.data;
};

export const deleteAchievement = async (id) => {
  const resp = await axios.delete(`/achievements/${id}`);
  return resp.data;
};

//Certificates Endpoints
export const createCertificate = async (certificateData) => {
  const resp = await axios.post(`/certificates/`, certificateData);
  return resp.data;
};

export const getCertificates = async () => {
  const resp = await axios.get(`/certificates/`);
  return resp.data;
};
export const getCertificate = async (id) => {
  const resp = await axios.get(`/certificates/${id}`);
  return resp.data;
};

export const deleteCertificate = async (id) => {
  const resp = await axios.delete(`/certificates/${id}`);
  return resp.data;
};

//Awards Endpoints
export const createAward = async (awardData) => {
  const resp = await axios.post(`/awards/`, awardData);
  return resp.data;
};

export const getAwards = async () => {
  const resp = await axios.get(`/awards/`);
  return resp.data;
};
export const getAward = async (id) => {
  const resp = await axios.get(`/awards/${id}`);
  return resp.data;
};

export const deleteAward = async (id) => {
  const resp = await axios.delete(`/awards/${id}`);
  return resp.data;
};

export const createField = async (fieldData) => {
  const resp = await axios.post(`/fields/`, fieldData);
  return resp.data;
};

export const getFields = async () => {
  const resp = await axios.get(`/fields/`);
  return resp.data;
};
export const getField = async (id) => {
  const resp = await axios.get(`/fields/${id}`);
  return resp.data;
};

export const deleteField = async (id) => {
  const resp = await axios.delete(`/fields/${id}`);
  return resp.data;
};

import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import TargetAudienceEditorForm from './TargetAudienceEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { TargetAudienceConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import targetAudienceApiWrapped from '../api/targetAudienceApi';

const TargetAudiencesEditorPage = (props) => {
  const { targetAudienceId } = props;
  const targetAudienceApi = targetAudienceApiWrapped();
  const { item, loadItem, saveItem } = useItemPersistence(targetAudienceApi);
  useEffect(() => {
    if (targetAudienceId) (async () => await loadItem(targetAudienceId))();
  }, [targetAudienceId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, TargetAudienceConstants);
  return (
    <CmsPage title={`${targetAudienceId === 'new' ? 'Create New' : 'Edit'} Audience`}>
      <TargetAudienceEditorForm
        form={form}
        growlId={TargetAudienceConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default TargetAudiencesEditorPage;

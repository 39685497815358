import React, { useState, useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
// import PaginationControl from '../../shared/itemTable/components/PaginationControl';
// import LogFilter from './LogFilter';
import workflowApi from '../api/workflowApi';
// import Editor from '@monaco-editor/react';
import WorkflowLogTable from './WorkflowLogTable';
import log from '../../logger';

// function formatLogs(logs) {
//   return logs.map((log) => JSON.stringify(log, null, 2)).join('\n\n');
// };

const WorkflowLogPage = (props) => {
  const { workflowType } = props;
  const [logs, setLogs] = useState([]);
  const [filterArray] = useState([]);
  const [sortModel] = useState({ field: 'id', sort: 'asc' });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
    pageCount: 1,
  });

  const fetchLogs = async (updPagination) => {
    const pageModel = updPagination || paginationModel;
    try {
      const { records, numRecords } = await workflowApi.loadLogs({
        filterArray,
        search: '',
        offset: pageModel.page,
        limit: pageModel.pageSize,
        sortField: sortModel.field,
        sortReverse: sortModel.sort,
        workflowType,
      }); // Call getAssets with desired parameters

      if (paginationModel.pageCount !== Math.ceil(numRecords / paginationModel.pageSize)) {
        setPaginationModel({
          ...pageModel,
          pageCount: Math.ceil(numRecords / paginationModel.pageSize),
        });
      }
      // setRowCount(pagination.rowCount);
      setLogs(records);
    } catch (error) {
      log.error('Failed to fetch logs', error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [filterArray, paginationModel]);

  // const handlePagintionChange = (value) => {
  //   setPaginationModel(value);
  //   fetchLogs(value);
  // };

  // const onPageSelected = useCallback((page) => {
  //   setPaginationModel({
  //     ...paginationModel,
  //     page,
  //   });
  // }, []);

  // const handleEditorChange = () => {};
  // const remodel = {
  //   pageCount: paginationModel.pageCount,
  //   currentPage: paginationModel.page,
  // };

  return (
    <>
      <CmsPage title={'Workflow Logs'}>
        {/* <LogFilter setFilterArray={setFilterArray} />
        <PaginationControl {...remodel} onPageSelected={onPageSelected} /> */}
        <WorkflowLogTable data={logs} />
      </CmsPage>
    </>
  );
};

export default WorkflowLogPage;

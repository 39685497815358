import { EMPTY_CALENDAR_EVENT } from '../definitions';
import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/calendar-events/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getAdminEvents`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { adminEvents, count, offset } = data;
    result = {
      records: adminEvents,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load calendarEvents');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_CALENDAR_EVENT;
  try {
    const { data } = await axios.get(`${baseUrl}/getCalendarEvent`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return data.adminEvent;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load calendarEvent.');
  }
};

const saveRecord = async (item) => {
  try {
    const { id, ...revisionProps } = item;
    //eslint-disable-next-line
    let { startTime, endTime, ...otherProps } = revisionProps;
    //adjust start and end times to be in UTC
    startTime = startTime ? new Date(startTime).toISOString() : startTime;
    endTime = endTime ? new Date(endTime).toISOString() : endTime;
    const { data } = await axios.post(
      `${baseUrl}/saveAdminEvent`,
      { ...otherProps, startTime, endTime, id },
      {
        timeout: 10_000,
      }
    );
    return data.calendarEvent;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save calendarEvent.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/deleteAdminEvent`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove calendarEvent.');
  }
};

const calendarEventApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};

export default calendarEventApi;

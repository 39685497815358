import axios from 'axios';
import log from '../../logger';

export async function getAllUserActivity(exportOptions) {
  const { startDate, endDate } = exportOptions;
  const url = '/plugins/reporting/api/getAllUserActivity';
  const params = {
    startDate,
    endDate,
  };

  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    log.error('Error occurred while fetching user activity:', error);
    throw error;
  }
}

export async function getUniqueUserActivity(exportOptions) {
  const { startDate, endDate } = exportOptions;
  const url = '/plugins/reporting/api/getUniqueUsersWithActivity';
  const params = {
    startDate,
    endDate,
  };

  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    log.error('Error occurred while fetching unique user activity:', error);
    throw error;
  }
}

export async function getAllUsersExport() {
  const url = '/plugins/reporting/api/getAllUsersExport';

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    log.error('Error occurred while fetching all users export:', error);
    throw error;
  }
}

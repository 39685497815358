import { Box } from '@mui/material';
import React from 'react';
import ActionButton from '../../shared/actionButton/components/ActionButton';
import FormInput from '../../shared/form/components/FormInput';
import useMuiDndEditorTable from '../../shared/useMuiDndEditorTable';
import useRegistrationEditorSetup from '../hooks/useRegistrationEditorSetup';

const RegistrationEditor = ({ itemId: id, onItemChange: onChange }) => {
  const columns = [
    {
      name: 'Session Name',
      field: 'name',
      width: 45,
      textFieldProps: { inputProps: { placeholder: 'Session Name' } },
    },
    {
      name: 'Memo',
      field: 'subtitle',
      textFieldProps: { inputProps: { maxLength: 24, placeholder: 'Memo' } },
      width: 20,
    },
    {
      name: 'Max Occupancy',
      inputType: 'number',
      textFieldProps: { inputProps: { min: 0, placeholder: 'max occupancy' } },
      field: 'maximumOccupancy',
      width: 15,
    },
    {
      name: 'Occupancy',
      field: 'count',
      width: 15,
      viewOnly: true,
      defaultViewValue: 0,
    },
    {
      name: '',
      noSort: true,
      isRemove: true,
      width: 5,
      confirm: {
        title: `Remove Session`,
        message: `Are you sure you want to remove this session?`,
      },
    },
  ];
  const { form, actions: [save, cancel] = [] } = useRegistrationEditorSetup(id, onChange);
  const { addRow, PreRenderedTable } = useMuiDndEditorTable({
    form,
    columns,
    formRowsKey: 'userGroups',
    defaultRow: { name: '', maximumOccupancy: 0 },
  });
  return (
    <Box className="row no-padding-left">
      <form role="form" className="neg-left-margin-13">
        <FormInput label="Title" fieldName="title" formContext={form} />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', my: '1rem' }}>
          <Box>
            <ActionButton type="button" label={`Add Session +`} onClick={addRow} />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: 1 }}>
              <ActionButton {...cancel} />
            </Box>
            <ActionButton {...save} />
          </Box>
        </Box>
        {PreRenderedTable}
      </form>
    </Box>
  );
};

export default RegistrationEditor;

import axios from 'axios';

const instance = axios.create({
  baseURL: `/plugins/achievements/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Achievements Endpoints
export const createAchievement = async (achievementData) => {
  const resp = await instance.post(`/saveAchievement/`, achievementData);
  return resp.data;
};

export const getAchievements = async () => {
  const { data } = await instance.get(`/getAchievements/`);
  return data.achievements;
};

export const getAchievement = async (id) => {
  const resp = await instance.get(`/getAchievement?id=${id}`);
  return resp.data;
};

export const deleteAchievement = async (id) => {
  const resp = await instance.delete(`/achievements?id=${id}`);
  return resp.data;
};

// Certificates Endpoints
export const createCertificate = async (certificateData) => {
  const resp = await instance.post(`/saveCertificate/`, certificateData);
  return resp.data;
};

export const getCertificates = async () => {
  const { data } = await instance.get(`/getCertificates/`);
  return data.certificates;
};

export const getCertificate = async (id) => {
  const resp = await instance.get(`/getCertificate?id=${id}`);
  return resp.data;
};

export const deleteCertificate = async (id) => {
  const resp = await instance.delete(`/certificates?id=${id}`);
  return resp.data;
};

// Awards Endpoints
export const createAward = async (awardData) => {
  const resp = await instance.post(`/saveAward/`, awardData);
  return resp.data;
};

export const getAwards = async () => {
  const { data } = await instance.get(`/getAwards/`);
  return data.awards;
};

export const getAward = async (id) => {
  const resp = await instance.get(`/getAward?id=${id}`);
  return resp.data;
};

export const deleteAward = async (id) => {
  const resp = await instance.delete(`/awards?id=${id}`);
  return resp.data;
};

// Fields Endpoints
export const createField = async (fieldData) => {
  const resp = await instance.post(`/saveField/`, fieldData);
  return resp.data;
};

export const getFields = async () => {
  const { data } = await instance.get(`/getFields/`);
  return data.fields;
};

export const getField = async (id) => {
  const resp = await instance.get(`/getField?id=${id}`);
  return resp.data;
};

export const deleteField = async (id) => {
  const resp = await instance.delete(`/fields?id=${id}`);
  return resp.data;
};

import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';

const loadRecord = async (id) => {
  if (id === 'new') return { id: 'new' };

  try {
    const { data } = await axios.get(`${baseUrl}/getSession`, { params: { id } });
    return data.session;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load Session.');
  }
};

const removeRecord = async (id) => {
  try {
    await axios.post(`${baseUrl}/deleteSession`, {}, { params: { id } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove Session.');
  }
};

const approveTrainingRequest = async (approvalId, status) => {
  try {
    await axios.post(`${baseUrl}/updateApproval`, {}, { params: { id: approvalId, status } });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to Approve Request.');
  }
};

const loadRecords = async (params) => {
  try {
    const { courseId, sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'DESC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getSessionsByCourseId`, {
      params: { ...otherParams, courseId, sortField, sortReverse },
      timeout: 10_000,
    });

    return {
      records: rows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load session');
  }
};

const saveRecord = async (session) => {
  if (!session.id) session.id = 'new';
  try {
    const { data } = await axios.post(`${baseUrl}/upsertSession`, session);
    return data.session;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save session.');
  }
};

const itrsApi = { loadRecord, loadRecords, saveRecord, removeRecord, approveTrainingRequest };

export const getSessionDetails = async (id) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getSessionDetails`, { params: { id } });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load Session.');
  }
};

export default itrsApi;

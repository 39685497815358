import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
const useLoadEditorItem = (id, loadItem, onItemLoaded, item, pageConstants, version = 0) => {
  const { editorPageId } = pageConstants;
  const dispatch = useDispatch();
  const loader = useCallback(async () => {
    try {
      await loadItem(id);
    } catch (e) {
      dispatch(errorGrowl({ groupId: editorPageId, props: e.message }));
    }
  }, [loadItem, id, dispatch, editorPageId]);
  useEffect(() => {
    if (id) loader();
  }, [editorPageId, loadItem, version, id]);
  useEffect(() => {
    if (onItemLoaded) onItemLoaded(item);
  }, [item]);
};

export default useLoadEditorItem;

import get from 'lodash/get';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { navigateToPluginPage } from '../../store/rootReducer';
import collectionApi from '../api/collectionApi';
import { CollectionConstants } from '../definitions';

const useCollectionListActions = (pageConstants) => {
  const [isDuplicating, setIsDuplicating] = useState(false);
  const dispatch = useDispatch();
  const { typeName, pluginId, defaultPageId } = pageConstants;
  const { add, edit, remove, restore, publish, requestPending } = useItemListActions(
    collectionApi,
    CollectionConstants
  );
  const addOverride = useMemo(
    () => ({
      ...add,
      onClick: async () => {
        const newCollection = await collectionApi.loadRecord('new');
        dispatch(
          navigateToPluginPage([
            CollectionConstants.pluginId,
            CollectionConstants.settingsPageName,
            newCollection.id || 'new',
          ])
        );
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId]
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (c) => c?.isSubtenantNavigation || c?.baseCollectionType === 'itrs-courses',
      visible: (c) => get(c, 'status', '') !== 'removed',
    }),
    [typeName, pluginId, defaultPageId]
  );
  const restoreOverride = useMemo(
    () => ({ ...restore, visible: (context) => get(context, 'status', '') === 'removed' }),
    [typeName, pluginId, defaultPageId]
  );

  const duplicate = useMemo(
    () => ({
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      disabled: (c) => c?.baseCollectionType === 'itrs-courses',
      onClick: async (context, { reload }) => {
        setIsDuplicating(true); //eslint-disable-next-line
        await collectionApi.duplicateRecord(context.id);
        setIsDuplicating(false);
        reload();
      },
    }),
    []
  );

  const publishOverride = useMemo(() => ({ ...publish, useSnapshotCheck: true }), [publish]);

  return useMemo(
    () => ({
      add: addOverride,
      duplicate,
      edit,
      isDuplicating,
      publish: publishOverride,
      remove: removeOverride,
      requestPending,
      restore: restoreOverride,
    }),
    [addOverride, edit, removeOverride, restore, publish, requestPending, isDuplicating]
  );
};

export default useCollectionListActions;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { useCmsForm } from '../../form/hooks/useCmsForm';
import GrowlContainer from '../../growl/components/GrowlContainer';
import MultiSpinner from '../../spinner/components/MultiSpinner';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import { useSpinnerProvider } from '../../spinner/hooks/useSpinnerProvider';
const ItemEditorModal = (props) => {
  const { typeName, item, visible, onClose, FormComponent, itemApi, labelField, allowClean } =
    props;
  const [show, setShow] = useState(false);
  const [growlId, setGrowlId] = useState('');
  useEffect(() => {
    setGrowlId(`${Date.now()}`);
  }, []);
  useEffect(() => {
    setShow(visible);
  }, [visible]);
  const dispatch = useDispatch();
  const { spinnerProvider, spinners } = useSpinnerProvider();
  const form = useCmsForm(item);
  const { handleSubmit, formState } = form;
  const { isValid, isDirty } = formState;
  const { saveItem, requestPending } = useItemPersistence(itemApi);
  useSpinner(spinnerProvider, requestPending);
  const closeHandler = useCallback(
    (savedItem) => {
      if (onClose) onClose(savedItem);

      setShow(false);
    },
    [onClose]
  );
  const onSave = useCallback(
    async (item) => {
      try {
        const savedItem = await saveItem(item);
        closeHandler(savedItem);
      } catch (e) {
        dispatch(errorGrowl({ groupId: growlId, props: e.message }));
      }
    },
    [saveItem, dispatch, closeHandler]
  );
  const title = useMemo(() => {
    const label = item && item[labelField];
    return label ? `${typeName} Settings for ${label}` : `${typeName} Settings`;
  }, [item, typeName]);
  const allowSubmit = (isDirty || allowClean) && isValid;
  useSpinner(spinnerProvider, requestPending);
  return (
    <Modal show={show} animation={false}>
      <Modal.Header>
        <Modal.Title>
          {title}

          <span className="page-spinner">
            <MultiSpinner spinners={spinners} />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GrowlContainer groupId={growlId} />
        <FormComponent form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={!allowSubmit} onClick={handleSubmit(onSave)}>
          Save
        </Button>
        <Button variant="cancel" onClick={() => closeHandler(null)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemEditorModal;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToParentPage } from '../../../store/rootReducer';
const BreadcrumbLink = ({
  pluginId,
  parentPageId,
  parentPageName,
  parentPageTitle,
  parentPageArgs,
  style,
  parentElement,
}) => {
  const dispatch = useDispatch();
  const navigateToParent = useCallback(() => {
    dispatch(
      navigateToParentPage(
        parentPageArgs
          ? [pluginId, parentPageName, parentPageId, ...parentPageArgs]
          : [pluginId, parentPageName, parentPageId]
      )
    );
  }, [dispatch, parentPageId, parentPageName]);
  return (
    <div
      style={
        style || {
          marginTop: 25,
          marginLeft: -15,
          marginBottom: -15,
          cursor: 'pointer',
        }
      }
      className="header-link"
    >
      <a onClick={navigateToParent}>
        &lt; Back to {parentElement}
        {parentPageTitle}
      </a>
    </div>
  );
};

export default BreadcrumbLink;

import { EMPTY_COLL_REVISION } from '../definitions';
import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/collections/api';
const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getNavigationCollection`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { collection, count, offset } = data;
    result = {
      records: collection,
      numRecords: count,
      offset,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collections');
  }
  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') return EMPTY_COLL_REVISION;

  try {
    const { data } = await axios.get(`${baseUrl}/getCollection`, {
      params: {
        id,
      },
      timeout: 10_000,
    });
    return data.collection;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load collection.');
  }
};

const saveRecord = async (item) => {
  try {
    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    const { id, ...revisionProps } = item;
    formData.append('collectionProps', JSON.stringify({ ...revisionProps, id: item.menu_id }));

    const { data } = await axios.post(`${baseUrl}/saveCollection`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10_000,
    });
    return data.menu;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save menu.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeCollection`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove menu.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreCollection`,
      {
        id,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to restore menu.');
  }
};

const reorderRecords = async (parentId, itemIds) => {
  try {
    await axios.post(
      `${baseUrl}/reorderCollectionItems`,
      {
        itemIds,
        id: parentId,
      },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to reorder menu.');
  }
};

const publishRecord = async (id, itemIds = []) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishCollection`,
      {
        id,
        itemIds,
      },
      {
        timeout: 10_000,
      }
    );
    return data.menu;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to publish menu.');
  }
};

const resetSnapshot = async () => {
  try {
    const { data } = await axios.get(`/content-api/resetSnapshot`, {
      timeout: 10_000,
    });

    return data.status;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to reset snapshot');
  }
};

const navbarApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  reorderRecords,
  publishRecord,
  resetSnapshot,
};
export default navbarApi;

import { useCallback, useEffect } from 'react';
import useLoadListItems from '../../shared/cmsPage/hooks/useLoadListItems';
import { CohortConstants } from '../definitions';

const { editorPageId } = CohortConstants;

const useReload = ({ cohortId, loadItem, loadItems, loaderParams }) => {
  const reloadMembers = useLoadListItems(editorPageId, loadItems, loaderParams);

  const reloadPageData = useCallback(
    async (loaderParams) => {
      await Promise.all([loadItem(cohortId), reloadMembers(loaderParams)]);
    },
    [cohortId, reloadMembers]
  );
  const reload = useCallback(async () => {
    await reloadPageData(loaderParams);
  }, [loaderParams, reloadPageData]);

  useEffect(() => {
    if (cohortId) (async () => await loadItem(cohortId))();
  }, [cohortId, loadItem]);

  return { reload, reloadPageData };
};

export default useReload;

import get from 'lodash/get';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export default function useRows({ form, formRowsKey, handleFieldClick }) {
  const formRows = get(form?.getValues(), formRowsKey, []);
  const [localRows, setLocalRows] = useState([]);
  const [hasSetLocalRows, setHasSetLocalRows] = useState(false);
  const [hasManuallyEdited, setHasManuallyEdited] = useState(false);
  const rowsWithoutCursor = (rows, shouldSetLocal = false) => {
    const newRows = [...rows].map((row) => omit(row, ['setCursor']));
    if (shouldSetLocal) setLocalRows(newRows);
    return newRows;
  };

  useEffect(() => {
    if (!hasSetLocalRows && !hasManuallyEdited && formRows.length > 0) {
      setLocalRows(formRows.map((r) => ({ ...r, draggableId: uuid() })));
      setHasSetLocalRows(true);
    }
  }, [formRows, hasManuallyEdited, setLocalRows, hasSetLocalRows, setHasSetLocalRows]);

  const setRow = (idx, value, shouldDirty = true, shouldValidate = true) => {
    setHasManuallyEdited(true);
    form.setValue(`${formRowsKey}[${idx}]`, value, { shouldDirty, shouldValidate });
    setLocalRows([...localRows, { ...value, draggableId: uuid(), setCursor: true }]);
  };

  const setRows = (rows) => {
    setHasManuallyEdited(true);
    const newRows = rowsWithoutCursor(rows, true);
    const formRows = newRows.map((row) => omit(row, ['FIELD_ERRORS', 'draggableId']));
    form.setValue(formRowsKey, formRows, { shouldDirty: true, shouldValidate: true });
    deselect();
  };

  const deselect = useCallback(() => {
    if (localRows.some((r) => r.setCursor)) {
      rowsWithoutCursor(localRows, true);
    }
    return true || handleFieldClick(-1, '');
  }, [handleFieldClick, localRows, rowsWithoutCursor]);

  const setRowField = (idx, field, value) => {
    setHasManuallyEdited(true);
    const newRows = rowsWithoutCursor(localRows);
    const path = `[${idx}].${field}`;
    set(newRows, path, value);
    form.setValue(`${formRowsKey}${path}`, value, { shouldDirty: true, shouldValidate: true });
    setLocalRows(newRows);
    deselect();
  };

  return { setRowField, setRow, setRows, deselect, localRows };
}

import React, { useEffect, useMemo, useState } from 'react';
import { validationIncludesRequired } from '../formUtils';

export const parseDate = (dt) => {
  return dt && typeof dt === 'string' && dt.includes('T') ? dt.split('T')[0] : dt;
};

const FormDateOnly = ({
  defaultValue,
  fieldName,
  fieldWidth,
  formContext,
  formFieldOptions = {},
  label,
}) => {
  const [hasSet, setHasSet] = useState(false);

  const isRequired = validationIncludesRequired(formFieldOptions);
  const { register, formState, setValue, getValues } = formContext;
  const err = formState?.errors?.[fieldName]?.message;

  const def = useMemo(() => parseDate(defaultValue), [defaultValue]);
  const val = parseDate(formContext.getValues()?.[fieldName]);

  useEffect(() => {
    if (!hasSet && (val || def)) {
      setHasSet(true);
      setValue(fieldName, parseDate(val || def));
    }
  }, [def, hasSet, val, fieldName, setValue, getValues]);

  const { ref, ...rest } = register(fieldName, {
    setValueAs: parseDate,
    validate: (value) => value !== '' || 'Invalid date',
    ...formFieldOptions,
  });

  return (
    <div className={`form-group input-${fieldWidth}`}>
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <input
        defaultValue={def}
        type="date"
        {...rest}
        ref={ref}
        className={`form-control ${err ? 'is-invalid' : ''}`}
      />
      {err && <div className="invalid-feedback">{err}</div>}
    </div>
  );
};

export default FormDateOnly;

import React, { useState } from 'react';
import useArray from '../../shared/util/hooks/useArray';
import { CalendarEventConstants } from '../definitions';
const { defaultPageId, typeName } = CalendarEventConstants;
import useCalendarEventActions from '../hooks/useCalendarEventsActions';
import calendarEventApi from '../api/calendarEventsApi';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import classNames from 'classnames';

const CalendarEventsListPage = () => {
  const { add, remove } = useCalendarEventActions(CalendarEventConstants);
  const itemActions = useArray(remove);
  const columns = [
    {
      label: 'Event Name',
      valueField: 'title',
      sortable: true,
      renderer: (item) => <span>{item.title}</span>,
    },
    {
      label: `Date/Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
      valueField: 'startTime',
      sortable: true,
      initialSort: 'ASC',
      renderer: (item) => <Timestamp date={item.startTime} />,
    },

    {
      label: 'Groups',
      renderer: (item) => {
        const [showTip, setShowTip] = useState(false);
        const val = item.groups && item.groups.length;
        const className = classNames('fa', 'fa-solid', { 'fa-users-line': Boolean(val) });
        if (!val) return '';
        return (
          <span className={'tooltip-container'} onClick={() => setShowTip(!showTip)}>
            <span className={className} />
            {showTip && (
              <span className={'tooltip-text-click'}>
                {item?.groups?.map((userGroup) => (
                  <div key={userGroup.name}>{userGroup.name}</div>
                ))}
              </span>
            )}
          </span>
        );
      },
      sortable: false,
      valueField: 'groups',
    },
  ];
  return (
    <CmsPage title={'Calendar'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={[add]}
        itemActions={itemActions}
        columns={columns}
        itemApi={calendarEventApi}
        searchPlaceholder={'Search by Event Name'}
      />
    </CmsPage>
  );
};

export default CalendarEventsListPage;

import axios from 'axios';
import log from '../../logger';
import definitions from '../definitions';

export default {
  /**
   * Fetches application assets based on the provided parameters.
   *
   * @param {Object} params - The parameters to filter or specify the assets.
   * @param {string} [params.search] - The search term to filter assets (optional).
   * @param {string} [params.instance] - The instance of assets to retrieve (optional).
   * @param {string} [params.type] - The type of assets to retrieve (optional).
   * @param {string} [params.group] - The group of assets to retrieve (optional).
   * @param {string} [params.orderBy] - The order by field for the assets (optional).
   * @param {string} [params.orderDirection] - The order direction for the assets (optional).
   * @param {number} [params.limit] - Limit the number of assets returned (optional).
   * @param {number} [params.page] - The page number of assets to retrieve (optional).
   * @param {string} [params.status] - The status of assets to retrieve (optional).
   *
   * @returns {Promise<Object>} A promise that resolves with the fetched assets data.
   */
  async getAssets(params = {}) {
    return await this.request('get', `getAllAssets`, params);
  },

  /**
   * Retrieves an asset from the server by making an HTTP GET request.
   * If `forceText` is set to true, the response will be returned as plain text,
   * preventing automatic parsing of certain content types like JSON.
   *
   * @param {string} assetId - The unique identifier for the asset.
   * @param {number} [revisionId=-1] - The revision identifier of the asset. Defaults to -1, which usually indicates the latest revision.
   * @param {boolean} [forceText=true] - Whether to force the HTTP response to be treated as plain text.
   * @returns {Promise<any>} A promise that resolves to the response data of the HTTP request.
   */
  async getAsset(assetId, revisionId, forceText = true) {
    return await axios.get(`/preview/asset/${assetId}?revisionId=${revisionId}`, {
      transformResponse: forceText
        ? function (value) {
            return value;
          }
        : undefined,
    });
  },

  /**
   * Publish Content from Sandbox to Live
   * @param {[number]} assetIds
   * @returns
   */
  async publishContent(assetIds) {
    const result = this.request('post', 'publishContent', {
      fromInstance: 'sandbox',
      toInstance: 'live',
      assetIds,
    });
    return result;
  },

  /**
   * Proxy for publishContent to publish all content
   * @returns
   */
  async publishAllContent() {
    return await this.publishContent(null);
  },

  /**
   * Saves an asset to the server
   *
   * @param {string} contents
   * @param {object} asset
   * @param {boolean} overwrite
   * @returns
   */
  async saveAsset(contents, asset, overwrite) {
    const data = {
      asset,
      overwrite: !!overwrite,
      fileContents: contents,
    };
    return await this.request('post', 'saveAsset', data);
  },

  /**
   * Upload or create a new file on the server
   *
   * @param {*} file The file to upsert
   * @param {*} asset The asset to upsert
   * @param {*} overwrite Wether or not to overwrite the file
   * @returns
   */
  async upsertFile(file, asset, overwrite) {
    const formData = new FormData();
    formData.append('assetData', file);
    formData.append(
      'data',
      JSON.stringify({
        asset,
        overwrite,
      })
    );
    return await this.request('post', 'uploadAsset', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * Remove an asset from the server
   *
   * @param {object} asset The asset to remove
   * @returns
   */
  async removeAsset(asset) {
    return await this.request('post', 'removeOverride', {
      assetId: asset.id,
    });
  },

  /**
   * Restore an asset to a previous revision
   * @param {object} asset
   * @param {number} revisionId
   * @returns
   */
  async restoreAsset(asset, revisionId) {
    return await this.request('post', 'restoreRevision', {
      assetId: asset.id,
      revisionId,
    });
  },

  /**
   * Executes a HTTP request using Axios with the specified method, URL, parameters or data, and additional Axios configuration.
   * The method automatically handles the distinction between query parameters for GET requests and request body data for POST requests.
   * It also cleans the URL to prevent issues with leading slashes.
   *
   * @param {string} method - The HTTP method to use for the request (e.g., 'get', 'post').
   * @param {string} url - The endpoint URL to which the request is sent. Leading slashes will be removed if present.
   * @param {Object} paramsOrData - For GET requests, this object contains query parameters. For POST and other methods, it contains data to be sent in the request body.
   * @param {Object} [config={}] - Optional Axios configuration settings that can be passed to customize the request further.
   * @returns {Promise<Object>} A promise that resolves with the response data from the request.
   * @throws {Error} Throws an error if the request fails.
   */

  async request(method, url, paramsOrData = {}, config = {}) {
    try {
      // Remove leading forward slash from the url if present
      url = url.replace(/^\//, '');

      // Determine if the request is a GET request. If so, use params; otherwise, use data.
      const isGetRequest = method.toLowerCase() === 'get';

      // Construct the request configuration.
      const requestConfig = {
        method,
        url: `${definitions.baseUrl}/${url}`,
        ...config, // Spread any additional config passed to the method.
      };

      // Conditionally add params or data to the request configuration based on the method.
      if (isGetRequest) {
        requestConfig.params = paramsOrData;
      } else {
        requestConfig.data = paramsOrData;
      }

      // Make the request with axios.
      const { data } = await axios.request(requestConfig);
      return data;
    } catch (e) {
      log.error(e);
      throw new Error('Failed to make request');
    }
  },
};

import { Box, TableCell, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useTableEditorContext } from './TableContext';
import _ from 'lodash';
const TableCellTextField = ({
  cellAlign = 'left',
  cellWidth,
  field,
  idx,
  tableCellProps = {},
  textFieldProps = {},
  inputType,
  viewOnly,
  error,
  setCursor,
  value,
  colIndex,
  defaultViewValue,
  hidden = false,
}) => {
  const { editField, handleFieldClick, setRowField, editIdx, canDrag, fontSize } =
    useTableEditorContext();
  const [isLocalEditing, setLocalIsEditing] = useState(false);
  const inputRef = useRef(null);
  const isEditing = useMemo(
    () => editIdx === idx && editField === field,
    [editField, editIdx, idx, field]
  );
  useEffect(() => {
    if (setCursor && inputRef.current && colIndex === 0) {
      inputRef.current.focus();
    }
  }, [setCursor, colIndex, inputRef.current]);

  useEffect(() => {
    if (isLocalEditing && !isEditing) {
      setLocalIsEditing(false);
    } else if (isEditing && !isLocalEditing) {
      setLocalIsEditing(true);
    }
  }, [isLocalEditing, setLocalIsEditing, isEditing]);

  const handleOnChange = useCallback(
    (e) => {
      const val = e.target.value;
      if (inputType === 'number' && val !== '' && _.isNumber(min) && Number(val) < min) {
        e.preventDefault();
        return;
      }
      setRowField(idx, field, val);
    },
    [setRowField, idx, field, inputType, min]
  );
  const min = textFieldProps?.inputProps?.min;

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => handleFieldClick(idx, field)}
      align={cellAlign}
      sx={{
        borderBottom: '1px solid #e0e0e0',
        margin: 0,
        width: cellWidth,
        maxWidth: cellWidth,
        fontSize,
        wordWrap: 'break-word',
        verticalAlign: 'center',
        position: 'relative',
        '&:hover': { ...(!viewOnly && { backgroundColor: 'rgba(0, 0, 0, 0.04)' }) },
        '& .MuiInputBase-root': { verticalAlign: 'middle', border: 'none' },
        '& .MuiInputBase-body': { padding: '8px' },
        '& .MuiInputBase-input': { padding: '0', '&::placeholder': { lineHeight: 'inherit' } },
        ...(hidden && { display: 'none' }),
      }}
      {...tableCellProps}
    >
      <Box sx={{ display: 'flex', height: '100%' }}>
        {canDrag && colIndex === 0 && (
          <Box sx={{ display: 'flex', mr: 2, alignItems: 'center', justifyContent: 'center' }}>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
            <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
          </Box>
        )}
        <TextField
          inputRef={inputRef}
          size="small"
          variant="standard"
          margin="dense"
          sx={{
            '& .MuiInput-root': { borderBottom: 'none', fontSize },
            '& .MuiInputBase-root': { fontSize },
            '& .MuiInputBase-input': {
              fontSize,
              color: 'inherit',
              ...((viewOnly || inputType !== 'number') && { px: '.5rem' }),
            },
            '& .MuiInput-underline:before': { borderBottom: 'none' },
            '& .MuiInput-underline:after': { borderBottom: viewOnly ? 'none' : '1px solid black' },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
          }}
          defaultValue={
            value === undefined && defaultViewValue !== undefined ? defaultViewValue : value
          }
          type={inputType}
          autoFocus
          helperText={error?.message}
          error={!!error}
          onKeyDown={(e) => {
            if (inputType === 'number' && e.key === '-' && min === 0) {
              e.preventDefault();
            }
          }}
          onChange={handleOnChange}
          fullWidth
          InputProps={{
            readOnly: viewOnly,
          }}
          {...textFieldProps}
        />
      </Box>
    </TableCell>
  );
};

export default TableCellTextField;

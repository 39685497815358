(function () {
  angular.module('edit-group-members', ['smart-table']).controller('EditGroupMembersCtrl', [
    '$scope',
    '$log',
    '$location',
    '$timeout',
    'growl',
    'groupApiService',
    'userApiService',
    'confirmDialog',
    function (
      $scope,
      $log,
      $location,
      $timeout,
      growl,
      groupApiService,
      userApiService,
      confirmDialog
    ) {
      const urlParams = $location.search();
      $scope.groupName = urlParams.name;
      $scope.isLoading = true;
      $scope.modifiedUsers = [];
      $scope.showGroup = 'Show Members';
      function getLogic(name, id, inGroup) {
        const condition = inGroup ? '=' : '!=';
        return {
          operator: 'AND',
          rules: [
            {
              field: 'Group',
              data: [
                {
                  label: name,
                  id,
                  logicParams: {
                    condition,
                    fieldLogicType: 'memberStatus',
                  },
                  hasSetDefaultValue: true,
                },
              ],
              condition,
            },
          ],
        };
      }
      $scope.displayedUsers = [];
      function getUsers(tableState) {
        const pagination = tableState.pagination;
        const start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        const number = pagination.number || 10; // Number of entries showed per page.
        if ($scope.showGroup === 'Show Members') {
          const logic = getLogic(urlParams.name, urlParams.id, true);
          userApiService
            .getUsersFromLogic(logic, start, number, tableState, $scope.limitToPushEndpoints)
            .success(function (data) {
              $scope.allUsers = data.appUsers;
              $scope.checkedUsers = $scope.allUsers.map((i) => i.id);
              $scope.displayedUsers = [$scope.allUsers].flat();
              setNumberOfPages(data.count, start);
              $scope.isLoading = false;
            })
            .error(function (data) {
              growl.error('Failed to load users.');
              $log.debug('Failed to get users from logic. Response: ', data);
            });
        } else {
          const logic = getLogic(urlParams.name, urlParams.id, false);
          userApiService
            .getUsersFromLogic(logic, start, number, tableState, $scope.limitToPushEndpoints)
            .success(function (data) {
              $scope.allUsers = data.appUsers;
              $scope.checkedUsers = [];
              $scope.displayedUsers = [$scope.allUsers].flat();
              setNumberOfPages(data.count, start);
              $scope.isLoading = false;
            })
            .error(function (data) {
              growl.error('Failed to load users.');
              $log.debug('Failed to get users from logic. Response: ', data);
            });
        }
      }

      $scope.tableUsers = $scope.allUsers;
      $scope.sortColumn = '';
      $scope.reverseSort = false;

      $scope.sortData = function (column) {
        $scope.reverseSort = $scope.sortColumn == column ? !$scope.reverseSort : false;
        $scope.sortColumn = column;
      };

      $scope.getSortClass = function (column) {
        if ($scope.sortColumn == column) {
          return $scope.reverseSort ? 'arrow-down' : 'arrow-up';
        }
        return '';
      };

      function setNumberOfPages(count, start) {
        $scope.userCount = count;
        const numberOfPages = (count - (count % 10)) / 10 + 1;
        if ($scope.tableState) {
          $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
        }
        $timeout(function () {
          $scope.tableState.pagination.start = start <= count ? start : 0;
        });
      }
      $scope.callServer = function (tableState) {
        $scope.isLoading = true;
        $timeout(function () {
          $scope.allUsers = [];
          $scope.displayedGroups = [];
        });
        $scope.tableState = tableState;
        getUsers(tableState);
      };

      $scope.addOrRemoveMember = function (id) {
        const index = $scope.checkedUsers.indexOf(id);
        if (index > -1) {
          $scope.checkedUsers.splice(index, 1);
        } else {
          $scope.checkedUsers.push(id);
        }
      };

      $scope.buildMemberGroup = function () {
        updateMemberGroup('add');
        growl.success('User(s) Added');
      };

      $scope.removeMemberGroup = function () {
        updateMemberGroup('remove');
        growl.success('User(s) Removed');
      };

      $scope.cancelUserGroup = function (users) {
        users.splice(0, users.length);
      };

      function updateMemberGroup(action) {
        Promise.all(
          $scope.checkedUsers.map(function (user) {
            return groupApiService
              .updateAppUserGroups(urlParams.id, urlParams.key, user, action)
              .success(function (data) {
                if (data.status === 'error') growl.error(data.error);
              })
              .error(function (err) {
                growl.error(err.error || 'User upload failed.  Please try again later.');
              });
          })
        ).then(() => getUsers($scope.tableState));
      }

      $scope.cancel = function () {
        $location.path('/main/manage-groups').search({});
      };

      $scope.updateList = function () {
        $scope.callServer($scope.tableState);
      };
      function getFileName() {
        const now = new Date();
        const datetime = `${now.getFullYear()}_${
          now.getMonth() + 1
        }_${now.getDate()}__${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        return `${$scope.groupName}-${datetime}.csv`;
      }
      $scope.exportClicked = function () {
        confirmDialog({
          title: 'Confirmation',
          body: `You are about to export this group. Do you want to continue`,
          confirmText: 'Export',
          cancelText: 'Cancel',
        }).result.then(function () {
          $scope.buildExportCsv();
        });
      };
      $scope.buildExportCsv = function () {
        //check for custom fields
        groupApiService.getUsersFromGroup(urlParams.id).then(function (result) {
          const customFieldHeaders = $scope.customFieldHeaders || [];
          let csv = '';
          const groupUsers = [result.data.data].flat();
          const headers = ['First Name', 'Last Name', 'Email'].concat(customFieldHeaders);
          csv += `${headers.join(',')}\n`;
          groupUsers.forEach((user) => {
            const row = [
              user.firstName,
              user.lastName,
              user.email,
              ...customFieldHeaders.map(
                (field) => (user.customFields && user.customFields[field.toLowerCase()]) || ''
              ),
            ];
            csv += `${row.join(',')}\n`;
          });
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const csvUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = csvUrl;
          a.download = getFileName();
          document.body.append(a);
          a.click();
          window.URL.revokeObjectURL(csvUrl);
        });
      };
    },
  ]);
})();

import axios from 'axios';
import log from '../../logger';

export const upsertKmlFile = async (mapName, file) => {
  const formData = new FormData();
  let assetId = `newMapsKmlFile-${mapName}-${file.name}`;
  if (!assetId.endsWith('.kml')) assetId += '.kml';
  formData.append('assetData', file, assetId);

  try {
    const { data } = await axios.post('/plugins/new-maps/api/uploadKmlFile', formData, {
      params: { mapName },
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 10_000,
    });
    log.log('File was added successfully. Response:', data);
    return data;
  } catch (error) {
    log.error('Error uploading file:', error);
    throw error;
  }
};

export const upsertInfo = async (mapId, info) => {
  try {
    if (!mapId || !info) {
      log.error(`MapId: ${mapId}, Info: ${info}: Must Be Provided`);
      return undefined;
    }
    const { data } = await axios.post(
      '/plugins/new-maps/api/upsertInfo',
      { mapId, info },
      { timeout: 10_000 }
    );
    return data;
  } catch (error) {
    log.error('Error upserting info:', error);
    throw error;
  }
};

export const upsertMarkerInfo = async (mapId, info) => {
  try {
    if (!mapId || !info) {
      log.error(`MapId: ${mapId}, MarkerInfo: ${info}: Must Be Provided`);
      return undefined;
    }
    const { data } = await axios.post(
      '/plugins/new-maps/api/upsertMarkerInfo',
      { mapId, info },
      { timeout: 10_000 }
    );
    return data;
  } catch (error) {
    log.error('Error upserting info:', error);
    throw error;
  }
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CreateableSelect from '../../shared/form/components/CreatableSelect';
import FormImage from '../../shared/form/components/FormImage';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import FormSwitch from '../../shared/form/components/FormSwitch';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import LinkFinderInline from '../../shared/form/components/LinkFinderInline';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import collectionApi, { loadCategories } from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionEditorConstants } from '../definitions';

const CollectionItemEditor = ({ itemId, collectionId, growlId, onItemChange, changeTitle }) => {
  const { spinnerProvider } = useContext(CmsPageContext);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    collectionItemApi,
    collectionId
  );

  const form = useCmsForm(item);
  const { setValue, getValues, watch } = form;
  const actions = useItemEditActions(saveItem, form, CollectionEditorConstants, collectionId);
  const defaultPage = 'cms://default';

  const [imageBlock, setImageBlock] = useState(true);
  const [showTimeInputs, setShowTimeInputs] = useState(true);
  const [linkUsed, setLinkUsed] = useState(false);
  const [detailsUsed, setDetailsUsed] = useState(false);
  const [lockedUsed, setLockedUsed] = useState(false);
  const [currentCollectinType, setCurrentCollectinType] = useState('');

  const {
    item: collection,
    loadItem: loadCollection,
    requestPending: collectionRequestPending,
  } = useItemPersistence(collectionApi);

  const watchTimed = watch('timed');
  const watchLink = watch('useLink');

  const watchDetails = watch('useDetails');
  const disLabel = watch('displayLabel');
  const locked = watch('locked');
  useEffect(() => {
    if (item) {
      setLockedUsed(!!locked);
      setValue('lockedText', locked ? item?.lockedText || 'Locked' : '');
    }
  }, [locked, item]);
  useEffect(() => {
    if (item) {
      if (
        typeof item.objectReference === 'string' &&
        item.objectDetail != null &&
        item.objectDetail.plugin != 'default'
      ) {
        setValue('displayLabel', item.objectDetail.displayName || item.objectDetail.plugin);
      }
      if (typeof item.objectReference === 'string' && item.objectDetail == null) {
        setValue('displayLabel', 'cms://default');
        setValue('objectReference', 'cms://default');
      }
    }
  }, [item]);

  useEffect(() => {
    if (item && typeof disLabel === 'object') {
      setValue('displayLabel', disLabel.targetLabel);
      setValue('objectReference', disLabel.returnVal);
    }
  }, [disLabel]);

  useEffect(() => {
    if (collectionId) (async () => await loadCollection(collectionId))();
  }, [collectionId, loadCollection]);

  useEffect(() => {
    if (collection) {
      setCurrentCollectinType(collection.type);
      changeTitle(collection.name);
      setImageBlock(collection.useImage);
      const timedLoadValue = getValues('timed');
      if (timedLoadValue == null) setValue('timed', 'untimed');
    }
  }, [collection]);

  useEffect(() => {
    if (item) setShowTimeInputs(watchTimed === 'timed');
  }, [watchTimed, item]);

  useEffect(() => {
    if (item) {
      setLinkUsed(!!watchLink);
      setValue('actionButtonText', watchLink ? item.actionButtonText || 'View' : '');
    }
  }, [watchLink, item]);

  useEffect(() => {
    if (item) {
      setDetailsUsed(!!watchDetails);
      setValue('infoButtonText', watchDetails ? item.infoButtonText || 'Details' : '');
    }
  }, [watchDetails, item]);

  useSpinner(spinnerProvider, requestPending || collectionRequestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, CollectionEditorConstants);
  const getCategories = useCallback(async () => {
    return loadCategories(collectionId);
  }, [collectionId]);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const setRatio = currentCollectinType === 'icons' ? 300 / 300 : 325 / 200;

  return (
    <>
      <div>
        <form role="form" className="form-control-margin-1rem">
          <div className="col-12">
            <FormInput
              label="Name"
              fieldName="label"
              formContext={form}
              formFieldOptions={{ required: 'This field is required.' }}
            />
          </div>

          <div className="col-sm-12">
            <div className="col col-lg-2 col-md-12 col-sm-12 mw24m no-padding-left">
              <CreateableSelect
                label="Timed/Untimed *"
                fieldName="timed"
                fieldWidth="long"
                formContext={form}
                isClearable={false}
                optionsLoader={() =>
                  Promise.resolve([
                    { label: 'Untimed', value: 'untimed' },
                    { label: 'Timed', value: 'timed' },
                  ])
                }
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormInput
                label={`Start (${timezone})`}
                fieldName="startTime"
                fieldWidth="long"
                type="datetime-local"
                formContext={form}
                formFieldOptions={{ required: showTimeInputs && 'This field is required.' }}
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormInput
                label={`End (${timezone})`}
                fieldName="endTime"
                fieldWidth="long"
                type="datetime-local"
                formContext={form}
                formFieldOptions={{ required: showTimeInputs && 'This field is required.' }}
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m top-margin-28 no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormSwitch
                switchLabel="Display Time"
                fieldName="displayTime"
                selectedName="displayTime"
                formContext={form}
              />
            </div>
          </div>

          <div className="col col-lg-5"></div>
          <div
            className="col col-sm-12 no-padding-left"
            style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
          >
            <div style={{ display: 'inline-block' }}>
              <FormSwitch switchLabel="Link" fieldName="useLink" formContext={form} />
            </div>

            <div style={{ display: 'inline-block', marginLeft: 20 }}>
              <FormInput
                fieldName="actionButtonText"
                formContext={form}
                placeholder="View"
                fieldWidth="tiny"
                className="neg-top-margin"
                disabled={!linkUsed}
              />
            </div>

            {linkUsed && (
              <div className="left-margin-12">
                <LinkFinderInline
                  formContext={form}
                  label="Link Item To:"
                  fieldName="displayLabel"
                  fieldWidth="short"
                  defaultValue={defaultPage}
                />
                {showTimeInputs && (
                  <div>
                    <FormSwitch
                      switchLabel="Show Link outside active time"
                      fieldName="alwaysShowLink"
                      formContext={form}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="col col-lg-12 col-md-12 col-sm-12 no-padding-left"
            style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
          >
            <div style={{ display: 'inline-block' }}>
              <FormSwitch switchLabel="Details Page" fieldName="useDetails" formContext={form} />
            </div>

            <div style={{ display: 'inline-block', marginLeft: 20 }}>
              <FormInput
                fieldName="infoButtonText"
                formContext={form}
                placeholder="Details"
                fieldWidth="tiny"
                className="neg-top-margin"
                disabled={!detailsUsed}
              />
            </div>
          </div>

          {watchDetails && (
            <div className="col col-lg-6 col-md-6 col-sm-6 no-padding-left">
              <FormWysiwyg
                tag="textarea"
                label=""
                formContext={form}
                fieldName="infoText"
                growlId={growlId}
              />
            </div>
          )}

          {imageBlock && (
            <div className="col col-lg-12 col-md-12 col-sm-12 mh320px no-padding-left">
              <FormImage
                label="Display Image"
                uploadButtonText="Upload"
                existingAssetIdField="tileAssetId"
                fieldName="tileAssetUpload"
                formContext={form}
                defaultAssetId="collection-item-default.png"
                useCropper={true}
                croppedRatio={setRatio}
                dropzoneSettings={{
                  maxSize: 5 * 1024 * 1024, // 5MB
                }}
              />
            </div>
          )}

          <div className="col col-lg-12 col-md-12 col-sm-12 no-padding-left">
            <FormInputTextArea
              className="col col-sm-5 no-padding-left"
              placeholder="Description of collection"
              label="Description"
              fieldWidth="long"
              fieldName="description"
              formContext={form}
            />

            <div className="col col-sm-12 col-md-6  col-lg-6 no-padding-left">
              <CreateableSelect
                label="Category"
                fieldName="category"
                fieldWidth="long"
                formContext={form}
                optionsLoader={getCategories}
              />
            </div>

            <div className="col col-sm-3">
              <AudienceSelector
                selectTextOnNewLine={false}
                className="pa-2"
                formContext={form}
                label="Available To "
                fieldName="condition"
                growlId={growlId}
                selectFieldText="Select"
              />
            </div>
            <div
              className="col col-sm-12 no-padding-left"
              style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
            >
              <div style={{ display: 'inline-block' }}>
                <FormSwitch switchLabel="Display As Locked" fieldName="locked" formContext={form} />
              </div>

              <div style={{ display: 'inline-block', marginLeft: 20 }}>
                <FormInput
                  fieldName="lockedText"
                  formContext={form}
                  placeholder="Locked"
                  className="neg-top-margin"
                  disabled={!lockedUsed}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col col-lg-12 col-md-12 col-sm-12">
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </div>
    </>
  );
};

export default CollectionItemEditor;

import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CmsContext } from '../../context/definitions';
import useConfirmPublish from '../../shared/cmsPage/hooks/useConfirmPublish';
import useArray from '../../shared/util/hooks/useArray';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import { MenuEditorConstants, MenuPublishPageConstants } from '../definitions';
export default function useMenuItemPublishActions({
  selectedItems,
  item,
  publishItem,
  menuId,
  returnPageName,
  successGrowlId,
}) {
  const { confirm } = useContext(CmsContext);
  const dispatch = useDispatch();
  const confirmPublish = useConfirmPublish('menu');
  const publish = useMemo(
    () => ({
      label: `Publish Now`,
      onClick: async () => {
        const confirmation = await confirmPublish();
        if (confirmation) {
          try {
            await publishItem(menuId, selectedItems);
            dispatch(navigateToPluginPage([MenuEditorConstants.pluginId, returnPageName, menuId]));
            dispatch(
              successGrowl({
                groupId: successGrowlId,
                props: `The menu has been successfully published!`,
              })
            );
          } catch (e) {
            dispatch(
              errorGrowl({
                groupId: MenuEditorConstants.defaultPageId,
                props: e.message,
              })
            );
          }
        }
      },
    }),
    [
      MenuEditorConstants,
      confirm,
      confirmPublish,
      dispatch,
      item,
      menuId,
      publishItem,
      returnPageName,
      selectedItems,
      successGrowlId,
    ]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      onClick: () => {
        dispatch(navigateToPluginPage([MenuPublishPageConstants.pluginId, returnPageName, menuId]));
      },
    }),
    [dispatch, MenuEditorConstants, menuId, returnPageName]
  );
  const toolbarActions = useArray(publish, cancel);
  return {
    toolbarActions,
  };
}
